$edit-user-profile-upper-height: 500px;
.edit-user-profile-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  height: $edit-user-profile-upper-height;

  @include bg-picture('abp-bg.png');
}

.edit-user-profile-upper {
  width: 100%;
  height: $edit-user-profile-upper-height;
  padding: 330px 0 0 0px;
  text-align: center;

  p {
    font-family: "PT Sans", sans-serif;
    color: $default;

    &.highlight, .highlight {
      color: $primary;
    }

    &.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
      &:last-child {
        margin: 0 0 70px 0;
      }
    }

    &.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
      &:last-child {
        margin: 0 0 70px 0;
      }
    }

  }
}

.edit-user-profile {

  &.sections {

    padding: 40px 30px 100px 120px;

    a {
      color: $info;
    }

    form {
      overflow: hidden;
    }

    .row {
      //padding: 0px 0px 0px 120px;

      p {
        font-size: 18px;
        line-height: 1.4;
        letter-spacing:0.2px;
        //padding: 0px 0px 0px 120px;

        &.title {
          font-size: 32px;
          line-height: 1.4;
          color: $info;
          font-weight: bold;

          &.small {
            font-size: 28px;
            line-height: 1.4;
            color: $black;
            font-weight: bold;
            margin-bottom: 20px;
          }

        }

        &.subtitle {
          font-weight: bold;
          padding: 0px 0px 20px 0px;
        }
      }

      &.text {
        padding: 60px 0px 20px 0px;
        display: table;

        .lead {
          font-size: 18px;
          font-weight: bold;
        }

        .more-or-less {
          //border: 1px solid #cccccc;
          padding: 30px 30px 30px 30px;
          display: table-cell;
          float: none;

          .title {
            font-size: 32px;
            line-height: 40px;
            color: $info;
            font-weight: bold;

            &.small {
              font-size: 28px;
              line-height: 40px;
              color: $black;
              font-weight: bold;
              margin-bottom: 0px;
            }

            &.narrow {
              font-size: 28px;
              line-height: 40px;
              color: $info;
              font-weight: bold;
              margin-bottom: 20px;
            }

          }

          .icon-calendar-plus {
            width: 120px;
            height: 100px;
            background-size:120px 100px;
            margin-bottom: 40px;
            @include bg-image('app/icon-calendar-plus.png');
          }
          .icon-calendar-minus {
            width: 120px;
            height: 100px;
            background-size:120px 100px;
            margin-bottom: 40px;
            @include bg-image('app/icon-calendar-minus.png');
          }

        }



      }
    }
  }
  .table-col {
    display: table-cell;
    float:none;
    border: 1px solid #cccccc;
  }
  .table-spacer {
    display: table-cell;
    width:1%;
    height: 1px;
  }
}