#about_us {
  .header-page {
    h1 {
      &:before {
        @include bg-picture('icon-aboutus.png');
      }
      a {
        color: #fff;
      }
    }
    .sub-title-header {
      h1 {
        padding-left: 0;
        &:before {
          background-image: none;
        }
      }
    }
    &.header-do-it-yourself {
      h1 {
        &:before {
          @include bg-picture('icon-do-it-yourself.png');
        }
      }
    }
    &.header-for-family-and-friends {
      h1 {
        &:before {
          @include bg-picture('icon-for-family-and-friends.png');
        }
      }
    }
  }
  .box-content-page {
    .title-about-us {
      color: #177bbe;
      font-size: 28px;
      @include PT_San();
      margin-bottom: 30px;
      font-weight: 700;
    }
    .icon-image-about-us {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      padding: 50px 10px 20px 10px;
      margin-bottom: 50px;
    }
    .sub-title-about-us {
      color: #000;
      font-size: 20px;
      font-weight: 700;
      @include lato();
      margin-bottom: 30px;
    }
    .list-sub-image {
      margin-bottom: 30px;
      @media (max-width: 767px) {
        .text-center {
          text-align: left;
        }
      }
    }
    .border-box-right {
      border-right: 1px solid #e2e2e2;
    }
    @media (max-width: 991px) {
      .border-box-right {
        border-right: none;
      }
    }
    @media (max-width: 767px) {
      .title-about-us {
        margin-bottom: 10px;
      }
    }
  }
  .box-socical-like {
    float: right;

  }
  @media only screen and (max-width: 767px) {
    .box-socical-like {
      float: none;
    }
  }
}

#contact_us {
  .header-page {
    h1 {
      &:before {
        @include bg-picture('icon-contact-us.png');
      }
    }
  }
  .box-form-contact {
    h2 {
      color: #177bbe;
      font-size: 28px;
      @include PT_San();
      margin-bottom: 40px;
      font-weight: bold;

    }
  }
  .box-form-success {
    display: none;
    h2 {
      color: #177bbe;
      font-size: 28px;
      @include PT_San();
      margin-bottom: 40px;
      font-weight: bold;
    }
    p {
      color: #6A6E72;
      font-size: 16px;
      @include PT_San();
      font-weight: normal;
    }
  }
  .title-contact-us {
    color: #177bbe;
    font-size: 28px;
    @include PT_San();
    margin-bottom: 50px;
    font-weight: bold;
  }
  .sub-title-contact-us {
    @include lato_bold();
    font-size: 20px;
    color: #000;
    margin-bottom: 35px;
    font-weight: bold;
  }
  .form-contact-us {
    @media (max-width: 767px) {
      .box-country-contact {
        padding-bottom: 15px;
      }
    }
    label {
      @include lato();
      font-size: 16px;
      font-weight: 700;
      color: #000;
      margin-bottom: 15px;
      &.error {
        position: relative;
        bottom: -10px;
        color: #f00;
        padding-left: 25px;
        font-weight: normal;
        font-size: 15px;
        &#contact_country-selectized-error {
          position: absolute;
          bottom: -45px;
          left: 0;
        }
        .for-default, .for-myaccount {
          display: none;
        }
        .for-account {
          display: block;
        }
        &:before {
          content: "\00d7";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #ff0000;
          color: #ffffff;
          font-size: 20px;
          line-height: 18px;
          text-align: center;
          font-weight: normal;
        }
      }
    }
    input, textarea {
      border-radius: 0;
      resize: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
    .selectize-input {
      border: 1px solid #d0d0d0;
      padding: 8px 8px;
      display: inline-block;
      width: 100%;
      overflow: initial;
      position: relative;
      z-index: 1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
    }
    .submit-form-contact-us {
      @include lato();
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      background-color: #177bbe;
      border: none;
    }
  }
  .box-follow-us {
    background-color: #f2f3f3;
    padding: 35px 30px;
    .title-follow-us {
      @include lato();
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      color: #000;
    }
    .content-follow-us {
      color: #848789;
      font-size: 16px;
      @include lato();
      margin-bottom: 40px;
      text-align: center;
    }
    .follow-social {
      width: 200px;
      margin: 0 auto;
      .social-fb {
        a {
          color: #4d6fa5;
          font-size: 30px;
        }
      }
      .social-twitter {
        a {
          color: #1db6ea;
          font-size: 30px;
        }
      }
      .social-youtube {
        a {
          color: #c73636;
          font-size: 30px;
        }
      }
    }
    @media only screen and (max-width: 991px) {

    }
  }
}

.term_privacy {
  .box-main-content-page {
    .main-content-with-collapse {
      .content {
        h2{
          font-family: "PT Sans", sans-serif;
          color: #177bbe;
          font-weight: bold;
          font-size: 28px;
          margin-bottom: 40px;
        }
        h3{
          font-family: "Lato-Bold";
          font-size: 20px;
          color: #292f35;
        }
        h4 {
          font-family: "Lato-Black";
          font-size: 16px;
        }
        strong {
          color: #000;
        }
      }
    }
  }
}