/* msg center */
@-webkit-keyframes pulsate {
  30% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  35% {
    opacity: 1.0;
  }
  40% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

.al-msg-center {
  float: right;
  padding: 0;
  list-style: none;
  margin: 13px 47px 0 0;

  li {
    list-style: none;
    float: left;
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
    & > a {
      color: $sidebar-text;
      text-decoration: none;
      font-size: 13px;
      position: relative;
      span {
        display: inline-block;
        min-width: 10px;
        padding: 2px 4px 2px 4px;
        color: $sidebar-text;
        vertical-align: baseline;
        white-space: nowrap;
        text-align: center;
        border-radius: 13px;
        text-shadow: none;
        line-height: 11px;
        background-color: $danger;
        position: absolute;
        top: -5px;
        right: -14px;
        font-size: 11px;
      }
      .notification-ring {
        border: 1px solid $danger;
        border-radius: 100px;
        height: 40px;
        width: 40px;
        position: absolute;
        top: -18px;
        right: -27px;
        animation: pulsate 8s ease-out;
        animation-iteration-count: infinite;
        opacity: 0.0
      }

      &:hover {
        color: $danger;
        &.msg {
          color: $primary;
        }
      }
      &.msg {
        span {
          background-color: $primary;
        }
        .notification-ring {
          border-color: $primary;
        }
      }
    }
    &.open {
      & > a {
        color: $danger;
        &.msg {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: $resXXS) {
  .al-msg-center {
    margin-right: 20px;
    li {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.msg-block-header {
  display: inline-block;
  padding: 0;
  font-size: 13px;
  margin: 0 0 0 6px;
}