.main-content-with-collapse {
  .list-current-post-FAQS {
    border-bottom: 1px solid #e3e2e2;
    .content-collapse {
      > a {
        border-bottom: 0;
        margin-bottom: 0;
      }
      .box-item-socail{
        border-bottom: 1px solid #e3e2e2;
        margin-bottom: 20px;
      }
      .collapse{
       &.in{
         margin-bottom: 20px;
       }
      }
    }
  }
  .content-collapse {
    > a {
      display: inline-block;
      width: 100%;
      padding: 15px 0 20px;
      border-bottom: 1px solid #e3e2e2;
      margin-bottom: 20px;
      color: #177bbe;
      font-size: 20px;
      position: relative;
      padding-right: 60px;
      font-family: "PT Sans";
      .button-show-content {
        color: $color-strong-blue;
        border: 1px solid $color-strong-blue;
        height: 26px;
        width: 26px;
        padding: 0;
        position: relative;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 28px;
        transform: translateY(-50%);
        &:before {
          content: "";
          width: 15px;
          height: 2px;
          background-color: $color-strong-blue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:after {
          content: "";
          height: 15px;
          width: 2px;
          background-color: $color-strong-blue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);;
        }
      }
      &[aria-expanded="true"] {
        .button-show-content {
          &:after {
            display: none;
          }
        }
      }
      &.view-detail {
        padding: 0;
        display: inline;
        border: none;
        font-size: 16px;
        float: left;
      }
    }
    .box-socical-like {
      a {
        margin-bottom: 5px;
      }
    }
    .collapse {
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    ul {
      list-style-type: disc;
      margin-left: 0;
      line-height: 26px;
      padding-left: 17px;
    }
    ol {
      line-height: 26px;
      padding-left: 17px;
    }
    .box-table-content {
      table {
        margin-bottom: 20px;
      }
    }
  }
  .content {
    h1 {
      color: #292F35;
      font-family: "PT Sans", sans-serif;
      line-height: 34px;
      font-weight: bold;
      font-size: 20px;
      strong {
        font-weight: bold;
      }
    }
    h2 {
      color: #292F35;
      font-family: "Lato", sans-serif;
      line-height: 34px;
      font-weight: 900;
      font-size: 16px;
      strong {
        font-weight: 900;
      }
    }
    h3, h4, h5, h6 {
      font-size: 16px;
      font-weight: normal;
    }
    p {
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    ul {
      list-style-type: disc;
      margin-left: 0;
      line-height: 26px;
      padding-left: 17px;
    }
    ol {
      line-height: 26px;
    }
  }
  h3 {
    margin-bottom: 20px;
    color: #292F35;
    font-size: 20px;
    font-weight: normal;
  }
}