$help-browse-upper-height: 500px;
.help-browse-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  /*height: $help-browse-upper-height;*/
  @include bg-picture('blue-bg.png');
}

.help-browse-upper {
  width: 100%;
  /*height: $help-browse-upper-height;*/
  /*padding: 280px 0 0 0px;*/
  text-align: center;

  p {
    font-family: "PT Sans", sans-serif;
    color: $default;

    &.highlight, .highlight {
      color: $primary;
    }

    &.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
    }

    &.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
      &:last-child {
        margin: 0 0 70px 0;
      }
    }

  }
  .icon {
    @include bg-picture('icon-faq.png');
  }
}

.help-browse {
  &.sections {

    //padding: 40px 30px 100px 120px;
    padding: 80px 140px 100px 140px;
    @media screen and (max-width: 1400px) {
      padding: 80px 100px 100px 100px;
    }

    .back-button {
      margin-left:0;
      margin-top: -20px;
      margin-bottom: 20px;
      font-size: 32px;
      display: block;
      color: $info;
    }

    .row {
      //padding: 0px 0px 0px 120px;
      display: block;
      ul li {
        line-height: 28px;
      }

      p {
        //padding: 0px 0px 0px 120px;
        font-size: 16px;
        line-height: 1.4;
        letter-spacing: 0.2px;
        color: $darkgrey;
        font-family: 'Lato', sans-serif;

        a {
          color: $info;
        }

        &.title {
          font-size: 28px;
          line-height: 40px;
          color: $info;
          font-weight: bold;

          &.narrow {
            font-family: "PT Sans", sans-serif;
            font-size: 28px;
            font-weight: normal;
            display: block;
            border-bottom: 1px solid #cccccc;
            padding-bottom: 20px;
            margin-bottom: 30px;
          }

          &.small {
            font-family: 'Lato', sans-serif;
            font-size: 20px;
            line-height: 40px;
            color: $black;
            font-weight: normal;
            margin-bottom: 10px;
            display: inline-block;
          }

        }

        &.subtitle {
          font-weight: bold;
          padding: 0px 0px 20px 0px;
        }
      }

      &.text {
        padding: 60px 0px 20px 0px;

        .lead {
          font-size: 22px;
          font-weight: bold;
        }
      }
    }

    .gizmo-icon {
      color: #000;
      position: absolute;
      right: 0;
      top: 10px;
    }

    .panel-group .panel {
      border: none;
      box-shadow: none;
    }
    .accordion-panel .panel-heading .accordion-toggle {
      border-bottom: none;
      box-shadow: none;
      padding: 30px 0px 0px 0px;

      > span {
        display: block;
        position: relative;
      }

    }

  }

  &.questions {
    text-align: center;
    background-color: $light-background;
    padding: 100px 0px 100px 0px;
    .questions-wrapper {
      padding: 0px 100px 0px 100px;
      .question {
        .question-box {
          padding: 10px 50px 40px 50px;
          background-color: #ffffff;

          .question-icon {
            background-size: auto;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            background-color: transparent;
            width: 100%;
            height: 150px;

            &.seeking-help {
              @include bg-picture('seeking-help.png');
            }
            &.back-pain-causes {
              @include bg-picture('back-pain-causes.png');
            }
            &.what-happens {
              @include bg-picture('what-happens.png');
            }
            &.what-can-i-do {
              @include bg-picture('what-can-i-do.png');
            }
          }
        }
        .title {

          height: 120px;
          overflow: hidden;
          display: table-cell;
          vertical-align: middle;
          
          p {
            color: $black;
            font-weight: bold;
            font-size: 28px;
            line-height: 28px;
            display: block;
            padding: 10px 0px 10px 0px;
          }
        }
      }
    }
  }
}

