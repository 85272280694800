.terms-wrapper {
  width: 100%;
  padding: 100px 100px 100px 100px;

  h1 {
    color: $info;
    font-family: "PT Sans", sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 40px 0px 40px 0px;
  }

  h2 {
    color: $darkgrey-full;
    font-family: "PT Sans", sans-serif;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 30px 0px 10px 0px;
  }

  h3 {
    color: $darkgrey-full;
    font-family: "PT Sans", sans-serif;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.2px;
    font-weight: normal;
    margin: 40px 0px 20px 0px;
  }

  p, li {
    font-family: "Lato", Arial;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;

  }}
