.header-about {

  @include bg-image('theme/background-title.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .title-header-page {
    padding: 65px 0;
    text-align: center;
    h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        @include bg-picture('icon-about-pain.png');
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.box-main-content-about-back {
  /*padding: 60px 0;*/
  .box-about-back-title {
    /*display: flex;
    flex-wrap: wrap;*/
    padding-bottom: 30px;
    @media (max-width: 991px) {
      padding-bottom: 20px;
    }
    .box-social {
      text-align: right;
      .box-socical-like {
        max-width: 192px;
        display: inline-block;
        .socical-share {
          float: left;
          margin-right: 35px;
          ul {
            padding: 0;
            li {
              display: inline-block;
              a {
                color: $default;
                display: block;
                background-color: rgba(180, 182, 184, .8);
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                position: relative;
                font-size: 20px;
                transition: 1s;
                &.facebook {
                  &:hover {
                    background-color: $facebook-color;
                  }
                }
                &.twitter {
                  &:hover {
                    background-color: $twitter-color;
                  }
                }
                &.envelope {
                  &:hover {
                    background-color: $color-very-dark;
                  }
                }
                i {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              &:last-child {
                a {
                  font-size: 16px;
                }
              }
            }
          }
        }
        .box-like {
          float: right;
          .tooltip {
            white-space: nowrap;
          }
          span {
            cursor: pointer;
            height: 30px;
            width: 30px;
            display: inline-block;
            @include bg-image('app/video-img/heart.png');
            background-size: 30px 30px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s;
            &:hover,&.active {
              @include bg-image('app/video-img/heart-active.png');
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s;
            }
          }
        }
      }
      @media only screen and (max-width: 991px) {
        .box-socical-like {
          max-width: 207px;
          .socical-share {
            /*margin-left: 15px;*/
          }
        }
      }
    }
    .box-title {
      h2 {
        font-size: 28px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        color: $color-strong-blue;
      }
    }
    @media (max-width: 991px) {
      .box-social {
        order: 1;
        text-align: left;
      }
      .box-title {
        order: 2;
        margin-bottom: 20px;
      }
    }
  }
  .box-content {
    p {
      font-size: 16px;
      font-family: "Lato", Arial;
      font-weight: bold;
      color: $color-dark-grayish-blue;
      a {
        font-size: 16px;
        font-weight: normal;
        color: $color-strong-blue;
        &:hover, &:focus {
          text-decoration: underline !important;
        }
      }
    }
  }
  .box-time-back-pain {
    /*display: flex;
    flex-wrap: wrap;*/
    .box-back-pain-minus {
      padding-left: 0;
    }
    .box-back-pain-plus {
      padding-right: 0;
    }
    .box-back-pain-minus, .box-back-pain-plus {
      padding: 40px;
      border: 1px solid #e1e1e2;
      width: 49%;
      .box-img {

      }
      .box-title {
        padding: 40px 0 30px 0;
        h3 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: $color-very-dark;
        }
        h4 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: $color-strong-blue;
        }
      }
      .box-content {
        p {
          font-size: 16px;
          font-family: "Lato", Arial;
          font-weight: normal;
          color: $color-dark-grayish-blue;
        }
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 40px 20px;
      }
    }
    @media only screen and (max-width: 767px) {
      .box-back-pain-minus {
        padding-left: 15px;
      }
      .box-back-pain-plus {
        padding-right: 15px;
      }
    }
    .box-back-pain-minus {
      margin-right: 20px;
      @media only screen and (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        margin-right: 13px;
      }
    }
  }
}

.main-content-treatment {
  .content-hcps {
    h4 {
      font-family: "PT Sans", Arial;
      font-size: 20px;
      color: #292f35;
      font-weight: 700;
    }

  }
}