.modal-play-video {
  background-color: $color-very-dark;
  .sn-link-close {
    color: $default !important;
  }
  .modal-header {
    padding: 10px 10px 0 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .modal-content {
    border-radius: 5px;
  }
  .modal-body {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 10px 10px 10px;
    }
  }
  @media only screen and (max-width: 991px) {
    .modal-body {
      padding-bottom: 52.25%;
    }
  }
  @media only screen and (max-width: 600px) {
    .modal-body {
      padding-bottom: 50.25%;
    }
  }
  .social-video {
    display: inline-block;
    margin-left: -15px;
    .socical-share {
      display: inline-block;
      ul {
        padding: 0;
        li {
          display: inline-block;
          margin-right: 5px;
          a {
            color: $color-very-dark;
            display: block;
            background-color: #73777b;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: relative;
            font-size: 20px;
            transition: 1s;
            &.facebook {
              &:hover {
                background-color: $facebook-color;
                color: $default;
              }
            }
            &.twitter {
              &:hover {
                background-color: $twitter-color;
                color: $default;
              }
            }
            &.envelope {
              &:hover {
                /*background-color: $color-very-dark;*/
                background-color: #1a1e21;
                color: $default;
              }
            }
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          &:last-child {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
    .box-like {
      display: inline-block;
      .tooltip {
        white-space: nowrap;
      }
      span {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: inline-block;
        @include bg-image('app/video-img/heart-gray.png');
        background-size: 30px 30px;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
        -webkit-transition: all .8s;
        transition: all .8s;
        -moz-transition: all .7s;
        &:hover,&.active {
          @include bg-image('app/video-img/heart-active.png');
          -webkit-transition: all .8s;
          transition: all .8s;
          -moz-transition: all .7s;
        }
      }
    }
  }
}

.header-videos {
  @include bg-image('theme/background-title.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .title-header-page {
    padding: 65px 0;
    text-align: center;
    h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        @include bg-picture('icon-videos.png');
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.box-list-videos {
  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    .left-aside {
      width: 220px;
    }
    .container {
      padding: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0;
    }
    .left-aside {
      float: none;
      width: 100%;
      h4 {
        display: block;
        padding: 15px;
        background-color: #bed74b;
        color: $default;
        font-size: 16px;
        position: relative;
        margin: 0;
        .arrow-drop-menu-video {
          cursor: pointer;
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid $default;
        }
      }
      .aside-main-menu {
        display: none;
      }
    }
  }
  .main-content-video {
    padding: 60px;
    overflow: hidden;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 30px;
    }
    @media only screen and (max-width: 480px) {
      padding: 15px;
    }
    .box-video {
      margin: -15px;
      .tab-content {
        padding: 0;
      }
      .item-video {
        width: 270px;
        float: left;
        padding: 15px;
        .img-video {
          position: relative;
          cursor: pointer;
          img {
            width: 100%;
            border: 1px solid #d0d2d3;
          }
          .icon-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include bg-image('app/video-img/icon-play.png');
            background-size: 35px 35px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            width: 35px;
            height: 35px;
            cursor: pointer;
            
          }
          &:hover {
            .icon-play {
              @include bg-image('app/video-img/icon-play-hover.png');
            }
          }
          .time-video {
            position: absolute;
            bottom: 10px;
            right: 10px;
            color: $default;
            font-size: 12px;
            padding: 6px;
            background-color: rgba(0, 0, 0, .7);
            font-family: "Lato", Arial;
            font-weight: bold;
            border-radius: 5px;
          }
        }
        .title-video {
          a {
            h2 {
              font-size: 16px;
              font-family: "Lato-Bold";
              padding: 10px 0;
              line-height: 18px;
              color: $color-very-dark;
              margin-bottom: 0;
            }
            &:hover {
              h2 {
                text-decoration: underline;
                color: $color-strong-blue;
              }
            }
          }
        }
        .social-video {
          .box-socical-like {
            max-width: 192px;
            display: inline-block;
            .socical-share {
              float: left;
              margin-right: 35px;
              ul {
                padding: 0;
                li {
                  display: inline-block;
                  a {
                    display: block;
                    color: $default;
                    margin-right: 10px;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    position: relative;
                    font-size: 20px;
                    transition: 1s;
                    background-color: #b4b6b8;
                    &.facebook {
                      &:hover {
                        background-color: $facebook-color;
                      }
                    }
                    &.twitter {
                      &:hover {
                        background-color: $twitter-color;
                      }
                    }
                    &.envelope {
                      &:hover {
                        background-color: #1a1e21;
                      }
                    }
                    i {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                  &:last-child {
                    a {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .box-like {
              float: right;
              .tooltip {
                white-space: nowrap;
              }
              span {
                cursor: pointer;
                height: 30px;
                width: 30px;
                display: inline-block;
                @include bg-image('app/video-img/heart.png');
                background-size: 30px 30px;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
                -webkit-transition: all .8s;
                transition: all .8s;
                -moz-transition: all .7s;
                &:hover,&.active {
                  @include bg-image('app/video-img/heart-active.png');
                  -webkit-transition: all .8s;
                  transition: all .8s;
                  -moz-transition: all .7s;
                }
              }
            }
          }
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .item-video {
          width: 256px;
        }
      }
      @media only screen and (min-width: 481px) and (max-width: 767px) {
        .item-video {
          width: 50%;
        }
      }
      @media only screen and (max-width: 480px) {
        .item-video {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 90%;
    margin: 30px auto;
  }
}