.content-top {
  padding-top: 13px;
  padding-bottom: 27px;
}

h1.al-title {
  font-weight: $font-bold;
  color: $default-text;
  float: left;
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-transform: uppercase;
  opacity: 0.9;
}

.al-breadcrumb {
  background: none;
  color: $default-text;
  padding: 0;
  margin: 0;
  float: right;
  padding-top: 11px;
  li {
    font-size: 18px;
    font-weight: $font-normal;
  }
}

.al-look {
  float: right;
  margin-right: 10px;
  padding-top: 10px;
  > a {
    font-size: 19px;
  }
}



/* 
* new header 
*/

.header-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  @include bg-picture('blue-bg.png');
}
.header-upper {
  width: 100%;
  padding: 20px 0 20px 0px;
  text-align: center;

  p {
    font-family: "PT Sans", sans-serif;
    color: $default;

    &.highlight, .highlight {
      color: $primary;
    }

    &.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
    }

    &.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold;
      &:last-child {
        margin: 0 0 70px 0;
      }
    }

  }
  .icon {
    /*@include bg-picture('icon-treatments.png');*/
    background-size: 100%;
    /*border:1px solid red;*/
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 20px;
  }

  span {
    display: inline-block; 
    vertical-align: middle;
  }

}