.header-hcps {
  @include bg-image('theme/background-title.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .title-header-page {
    padding: 65px 0;
    text-align: center;
    h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        @include bg-picture('icon-hcps.png');
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.box-main-content-hcps {
  .left-aside {
    float: left;
    width: 280px;
    background-color: #f2f3f3;
    h4 {
      display: none;
    }
    .aside-main-menu {
      padding: 0;
      display: block;
      margin-bottom: 0;
      li {
        position: relative;
        border-bottom: 1px solid $default;
        a {
          padding-left: 40px;
          margin-left: 20px;
          position: relative;
          font-size: 14px;
          font-family: "Lato", Arial;
          color: $color-very-dark;
        }
        &:hover {
          background-color: #e8e9e9;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    .left-aside {
      width: 220px;
    }
    .container {
      padding: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0;
    }
    .left-aside {
      float: none;
      width: 100%;
      h4 {
        display: block;
        padding: 15px;
        background-color: #bed74b;
        color: $default;
        font-size: 16px;
        position: relative;
        margin: 0;
        .arrow-drop-menu-hcps {
          cursor: pointer;
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid $default;
        }
      }
      .aside-main-menu {
        display: none;
      }
    }
  }
  .main-content-hcps {
    padding: 60px 20px 60px 60px;
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      padding: 40px 20px 20px 30px;
    }
    @media only screen and (max-width: 767px) {
      padding: 40px 20px;
    }
    @media only screen and (max-width: 547px) {
      padding: 20px;
      padding-top: 35px;
    }
    /*overflow: hidden;*/
    .box-content-hcps {
      .box-title-hcps {
        /*display: flex;
        flex-wrap: wrap;*/
        padding-bottom: 30px;
        @media (max-width: 991px) {
          padding-bottom: 20px;
        }
        .box-social {
          text-align: right;
          .box-socical-like {
            max-width: 192px;
            display: inline-block;
            .socical-share {
              float: left;
              margin-right: 35px;
              ul {
                padding: 0;
                li {
                  display: inline-block;
                  a {
                    display: block;
                    color: $default;
                    margin-right: 10px;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    position: relative;
                    font-size: 20px;
                    transition: 1s;
                    background-color: #b4b6b8;
                    &.facebook {
                      &:hover {
                        background-color: $facebook-color;
                      }
                    }
                    &.twitter {
                      &:hover {
                        background-color: $twitter-color;
                      }
                    }
                    &.envelope {
                      &:hover {
                        background-color: #1a1e21;
                      }
                    }
                    i {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                  &:last-child {
                    a {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            .box-like {
              float: right;
              .tooltip {
                white-space: nowrap;
              }
              span {
                cursor: pointer;
                height: 30px;
                width: 30px;
                display: inline-block;
                @include bg-image('app/video-img/heart.png');
                background-size: 30px 30px;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
                -webkit-transition: all .8s;
                transition: all .8s;
                -moz-transition: all .7s;
                &:hover, &.active {
                  @include bg-image('app/video-img/heart-active.png');
                  -webkit-transition: all .8s;
                  transition: all .8s;
                  -moz-transition: all .7s;
                }
              }
            }
          }
        }
        .box-title {
          h2 {
            font-size: 32px;
            font-family: "PT Sans", sans-serif;
            font-weight: bold;
            color: $color-strong-blue;
            margin-bottom: 25px;
          }
        }
        @media (max-width: 991px) {
          .box-social {
            order: 1;
            text-align: left;
          }
          .box-title {
            order: 2;
          }
        }
      }
      .content-hcps {
        margin-bottom: 50px;
        p {
          font-size: 16px;
          font-family: "Lato", Arial;
          color: $color-dark-grayish-blue;
        }
        h4 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: #292f35;
        }
      }
      .box-feedback{
        border-bottom: 2px solid #e3e2e2;
        h5{
          padding: 25px 0;
          margin-bottom: 0;
          color: #bdbebf;
          font-size: 14px;
          font-family: "PT Sans", sans-serif;
          text-transform: uppercase;
          display: inline-block;
        }
      }
      .box-helpful{
        /*border-bottom: 2px solid #e3e2e2;*/
        h5{
          color: #808385;
          font-size: 16px;
          font-family: "Lato", Arial;
          font-weight: bold;
          padding: 35px 0;
          margin-bottom: 0;
          display: inline-block;
        }
        .btn-yes,.btn-no{
          padding: 8px 35px;
          background-color: transparent;
          border: 1px solid $color-strong-blue;
          font-size: 14px;
          font-family: "Lato", Arial;
          font-weight: bold;
          color: $color-strong-blue;
          margin-left: 30px;
          margin-right: 0;
          &.active {
            background-color: #177bbe;
            color: #ffffff;
          }
        }
        button {
          &:hover {
            background-color: #177bbe;
            color: #ffffff;
          }
          &.active {
            background-color: #177bbe;
            color: #ffffff;
          }
        }
        @media only screen and (max-width: 767px) {
          h5 {
            width: 100%;
            padding: 30px 0;
          }
          .btn-yes,.btn-no{
            margin-left: 0;
            margin-right: 30px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.box-content-feedback{
  border-bottom: 2px solid #e3e2e2;
  button{
    &:hover {
      background-color: $color-strong-blue;
      color: $default;
    }
    &.active {
      background-color: $color-strong-blue;
      color: $default;
    }
  }
  .box-helpful{
    border: none;
  }
  .box-thank{
    h6{
      padding: 35px 0 0 0;
      font-size: 16px;
      font-family: "Lato", Arial;
      color: #6a6e72;
    }
    .box-btn{
      display: block;
      text-align: right;
      .btn-tell-me{
        &:hover{
          background-color: $color-strong-blue;;
          color: $default;
          &:before{
            @include bg-image("app/effectiveness/icon-feed-active.png");
          }
        }
        &.active {
          background-color: $color-strong-blue;;
          color: $default;
          &:before{
            @include bg-image("app/effectiveness/icon-feed-active.png");
          }
        }
        margin: 20px 0;
        padding: 8px 15px;
        padding-left: 40px;
        background-color: transparent;
        border: 1px solid $color-strong-blue;
        font-size: 14px;
        font-family: "Lato", Arial;
        font-weight: bold;
        color: $color-strong-blue;
        margin-right: 0;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 13px;
          height: 11px;
          @include bg-image("app/effectiveness/icon-feed.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .box-comment{
    padding: 20px;
    background: rgba(16,18,20,0.05);
    h4{
      font-size: 18px;
      font-family: "Lato";
      font-weight: bold;
      color: #292f35;
      display: inline-block;
      &:before{
        content: "";
        display: inline-block;
        width: 14px;
        height: 15px;
        @include bg-image("app/effectiveness/icon-feed-black.png");
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 15px;
      }
      &:after{
        content: "";
        display: inline-block;
        width: 13px;
        height: 8px;
        @include bg-image("app/effectiveness/icon-arrow.png");
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
    form{
      input,textarea{
        margin-bottom: 15px;
      }
      .btn-info{
        padding: 8px 20px;
      }
    }
  }
  .box-feedback-success{
    h6{
      padding: 35px 0 35px 35px;
      font-size: 16px;
      font-family: "Lato", Arial;
      color: #6a6e72;
      @include bg-image("app/effectiveness/icon-success.png");
      background-repeat: no-repeat;
      background-size: 25px;
      background-position-y: 32px;
      /*&:before{
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        @include bg-image("app/effectiveness/icon-success.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
        vertical-align: middle;
      }*/
    }
  }
  @media only screen and (max-width: 767px) {
    .box-thank {
      h6 {
        padding: 30px 0 10px 0;
        background-position-y: 30px;
      }
      .box-btn {
        text-align: left;
        .btn-tell-me {
          margin: 0 0 30px;
        }
      }
    }
  }
}