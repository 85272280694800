.al-footer {
  .bottom-wrapper {
    background-color: #3e4349;
    height: 60px;
    .footer-copyright {
      text-align: left;
      p {
        line-height: 60px;
        color: #b2b4b6;
        margin: 0 auto;
        font-size: 12px;
        a {
          color: #b2b4b6;
          &:hover {
            color: $default;
          }
        }
      }
    }
    .footer-term {
      text-align: right;
      p {
        line-height: 58px;
        color: #b2b4b6;
        margin: 0 auto;
        a {
          color: #b2b4b6;
          font-size: 12px;
          &:hover {
            color: $default;
          }
        }
      }
    }
    @media (max-width: 992px) {
      height: auto;
      padding: 10px 0;
      .footer-copyright {
        p {
          line-height: 24px;
          font-size: 12px;
        }
      }
      .footer-term {
        text-align: left;
        p {
          line-height: 24px;
        }
      }
    }
  }
  .logo-footer {
    background-color: #282E34;
    height: 75px;
    line-height: 75px;
    @media (max-width: 767px) {
      line-height: normal;
      height: auto;
    }
    .box-logo-footer {
      .mbplogo {
        max-width: 220px;
      }
      .arthritislogo {
        max-width: 73px;
      }
      @media (max-width: 767px) {
        height: 60px;
        line-height: 60px;
      }
      .mbplogo {
        @media (max-width: 767px) {
          padding: 10px;
        }
        img {
          border-right: 1px solid #ccc;
          padding-right: 15px;
          @media (max-width: 767px) {
            padding-right: 20px;
            padding-left: 20px;
          }
        }
      }
      .arthritislogo {
          padding: 0;
        @media (max-width: 767px) {
          padding: 10px;
        }
        img{
          @media (max-width: 767px) {
            padding-right: 10px;
          }
        }
      }
      img {
        width: 100%;
      }
    }
    .box-contact {
      float: right;
      @media (max-width: 767px) {
        float: none;
        clear: both;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #31363c;
        margin: 0 -15px;
      }
      a {
        color: #ffffff;
        position: relative;
        padding-left: 35px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, .3);
          border-radius: 50%;
          @include bg-image('app/icon-contact.png');
          background-size: 20px 15px;
          background-position: center center;
          background-repeat: no-repeat no-repeat;
          display: inline-block;
          height: 30px;
          width: 30px;
          @media (max-width: 767px) {
            background-color: transparent;
            @include bg-image('app/icon-contact-res.png');
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    .menu-footer {
      display: none !important;
    }
  }
  .menu-footer {
    background-color: #282E34;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #53585d;
    .col-menu {
      float: left;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
    .box-menu-footer {
      .title-menu {
        text-transform: uppercase;
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .menu {
        ul {
          padding: 0;
          li {
            padding: 5px 0;
            &:before {
              content: "";
              margin: 0 auto;
              padding: 0;
            }
            a {
              color: #94979a;
              font-family: "Lato", Arial;
              font-size: 12px;
              font-weight: normal;
              position: relative;
              padding-right: 20px;
              &:hover {
                color: $default;
              }

              &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 60%;
                @include bg-image('app/arrow/arrow-right.png');
                background-repeat: no-repeat;
                display: inline-block;
                height: 10px;
                width: 10px;
                background-size: contain;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .al-footer {
    .bottom-wrapper {
      .footer-copyright {
        p {
          font-size: 12px;
        }
      }
    }
  }
}