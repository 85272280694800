.auth-wrapper {
  .panel {
    padding: 0px 0px 0px 0px;
    form {
      padding: 40px 20px 60px 20px;
    }

    p, label, span, input, button {
      font-size: 18px;
      line-height: 18px;
      font-family: "Lato", Arial;
    }

    label {
      margin-top: 7px;
      margin-bottom: 10px;
      font-weight: bold;
      text-align: left;

      a {
        text-decoration: underline !important;
      }
    }

    .alert-messages {
      margin: 5px 0px 20px 0px;
      color: red;
      position: absolute;

      span {
        font-size: 15px;
      }
    }

  }

  .form-horizontal .form-group {
    padding: 0 30px;
    margin-bottom: 25px;
  }

  .form-login .form-group {
    margin-bottom: 35px;
  }


}


