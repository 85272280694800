@media only screen and (max-width: 767px) {
  body {
    &.overflow-hidden {
      overflow: hidden;
    }
  }
}

.tab-custom-menu {
  cursor: pointer;
  ul {
    position: relative;
    li {
      &.has-sub {
        .tab-custom-menu-item-parent {
          padding-left: 0;
        }
        &:hover {
          .tab-custom-menu-item-parent {
            .item-has-sub {
              background-color: #ebebeb;
              /*color: #FFF;*/
              /*span {
                filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1);
              }*/
            }
          }
          .drop-menu-sub {
            li {
              a {
                background-color: #e7e8e9;
              }
            }
          }
        }
        &.selected {
          /*border-bottom: 1px solid $color-bg-gray-hover;*/
          .tab-custom-menu-item-parent {
            .item-has-sub {
              background-color: #50565a;
              color: #FFF;
              span {
                filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1);
              }
            }
          }
          .drop-menu-sub {
            li {
              a {
                background-color: #8e9193;
              }
            }
          }
        }
      }
      //@media only screen and (max-width: 767px) {
        &.tab-custom-menu-item {
          &.active {
            &::before {
              content: "";
              height: 60px;
              width: 7px;
              background-color: #bed74b;
              position: absolute;
              right: 0;
              z-index: 5;
            }
          }
        }
      //}
    }
  }
  /*> ul {
    .scrollBar {
      content: "";
      height: 60px;
      width: 7px;
      background-color: #bed74b;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: all .3s; // Chrome
      -moz-transition: all .3s; // Mozilla
      -o-transition: all .3s; // Opera
      transition: all .3s;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }*/
  /*&.sticky {
    position: fixed;
    left: 0px;
    top: 50px;
    z-index: 2;
    .aside-main-menu {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }*/
}
.tab-custom-content {
  transition: initial;
  -webkit-transition: initial;
  -ms-transition: initial;
  -moz-transition: initial;
  .tab-custom-content-item {
    display: none;
    &.active {
      display: block;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 35px 20px 20px 20px !important;
  }
  @media only screen and (min-width: 768px) {
    padding: 40px 20px 20px 30px !important;
  }
}