.chart-canvas {
  position: relative;
  display: inline-block;
}

#dashboard {
  .header-page {
    .title-header-page {
      h1 {
        &:before {
          @include bg-picture('icon-dashboard.png');
        }
      }
    }
  }
  .box-main-content-page {
    .main-content-article {
      //margin-top: 60px;
    }
    .list-box-content-careplain{
      padding-top: 60px;
    }
    .box-shadow-border-right {
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2);
    }
    .box-shadow-border-left {
      box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
      @media screen and (max-width: 991px) {
        box-shadow: none;
      }
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .sub-menu-dashboard {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: inline-block;
      width: 50%;
      margin: 0;
      padding: 24px 0;
      border: 1px solid #f8f8f8;
      text-align: center;
      background-color: #fdfdfd;
      height: 72px;
      @media screen and (max-width: 480px) {
        height: 95px;
        display: block;
      }
      &:before {
        content: "";
        width: 100%;
        height: 3px;
        background-color: #fdfdfd;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      a {
        font-size: 18px;
        @include lato();
        font-weight: 700;
        text-align: center;
        position: relative;
        color: #282e34;
        display: block;
      }
      .visible-mobile-small {
        display: none;
      }
      &:first-child {
        a {
          @media screen and (max-width: 520px) {
            line-height: 40px;
            padding-left: 10%;
          }
          &:before {
            content: "";
            @include bg-picture('icon-my-careplan.png');
            background-size: contain;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: bottom;
            @media (max-width: 520px) {
              position: absolute;
              top: 50%;
              left: 10%;
              transform: translateY(-50%);
            }
          }
        }
      }
      &:last-child {
        border-right: none;
        a {
          @media (max-width: 480px) {
            padding-left: 35px;
          }
          &:before {
            content: "";
            @include bg-picture('icon-favourite-content.png');
            background-size: contain;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: bottom;
            margin-right: 10px;
            @media (max-width: 480px) {
              position: absolute;
              top: 50%;
              left:15px;
              transform: translateY(-50%);
            }
          }
        }
      }
      &.active, &:hover, &:focus {
        background-color: #fff;
        position: relative;
        &:before {
          content: "";
          width: 100%;
          height: 3px;
          background-color: #bed74b;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      @media only screen and (max-width: 480px) {
        .hidden-mobile-small {
          display: none;
        }
        .visible-mobile-small {
          display: block;
        }
      }
    }
  }

  .list-box-content-dashboard {
    /*overflow: hidden;*/
    .box-load-more {
      width: 100%;
      text-align: center;
      background: #F2F3F4;
      padding: 20px;
      margin-top: 50px;
      h6 {
        font-size: 22px;
        font-family: "Lato";
        font-weight: bold;
        color: #282E34;
        cursor: pointer;
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        padding-left: 25px;
        &.active {
          img {
            animation: spin 2s linear infinite;
          }
        }
        div {
          &.btn-loading {
            position: relative;
            width: 20px;
            height: 20px;
            display: inline-block;
            img {
              width: 100%;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .current-box-content-title {
      .box-title-content {
        border-bottom: 1px solid #f8f8f8;
        padding: 0;
        .title-content {
          font-size: 28px;
          font-weight: 700;
          @include PT_San();
          color: #177bbe;
          margin-bottom: 20px;
          margin-top: 20px;
          cursor: pointer;
        }
        .button-show-content {
          color: $color-strong-blue;
          border: 1px solid;
          border-color: rgba(23, 123, 190, 0.5);
          height: 35px;
          width: 35px;
          padding: 0;
          position: relative;
          float: right;
          cursor: pointer;
          margin-top: 20px;
          &.show {
            &:after {
              content: "";
              height: 0;
              width: 0;
              background-color: transparent;
            }
          }
          &:before {
            content: "";
            width: 15px;
            height: 2px;
            background-color: $color-strong-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &:after {
            content: "";
            height: 15px;
            width: 2px;
            background-color: $color-strong-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);;
          }
        }
      }
      .content-dashboard {
        &.dashboard-content-hidden {
          display: none;
        }
        padding: 0;
        margin: 20px 0;
        @media screen and (max-width: 991px) {
          margin-bottom: 10px;
        }
        @include lato();
        font-size: 16px;
        color: #6a6e72;
        .sub-title-content {
          color: #282e34;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 10px;
          @include PT_San();
          h4 {
            color: #282e34;
            font-size: 20px;
            font-weight: 700;
            @include PT_San();
            padding-right: 35px;
          }
        }
        p {
          a {
            color: #177bbe;
            display: inline-block;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .content-list-dashboard {
          p {
            padding-left: 20px;
            position: relative;
            &:before {
              content: "• ";
              color: #1a7cbd;
              left: 0;
              position: absolute;
            }
          }
        }
        .box-attention-content {
          /*background-color: #b2c946;*/
          padding: 35px;
          color: #282e34;
          p {
            padding-left: 50px;
            &:first-child {
              position: relative;
              padding-left: 50px;
              font-weight: 700;
              color: #F00;
              &:before {
                content: "";
                position: absolute;
                left: -35px;
                top: 0;
                @include bg-picture('icon-careful-red.png');
                background-size: 100%;
                width: 60px;
                height: 60px;
                background-repeat: no-repeat;
              }
            }
          }
        }
        .excellent-prognosis {
          border: 1px solid #35a100;
          padding: 35px;
          background-color: #f9fcf7;
          &.rank-1-10 {
            border: 1px solid #0d9000;
            background-color: #f0f7ef;
            .title-excellent-prognosis {
              color: #0d9000;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #0d9000;
                }
              }
            }
          }
          &.rank-11-20 {
            border: 1px solid #35a100;
            background-color: #f1f7ee;
            .title-excellent-prognosis {
              color: #35a100;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #35a100;
                }
              }
            }
          }
          &.rank-21-30 {
            border: 1px solid #5db000;
            background-color: #f3f7ee;
            .title-excellent-prognosis {
              color: #5db000;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #5db000;
                }
              }
            }
          }
          &.rank-31-40 {
            border: 1px solid #8abf00;
            background-color: #f5f7ef;
            .title-excellent-prognosis {
              color: #8abf00;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #8abf00;
                }
              }
            }
          }
          &.rank-41-50 {
            border: 1px solid #bbce00;
            background-color: #f6f7ef;
            .title-excellent-prognosis {
              color: #bbce00;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #bbce00;
                }
              }
            }
          }
          &.rank-51-60 {
            border: 1px solid #ddc800;
            background-color: #f7f6ef;
            .title-excellent-prognosis {
              color: #ddc800;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #ddc800;
                }
              }
            }
          }
          &.rank-61-70 {
            border: 1px solid #eda900;
            background-color: #f7f5ef;
            .title-excellent-prognosis {
              color: #eda900;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #eda900;
                }
              }
            }
          }
          &.rank-71-80 {
            border: 1px solid #fc8600;
            background-color: #f7f3ef;
            .title-excellent-prognosis {
              color: #fc8600;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #fc8600;
                }
              }
            }
          }
          &.rank-81-90 {
            border: 1px solid #ff5f00;
            background-color: #f7f2ef;
            .title-excellent-prognosis {
              color: #ff5f00;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #ff5f00;
                }
              }
            }
          }
          &.rank-91-100 {
            border: 1px solid #ff3d00;
            background-color: #f7f1ef;
            .title-excellent-prognosis {
              color: #ff3d00;
            }
            .percent-excellent-prognosis {
              .percent-your-score-box {
                i {
                  color: #ff3d00;
                }
              }
            }
          }
          .title-excellent-prognosis {
            color: #35a100;
            font-size: 28px;
            @include PT_San();
            text-align: center;
            font-weight: 700;
            margin-bottom: 75px;
          }
          .percent-excellent-prognosis {
            @include bg-picture('icon-excellent-prognosis.png');
            width: 100%;
            height: 38px;
            background-size: 100%;
            background-repeat: no-repeat;
            padding: 35px 0;
            position: relative;
            margin-bottom: 35px;
            .average-excellent-prognosis {
              position: absolute;
              width: 1px;
              height: 50px;
              top: -8px;
              @media screen and (max-width: 1199px) {
                top: -14px;
              }
              background-color: #293b2e;
              margin-left: -1px;
              span {
                position: absolute;
                top: -30px;
                left: -45px;
                color: #6a6e72;
                width: 100px;
              }
            }
            .percent-your-score {
              position: absolute;
              width: 2px;
              height: 55px;
              top: 0;
              background-color: #323a2e;
            }
            .percent-your-score-box {
              position: absolute;
              bottom: -35px;
              margin-left: -77px;
              color: #292f35;
              width: 145px;
              background-color: #ffffff;
              border: 1px solid #f1f2f2;
              padding: 15px;
              @media screen and (max-width: 480px) {
                margin-left: -50px;
                width: 125px;
                font-size: 13px;
              }
              i {
                font-style: normal;
                font-weight: bold;
              }
            }
          }
        }
        .statistical-recovered {
          background-color: #fbfcfc;
          .map-statistical-recovered {
            border-right: 1px solid #f1f1f1;
            text-align: center;
            @media screen and (max-width: 991px) {
              border-bottom: 1px solid #f1f1f1;
            }
            .title-map-recovered {
              font-weight: 700;
              font-size: 18px;
              @include lato;
              text-align: center;
              color: #1a7cbd;
              margin-bottom: 30px;
            }
            .paint-map-recovered {
              display: inline-block;
              position: relative;
              height: 160px;
              width: 160px;
              .percircle {
                background-color: #92c53f;
                font-size: 160px;
                &:after {
                  background-color: #fff;
                }
                .bar {
                  border: .08em solid #1a7cbd;
                  width: .846em;
                  height: .846em;
                }
              }
              .image-bluecircle {
                position: absolute;
                top: 49%;
                left: 53%;
                transform: translate(-50%, -50%);
                width: 140px;
              }
            }
            .number-percent-recovered {
              text-align: left;
              margin-top: 35px;
              margin-left: 15px;
              .recovered {
                color: #1a7cbd;
                margin-right: 20px;
                &.not-have {
                  color: #92c53f;
                }
              }
            }
          }
        }
        .pain-coping-score {
          background-color: #fbfcfc;
          padding: 40px;
          text-align: center;
          .chart-canvas-ab {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .title-pain-coping-score {
            font-weight: 700;
            font-size: 18px;
            @include lato;
            text-align: center;
            color: #1a7cbd;
            margin-bottom: 30px;
          }
          .paint-pain-coping-score {
            display: inline-block;
            position: relative;
            width: 100%;
            height: 160px;
            .paint-score {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              &.percircle {
                font-size: 160px;
                &:after {
                  background-color: #fff;
                }
                .bar {
                  border: .081em solid #307bbb;
                  width: .85em;
                  height: .85em;
                }
              }
            }
            .paint-previous {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              &.percircle {
                &:after {
                  background-color: #fff;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                .bar {
                  border: .09em solid #92c53f;
                }
              }
            }
            .image-bluecircle {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              position: absolute;
              top: 49%;
              left: 50%;
              transform: translate(-50%, -50%);
              /*> img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
              }*/
            }
          }
        }
        hr {
          &.line-content {
            width: 100%;
            border-top: 0.5px solid #f5f6f6;;
            margin: 50px 0;
          }
        }
        .note-color {
          margin-top: 25px;
          .percent-score, .percent-previous {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            text-align: center;
            line-height: 60px;
            color: #fff;
            @include lato();
            font-size: 18px;
          }
          &.color-pain-coping-score {
            .percent-score {
              background-color: #1a7cbd;
            }
            .text-percent-score {
              color: #1a7cbd;
              padding-right: 10px;
              margin-top: 10px;
              clear: both;
            }
            .percent-previous {
              background-color: #d4d5d7;
            }
            .text-percent-previous {
              color: #d4d5d7;
              margin-top: 10px;
              clear: both;
            }
          }
          &.color-fear-avoidance-scale {
            .percent-score {
              background-color: #1a7cbd;
            }
            .text-percent-score {
              color: #1a7cbd;
              padding-right: 10px;
              margin-top: 10px;
              clear: both;
            }
            .percent-previous {
              background-color: #92c53f;
            }
            .text-percent-previous {
              color: #92c53f;
              margin-top: 10px;
              clear: both;
            }
          }
        }
        .background-your-mood {
          background-color: #fbfcfc;
          .border-box-right {
            border-right: 1px solid #f3f5f5;
          }
          .title-your-mood {
            font-weight: 700;
            font-size: 18px;
            @include lato;
            text-align: center;
            color: #1a7cbd;
            margin-bottom: 30px;
          }
        }
        .box-point-ladder {
          position: relative;
          width: 100%;
          height: 24px;
          background-color: #d1d3d4;
          border-radius: 12px;
          margin: 20px 0;
          .score-point-ladder-mask {
            position: relative;
            height: 24px;
            overflow: hidden;
            border-radius: 12px;
          }
          .score-point-ladder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 24px;
            background-color: #1a7cbd;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }
          .previous-point-ladder {
            position: absolute;
            top: -8px;
            z-index: 5;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 14px solid #92c53f;
            margin-left: -8px;
            &:before {
              content: 'Previous score';
              color: #92c53f;
              font-size: 11px;
              width: 80px;
              display: inline-block;
              position: absolute;
              top: -35px;
              left: -35px;
            }
          }
          .box-divide-div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 24px;
            overflow: hidden;
            .divide-div-left {
              position: absolute;
              top: 0;
              left: 25%;
              height: 24px;
              width: 1px;
              background-color: #fff;
              z-index: 3;
            }
            .divide-div-center {
              position: absolute;
              top: 0;
              left: 50%;
              height: 24px;
              width: 1px;
              background-color: #fff;
              z-index: 3;
            }
            .divide-div-right {
              position: absolute;
              top: 0;
              left: 75%;
              height: 24px;
              width: 1px;
              background-color: #fff;
              z-index: 3;
            }
          }
        }
        a {
          color: #1a7cbd;
          @include lato();
          font-size: 15px;
          font-weight: 700;
        }
        .play-video {
          cursor: pointer;
        }        
      }
    }
    .box-none-data {
      text-align: center;
      @media (max-width: 480px) {
        margin-top: 30px;
      }
      span {
        @include bg-picture('icon-favourite-content.png');
        background-size: 100%;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: bottom;
      }
      h4 {
        font-family: "Lato-Black";
        font-size: 22px;
        color: #a5a8aa;
        margin: 20px;
      }
      p {
        font-family: "Lato";
        font-size: 16px;
        color: #a5a8aa;
      }
    }
  }

  .backgroud-right-content {
    background-color: #fbfcfc;
  }
  .title-content-right {
    font-size: 18px;
    font-weight: 700;
    @include lato();
    color: #177bbe;
    margin-bottom: 20px;
  }
  .sub-title-content-right {
    font-size: 16px;
    font-weight: 700;
    @include lato();
    color: #6a6e72;
    margin-bottom: 20px;
  }
  .content-content-right {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #6a6e72;
    margin-bottom: 20px;
  }
  .date-content-right {
    color: #282e34;
    font-size: 20px;
    font-weight: 700;
    font-family: "PT Sans", sans-serif;
  }
  .box-print-your-report {
    background-color: #b2c946;
    padding: 40px 20px;
    text-align: center;
    .title-content-right, .content-content-right {
      color: #282e34;
    }
    .button-print-report {
      color: #fff;
      background-color: #177bbe;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 700;
      @include lato();
      text-transform: uppercase;
      display: inline-block;
      padding: 15px;
      cursor: pointer;
    }
  }
  .box-giude-me {
    background-color: #177bbe;
    padding: 40px 20px;
    text-align: center;
    .title-content-right {
      color: #fff;
    }
    .content-content-right {
      color: #fff;
    }
    .button-print-report {
      background-color: #fff;
      color: #177bbe;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 700;
      @include lato();
      text-transform: uppercase;
      display: inline-block;
      padding: 15px;
    }
  }
  hr {
    &.line-content {
      width: 100%;
      height: 1px;
      background-color: #f7f8f8;
      margin: 50px 0;
    }
  }
  .box-did-you-now {
    padding: 45px 30px 30px;
    position: relative;
    background-color: #fff;
    border-bottom: 1px solid #f7f8f8;
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2);
    .icon-print-your-report {
      position: absolute;
      top: 30px;
      right: 30px;
    }
    ul {
      padding-left: 20px;
      li {
        position: relative;
        &:before {
          content: "• ";
          color: #1a7cbd;
          left: -20px;
          position: absolute;
        }
      }
    }
  }
  .box-recomended-for-you {
    padding: 30px;
    /*background-color: #fbfcfc;*/
    a {
      @include lato();
      font-size: 15px;
      font-weight: 700;
      color: #177bbe;
    }
  }
  .box-like {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    .tooltip {
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        left: initial !important;
        right: 0;
        .tooltip-arrow {
          left: 100% !important;
          margin-left: -20px !important;
        }
      }
    }
    span {
      cursor: pointer;
      height: 30px;
      width: 30px;
      display: inline-block;
      @include bg-image('app/video-img/heart.png');
      background-size: 30px 30px;
      background-position: center center;
      background-repeat: no-repeat no-repeat;
      -webkit-transition: all .8s;
      transition: all .8s;
      -moz-transition: all .7s;
      &:hover, &.active {
        @include bg-image('app/video-img/heart-active.png');
        -webkit-transition: all .8s;
        transition: all .8s;
        -moz-transition: all .7s;
      }
    }
  }
  .percircle:hover:after {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .percircle.small {
    font-size: 120px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}