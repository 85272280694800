@charset "UTF-8";
.header-about {
  background-image: url("../../assets/img/theme/background-title.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .header-about .title-header-page {
    padding: 65px 0;
    text-align: center; }
    .header-about .title-header-page h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center; }
      @media (max-width: 767px) {
        .header-about .title-header-page h1 {
          font-size: 22px; } }
      .header-about .title-header-page h1:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("../../assets/pictures/icon-about-pain.png");
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px; }

.box-main-content-about-back {
  /*padding: 60px 0;*/ }
  .box-main-content-about-back .box-about-back-title {
    /*display: flex;
    flex-wrap: wrap;*/
    padding-bottom: 30px; }
    @media (max-width: 991px) {
      .box-main-content-about-back .box-about-back-title {
        padding-bottom: 20px; } }
    .box-main-content-about-back .box-about-back-title .box-social {
      text-align: right; }
      .box-main-content-about-back .box-about-back-title .box-social .box-socical-like {
        max-width: 192px;
        display: inline-block; }
        .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share {
          float: left;
          margin-right: 35px; }
          .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul {
            padding: 0; }
            .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li {
              display: inline-block; }
              .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li a {
                color: #ffffff;
                display: block;
                background-color: rgba(180, 182, 184, 0.8);
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                position: relative;
                font-size: 20px;
                transition: 1s; }
                .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li a.facebook:hover {
                  background-color: #3b5998; }
                .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li a.twitter:hover {
                  background-color: #55acee; }
                .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li a.envelope:hover {
                  background-color: #282e34; }
                .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li a i {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); }
              .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share ul li:last-child a {
                font-size: 16px; }
        .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .box-like {
          float: right; }
          .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .box-like .tooltip {
            white-space: nowrap; }
          .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .box-like span {
            cursor: pointer;
            height: 30px;
            width: 30px;
            display: inline-block;
            background-image: url("../../assets/img/app/video-img/heart.png");
            background-size: 30px 30px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s; }
            .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .box-like span:hover, .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .box-like span.active {
              background-image: url("../../assets/img/app/video-img/heart-active.png");
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s; }
      @media only screen and (max-width: 991px) {
        .box-main-content-about-back .box-about-back-title .box-social .box-socical-like {
          max-width: 207px; }
          .box-main-content-about-back .box-about-back-title .box-social .box-socical-like .socical-share {
            /*margin-left: 15px;*/ } }
    .box-main-content-about-back .box-about-back-title .box-title h2 {
      font-size: 28px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      color: #177bbe; }
    @media (max-width: 991px) {
      .box-main-content-about-back .box-about-back-title .box-social {
        order: 1;
        text-align: left; }
      .box-main-content-about-back .box-about-back-title .box-title {
        order: 2;
        margin-bottom: 20px; } }
  .box-main-content-about-back .box-content p {
    font-size: 16px;
    font-family: "Lato", Arial;
    font-weight: bold;
    color: #6a6e72; }
    .box-main-content-about-back .box-content p a {
      font-size: 16px;
      font-weight: normal;
      color: #177bbe; }
      .box-main-content-about-back .box-content p a:hover, .box-main-content-about-back .box-content p a:focus {
        text-decoration: underline !important; }
  .box-main-content-about-back .box-time-back-pain {
    /*display: flex;
    flex-wrap: wrap;*/ }
    .box-main-content-about-back .box-time-back-pain .box-back-pain-minus {
      padding-left: 0; }
    .box-main-content-about-back .box-time-back-pain .box-back-pain-plus {
      padding-right: 0; }
    .box-main-content-about-back .box-time-back-pain .box-back-pain-minus, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus {
      padding: 40px;
      border: 1px solid #e1e1e2;
      width: 49%; }
      .box-main-content-about-back .box-time-back-pain .box-back-pain-minus .box-title, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus .box-title {
        padding: 40px 0 30px 0; }
        .box-main-content-about-back .box-time-back-pain .box-back-pain-minus .box-title h3, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus .box-title h3 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: #282e34; }
        .box-main-content-about-back .box-time-back-pain .box-back-pain-minus .box-title h4, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus .box-title h4 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: #177bbe; }
      .box-main-content-about-back .box-time-back-pain .box-back-pain-minus .box-content p, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus .box-content p {
        font-size: 16px;
        font-family: "Lato", Arial;
        font-weight: normal;
        color: #6a6e72; }
      @media only screen and (max-width: 767px) {
        .box-main-content-about-back .box-time-back-pain .box-back-pain-minus, .box-main-content-about-back .box-time-back-pain .box-back-pain-plus {
          width: 100%;
          padding: 40px 20px; } }
    @media only screen and (max-width: 767px) {
      .box-main-content-about-back .box-time-back-pain .box-back-pain-minus {
        padding-left: 15px; }
      .box-main-content-about-back .box-time-back-pain .box-back-pain-plus {
        padding-right: 15px; } }
    .box-main-content-about-back .box-time-back-pain .box-back-pain-minus {
      margin-right: 20px; }
      @media only screen and (max-width: 767px) {
        .box-main-content-about-back .box-time-back-pain .box-back-pain-minus {
          margin-right: 0;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        .box-main-content-about-back .box-time-back-pain .box-back-pain-minus {
          margin-right: 13px; } }

.main-content-treatment .content-hcps h4 {
  font-family: "PT Sans", Arial;
  font-size: 20px;
  color: #292f35;
  font-weight: 700; }

#about_us .header-page h1:before {
  background-image: url("../../assets/pictures/icon-aboutus.png"); }

#about_us .header-page h1 a {
  color: #fff; }

#about_us .header-page .sub-title-header h1 {
  padding-left: 0; }
  #about_us .header-page .sub-title-header h1:before {
    background-image: none; }

#about_us .header-page.header-do-it-yourself h1:before {
  background-image: url("../../assets/pictures/icon-do-it-yourself.png"); }

#about_us .header-page.header-for-family-and-friends h1:before {
  background-image: url("../../assets/pictures/icon-for-family-and-friends.png"); }

#about_us .box-content-page .title-about-us {
  color: #177bbe;
  font-size: 28px;
  font-family: "PT Sans", sans-serif;
  margin-bottom: 30px;
  font-weight: 700; }

#about_us .box-content-page .icon-image-about-us {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 50px 10px 20px 10px;
  margin-bottom: 50px; }

#about_us .box-content-page .sub-title-about-us {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  margin-bottom: 30px; }

#about_us .box-content-page .list-sub-image {
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    #about_us .box-content-page .list-sub-image .text-center {
      text-align: left; } }

#about_us .box-content-page .border-box-right {
  border-right: 1px solid #e2e2e2; }

@media (max-width: 991px) {
  #about_us .box-content-page .border-box-right {
    border-right: none; } }

@media (max-width: 767px) {
  #about_us .box-content-page .title-about-us {
    margin-bottom: 10px; } }

#about_us .box-socical-like {
  float: right; }

@media only screen and (max-width: 767px) {
  #about_us .box-socical-like {
    float: none; } }

#contact_us .header-page h1:before {
  background-image: url("../../assets/pictures/icon-contact-us.png"); }

#contact_us .box-form-contact h2 {
  color: #177bbe;
  font-size: 28px;
  font-family: "PT Sans", sans-serif;
  margin-bottom: 40px;
  font-weight: bold; }

#contact_us .box-form-success {
  display: none; }
  #contact_us .box-form-success h2 {
    color: #177bbe;
    font-size: 28px;
    font-family: "PT Sans", sans-serif;
    margin-bottom: 40px;
    font-weight: bold; }
  #contact_us .box-form-success p {
    color: #6A6E72;
    font-size: 16px;
    font-family: "PT Sans", sans-serif;
    font-weight: normal; }

#contact_us .title-contact-us {
  color: #177bbe;
  font-size: 28px;
  font-family: "PT Sans", sans-serif;
  margin-bottom: 50px;
  font-weight: bold; }

#contact_us .sub-title-contact-us {
  font-family: 'Lato-Bold', sans-serif;
  font-size: 20px;
  color: #000;
  margin-bottom: 35px;
  font-weight: bold; }

@media (max-width: 767px) {
  #contact_us .form-contact-us .box-country-contact {
    padding-bottom: 15px; } }

#contact_us .form-contact-us label {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px; }
  #contact_us .form-contact-us label.error {
    position: relative;
    bottom: -10px;
    color: #f00;
    padding-left: 25px;
    font-weight: normal;
    font-size: 15px; }
    #contact_us .form-contact-us label.error#contact_country-selectized-error {
      position: absolute;
      bottom: -45px;
      left: 0; }
    #contact_us .form-contact-us label.error .for-default, #contact_us .form-contact-us label.error .for-myaccount {
      display: none; }
    #contact_us .form-contact-us label.error .for-account {
      display: block; }
    #contact_us .form-contact-us label.error:before {
      content: "\00d7";
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ff0000;
      color: #ffffff;
      font-size: 20px;
      line-height: 18px;
      text-align: center;
      font-weight: normal; }

#contact_us .form-contact-us input, #contact_us .form-contact-us textarea {
  border-radius: 0;
  resize: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none; }

#contact_us .form-contact-us .selectize-input {
  border: 1px solid #d0d0d0;
  padding: 8px 8px;
  display: inline-block;
  width: 100%;
  overflow: initial;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

#contact_us .form-contact-us .submit-form-contact-us {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #177bbe;
  border: none; }

#contact_us .box-follow-us {
  background-color: #f2f3f3;
  padding: 35px 30px; }
  #contact_us .box-follow-us .title-follow-us {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    color: #000; }
  #contact_us .box-follow-us .content-follow-us {
    color: #848789;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 40px;
    text-align: center; }
  #contact_us .box-follow-us .follow-social {
    width: 200px;
    margin: 0 auto; }
    #contact_us .box-follow-us .follow-social .social-fb a {
      color: #4d6fa5;
      font-size: 30px; }
    #contact_us .box-follow-us .follow-social .social-twitter a {
      color: #1db6ea;
      font-size: 30px; }
    #contact_us .box-follow-us .follow-social .social-youtube a {
      color: #c73636;
      font-size: 30px; }

.term_privacy .box-main-content-page .main-content-with-collapse .content h2 {
  font-family: "PT Sans", sans-serif;
  color: #177bbe;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 40px; }

.term_privacy .box-main-content-page .main-content-with-collapse .content h3 {
  font-family: "Lato-Bold";
  font-size: 20px;
  color: #292f35; }

.term_privacy .box-main-content-page .main-content-with-collapse .content h4 {
  font-family: "Lato-Black";
  font-size: 16px; }

.term_privacy .box-main-content-page .main-content-with-collapse .content strong {
  color: #000; }

.alert {
  color: #ffffff;
  font-size: 13px;
  font-weight: 300; }
  .alert strong {
    font-weight: 400; }
  .alert .close {
    margin-top: -3px; }
  .alert .alert-link {
    font-weight: 400; }
  .alert .control-alert {
    padding-top: 10px; }
    .alert .control-alert button {
      margin-right: 10px; }

.closeable button {
  color: #cbcbcb; }

.bg-success {
  background-color: rgba(144, 185, 0, 0.85);
  color: #ffffff; }
  .bg-success a {
    color: #cdcdcd; }
    .bg-success a:hover {
      color: #f1f1f1; }

.bg-info {
  background-color: rgba(1, 123, 193, 0.85);
  color: #ffffff; }
  .bg-info a {
    color: #e9cd60; }
    .bg-info a:hover {
      color: #dfb81c; }

.bg-warning {
  background-color: rgba(223, 184, 28, 0.85);
  color: #ffffff; }
  .bg-warning a {
    color: #cdcdcd; }
    .bg-warning a:hover {
      color: #f1f1f1; }

.bg-danger {
  background-color: rgba(241, 241, 241, 0.85);
  color: #ffffff; }
  .bg-danger a {
    color: #e9cd60; }
    .bg-danger a:hover {
      color: #dfb81c; }

.auth-wrapper .panel {
  padding: 0px 0px 0px 0px; }
  .auth-wrapper .panel form {
    padding: 40px 20px 60px 20px; }
  .auth-wrapper .panel p, .auth-wrapper .panel label, .auth-wrapper .panel span, .auth-wrapper .panel input, .auth-wrapper .panel button {
    font-size: 18px;
    line-height: 18px;
    font-family: "Lato", Arial; }
  .auth-wrapper .panel label {
    margin-top: 7px;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left; }
    .auth-wrapper .panel label a {
      text-decoration: underline !important; }
  .auth-wrapper .panel .alert-messages {
    margin: 5px 0px 20px 0px;
    color: red;
    position: absolute; }
    .auth-wrapper .panel .alert-messages span {
      font-size: 15px; }

.auth-wrapper .form-horizontal .form-group {
  padding: 0 30px;
  margin-bottom: 25px; }

.auth-wrapper .form-login .form-group {
  margin-bottom: 35px; }

.basic-btns {
  padding-top: 8px;
  margin-bottom: -8px; }
  .basic-btns h5 {
    line-height: 35px;
    font-size: 12px; }
    .basic-btns h5.row-sm {
      line-height: 30px; }
    .basic-btns h5.row-xs {
      line-height: 22px; }
  .basic-btns > .row {
    padding-bottom: 4px; }

.btns-row > div {
  margin-bottom: 12px; }

.btns-same-width-sm .btn {
  width: 48px; }

.btns-same-width-md .btn {
  width: 79px; }

.btns-same-width-lg .btn {
  width: 112px; }

ul.btn-list {
  margin: 0 0 0 -18px;
  padding: 0;
  padding-top: 6px;
  clear: both; }
  ul.btn-list li {
    margin: 0px 0 12px 18px;
    padding: 0;
    list-style: none;
    float: left; }

.btn-group-wrapper {
  margin-bottom: 12px; }

.btn-icon {
  width: 34px;
  height: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center; }

.btn-group-example {
  float: left;
  margin-right: 30px;
  margin-bottom: 12px; }

.btn-toolbar-example {
  float: left; }

.progress-buttons-container {
  text-align: center;
  font-size: 16px; }
  .progress-buttons-container span.button-title {
    display: inline-block;
    width: 100%;
    line-height: 1;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px; }
  .progress-buttons-container .row + .row {
    margin-top: 30px; }

.button-panel {
  height: 315px; }
  .button-panel .btn {
    width: 150px; }

.large-buttons-panel {
  height: 202px; }

.button-panel.df-size-button-panel .btn-xs {
  width: 60px; }

.button-panel.df-size-button-panel .btn-sm {
  width: 90px; }

.button-panel.df-size-button-panel .btn-mm {
  width: 120px; }

.button-panel.df-size-button-panel .btn-md {
  width: 150px; }

.button-panel.df-size-button-panel .btn-xm {
  width: 175px; }

.button-panel.df-size-button-panel .btn-lg {
  width: 200px; }

.button-wrapper {
  text-align: center;
  margin: 5px 0; }

.admin-chart {
  width: 100%;
  height: 500px;
  font-size: 11px; }

.amcharts-export-menu-top-right {
  top: 10px;
  right: 0; }

#pieChart {
  max-width: 1120px; }

.amcharts-pie-slice {
  transform: scale(1);
  transform-origin: 50% 50%;
  transition-duration: 0.3s;
  transition: all .3s ease-out;
  cursor: pointer;
  box-shadow: 0 0 30px 0 #000; }

.amcharts-pie-slice:hover {
  transform: scale(1.1);
  filter: url(#shadow); }

.amChartsButtonSelected {
  background-color: #CC0000;
  border: 1px solid #CC0000;
  color: #666666;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 1px; }

.amChartsButton {
  background-color: #EEEEEE;
  border: 1px solid #CCCCCC;
  color: #000000;
  border-radius: 5px;
  margin: 1px; }

.ct-area {
  fill-opacity: .5; }

.ct-label {
  color: #666666;
  opacity: 0.9;
  fill: #666666; }

.ct-chart .ct-label {
  font-size: 1em; }

.ct-chart svg {
  width: 100%;
  display: block; }

.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut, .ct-series-a .ct-slice-pie {
  stroke: #c2de44; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-area {
  fill: #c2de44; }

.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut, .ct-series-b .ct-slice-pie {
  stroke: #90b900; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: #90b900; }

.ct-series-c .ct-bar, .ct-series-c .ct-line, .ct-series-c .ct-point, .ct-series-c .ct-slice-donut, .ct-series-c .ct-slice-pie {
  stroke: #f1f1f1; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-area {
  fill: #f1f1f1; }

.ct-series-d .ct-bar, .ct-series-d .ct-line, .ct-series-d .ct-point, .ct-series-d .ct-slice-donut, .ct-series-d .ct-slice-pie {
  stroke: #dfb81c; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-area {
  fill: #dfb81c; }

.ct-series-e .ct-bar, .ct-series-e .ct-line, .ct-series-e .ct-point, .ct-series-e .ct-slice-donut, .ct-series-e .ct-slice-pie {
  stroke: #017bc1; }

.ct-series-e .ct-slice-pie, .ct-series-e .ct-area {
  fill: #017bc1; }

@media screen and (min-width: 992px) {
  .row.morris-up > div {
    margin-top: -434px; } }

.area-morris-header {
  margin-top: 20px; }

.stacked-bar .ct-bar {
  stroke-width: 30px; }

.amChartsCompareList {
  border: 1px solid #CCCCCC; }

.pie-chart-panel {
  padding: 0; }

.chart-panel {
  height: 495px; }

#filterChart {
  width: 96%;
  font-size: 11px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px; }

.amcharts-graph-g1 .amcharts-graph-fill {
  filter: url(#blur); }

.amcharts-graph-g2 .amcharts-graph-fill {
  filter: url(#blur); }

.amcharts-cursor-fill {
  filter: url(#shadow); }

.chartist h5 {
  font-weight: 400; }

.chart-canvas {
  position: relative;
  display: inline-block; }

#dashboard .header-page .title-header-page h1:before {
  background-image: url("../../assets/pictures/icon-dashboard.png"); }

#dashboard .box-main-content-page .list-box-content-careplain {
  padding-top: 60px; }

#dashboard .box-main-content-page .box-shadow-border-right {
  box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2); }

#dashboard .box-main-content-page .box-shadow-border-left {
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 991px) {
    #dashboard .box-main-content-page .box-shadow-border-left {
      box-shadow: none; } }

@media screen and (max-width: 767px) {
  #dashboard .box-main-content-page {
    padding-left: 0;
    padding-right: 0; } }

#dashboard .sub-menu-dashboard {
  list-style: none;
  margin: 0;
  padding: 0; }
  #dashboard .sub-menu-dashboard li {
    list-style-type: none;
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 24px 0;
    border: 1px solid #f8f8f8;
    text-align: center;
    background-color: #fdfdfd;
    height: 72px; }
    @media screen and (max-width: 480px) {
      #dashboard .sub-menu-dashboard li {
        height: 95px;
        display: block; } }
    #dashboard .sub-menu-dashboard li:before {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #fdfdfd;
      position: absolute;
      bottom: 0;
      left: 0; }
    #dashboard .sub-menu-dashboard li a {
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-align: center;
      position: relative;
      color: #282e34;
      display: block; }
    #dashboard .sub-menu-dashboard li .visible-mobile-small {
      display: none; }
    @media screen and (max-width: 520px) {
      #dashboard .sub-menu-dashboard li:first-child a {
        line-height: 40px;
        padding-left: 10%; } }
    #dashboard .sub-menu-dashboard li:first-child a:before {
      content: "";
      background-image: url("../../assets/pictures/icon-my-careplan.png");
      background-size: contain;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: bottom; }
      @media (max-width: 520px) {
        #dashboard .sub-menu-dashboard li:first-child a:before {
          position: absolute;
          top: 50%;
          left: 10%;
          transform: translateY(-50%); } }
    #dashboard .sub-menu-dashboard li:last-child {
      border-right: none; }
      @media (max-width: 480px) {
        #dashboard .sub-menu-dashboard li:last-child a {
          padding-left: 35px; } }
      #dashboard .sub-menu-dashboard li:last-child a:before {
        content: "";
        background-image: url("../../assets/pictures/icon-favourite-content.png");
        background-size: contain;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: bottom;
        margin-right: 10px; }
        @media (max-width: 480px) {
          #dashboard .sub-menu-dashboard li:last-child a:before {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%); } }
    #dashboard .sub-menu-dashboard li.active, #dashboard .sub-menu-dashboard li:hover, #dashboard .sub-menu-dashboard li:focus {
      background-color: #fff;
      position: relative; }
      #dashboard .sub-menu-dashboard li.active:before, #dashboard .sub-menu-dashboard li:hover:before, #dashboard .sub-menu-dashboard li:focus:before {
        content: "";
        width: 100%;
        height: 3px;
        background-color: #bed74b;
        position: absolute;
        bottom: 0;
        left: 0; }
    @media only screen and (max-width: 480px) {
      #dashboard .sub-menu-dashboard li .hidden-mobile-small {
        display: none; }
      #dashboard .sub-menu-dashboard li .visible-mobile-small {
        display: block; } }

#dashboard .list-box-content-dashboard {
  /*overflow: hidden;*/ }
  #dashboard .list-box-content-dashboard .box-load-more {
    width: 100%;
    text-align: center;
    background: #F2F3F4;
    padding: 20px;
    margin-top: 50px; }
    #dashboard .list-box-content-dashboard .box-load-more h6 {
      font-size: 22px;
      font-family: "Lato";
      font-weight: bold;
      color: #282E34;
      cursor: pointer;
      position: relative;
      display: inline-block;
      margin-bottom: 0;
      padding-left: 25px; }
      #dashboard .list-box-content-dashboard .box-load-more h6.active img {
        animation: spin 2s linear infinite; }
      #dashboard .list-box-content-dashboard .box-load-more h6 div.btn-loading {
        position: relative;
        width: 20px;
        height: 20px;
        display: inline-block; }
        #dashboard .list-box-content-dashboard .box-load-more h6 div.btn-loading img {
          width: 100%;
          margin-bottom: 5px; }
  #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content {
    border-bottom: 1px solid #f8f8f8;
    padding: 0; }
    #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content .title-content {
      font-size: 28px;
      font-weight: 700;
      font-family: "PT Sans", sans-serif;
      color: #177bbe;
      margin-bottom: 20px;
      margin-top: 20px;
      cursor: pointer; }
    #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content .button-show-content {
      color: #177bbe;
      border: 1px solid;
      border-color: rgba(23, 123, 190, 0.5);
      height: 35px;
      width: 35px;
      padding: 0;
      position: relative;
      float: right;
      cursor: pointer;
      margin-top: 20px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content .button-show-content.show:after {
        content: "";
        height: 0;
        width: 0;
        background-color: transparent; }
      #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content .button-show-content:before {
        content: "";
        width: 15px;
        height: 2px;
        background-color: #177bbe;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      #dashboard .list-box-content-dashboard .current-box-content-title .box-title-content .button-show-content:after {
        content: "";
        height: 15px;
        width: 2px;
        background-color: #177bbe;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard {
    padding: 0;
    margin: 20px 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #6a6e72; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard.dashboard-content-hidden {
      display: none; }
    @media screen and (max-width: 991px) {
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard {
        margin-bottom: 10px; } }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .sub-title-content {
      color: #282e34;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
      font-family: "PT Sans", sans-serif; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .sub-title-content h4 {
        color: #282e34;
        font-size: 20px;
        font-weight: 700;
        font-family: "PT Sans", sans-serif;
        padding-right: 35px; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard p a {
      color: #177bbe;
      display: inline-block; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard p a:hover {
        text-decoration: underline; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .content-list-dashboard p {
      padding-left: 20px;
      position: relative; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .content-list-dashboard p:before {
        content: "• ";
        color: #1a7cbd;
        left: 0;
        position: absolute; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-attention-content {
      /*background-color: #b2c946;*/
      padding: 35px;
      color: #282e34; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-attention-content p {
        padding-left: 50px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-attention-content p:first-child {
          position: relative;
          padding-left: 50px;
          font-weight: 700;
          color: #F00; }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-attention-content p:first-child:before {
            content: "";
            position: absolute;
            left: -35px;
            top: 0;
            background-image: url("../../assets/pictures/icon-careful-red.png");
            background-size: 100%;
            width: 60px;
            height: 60px;
            background-repeat: no-repeat; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis {
      border: 1px solid #35a100;
      padding: 35px;
      background-color: #f9fcf7; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-1-10 {
        border: 1px solid #0d9000;
        background-color: #f0f7ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-1-10 .title-excellent-prognosis {
          color: #0d9000; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-1-10 .percent-excellent-prognosis .percent-your-score-box i {
          color: #0d9000; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-11-20 {
        border: 1px solid #35a100;
        background-color: #f1f7ee; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-11-20 .title-excellent-prognosis {
          color: #35a100; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-11-20 .percent-excellent-prognosis .percent-your-score-box i {
          color: #35a100; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-21-30 {
        border: 1px solid #5db000;
        background-color: #f3f7ee; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-21-30 .title-excellent-prognosis {
          color: #5db000; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-21-30 .percent-excellent-prognosis .percent-your-score-box i {
          color: #5db000; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-31-40 {
        border: 1px solid #8abf00;
        background-color: #f5f7ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-31-40 .title-excellent-prognosis {
          color: #8abf00; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-31-40 .percent-excellent-prognosis .percent-your-score-box i {
          color: #8abf00; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-41-50 {
        border: 1px solid #bbce00;
        background-color: #f6f7ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-41-50 .title-excellent-prognosis {
          color: #bbce00; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-41-50 .percent-excellent-prognosis .percent-your-score-box i {
          color: #bbce00; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-51-60 {
        border: 1px solid #ddc800;
        background-color: #f7f6ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-51-60 .title-excellent-prognosis {
          color: #ddc800; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-51-60 .percent-excellent-prognosis .percent-your-score-box i {
          color: #ddc800; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-61-70 {
        border: 1px solid #eda900;
        background-color: #f7f5ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-61-70 .title-excellent-prognosis {
          color: #eda900; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-61-70 .percent-excellent-prognosis .percent-your-score-box i {
          color: #eda900; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-71-80 {
        border: 1px solid #fc8600;
        background-color: #f7f3ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-71-80 .title-excellent-prognosis {
          color: #fc8600; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-71-80 .percent-excellent-prognosis .percent-your-score-box i {
          color: #fc8600; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-81-90 {
        border: 1px solid #ff5f00;
        background-color: #f7f2ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-81-90 .title-excellent-prognosis {
          color: #ff5f00; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-81-90 .percent-excellent-prognosis .percent-your-score-box i {
          color: #ff5f00; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-91-100 {
        border: 1px solid #ff3d00;
        background-color: #f7f1ef; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-91-100 .title-excellent-prognosis {
          color: #ff3d00; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis.rank-91-100 .percent-excellent-prognosis .percent-your-score-box i {
          color: #ff3d00; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .title-excellent-prognosis {
        color: #35a100;
        font-size: 28px;
        font-family: "PT Sans", sans-serif;
        text-align: center;
        font-weight: 700;
        margin-bottom: 75px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis {
        background-image: url("../../assets/pictures/icon-excellent-prognosis.png");
        width: 100%;
        height: 38px;
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 35px 0;
        position: relative;
        margin-bottom: 35px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .average-excellent-prognosis {
          position: absolute;
          width: 1px;
          height: 50px;
          top: -8px;
          background-color: #293b2e;
          margin-left: -1px; }
          @media screen and (max-width: 1199px) {
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .average-excellent-prognosis {
              top: -14px; } }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .average-excellent-prognosis span {
            position: absolute;
            top: -30px;
            left: -45px;
            color: #6a6e72;
            width: 100px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .percent-your-score {
          position: absolute;
          width: 2px;
          height: 55px;
          top: 0;
          background-color: #323a2e; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .percent-your-score-box {
          position: absolute;
          bottom: -35px;
          margin-left: -77px;
          color: #292f35;
          width: 145px;
          background-color: #ffffff;
          border: 1px solid #f1f2f2;
          padding: 15px; }
          @media screen and (max-width: 480px) {
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .percent-your-score-box {
              margin-left: -50px;
              width: 125px;
              font-size: 13px; } }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .excellent-prognosis .percent-excellent-prognosis .percent-your-score-box i {
            font-style: normal;
            font-weight: bold; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered {
      background-color: #fbfcfc; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered {
        border-right: 1px solid #f1f1f1;
        text-align: center; }
        @media screen and (max-width: 991px) {
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered {
            border-bottom: 1px solid #f1f1f1; } }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .title-map-recovered {
          font-weight: 700;
          font-size: 18px;
          font-family: 'Lato', sans-serif;
          text-align: center;
          color: #1a7cbd;
          margin-bottom: 30px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .paint-map-recovered {
          display: inline-block;
          position: relative;
          height: 160px;
          width: 160px; }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .paint-map-recovered .percircle {
            background-color: #92c53f;
            font-size: 160px; }
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .paint-map-recovered .percircle:after {
              background-color: #fff; }
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .paint-map-recovered .percircle .bar {
              border: .08em solid #1a7cbd;
              width: .846em;
              height: .846em; }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .paint-map-recovered .image-bluecircle {
            position: absolute;
            top: 49%;
            left: 53%;
            transform: translate(-50%, -50%);
            width: 140px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .number-percent-recovered {
          text-align: left;
          margin-top: 35px;
          margin-left: 15px; }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .number-percent-recovered .recovered {
            color: #1a7cbd;
            margin-right: 20px; }
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .statistical-recovered .map-statistical-recovered .number-percent-recovered .recovered.not-have {
              color: #92c53f; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score {
      background-color: #fbfcfc;
      padding: 40px;
      text-align: center; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .chart-canvas-ab {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .title-pain-coping-score {
        font-weight: 700;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        text-align: center;
        color: #1a7cbd;
        margin-bottom: 30px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 160px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-score {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-score.percircle {
            font-size: 160px; }
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-score.percircle:after {
              background-color: #fff; }
            #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-score.percircle .bar {
              border: .081em solid #307bbb;
              width: .85em;
              height: .85em; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-previous {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-previous.percircle:after {
            background-color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
          #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .paint-previous.percircle .bar {
            border: .09em solid #92c53f; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .pain-coping-score .paint-pain-coping-score .image-bluecircle {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          position: absolute;
          top: 49%;
          left: 50%;
          transform: translate(-50%, -50%);
          /*> img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
              }*/ }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard hr.line-content {
      width: 100%;
      border-top: 0.5px solid #f5f6f6;
      margin: 50px 0; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color {
      margin-top: 25px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color .percent-score, #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color .percent-previous {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 18px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-pain-coping-score .percent-score {
        background-color: #1a7cbd; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-pain-coping-score .text-percent-score {
        color: #1a7cbd;
        padding-right: 10px;
        margin-top: 10px;
        clear: both; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-pain-coping-score .percent-previous {
        background-color: #d4d5d7; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-pain-coping-score .text-percent-previous {
        color: #d4d5d7;
        margin-top: 10px;
        clear: both; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-fear-avoidance-scale .percent-score {
        background-color: #1a7cbd; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-fear-avoidance-scale .text-percent-score {
        color: #1a7cbd;
        padding-right: 10px;
        margin-top: 10px;
        clear: both; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-fear-avoidance-scale .percent-previous {
        background-color: #92c53f; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .note-color.color-fear-avoidance-scale .text-percent-previous {
        color: #92c53f;
        margin-top: 10px;
        clear: both; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .background-your-mood {
      background-color: #fbfcfc; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .background-your-mood .border-box-right {
        border-right: 1px solid #f3f5f5; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .background-your-mood .title-your-mood {
        font-weight: 700;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        text-align: center;
        color: #1a7cbd;
        margin-bottom: 30px; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder {
      position: relative;
      width: 100%;
      height: 24px;
      background-color: #d1d3d4;
      border-radius: 12px;
      margin: 20px 0; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .score-point-ladder-mask {
        position: relative;
        height: 24px;
        overflow: hidden;
        border-radius: 12px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .score-point-ladder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 24px;
        background-color: #1a7cbd;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .previous-point-ladder {
        position: absolute;
        top: -8px;
        z-index: 5;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 14px solid #92c53f;
        margin-left: -8px; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .previous-point-ladder:before {
          content: 'Previous score';
          color: #92c53f;
          font-size: 11px;
          width: 80px;
          display: inline-block;
          position: absolute;
          top: -35px;
          left: -35px; }
      #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .box-divide-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 24px;
        overflow: hidden; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .box-divide-div .divide-div-left {
          position: absolute;
          top: 0;
          left: 25%;
          height: 24px;
          width: 1px;
          background-color: #fff;
          z-index: 3; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .box-divide-div .divide-div-center {
          position: absolute;
          top: 0;
          left: 50%;
          height: 24px;
          width: 1px;
          background-color: #fff;
          z-index: 3; }
        #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .box-point-ladder .box-divide-div .divide-div-right {
          position: absolute;
          top: 0;
          left: 75%;
          height: 24px;
          width: 1px;
          background-color: #fff;
          z-index: 3; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard a {
      color: #1a7cbd;
      font-family: 'Lato', sans-serif;
      font-size: 15px;
      font-weight: 700; }
    #dashboard .list-box-content-dashboard .current-box-content-title .content-dashboard .play-video {
      cursor: pointer; }
  #dashboard .list-box-content-dashboard .box-none-data {
    text-align: center; }
    @media (max-width: 480px) {
      #dashboard .list-box-content-dashboard .box-none-data {
        margin-top: 30px; } }
    #dashboard .list-box-content-dashboard .box-none-data span {
      background-image: url("../../assets/pictures/icon-favourite-content.png");
      background-size: 100%;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: bottom; }
    #dashboard .list-box-content-dashboard .box-none-data h4 {
      font-family: "Lato-Black";
      font-size: 22px;
      color: #a5a8aa;
      margin: 20px; }
    #dashboard .list-box-content-dashboard .box-none-data p {
      font-family: "Lato";
      font-size: 16px;
      color: #a5a8aa; }

#dashboard .backgroud-right-content {
  background-color: #fbfcfc; }

#dashboard .title-content-right {
  font-size: 18px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #177bbe;
  margin-bottom: 20px; }

#dashboard .sub-title-content-right {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #6a6e72;
  margin-bottom: 20px; }

#dashboard .content-content-right {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #6a6e72;
  margin-bottom: 20px; }

#dashboard .date-content-right {
  color: #282e34;
  font-size: 20px;
  font-weight: 700;
  font-family: "PT Sans", sans-serif; }

#dashboard .box-print-your-report {
  background-color: #b2c946;
  padding: 40px 20px;
  text-align: center; }
  #dashboard .box-print-your-report .title-content-right, #dashboard .box-print-your-report .content-content-right {
    color: #282e34; }
  #dashboard .box-print-your-report .button-print-report {
    color: #fff;
    background-color: #177bbe;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
    cursor: pointer; }

#dashboard .box-giude-me {
  background-color: #177bbe;
  padding: 40px 20px;
  text-align: center; }
  #dashboard .box-giude-me .title-content-right {
    color: #fff; }
  #dashboard .box-giude-me .content-content-right {
    color: #fff; }
  #dashboard .box-giude-me .button-print-report {
    background-color: #fff;
    color: #177bbe;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px; }

#dashboard hr.line-content {
  width: 100%;
  height: 1px;
  background-color: #f7f8f8;
  margin: 50px 0; }

#dashboard .box-did-you-now {
  padding: 45px 30px 30px;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #f7f8f8;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.2); }
  #dashboard .box-did-you-now .icon-print-your-report {
    position: absolute;
    top: 30px;
    right: 30px; }
  #dashboard .box-did-you-now ul {
    padding-left: 20px; }
    #dashboard .box-did-you-now ul li {
      position: relative; }
      #dashboard .box-did-you-now ul li:before {
        content: "• ";
        color: #1a7cbd;
        left: -20px;
        position: absolute; }

#dashboard .box-recomended-for-you {
  padding: 30px;
  /*background-color: #fbfcfc;*/ }
  #dashboard .box-recomended-for-you a {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #177bbe; }

#dashboard .box-like {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0; }
  #dashboard .box-like .tooltip {
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      #dashboard .box-like .tooltip {
        left: initial !important;
        right: 0; }
        #dashboard .box-like .tooltip .tooltip-arrow {
          left: 100% !important;
          margin-left: -20px !important; } }
  #dashboard .box-like span {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: inline-block;
    background-image: url("../../assets/img/app/video-img/heart.png");
    background-size: 30px 30px;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    -webkit-transition: all .8s;
    transition: all .8s;
    -moz-transition: all .7s; }
    #dashboard .box-like span:hover, #dashboard .box-like span.active {
      background-image: url("../../assets/img/app/video-img/heart-active.png");
      -webkit-transition: all .8s;
      transition: all .8s;
      -moz-transition: all .7s; }

#dashboard .percircle:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1); }

#dashboard .percircle.small {
  font-size: 120px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.letter-layout {
  margin-top: -15px;
  margin-right: -22px; }

.mail-panel.panel > .panel-body {
  padding-left: 0; }

.mail-navigation-container {
  float: left;
  position: relative;
  height: 550px;
  transition: width 0.5s;
  color: #666666;
  width: 200px;
  overflow: hidden;
  padding: 0; }
  .mail-navigation-container .mail-navigation {
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    padding: 10px 10px 10px 30px;
    margin-left: 0;
    transition: 0.8s padding ease; }
    .mail-navigation-container .mail-navigation .new-mails {
      position: absolute;
      left: 150px;
      padding: 1px 6px;
      border: 1px solid;
      background-color: transparent;
      margin-top: -2px;
      font-size: 12px;
      margin-right: 5px;
      border-radius: 10px;
      transition: 0.8s left ease; }
    .mail-navigation-container .mail-navigation.active {
      background: rgba(0, 0, 0, 0.2);
      color: white;
      transition: background-color .5s ease; }
      .mail-navigation-container .mail-navigation.active:hover {
        background-color: rgba(0, 0, 0, 0.3); }
      .mail-navigation-container .mail-navigation.active .new-mails {
        background-color: transparent; }
    .mail-navigation-container .mail-navigation:hover {
      background-color: rgba(0, 0, 0, 0.1); }
  .mail-navigation-container .btn.compose-button {
    width: 140px;
    font-weight: 300;
    border: #d6d6d6 2px solid;
    background-color: transparent;
    margin: 15px 0; }

.labels {
  margin-top: 14px; }

.labels-container {
  margin-top: 16px;
  text-align: center;
  padding-right: 28px;
  padding-left: 20px; }
  .labels-container .label-item {
    display: inline-block; }

.labels-title {
  padding-left: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12); }
  .labels-title .label-header {
    line-height: 24px; }

.label-item {
  margin: 5px; }

.add-label-container {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 300; }
  .add-label-container .label-input-stub {
    font-size: 14px;
    margin-left: 5px; }
  .add-label-container i {
    cursor: pointer; }

.margin-left {
  margin-left: 10px; }

.mail-messages-control {
  padding: 10px; }
  .mail-messages-control ul.dropdown-menu {
    margin-top: 5px; }
  .mail-messages-control .btn {
    background-color: transparent;
    width: auto; }
    .mail-messages-control .btn:focus, .mail-messages-control .btn:hover, .mail-messages-control .btn:active {
      color: #666666;
      opacity: 0.8;
      box-shadow: none; }
  .mail-messages-control .more-button {
    width: 60px;
    font-size: 14px;
    padding: 6px 5px; }
  .mail-messages-control .refresh-button {
    margin-left: 10px; }
  .mail-messages-control span.select-all-label {
    font-size: 13px;
    font-weight: 300; }

.message-container, .side-message-navigation {
  float: left;
  padding: 0 15px;
  position: relative;
  height: 550px;
  transition: width 0.5s;
  width: calc(100% - 200px);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.12); }

.side-message-navigation {
  padding: 0;
  width: calc(100% - 200px); }
  .side-message-navigation .side-message-navigation-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.mail-body-part {
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  height: 16px;
  overflow: hidden;
  margin-top: 3px;
  margin-left: 5px;
  opacity: .8; }

.mail-tag.tag.label {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  width: 65px; }

.phone-email i {
  color: #666666; }

.message-container {
  padding: 15px 15px 10px 15px;
  overflow-x: hidden; }

.little-human {
  cursor: pointer;
  transition: border-left 1.5s ease;
  font-weight: 300; }
  .little-human .little-human-picture {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    margin: 7px 0px 7px 7px;
    transition: all .2s ease-in-out; }
  .little-human .name {
    font-size: 14px;
    vertical-align: super;
    white-space: nowrap; }
  .little-human .name-wrap {
    margin-left: 10px; }
  .little-human .date {
    float: right;
    margin: 10px 10px 0 5px;
    display: inline-block;
    font-size: 13px;
    white-space: nowrap; }
  .little-human .tag {
    vertical-align: super; }
  .little-human .subject {
    white-space: nowrap;
    margin: 0 30px;
    font-size: 16px; }
  .little-human:hover {
    background-color: rgba(255, 255, 255, 0.1); }
    .little-human:hover .little-human-picture {
      animation: rotateReturnAnimation 0.5s; }

.back-button {
  margin-left: 45px; }

.name-container {
  margin-left: 10px; }

.mail-checkbox {
  margin-top: 5px;
  margin-left: 6px; }
  .mail-checkbox .custom-checkbox, .mail-checkbox label.custom-radio {
    margin-left: 5px; }

.mail-panel .tag {
  text-transform: lowercase;
  font-size: 11px;
  font-weight: 300;
  width: 45px;
  cursor: pointer;
  display: inline-block; }
  .mail-panel .tag.label {
    padding: .2em .5em;
    border-radius: 2px;
    line-height: 1.1; }
  .mail-panel .tag.work {
    background-color: #c2de44; }
  .mail-panel .tag.study {
    background-color: #dfb81c; }
  .mail-panel .tag.family {
    background-color: #90b900; }
  .mail-panel .tag.friend {
    background-color: #f1f1f1; }

.messages {
  overflow: auto; }
  .messages table {
    width: 100%; }

@media screen and (min-width: 1199px) {
  .photo-td {
    width: 55px; }
  .check-td {
    width: 35px; } }

.important {
  color: #dfb81c; }

.message-control-icon {
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px; }

.person-info {
  padding: 0 0 10px 45px;
  margin-top: 10px; }
  .person-info .human-picture {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: -65px; }
  .person-info .name {
    display: inline-block;
    margin-left: 10px;
    margin-top: 5px; }
    .person-info .name h2 {
      font-weight: 300;
      margin-bottom: 0;
      font-size: 24px; }
    .person-info .name .second-name {
      margin: 5px 0; }

.no-padding {
  padding: 0; }

.contact-info {
  display: inline-block; }
  .contact-info div {
    margin-top: 10px; }
  .contact-info.phone-email .ion-iphone {
    font-size: 32px;
    width: 27px;
    display: inline-block; }
  .contact-info.phone-email .phone {
    font-size: 19px;
    font-weight: 300;
    vertical-align: super; }
  .contact-info.phone-email .ion-email {
    font-size: 24px;
    width: 27px;
    display: inline-block; }
  .contact-info.phone-email .email {
    font-size: 16px;
    font-weight: 300;
    vertical-align: super; }
  .contact-info.position-address div {
    margin-top: 17px; }
  .contact-info.position-address .position {
    font-size: 20px;
    font-weight: 300; }
  .contact-info.position-address .address {
    font-size: 16px;
    font-weight: 300; }

.message-details {
  margin: 5px 0;
  padding: 5px 0 5px 30px; }
  .message-details .subject {
    font-size: 20px;
    margin-right: 10px;
    font-weight: 300; }
  .message-details .control-icons {
    float: right;
    font-size: 20px;
    margin-right: 40px; }
    .message-details .control-icons i {
      cursor: pointer; }
  .message-details .date {
    white-space: nowrap;
    font-size: 12px; }

.message-body {
  margin: 20px 0;
  padding-left: 30px;
  font-weight: 300;
  line-height: 18px; }
  .message-body p {
    margin-bottom: 0; }

.attachment {
  margin: 5px 0;
  padding-left: 30px; }
  .attachment .file-icon {
    font-size: 24px;
    cursor: pointer; }
  .attachment .file-name {
    font-size: 14px;
    margin-left: 3px;
    position: relative;
    top: -3px;
    font-weight: 300; }
  .attachment .file-links {
    font-size: 14px; }

.line {
  height: 1px;
  background-color: #cbcbcb;
  opacity: 0.3; }

.answer-button {
  margin-left: 20px;
  color: white;
  font-size: 16px;
  font-weight: 300;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #F26798; }
  .answer-button:hover {
    color: white; }
  .answer-button:focus {
    color: white; }

.answer-container {
  float: right;
  margin-top: 10px; }
  .answer-container .btn {
    margin-top: 3px;
    background-color: #d4e87c;
    border: none;
    color: white;
    width: 100px;
    transition: none; }
    .answer-container .btn:hover {
      transform: none;
      background-color: #f1f1f1; }

@keyframes rotateReturnAnimation {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(0deg); } }

@media screen and (max-width: 1199px) {
  .name-h {
    display: inline; }
  .person-info .human-picture {
    margin-top: -35px; }
  .person-info .name {
    margin-top: 20px; }
  .person-info .mail-tag {
    margin-top: 10px; }
  .second-name {
    margin-top: 0; } }

@media screen and (max-width: 990px) {
  .person-info .human-picture {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    margin: 5px auto;
    display: block; }
  .person-info, .message-details, .message-body, .attachment {
    padding-left: 10px; }
  .message-container {
    padding-left: 15px; }
  .back-button, .contact-info {
    margin-left: 15px; }
  .additional-info {
    display: none; }
  .little-human .date {
    font-size: 10px; }
  .margin-left {
    margin-left: 5px; } }

@media screen and (max-width: 760px) {
  .mail-navigation-container {
    width: 175px; }
    .mail-navigation-container .mail-navigation {
      padding-left: 19px; }
      .mail-navigation-container .mail-navigation .new-mails {
        left: 137px; }
  .side-message-navigation {
    width: calc(100% - 175px); }
  .message-container {
    width: calc(100% - 175px); }
  .mail-body-part {
    display: none; }
  .little-human .little-human-picture {
    width: 30px;
    height: 30px; }
  .messages-control .more-button {
    width: 50px; } }

@media screen and (max-width: 560px) {
  .mail-navigation-container {
    padding: 0;
    width: 0; }
    .mail-navigation-container.expanded {
      width: 155px; }
    .mail-navigation-container .mail-navigation {
      font-size: 14px;
      padding-left: 23px; }
      .mail-navigation-container .mail-navigation .new-mails {
        left: 116px;
        padding: 0px 5px;
        margin-top: 0px;
        font-size: 12px; }
      .mail-navigation-container .mail-navigation .labels {
        margin-left: 10px; }
    .mail-navigation-container .compose-button {
      width: 110px; }
  .mail-messages-control .custom-checkbox, .mail-messages-control label.custom-radio {
    margin-left: 5px;
    margin-top: -2px; }
  .side-message-navigation, .message-container {
    width: calc(100% - 155px); }
    .side-message-navigation.expanded, .message-container.expanded {
      width: 100%;
      border: none;
      box-shadow: none; }
  div.toggle-navigation-container {
    display: inline-block; }
  .little-human .name {
    white-space: inherit; }
  .little-human .little-human-picture {
    display: none; }
  .add-label-container {
    padding-left: 0;
    font-size: 13px;
    font-weight: 300; } }

.compose-header {
  padding: 8px 3px 8px 10px;
  color: white;
  font-weight: 300;
  font-size: 13px;
  background-color: #6b6b6b; }

.header-controls {
  display: inline-block;
  float: right; }
  .header-controls i {
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer; }
    .header-controls i:hover {
      color: #d4e87c; }

.compose-container .ta-scroll-window > .ta-bind {
  height: 290px;
  overflow-y: scroll; }

.modal-compose input.form-control.compose-input {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0; }

.compose-footer {
  padding: 2px 4px;
  background-color: whitesmoke; }
  .compose-footer .btn-send {
    background-color: #d4e87c;
    color: white;
    padding: 2px 10px;
    margin: 3px;
    font-weight: 300; }
  .compose-footer .btn-default {
    background-color: transparent;
    border: none; }

.footer-controls {
  float: right;
  margin: 6px; }

.compose-footer-icon {
  font-size: 18px;
  margin-right: 5px;
  cursor: pointer;
  color: #929497; }
  .compose-footer-icon:hover {
    color: #d4e87c; }

.compose-container {
  background-color: whitesmoke; }
  .compose-container .ta-text.ta-editor {
    background-color: #cbcbcb; }
    .compose-container .ta-text.ta-editor div:focus {
      outline: none; }

.footer-control-first {
  border-right: 2px solid #949494;
  display: inline-block;
  padding-right: 9px; }

.toggle-navigation-container {
  display: none;
  vertical-align: middle;
  margin-left: -1px; }
  .toggle-navigation-container.detail-page {
    margin-left: 10px; }

.collapse-navigation-link {
  font-size: 32px;
  color: #666666; }
  .collapse-navigation-link:visited {
    color: #666666; }
  .collapse-navigation-link:hover {
    color: #dfb81c;
    opacity: .8; }

.collapsed {
  width: 0; }

.modal .modal-dialog.modal-compose {
  max-width: 398px;
  position: fixed;
  bottom: 0;
  right: 0;
  max-height: 474px; }
  .modal .modal-dialog.modal-compose .form-control, .modal .modal-dialog.modal-compose .bootstrap-tagsinput input {
    color: #929497;
    border-radius: 0; }
    .modal .modal-dialog.modal-compose .form-control::-webkit-input-placeholder, .modal .modal-dialog.modal-compose .bootstrap-tagsinput input::-webkit-input-placeholder {
      color: #929497;
      opacity: 1; }
    .modal .modal-dialog.modal-compose .form-control:-moz-placeholder, .modal .modal-dialog.modal-compose .bootstrap-tagsinput input:-moz-placeholder {
      /* Firefox 18- */
      color: #929497;
      opacity: 1; }
    .modal .modal-dialog.modal-compose .form-control::-moz-placeholder, .modal .modal-dialog.modal-compose .bootstrap-tagsinput input::-moz-placeholder {
      /* Firefox 19+ */
      color: #929497;
      opacity: 1; }
    .modal .modal-dialog.modal-compose .form-control:-ms-input-placeholder, .modal .modal-dialog.modal-compose .bootstrap-tagsinput input:-ms-input-placeholder {
      color: #929497;
      opacity: 1; }
  .modal .modal-dialog.modal-compose .ta-toolbar .btn {
    border-radius: 0;
    color: #929497;
    border: 1px transparent; }
    .modal .modal-dialog.modal-compose .ta-toolbar .btn.active {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12); }
  .modal .modal-dialog.modal-compose p,
  .modal .modal-dialog.modal-compose h1, .modal .modal-dialog.modal-compose h2, .modal .modal-dialog.modal-compose h3, .modal .modal-dialog.modal-compose h4, .modal .modal-dialog.modal-compose h5, .modal .modal-dialog.modal-compose h6,
  .modal .modal-dialog.modal-compose .pie-chart-item,
  .modal .modal-dialog.modal-compose .panel-heading > .dropdown .dropdown-toggle,
  .modal .modal-dialog.modal-compose .panel-title,
  .modal .modal-dialog.modal-compose ol.blur span,
  .modal .modal-dialog.modal-compose ul.blur,
  .modal .modal-dialog.modal-compose .popular-app-cost,
  .modal .modal-dialog.modal-compose .popular-app-info,
  .modal .modal-dialog.modal-compose .panel-title > .small,
  .modal .modal-dialog.modal-compose .panel-title > .small > a,
  .modal .modal-dialog.modal-compose .panel-title > a,
  .modal .modal-dialog.modal-compose .panel-title > small,
  .modal .modal-dialog.modal-compose .panel-title > small > a,
  .modal .modal-dialog.modal-compose .traffic-text span,
  .modal .modal-dialog.modal-compose .form-group label,
  .modal .modal-dialog.modal-compose .help-block {
    color: #929497; }
  .modal .modal-dialog.modal-compose .feed-message .message-time, .modal .modal-dialog.modal-compose .text-muted {
    color: #5f6164; }

@media (max-width: 640px) {
  .modal .modal-dialog.modal-compose {
    max-height: 600px; } }

.tooltip {
  z-index: 99999; }

.btn {
  vertical-align: inherit; }

.label {
  border-radius: 0; }

.label-primary {
  background: #c2de44; }

.label-info {
  background: #d4e87c; }

.label-success {
  background: #90b900; }

.label-warning {
  background: #dfb81c; }

.label-danger {
  background: #f1f1f1; }

.form-horizontal label {
  line-height: 34px;
  margin-bottom: 0;
  padding-top: 0 !important; }

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 1; }

.form-group label {
  margin-bottom: 5px;
  color: #666666;
  font-weight: 400;
  font-size: 13px; }

.form-control {
  color: #666666;
  border: 1px solid #D0D2D3;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: none; }
  .form-control::-webkit-input-placeholder {
    color: #666666;
    opacity: 0.7; }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #666666;
    opacity: 0.7; }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #666666;
    opacity: 0.7; }
  .form-control:-ms-input-placeholder {
    color: #666666;
    opacity: 0.7; }
  .form-control:focus {
    box-shadow: none;
    border-color: #b8b9bc;
    background: #ffffff; }

select.form-control {
  padding-left: 8px; }

textarea.form-control {
  height: 96px; }

.form-inline .form-group input {
  width: 100%; }

.form-inline .form-group label {
  margin-right: 12px; }

.form-inline button[type="submit"] {
  margin-left: 12px; }

.form-inline label.custom-checkbox > span, .form-inline label.custom-radio > span {
  display: block;
  margin-top: -13px;
  margin-right: 10px; }

.switch-container {
  display: inline-block; }
  .switch-container.primary .bootstrap-switch.bootstrap-switch-on {
    border-color: #c2de44; }
  .switch-container.success .bootstrap-switch.bootstrap-switch-on {
    border-color: #90b900; }
  .switch-container.warning .bootstrap-switch.bootstrap-switch-on {
    border-color: #dfb81c; }
  .switch-container.danger .bootstrap-switch.bootstrap-switch-on {
    border-color: #f1f1f1; }
  .switch-container.info .bootstrap-switch.bootstrap-switch-on {
    border-color: #d4e87c; }

.bootstrap-switch {
  border-radius: 5px;
  border: 1px solid #ffffff;
  transition: border-color ease-in-out .7s, box-shadow ease-in-out .7s; }
  .bootstrap-switch:focus {
    outline: none; }
  .bootstrap-switch.bootstrap-switch-off {
    border-color: #d6d6d6; }
  .bootstrap-switch.bootstrap-switch-focused {
    box-shadow: none; }
    .bootstrap-switch.bootstrap-switch-focused.bootstrap-switch-off {
      border-color: #d6d6d6; }
  .bootstrap-switch .bootstrap-switch-container {
    border-radius: 0; }
    .bootstrap-switch .bootstrap-switch-container:focus {
      outline: none; }
  .bootstrap-switch .bootstrap-switch-handle-on {
    border-radius: 0; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
      background: #ffffff; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
      background: #90b900; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
      background: #c2de44; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
      background: #dfb81c; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
      background: #f1f1f1; }
    .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
      background: #d4e87c; }
  .bootstrap-switch .bootstrap-switch-handle-off {
    border-radius: 0; }
  .bootstrap-switch .bootstrap-switch-label {
    background: transparent; }
  .bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    transition: margin-left .2s; }

.switches {
  margin-left: -12px;
  margin-bottom: -12px; }
  .switches .switch-container {
    float: left;
    margin-left: 12px;
    margin-bottom: 12px; }

.input-group {
  width: 100%;
  margin-bottom: 15px; }
  .input-group > span {
    border-radius: 0; }

label.custom-checkbox, label.custom-radio {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0; }
  label.custom-checkbox > input, label.custom-radio > input {
    height: 0;
    z-index: -100 !important;
    opacity: 0;
    position: absolute; }
    label.custom-checkbox > input:checked + span:before, label.custom-radio > input:checked + span:before {
      content: "\f00c";
      font-weight: 300; }
    label.custom-checkbox > input:checked + label + span:before, label.custom-radio > input:checked + label + span:before {
      content: "\f00c";
      font-weight: 300; }
    label.custom-checkbox > input:disabled + span, label.custom-radio > input:disabled + span {
      color: #dddddd;
      cursor: not-allowed; }
      label.custom-checkbox > input:disabled + span:before, label.custom-radio > input:disabled + span:before {
        border-color: #dddddd !important;
        cursor: not-allowed; }
  label.custom-checkbox > span, label.custom-radio > span {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    padding-left: 22px;
    width: 100%; }
    label.custom-checkbox > span:before, label.custom-radio > span:before {
      cursor: pointer;
      content: '';
      display: inline-block;
      text-align: center;
      height: 16px;
      line-height: 16px;
      min-width: 16px;
      margin-right: 6px;
      position: relative;
      background-image: url("../../assets/img/un-check.png");
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: -22px;
      float: left; }
    label.custom-checkbox > span:hover:before, label.custom-radio > span:hover:before {
      border-color: #cee569; }

label.custom-radio input[type=radio]:checked ~ span:before {
  content: '';
  border-color: transparent;
  background-image: url("../../assets/img/check.png"); }

label.custom-checkbox-2 {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0; }
  label.custom-checkbox-2 > input {
    height: 0;
    z-index: -100 !important;
    opacity: 0;
    position: absolute; }
    label.custom-checkbox-2 > input:checked + span:before {
      content: "\f00c";
      font-weight: 300;
      color: #017bc1;
      border-color: #017bc1; }
    label.custom-checkbox-2 > input:disabled + span {
      color: #dddddd;
      cursor: not-allowed; }
      label.custom-checkbox-2 > input:disabled + span:before {
        border-color: #dddddd !important;
        cursor: not-allowed; }
  label.custom-checkbox-2 > span {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    padding-left: 22px;
    width: 100%;
    white-space: normal; }
    label.custom-checkbox-2 > span:before {
      cursor: pointer;
      font-family: fontAwesome;
      font-weight: 300;
      font-size: 12px;
      color: #666666;
      content: "\a0";
      background-color: transparent;
      border: 1px solid #d6d6d6;
      border-radius: 0;
      display: inline-block;
      text-align: center;
      height: 18px;
      line-height: 16px;
      min-width: 19px;
      margin-right: 6px;
      position: relative;
      border-radius: 4px;
      top: 0;
      margin-left: -22px;
      float: left; }
    label.custom-checkbox-2 > span:hover:before {
      border-color: #017bc1; }

.nowrap {
  white-space: nowrap; }

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; }

label.custom-radio > input:checked + span:before {
  content: "\f111"; }

label.custom-radio > input:checked + label + span:before {
  content: "\f111"; }

label.custom-radio > span:before {
  border-radius: 16px;
  font-size: 9px; }

label.custom-input-primary > span:before {
  color: #c2de44; }

label.custom-input-primary > span:hover:before {
  border-color: #c2de44; }

label.custom-input-success > span:before {
  color: #90b900; }

label.custom-input-success > span:hover:before {
  border-color: #90b900; }

label.custom-input-warning > span:before {
  color: #dfb81c; }

label.custom-input-warning > span:hover:before {
  border-color: #dfb81c; }

label.custom-input-danger > span:before {
  color: #f1f1f1; }

label.custom-input-danger > span:hover:before {
  border-color: #f1f1f1; }

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  padding-top: 0px; }

.input-demo {
  line-height: 25px; }

.has-success .control-label {
  color: #666666; }

.has-success .form-control {
  border: 1px solid #a6c733; }
  .has-success .form-control:focus {
    box-shadow: none;
    border-color: #90b900; }

.has-success label.custom-checkbox, .has-success label.custom-radio {
  color: #a6c733; }
  .has-success label.custom-checkbox > span:before, .has-success label.custom-radio > span:before {
    color: #a6c733; }
  .has-success label.custom-checkbox > span:hover:before, .has-success label.custom-radio > span:hover:before {
    border-color: #a6c733; }

.has-success .form-control-feedback {
  color: #a6c733; }

.has-success .input-group-addon {
  background-color: #a6c733;
  color: #ffffff; }

.has-warning .control-label {
  color: #666666; }

.has-warning .form-control {
  border: 1px solid #e5c649; }
  .has-warning .form-control:focus {
    box-shadow: none;
    border-color: #dfb81c; }

.has-warning label.custom-checkbox, .has-warning label.custom-radio {
  color: #e5c649; }
  .has-warning label.custom-checkbox > span:before, .has-warning label.custom-radio > span:before {
    color: #e5c649; }
  .has-warning label.custom-checkbox > span:hover:before, .has-warning label.custom-radio > span:hover:before {
    border-color: #e5c649; }

.has-warning .form-control-feedback {
  color: #e5c649; }

.has-warning .input-group-addon {
  background-color: #e5c649;
  color: #ffffff; }

.has-error .control-label {
  color: #666666; }

.has-error .form-control {
  border: 1px solid #f4f4f4; }
  .has-error .form-control:focus {
    box-shadow: none;
    border-color: #f1f1f1; }

.has-error label.custom-checkbox, .has-error label.custom-radio {
  color: #f4f4f4; }
  .has-error label.custom-checkbox > span:before, .has-error label.custom-radio > span:before {
    color: #f4f4f4; }
  .has-error label.custom-checkbox > span:hover:before, .has-error label.custom-radio > span:hover:before {
    border-color: #f4f4f4; }

.has-error .form-control-feedback {
  color: #f4f4f4; }

.has-error .input-group-addon {
  background-color: #f4f4f4;
  color: #ffffff; }

.has-feedback label ~ .form-control-feedback {
  top: 21px;
  font-size: 18px; }

.bootstrap-select .btn-default:focus {
  color: #666666; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #ffffff;
  color: #dddddd;
  border-color: #e2e2e2; }
  .form-control[disabled]::-webkit-input-placeholder, .form-control[readonly]::-webkit-input-placeholder, fieldset[disabled] .form-control::-webkit-input-placeholder {
    color: #666666;
    opacity: 0.5; }
  .form-control[disabled]:-moz-placeholder, .form-control[readonly]:-moz-placeholder, fieldset[disabled] .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #666666;
    opacity: 0.5; }
  .form-control[disabled]::-moz-placeholder, .form-control[readonly]::-moz-placeholder, fieldset[disabled] .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #666666;
    opacity: 0.5; }
  .form-control[disabled]:-ms-input-placeholder, .form-control[readonly]:-ms-input-placeholder, fieldset[disabled] .form-control:-ms-input-placeholder {
    color: #666666;
    opacity: 0.5; }

.form-control-rounded {
  border-radius: 16px; }

.help-block {
  color: #949494; }

.help-block.error-block {
  display: none; }
  .has-error .help-block.error-block.basic-block {
    display: block; }

.input-group-addon-danger {
  background: #f1f1f1;
  color: #ffffff;
  border-color: #f1f1f1; }

.input-group-addon-warning {
  background: #dfb81c;
  color: #ffffff;
  border-color: #dfb81c; }

.input-group-addon-success {
  background: #90b900;
  color: #ffffff;
  border-color: #90b900; }

.input-group-addon-primary {
  background: #c2de44;
  color: #ffffff;
  border-color: #c2de44; }

.checkbox-demo-row {
  margin-bottom: 12px; }

.dropdown-menu {
  border-radius: 5px; }

.bootstrap-select.btn-group button.btn.btn-default {
  background: transparent;
  color: #666666; }
  .bootstrap-select.btn-group button.btn.btn-default:hover {
    background: #ffffff;
    box-shadow: none;
    outline: 0 !important; }
  .bootstrap-select.btn-group button.btn.btn-default:active {
    background: #ffffff;
    box-shadow: none; }

.bootstrap-select.btn-group.open > .btn.btn-default.dropdown-toggle {
  background: #ffffff;
  box-shadow: none;
  border-color: #d6d6d6; }

.bootstrap-select.btn-group.open > .btn {
  border-radius: 5px 5px 0 0; }

.bootstrap-select.btn-group.open .dropdown-menu.open {
  border: 1px solid gainsboro;
  border-top: none;
  border-radius: 0 0 5px 5px; }

.bootstrap-select.btn-group.with-search.open .btn-default + .dropdown-menu .bs-searchbox .form-control {
  background-color: #ffffff;
  border: 1px solid #cbcbcb; }

.bootstrap-select.btn-group.with-search.open .btn-default + .dropdown-menu .no-results {
  color: #929497; }

.bootstrap-select.btn-group .notify {
  color: #929497; }

.bootstrap-tagsinput {
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  box-shadow: none;
  max-width: 100%;
  font-size: 14px;
  line-height: 26px;
  width: 100%; }
  .bootstrap-tagsinput.form-control {
    display: block;
    width: 100%; }
  .bootstrap-tagsinput .tag {
    border-radius: 3px;
    font-weight: 400;
    font-size: 11px;
    padding: 4px 8px; }
    .bootstrap-tagsinput .tag [data-role="remove"]:hover {
      box-shadow: none; }
  .bootstrap-tagsinput input {
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    line-height: 22px;
    font-size: 11px;
    min-width: 53px; }
    .bootstrap-tagsinput input::-webkit-input-placeholder {
      color: #666666;
      opacity: 0.8; }
    .bootstrap-tagsinput input:-moz-placeholder {
      /* Firefox 18- */
      color: #666666;
      opacity: 0.8; }
    .bootstrap-tagsinput input::-moz-placeholder {
      /* Firefox 19+ */
      color: #666666;
      opacity: 0.8; }
    .bootstrap-tagsinput input:-ms-input-placeholder {
      color: #666666;
      opacity: 0.8; }

.progress {
  background: rgba(0, 0, 0, 0.07); }

.progress-bar-primary {
  background-color: #c2de44; }

.progress-bar-success {
  background-color: #b1ce4d; }

.progress-bar-warning {
  background-color: #dfb81c; }

.progress-bar-danger {
  background-color: #f1f1f1; }

.has-success .input-group-addon {
  border: none; }

.input-group > span.addon-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.input-group > span.addon-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.with-primary-addon:focus {
  border-color: #c2de44; }

.with-warning-addon:focus {
  border-color: #dfb81c; }

.with-success-addon:focus {
  border-color: #90b900; }

.with-danger-addon:focus {
  border-color: #f1f1f1; }

.sub-little-text {
  font-size: 12px; }

.show-grid div[class^=col-] {
  padding: 10px;
  box-sizing: border-box; }
  .show-grid div[class^=col-] div {
    color: #666666;
    text-align: center;
    font-size: 18px;
    background-color: rgba(102, 102, 102, 0.3);
    padding: 12px 5px; }

.grid-h {
  margin-top: 40px;
  margin-bottom: 0; }
  .grid-h:first-child {
    margin-top: 0; }

.modal-content {
  border-radius: 5px !important; }

#page_guideme .header-guide-me .title-header-page h1:before {
  background-image: url("../../assets/pictures/icon-faq.png"); }

#page_guideme .box-guide-wrapper {
  min-height: 500px;
  width: 100%;
  background-image: url("../../assets/img/background-guide.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    #page_guideme .box-guide-wrapper {
      background-size: cover;
      background-position: center; } }
  #page_guideme .box-guide-wrapper .box-guide {
    padding: 80px 0;
    width: 50%; }
    #page_guideme .box-guide-wrapper .box-guide h2 {
      font-size: 40px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      color: #177bbe;
      margin-bottom: 40px; }
    #page_guideme .box-guide-wrapper .box-guide p {
      font-family: "Lato", Arial;
      color: #282e34;
      font-size: 20px; }
    #page_guideme .box-guide-wrapper .box-guide .link-info {
      margin-bottom: 40px; }
      #page_guideme .box-guide-wrapper .box-guide .link-info a {
        color: #278CCB; }
  @media only screen and (max-width: 767px) {
    #page_guideme .box-guide-wrapper {
      background-image: none; }
      #page_guideme .box-guide-wrapper .box-guide {
        width: 100%;
        padding: 40px 0; } }

.modal-guide-info .content-guide {
  padding: 0 40px 40px; }
  @media (max-width: 480px) {
    .modal-guide-info .content-guide {
      padding: 0 10px 10px; } }
  .modal-guide-info .content-guide h2 {
    color: #177bbe;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 30px; }
  .modal-guide-info .content-guide p {
    font-family: "Lato", Arial;
    color: #282e34;
    font-size: 20px; }
  .modal-guide-info .content-guide .box-check {
    padding: 20px 0; }

.header-hcps {
  background-image: url("../../assets/img/theme/background-title.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .header-hcps .title-header-page {
    padding: 65px 0;
    text-align: center; }
    .header-hcps .title-header-page h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center; }
      @media (max-width: 767px) {
        .header-hcps .title-header-page h1 {
          font-size: 22px; } }
      .header-hcps .title-header-page h1:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("../../assets/pictures/icon-hcps.png");
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px; }

.box-main-content-hcps .left-aside {
  float: left;
  width: 280px;
  background-color: #f2f3f3; }
  .box-main-content-hcps .left-aside h4 {
    display: none; }
  .box-main-content-hcps .left-aside .aside-main-menu {
    padding: 0;
    display: block;
    margin-bottom: 0; }
    .box-main-content-hcps .left-aside .aside-main-menu li {
      position: relative;
      border-bottom: 1px solid #ffffff; }
      .box-main-content-hcps .left-aside .aside-main-menu li a {
        padding-left: 40px;
        margin-left: 20px;
        position: relative;
        font-size: 14px;
        font-family: "Lato", Arial;
        color: #282e34; }
      .box-main-content-hcps .left-aside .aside-main-menu li:hover {
        background-color: #e8e9e9; }

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .box-main-content-hcps .left-aside {
    width: 220px; }
  .box-main-content-hcps .container {
    padding: 0; } }

@media only screen and (max-width: 767px) {
  .box-main-content-hcps .container {
    padding: 0; }
  .box-main-content-hcps .left-aside {
    float: none;
    width: 100%; }
    .box-main-content-hcps .left-aside h4 {
      display: block;
      padding: 15px;
      background-color: #bed74b;
      color: #ffffff;
      font-size: 16px;
      position: relative;
      margin: 0; }
      .box-main-content-hcps .left-aside h4 .arrow-drop-menu-hcps {
        cursor: pointer;
        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #ffffff; }
    .box-main-content-hcps .left-aside .aside-main-menu {
      display: none; } }

.box-main-content-hcps .main-content-hcps {
  padding: 60px 20px 60px 60px;
  /*overflow: hidden;*/ }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .box-main-content-hcps .main-content-hcps {
      padding: 40px 20px 20px 30px; } }
  @media only screen and (max-width: 767px) {
    .box-main-content-hcps .main-content-hcps {
      padding: 40px 20px; } }
  @media only screen and (max-width: 547px) {
    .box-main-content-hcps .main-content-hcps {
      padding: 20px;
      padding-top: 35px; } }
  .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps {
    /*display: flex;
        flex-wrap: wrap;*/
    padding-bottom: 30px; }
    @media (max-width: 991px) {
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps {
        padding-bottom: 20px; } }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social {
      text-align: right; }
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like {
        max-width: 192px;
        display: inline-block; }
        .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share {
          float: left;
          margin-right: 35px; }
          .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul {
            padding: 0; }
            .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li {
              display: inline-block; }
              .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li a {
                display: block;
                color: #ffffff;
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                position: relative;
                font-size: 20px;
                transition: 1s;
                background-color: #b4b6b8; }
                .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li a.facebook:hover {
                  background-color: #3b5998; }
                .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li a.twitter:hover {
                  background-color: #55acee; }
                .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li a.envelope:hover {
                  background-color: #1a1e21; }
                .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li a i {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); }
              .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .socical-share ul li:last-child a {
                font-size: 16px; }
        .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .box-like {
          float: right; }
          .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .box-like .tooltip {
            white-space: nowrap; }
          .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .box-like span {
            cursor: pointer;
            height: 30px;
            width: 30px;
            display: inline-block;
            background-image: url("../../assets/img/app/video-img/heart.png");
            background-size: 30px 30px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s; }
            .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .box-like span:hover, .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social .box-socical-like .box-like span.active {
              background-image: url("../../assets/img/app/video-img/heart-active.png");
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-title h2 {
      font-size: 32px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      color: #177bbe;
      margin-bottom: 25px; }
    @media (max-width: 991px) {
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-social {
        order: 1;
        text-align: left; }
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-title-hcps .box-title {
        order: 2; } }
  .box-main-content-hcps .main-content-hcps .box-content-hcps .content-hcps {
    margin-bottom: 50px; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .content-hcps p {
      font-size: 16px;
      font-family: "Lato", Arial;
      color: #6a6e72; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .content-hcps h4 {
      font-size: 20px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      color: #292f35; }
  .box-main-content-hcps .main-content-hcps .box-content-hcps .box-feedback {
    border-bottom: 2px solid #e3e2e2; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-feedback h5 {
      padding: 25px 0;
      margin-bottom: 0;
      color: #bdbebf;
      font-size: 14px;
      font-family: "PT Sans", sans-serif;
      text-transform: uppercase;
      display: inline-block; }
  .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful {
    /*border-bottom: 2px solid #e3e2e2;*/ }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful h5 {
      color: #808385;
      font-size: 16px;
      font-family: "Lato", Arial;
      font-weight: bold;
      padding: 35px 0;
      margin-bottom: 0;
      display: inline-block; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-yes, .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-no {
      padding: 8px 35px;
      background-color: transparent;
      border: 1px solid #177bbe;
      font-size: 14px;
      font-family: "Lato", Arial;
      font-weight: bold;
      color: #177bbe;
      margin-left: 30px;
      margin-right: 0; }
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-yes.active, .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-no.active {
        background-color: #177bbe;
        color: #ffffff; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful button:hover {
      background-color: #177bbe;
      color: #ffffff; }
    .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful button.active {
      background-color: #177bbe;
      color: #ffffff; }
    @media only screen and (max-width: 767px) {
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful h5 {
        width: 100%;
        padding: 30px 0; }
      .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-yes, .box-main-content-hcps .main-content-hcps .box-content-hcps .box-helpful .btn-no {
        margin-left: 0;
        margin-right: 30px;
        margin-bottom: 30px; } }

.box-content-feedback {
  border-bottom: 2px solid #e3e2e2; }
  .box-content-feedback button:hover {
    background-color: #177bbe;
    color: #ffffff; }
  .box-content-feedback button.active {
    background-color: #177bbe;
    color: #ffffff; }
  .box-content-feedback .box-helpful {
    border: none; }
  .box-content-feedback .box-thank h6 {
    padding: 35px 0 0 0;
    font-size: 16px;
    font-family: "Lato", Arial;
    color: #6a6e72; }
  .box-content-feedback .box-thank .box-btn {
    display: block;
    text-align: right; }
    .box-content-feedback .box-thank .box-btn .btn-tell-me {
      margin: 20px 0;
      padding: 8px 15px;
      padding-left: 40px;
      background-color: transparent;
      border: 1px solid #177bbe;
      font-size: 14px;
      font-family: "Lato", Arial;
      font-weight: bold;
      color: #177bbe;
      margin-right: 0;
      position: relative; }
      .box-content-feedback .box-thank .box-btn .btn-tell-me:hover {
        background-color: #177bbe;
        color: #ffffff; }
        .box-content-feedback .box-thank .box-btn .btn-tell-me:hover:before {
          background-image: url("../../assets/img/app/effectiveness/icon-feed-active.png"); }
      .box-content-feedback .box-thank .box-btn .btn-tell-me.active {
        background-color: #177bbe;
        color: #ffffff; }
        .box-content-feedback .box-thank .box-btn .btn-tell-me.active:before {
          background-image: url("../../assets/img/app/effectiveness/icon-feed-active.png"); }
      .box-content-feedback .box-thank .box-btn .btn-tell-me:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 13px;
        height: 11px;
        background-image: url("../../assets/img/app/effectiveness/icon-feed.png");
        background-repeat: no-repeat;
        background-size: contain; }
  .box-content-feedback .box-comment {
    padding: 20px;
    background: rgba(16, 18, 20, 0.05); }
    .box-content-feedback .box-comment h4 {
      font-size: 18px;
      font-family: "Lato";
      font-weight: bold;
      color: #292f35;
      display: inline-block; }
      .box-content-feedback .box-comment h4:before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 15px;
        background-image: url("../../assets/img/app/effectiveness/icon-feed-black.png");
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 15px; }
      .box-content-feedback .box-comment h4:after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 8px;
        background-image: url("../../assets/img/app/effectiveness/icon-arrow.png");
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-left: 15px; }
    .box-content-feedback .box-comment form input, .box-content-feedback .box-comment form textarea {
      margin-bottom: 15px; }
    .box-content-feedback .box-comment form .btn-info {
      padding: 8px 20px; }
  .box-content-feedback .box-feedback-success h6 {
    padding: 35px 0 35px 35px;
    font-size: 16px;
    font-family: "Lato", Arial;
    color: #6a6e72;
    background-image: url("../../assets/img/app/effectiveness/icon-success.png");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position-y: 32px;
    /*&:before{
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
        @include bg-image("app/effectiveness/icon-success.png");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
        vertical-align: middle;
      }*/ }
  @media only screen and (max-width: 767px) {
    .box-content-feedback .box-thank h6 {
      padding: 30px 0 10px 0;
      background-position-y: 30px; }
    .box-content-feedback .box-thank .box-btn {
      text-align: left; }
      .box-content-feedback .box-thank .box-btn .btn-tell-me {
        margin: 0 0 30px; } }

/* include public class */
.pos-re {
  position: relative !important; }

.pos-ab {
  position: absolute !important; }

.full-hieght {
  height: 100% !important; }

.img-height-responsive {
  max-height: 100%;
  width: auto;
  display: inline-block; }

/* include public class */
#FAQs {
  background-color: #f2f3f3; }
  #FAQs .header-page .title-header-page {
    padding: 65px 0;
    text-align: center; }
  #FAQs .header-page h1:before {
    background-image: url("../../assets/pictures/icon-faq.png"); }
  @media (max-width: 480px) {
    #FAQs .header-page h1 {
      font-size: 22px;
      padding-left: 75px; }
      #FAQs .header-page h1:before {
        width: 70px;
        height: 70px; } }
  @media (max-width: 340px) {
    #FAQs .header-page h1 {
      font-size: 18px;
      padding-left: 75px; }
      #FAQs .header-page h1:before {
        width: 70px;
        height: 70px; } }
  #FAQs .list-asked {
    padding: 60px 0; }
    #FAQs .list-asked .list-current-asked {
      background-color: #fff;
      position: relative;
      text-align: center;
      box-shadow: 0px 0px 10px #e3e4e4;
      padding: 30px 20px; }
      #FAQs .list-asked .list-current-asked .img-current-asked {
        text-align: center;
        height: 110px;
        overflow: hidden;
        display: inline-block; }
      #FAQs .list-asked .list-current-asked .title-current-asked {
        font-size: 20px;
        margin: 30px 0;
        font-weight: 700;
        font-family: "PT Sans", sans-serif;
        color: #282e34;
        min-height: 53px; }
      #FAQs .list-asked .list-current-asked .button-current-asked {
        background-color: #f6f7f8;
        padding: 20px 26px 23px;
        color: #037bc0;
        border-radius: 5px;
        display: inline-block;
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        position: relative;
        white-space: nowrap; }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
          #FAQs .list-asked .list-current-asked .button-current-asked {
            padding: 20px 15px 20px; } }
        #FAQs .list-asked .list-current-asked .button-current-asked i {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%); }
          @media only screen and (min-width: 768px) and (max-width: 992px) {
            #FAQs .list-asked .list-current-asked .button-current-asked i {
              right: 10px; } }
    @media (max-width: 991px) {
      #FAQs .list-asked .list-current-asked {
        padding: 30px 10px; } }
  #FAQs .top-rated-questions {
    background-color: #fff;
    padding-top: 65px;
    padding-bottom: 100px; }
    #FAQs .top-rated-questions .title-rated-questions {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 700;
      font-family: 'Lato', sans-serif;
      color: #282e34; }
    #FAQs .top-rated-questions .current-top-rated-question {
      margin-bottom: 25px; }
      #FAQs .top-rated-questions .current-top-rated-question a {
        font-size: 16px;
        color: #037bc0; }
        #FAQs .top-rated-questions .current-top-rated-question a:hover, #FAQs .top-rated-questions .current-top-rated-question a:focus {
          text-decoration: underline !important; }
  #FAQs .list-post-FAQs .box-post-title-FAQs .title-post-FAQs {
    font-family: "PT Sans", sans-serif;
    font-size: 20px;
    color: #177bbe; }
  #FAQs .list-post-FAQs .box-post-title-FAQs .button-show-content {
    color: #177bbe;
    border: 1px solid #177bbe;
    height: 35px;
    width: 35px;
    padding: 0;
    position: relative;
    float: right;
    cursor: pointer; }
    #FAQs .list-post-FAQs .box-post-title-FAQs .button-show-content.show:after {
      content: "";
      height: 0;
      width: 0;
      background-color: transparent; }
    #FAQs .list-post-FAQs .box-post-title-FAQs .button-show-content:before {
      content: "";
      width: 15px;
      height: 2px;
      background-color: #177bbe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    #FAQs .list-post-FAQs .box-post-title-FAQs .button-show-content:after {
      content: "";
      height: 15px;
      width: 2px;
      background-color: #177bbe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  @media (max-width: 1200px) and (min-width: 320px) {
    #FAQs .container {
      width: 100%;
      padding: 40px 20px; }
    #FAQs .top-rated-questions {
      padding: 0; }
    #FAQs .box-main-content-page .container {
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    #FAQs .list-asked {
      padding: 0;
      margin-right: -10px;
      margin-left: -15px; } }
  @media (max-width: 547px) {
    #FAQs .list-asked > div {
      width: 100%; }
      #FAQs .list-asked > div .list-current-asked {
        padding: 30px 10px; }
        #FAQs .list-asked > div .list-current-asked .container {
          padding: 40px 20px; } }

.help-browse-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  /*height: $help-browse-upper-height;*/
  background-image: url("../../assets/pictures/blue-bg.png"); }

.help-browse-upper {
  width: 100%;
  /*height: $help-browse-upper-height;*/
  /*padding: 280px 0 0 0px;*/
  text-align: center; }
  .help-browse-upper p {
    font-family: "PT Sans", sans-serif;
    color: #ffffff; }
    .help-browse-upper p.highlight, .help-browse-upper p .highlight {
      color: #c2de44; }
    .help-browse-upper p.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
    .help-browse-upper p.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
      .help-browse-upper p.title:last-child {
        margin: 0 0 70px 0; }
  .help-browse-upper .icon {
    background-image: url("../../assets/pictures/icon-faq.png"); }

.help-browse.sections {
  padding: 80px 140px 100px 140px; }
  @media screen and (max-width: 1400px) {
    .help-browse.sections {
      padding: 80px 100px 100px 100px; } }
  .help-browse.sections .back-button {
    margin-left: 0;
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 32px;
    display: block;
    color: #017bc1; }
  .help-browse.sections .row {
    display: block; }
    .help-browse.sections .row ul li {
      line-height: 28px; }
    .help-browse.sections .row p {
      font-size: 16px;
      line-height: 1.4;
      letter-spacing: 0.2px;
      color: rgba(41, 47, 53, 0.7);
      font-family: 'Lato', sans-serif; }
      .help-browse.sections .row p a {
        color: #017bc1; }
      .help-browse.sections .row p.title {
        font-size: 28px;
        line-height: 40px;
        color: #017bc1;
        font-weight: bold; }
        .help-browse.sections .row p.title.narrow {
          font-family: "PT Sans", sans-serif;
          font-size: 28px;
          font-weight: normal;
          display: block;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 20px;
          margin-bottom: 30px; }
        .help-browse.sections .row p.title.small {
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          line-height: 40px;
          color: #000000;
          font-weight: normal;
          margin-bottom: 10px;
          display: inline-block; }
      .help-browse.sections .row p.subtitle {
        font-weight: bold;
        padding: 0px 0px 20px 0px; }
    .help-browse.sections .row.text {
      padding: 60px 0px 20px 0px; }
      .help-browse.sections .row.text .lead {
        font-size: 22px;
        font-weight: bold; }
  .help-browse.sections .gizmo-icon {
    color: #000;
    position: absolute;
    right: 0;
    top: 10px; }
  .help-browse.sections .panel-group .panel {
    border: none;
    box-shadow: none; }
  .help-browse.sections .accordion-panel .panel-heading .accordion-toggle {
    border-bottom: none;
    box-shadow: none;
    padding: 30px 0px 0px 0px; }
    .help-browse.sections .accordion-panel .panel-heading .accordion-toggle > span {
      display: block;
      position: relative; }

.help-browse.questions {
  text-align: center;
  background-color: #f1f1f1;
  padding: 100px 0px 100px 0px; }
  .help-browse.questions .questions-wrapper {
    padding: 0px 100px 0px 100px; }
    .help-browse.questions .questions-wrapper .question .question-box {
      padding: 10px 50px 40px 50px;
      background-color: #ffffff; }
      .help-browse.questions .questions-wrapper .question .question-box .question-icon {
        background-size: auto;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
        background-color: transparent;
        width: 100%;
        height: 150px; }
        .help-browse.questions .questions-wrapper .question .question-box .question-icon.seeking-help {
          background-image: url("../../assets/pictures/seeking-help.png"); }
        .help-browse.questions .questions-wrapper .question .question-box .question-icon.back-pain-causes {
          background-image: url("../../assets/pictures/back-pain-causes.png"); }
        .help-browse.questions .questions-wrapper .question .question-box .question-icon.what-happens {
          background-image: url("../../assets/pictures/what-happens.png"); }
        .help-browse.questions .questions-wrapper .question .question-box .question-icon.what-can-i-do {
          background-image: url("../../assets/pictures/what-can-i-do.png"); }
    .help-browse.questions .questions-wrapper .question .title {
      height: 120px;
      overflow: hidden;
      display: table-cell;
      vertical-align: middle; }
      .help-browse.questions .questions-wrapper .question .title p {
        color: #000000;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        display: block;
        padding: 10px 0px 10px 0px; }

.main-content-article {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s; }

.safari .home-upper-wrapper {
  margin-top: -5px; }
  @media (min-width: 768px) and (max-width: 992px) {
    .safari .home-upper-wrapper .box-text-intro .box-button .btn-info, .safari .home-upper-wrapper .box-text-intro .box-button .btn-back {
      font-size: 18px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .safari .box-home .box-home-info button {
    font-size: 14px; }
    .safari .box-home .box-home-info button.btn-info p {
      font-size: 14px; } }

.home-upper-wrapper {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  position: relative;
  width: 100%;
  background-image: url("../../assets/img/bacnkground-home-upper-wrapper.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }
  .home-upper-wrapper .box-text-intro {
    padding: 50px;
    text-align: center;
    color: #ffffff; }
    .home-upper-wrapper .box-text-intro h2 {
      font-size: 44px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
      margin-bottom: 0; }
    .home-upper-wrapper .box-text-intro h3 {
      color: #ffffff;
      font-size: 44px;
      font-weight: normal;
      font-family: "PT Sans", sans-serif; }
    .home-upper-wrapper .box-text-intro h5 {
      padding: 20px 0;
      font-size: 28px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif; }
    .home-upper-wrapper .box-text-intro p {
      font-size: 18px;
      font-weight: normal;
      font-family: "PT Sans", sans-serif; }
      .home-upper-wrapper .box-text-intro p a {
        font-weight: bold;
        color: #ffffff; }
    .home-upper-wrapper .box-text-intro .box-button {
      padding: 20px 0; }
      .home-upper-wrapper .box-text-intro .box-button .btn-info {
        float: right;
        line-height: 35px;
        background: transparent;
        color: #ffffff;
        border: 1px solid #ffffff;
        font-weight: 500;
        font-family: "Lato", sans-serif;
        font-size: 16px;
        font-weight: 900; }
        .home-upper-wrapper .box-text-intro .box-button .btn-info .gizmo-play {
          background-size: 25px 25px;
          background-repeat: no-repeat;
          width: 25px;
          height: 25px;
          margin-right: 0px;
          margin-bottom: 2px; }
        .home-upper-wrapper .box-text-intro .box-button .btn-info:hover {
          background-color: #ffffff;
          color: #177bbe; }
      .home-upper-wrapper .box-text-intro .box-button .btn-back {
        float: left;
        background: #282e34;
        color: #ffffff;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        font-size: 16px;
        font-weight: 900;
        line-height: 35px; }
        .home-upper-wrapper .box-text-intro .box-button .btn-back:hover {
          background-color: #33393e; }
    @media only screen and (max-width: 767px) {
      .home-upper-wrapper .box-text-intro .box-button {
        padding: 20px 0 60px 0; }
        .home-upper-wrapper .box-text-intro .box-button .btn-info, .home-upper-wrapper .box-text-intro .box-button .btn-back {
          float: none; } }
  @media only screen and (min-width: 530px) and (max-width: 992px) {
    .home-upper-wrapper .box-text-intro {
      padding: 50px 10px; }
      .home-upper-wrapper .box-text-intro .box-button .btn-info, .home-upper-wrapper .box-text-intro .box-button .btn-back {
        margin-top: 10px;
        font-size: 20px;
        width: 100%; } }
  @media only screen and (max-width: 530px) {
    .home-upper-wrapper .box-text-intro {
      padding: 50px 10px; }
      .home-upper-wrapper .box-text-intro .box-button .btn-info, .home-upper-wrapper .box-text-intro .box-button .btn-back {
        margin-top: 10px;
        font-size: 14px;
        width: 100%; } }

.box-home-intro-image {
  width: 100%;
  margin: 0 auto;
  clear: both;
  position: relative;
  padding: 0;
  margin-top: -30px;
  z-index: 2; }
  .box-home-intro-image .col-33 {
    float: left; }
    @media (min-width: 1200px) {
      .box-home-intro-image .col-33 {
        width: 400px; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .box-home-intro-image .col-33 {
        width: 33.333339%; } }
    .box-home-intro-image .col-33 img {
      width: 100%; }
  @media only screen and (max-width: 767px) {
    .box-home-intro-image .col-33 {
      display: none; } }
  .box-home-intro-image .button-arrow {
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -12px;
    cursor: pointer;
    background-image: url("../../assets/img/app/round-arrow.png"); }

.box-home {
  padding-left: 0;
  padding-right: 0; }
  .box-home.background-gray {
    background-color: #f1f2f2; }
  .box-home .box-home-about.background-green {
    background-color: #f1f2f2; }
    .box-home .box-home-about.background-green button.btn-info i {
      margin-left: 10px; }
  .box-home .box-home-about {
    padding: 60px; }
    .box-home .box-home-about .box-hcp {
      padding-left: 0;
      padding-right: 30px; }
      @media only screen and (max-width: 992px) {
        .box-home .box-home-about .box-hcp {
          padding-right: 6px; } }
      @media only screen and (max-width: 767px) {
        .box-home .box-home-about .box-hcp {
          padding-right: 0px;
          margin-bottom: 30px; } }
    .box-home .box-home-about .box-careplan {
      padding-left: 30px;
      padding-right: 0; }
      .box-home .box-home-about .box-careplan button.btn-info {
        position: relative; }
        @media only screen and (max-width: 480px) {
          .box-home .box-home-about .box-careplan button.btn-info {
            line-height: 1.5; } }
        .box-home .box-home-about .box-careplan button.btn-info i {
          position: absolute;
          top: 53%;
          right: 10px;
          transform: translateY(-50%); }
      @media only screen and (max-width: 992px) {
        .box-home .box-home-about .box-careplan {
          padding-left: 6px; } }
      @media only screen and (max-width: 767px) {
        .box-home .box-home-about .box-careplan {
          padding-left: 0; } }
    @media (max-width: 1200px) {
      .box-home .box-home-about {
        padding: 40px 20px; } }
    .box-home .box-home-about .box-content-info {
      cursor: pointer; }
  .box-home .box-home-about, .box-home .box-home-info {
    text-align: center; }
    .box-home .box-home-about h2, .box-home .box-home-info h2 {
      color: #177bbe;
      margin-bottom: 40px;
      font-size: 36px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold; }
    .box-home .box-home-about h3, .box-home .box-home-info h3 {
      font-size: 28px;
      color: #282e34;
      font-family: "PT Sans", sans-serif; }
    .box-home .box-home-about p, .box-home .box-home-info p {
      padding: 0 15px;
      font-size: 18px; }
    .box-home .box-home-about .box-img, .box-home .box-home-info .box-img {
      padding: 30px; }
      .box-home .box-home-about .box-img img, .box-home .box-home-info .box-img img {
        display: inline-block;
        width: 100px; }
    .box-home .box-home-about button, .box-home .box-home-info button {
      margin-top: 30px;
      font-size: 16px; }
  .box-home .box-home-info h3 {
    font-size: 28px;
    font-family: "PT Sans", sans-serif; }
  .box-home .box-home-info p {
    font-size: 16px; }
  .box-home .box-home-info .border-box-content {
    border: 1px solid #ececed;
    padding: 40px 30px;
    border-radius: 3px;
    cursor: pointer; }
  .box-home .box-home-info button {
    margin-top: 40px;
    font-size: 16px;
    font-family: 'Lato', sans-serif; }
    .box-home .box-home-info button.btn-info {
      background: #dce5e8;
      border: none;
      color: #177bbe;
      position: relative; }
      .box-home .box-home-info button.btn-info:hover, .box-home .box-home-info button.btn-info:active, .box-home .box-home-info button.btn-info:focus, .box-home .box-home-info button.btn-info:visited {
        background: #cee0e6;
        border: none;
        color: #177bbe; }
      .box-home .box-home-info button.btn-info p {
        padding: 0; }
      .box-home .box-home-info button.btn-info i {
        margin-left: 0px;
        font-size: 13px; }
      .box-home .box-home-info button.btn-info .box-icon-chevron-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
  .box-home .box-home-what-best {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px; }
    .box-home .box-home-what-best .box-what-best-img {
      padding: 0;
      height: 370px;
      background-size: cover;
      background-position: left;
      /*img {
        width: 100%;
        height: 100%;
      }*/ }
    .box-home .box-home-what-best .box-what-best {
      background-image: url("../../assets/img/app/home-img/bacnkground-box-what-best.png");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      padding: 50px 60px;
      color: #fff;
      overflow: hidden; }
      .box-home .box-home-what-best .box-what-best p {
        font-size: 18px;
        width: 100%; }
      .box-home .box-home-what-best .box-what-best h2 {
        padding: 20px 0;
        font-size: 28px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold; }
      .box-home .box-home-what-best .box-what-best .btn-what-best {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        color: #fff; }
        .box-home .box-home-what-best .box-what-best .btn-what-best i {
          margin-left: 0px; }
        .box-home .box-home-what-best .box-what-best .btn-what-best:hover {
          background-color: #ffffff;
          color: #177bbe; }
    @media only screen and (max-width: 1199px) {
      .box-home .box-home-what-best .box-what-best {
        padding: 35px 20px; }
      .box-home .box-home-what-best .box-what-best p {
        font-size: 18px;
        width: 100%; } }
  .box-home .box-home-text .box-text {
    margin-top: 50px;
    padding: 40px;
    border: 1px solid #ececed;
    border-radius: 3px; }
    .box-home .box-home-text .box-text .box-text-title {
      position: relative; }
      .box-home .box-home-text .box-text .box-text-title h4 {
        color: #282e34;
        padding-left: 40px;
        line-height: 40px;
        font-size: 22px;
        font-weight: bold; }
      .box-home .box-home-text .box-text .box-text-title .img-box-text-title {
        top: 10px;
        left: 0;
        position: absolute;
        width: 23px;
        height: 23px; }
    .box-home .box-home-text .box-text ul {
      padding: 0; }
      .box-home .box-home-text .box-text ul li {
        position: relative;
        color: #6a6e72;
        line-height: 26px;
        font-size: 16px;
        padding-left: 40px; }
        .box-home .box-home-text .box-text ul li:before {
          content: "";
          position: absolute;
          top: 15px;
          left: 0;
          transform: translateY(-50%);
          height: 15px;
          width: 15px;
          background-image: url("../../assets/img/app/arrow/ticked.png");
          background-size: contain; }
  @media only screen and (max-width: 547px) {
    .box-home .box-home-text .box-text {
      padding: 35px 30px; } }
  .box-home .box-home-text-info {
    padding: 50px 15px;
    color: #282e34; }
    .box-home .box-home-text-info .box-text h5 {
      font-size: 12px;
      font-family: 'Lato-Black', sans-serif;
      color: #97989a; }
    .box-home .box-home-text-info .box-text p {
      color: #6a6e72;
      font-family: 'Lato', sans-serif;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      color: #97989a; }
      @media only screen and (max-width: 992px) {
        .box-home .box-home-text-info .box-text p {
          line-height: 18px; } }
      @media only screen and (max-width: 480px) {
        .box-home .box-home-text-info .box-text p {
          line-height: 18px; } }
      .box-home .box-home-text-info .box-text p a:hover {
        color: #177bbe;
        text-decoration: underline !important; }
  @media (min-width: 1200px) {
    .box-home .box-home-text-info {
      padding: 50px 60px; }
    .box-home .box-home-text {
      padding-left: 60px;
      padding-right: 60px; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .box-home .box-home-text-info {
      padding: 50px 20px; }
    .box-home .box-home-text {
      padding-left: 20px;
      padding-right: 20px; } }

@media (min-width: 1200px) {
  #startHome.container {
    background-color: #fff;
    box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
    margin-top: -15px; }
  .box-home-intro-image {
    box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2); } }

@media only screen and (max-width: 767px) {
  .box-home {
    padding-left: 20px;
    padding-right: 20px; }
    .box-home .box-home-about {
      padding: 40px 0; }
      .box-home .box-home-about .box-hcp {
        padding: 0;
        margin-bottom: 30px; }
      .box-home .box-home-about .box-careplan {
        padding: 0; }
    .box-home .box-home-info {
      padding: 0; }
      .box-home .box-home-info .border-box-content {
        padding: 40px 20px; }
      .box-home .box-home-info button {
        line-height: 1;
        margin: 0; }
    .box-home .box-home-what-best {
      text-align: center; }
      .box-home .box-home-what-best .box-what-best-img {
        height: 300px; } }

.text-mobile {
  display: none; }

@media (max-width: 480px) {
  .text-mobile {
    display: inline-block; }
  .text-desktop {
    display: none; } }

.page-not-found {
  text-align: center; }
  .page-not-found h1 {
    font-size: 22px;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    color: #177bbe;
    padding: 50px 0px 20px 0px;
    border-bottom: 1px solid #e3e2e2; }
  .page-not-found p {
    font-size: 22px;
    padding: 30px 0; }

.icons-list > div {
  text-align: center;
  margin-bottom: 32px; }

.icons-list i {
  font-weight: 400;
  font-size: 18px;
  cursor: pointer; }

.icons-list.primary i:hover {
  color: #c2de44; }

.icons-list.success i:hover {
  color: #90b900; }

.icons-list.warning i:hover {
  color: #dfb81c; }

.icons-list.danger i:hover {
  color: #f1f1f1; }

a.see-all-icons {
  float: right; }

.awesomeIcons {
  height: 308px; }

.kameleon-row {
  display: inline-block;
  min-width: 102px;
  width: 20%; }
  .kameleon-row .kameleon-icon {
    padding: 0 10px; }
    .kameleon-row .kameleon-icon img {
      width: 81px; }

@media (max-width: 750px) {
  .kameleon-row {
    width: 25%; } }

@media (max-width: 550px) {
  .kameleon-row {
    width: 33%; } }

@media (max-width: 430px) {
  .kameleon-row {
    width: 50%; } }

.kameleon-icon-tabs {
  max-width: 84px; }
  .kameleon-icon-tabs img {
    width: 100%;
    min-width: 81px;
    min-height: 81px; }

.kameleon-icon {
  text-align: center;
  margin: 0 auto; }
  .kameleon-icon img {
    width: 100%; }
  .kameleon-icon span {
    display: block;
    text-align: center;
    white-space: nowrap; }

.with-round-bg {
  margin-bottom: 6px; }
  .with-round-bg img {
    border-radius: 50%;
    margin-bottom: 4px; }
  .with-round-bg img {
    background: #ffffff; }
  .with-round-bg.success img {
    background: #90b900; }
  .with-round-bg.danger img {
    background: #f1f1f1; }
  .with-round-bg.warning img {
    background: #dfb81c; }
  .with-round-bg.info img {
    background: #017bc1; }
  .with-round-bg.primary img {
    background: #c2de44; }

.modal-play-video {
  background-color: #282e34; }
  .modal-play-video .sn-link-close {
    color: #ffffff !important; }
  .modal-play-video .modal-header {
    padding: 10px 10px 0 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .modal-play-video .modal-content {
    border-radius: 5px; }
  .modal-play-video .modal-body {
    position: relative;
    padding-bottom: 53.25%;
    padding-top: 25px;
    height: 0; }
    .modal-play-video .modal-body iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 10px 10px 10px; }
  @media only screen and (max-width: 991px) {
    .modal-play-video .modal-body {
      padding-bottom: 52.25%; } }
  @media only screen and (max-width: 600px) {
    .modal-play-video .modal-body {
      padding-bottom: 50.25%; } }
  .modal-play-video .social-video {
    display: inline-block;
    margin-left: -15px; }
    .modal-play-video .social-video .socical-share {
      display: inline-block; }
      .modal-play-video .social-video .socical-share ul {
        padding: 0; }
        .modal-play-video .social-video .socical-share ul li {
          display: inline-block;
          margin-right: 5px; }
          .modal-play-video .social-video .socical-share ul li a {
            color: #282e34;
            display: block;
            background-color: #73777b;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            position: relative;
            font-size: 20px;
            transition: 1s; }
            .modal-play-video .social-video .socical-share ul li a.facebook:hover {
              background-color: #3b5998;
              color: #ffffff; }
            .modal-play-video .social-video .socical-share ul li a.twitter:hover {
              background-color: #55acee;
              color: #ffffff; }
            .modal-play-video .social-video .socical-share ul li a.envelope:hover {
              /*background-color: $color-very-dark;*/
              background-color: #1a1e21;
              color: #ffffff; }
            .modal-play-video .social-video .socical-share ul li a i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
          .modal-play-video .social-video .socical-share ul li:last-child a {
            font-size: 16px; }
    .modal-play-video .social-video .box-like {
      display: inline-block; }
      .modal-play-video .social-video .box-like .tooltip {
        white-space: nowrap; }
      .modal-play-video .social-video .box-like span {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: inline-block;
        background-image: url("../../assets/img/app/video-img/heart-gray.png");
        background-size: 30px 30px;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
        -webkit-transition: all .8s;
        transition: all .8s;
        -moz-transition: all .7s; }
        .modal-play-video .social-video .box-like span:hover, .modal-play-video .social-video .box-like span.active {
          background-image: url("../../assets/img/app/video-img/heart-active.png");
          -webkit-transition: all .8s;
          transition: all .8s;
          -moz-transition: all .7s; }

.header-videos {
  background-image: url("../../assets/img/theme/background-title.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .header-videos .title-header-page {
    padding: 65px 0;
    text-align: center; }
    .header-videos .title-header-page h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center; }
      @media (max-width: 767px) {
        .header-videos .title-header-page h1 {
          font-size: 22px; } }
      .header-videos .title-header-page h1:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("../../assets/pictures/icon-videos.png");
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px; }

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .box-list-videos .left-aside {
    width: 220px; }
  .box-list-videos .container {
    padding: 0; } }

@media only screen and (max-width: 767px) {
  .box-list-videos .container {
    padding: 0; }
  .box-list-videos .left-aside {
    float: none;
    width: 100%; }
    .box-list-videos .left-aside h4 {
      display: block;
      padding: 15px;
      background-color: #bed74b;
      color: #ffffff;
      font-size: 16px;
      position: relative;
      margin: 0; }
      .box-list-videos .left-aside h4 .arrow-drop-menu-video {
        cursor: pointer;
        content: "";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #ffffff; }
    .box-list-videos .left-aside .aside-main-menu {
      display: none; } }

.box-list-videos .main-content-video {
  padding: 60px;
  overflow: hidden; }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .box-list-videos .main-content-video {
      padding: 30px; } }
  @media only screen and (max-width: 480px) {
    .box-list-videos .main-content-video {
      padding: 15px; } }
  .box-list-videos .main-content-video .box-video {
    margin: -15px; }
    .box-list-videos .main-content-video .box-video .tab-content {
      padding: 0; }
    .box-list-videos .main-content-video .box-video .item-video {
      width: 270px;
      float: left;
      padding: 15px; }
      .box-list-videos .main-content-video .box-video .item-video .img-video {
        position: relative;
        cursor: pointer; }
        .box-list-videos .main-content-video .box-video .item-video .img-video img {
          width: 100%;
          border: 1px solid #d0d2d3; }
        .box-list-videos .main-content-video .box-video .item-video .img-video .icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/img/app/video-img/icon-play.png");
          background-size: 35px 35px;
          background-position: center center;
          background-repeat: no-repeat no-repeat;
          width: 35px;
          height: 35px;
          cursor: pointer; }
        .box-list-videos .main-content-video .box-video .item-video .img-video:hover .icon-play {
          background-image: url("../../assets/img/app/video-img/icon-play-hover.png"); }
        .box-list-videos .main-content-video .box-video .item-video .img-video .time-video {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: #ffffff;
          font-size: 12px;
          padding: 6px;
          background-color: rgba(0, 0, 0, 0.7);
          font-family: "Lato", Arial;
          font-weight: bold;
          border-radius: 5px; }
      .box-list-videos .main-content-video .box-video .item-video .title-video a h2 {
        font-size: 16px;
        font-family: "Lato-Bold";
        padding: 10px 0;
        line-height: 18px;
        color: #282e34;
        margin-bottom: 0; }
      .box-list-videos .main-content-video .box-video .item-video .title-video a:hover h2 {
        text-decoration: underline;
        color: #177bbe; }
      .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like {
        max-width: 192px;
        display: inline-block; }
        .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share {
          float: left;
          margin-right: 35px; }
          .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul {
            padding: 0; }
            .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li {
              display: inline-block; }
              .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li a {
                display: block;
                color: #ffffff;
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                position: relative;
                font-size: 20px;
                transition: 1s;
                background-color: #b4b6b8; }
                .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li a.facebook:hover {
                  background-color: #3b5998; }
                .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li a.twitter:hover {
                  background-color: #55acee; }
                .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li a.envelope:hover {
                  background-color: #1a1e21; }
                .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li a i {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); }
              .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .socical-share ul li:last-child a {
                font-size: 16px; }
        .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .box-like {
          float: right; }
          .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .box-like .tooltip {
            white-space: nowrap; }
          .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .box-like span {
            cursor: pointer;
            height: 30px;
            width: 30px;
            display: inline-block;
            background-image: url("../../assets/img/app/video-img/heart.png");
            background-size: 30px 30px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s; }
            .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .box-like span:hover, .box-list-videos .main-content-video .box-video .item-video .social-video .box-socical-like .box-like span.active {
              background-image: url("../../assets/img/app/video-img/heart-active.png");
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s; }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .box-list-videos .main-content-video .box-video .item-video {
        width: 256px; } }
    @media only screen and (min-width: 481px) and (max-width: 767px) {
      .box-list-videos .main-content-video .box-video .item-video {
        width: 50%; } }
    @media only screen and (max-width: 480px) {
      .box-list-videos .main-content-video .box-video .item-video {
        width: 100%; } }

@media (max-width: 768px) {
  .modal-dialog {
    width: 90%;
    margin: 30px auto; } }

/*Loader*/
.loading .loading-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999; }

.loading .loading-bg .progress-ring {
  position: absolute;
  padding-top: 12px;
  width: 60px;
  height: 60px;
  margin-left: -20px;
  margin-top: -20px;
  left: 50%;
  top: 50%;
  z-index: 2001; }

.loading .loading-bg .progress-ring .progress-ring__wrap {
  position: absolute;
  width: 58px;
  height: 58px; }

.loading .loading-bg .progress-ring .progress-ring__wrap .progress-ring__circle {
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 4000ms;
  width: 58px;
  height: 58px;
  opacity: 0; }

.loading .loading-bg .progress-ring .progress-ring__wrap .progress-ring__circle:after {
  content: '';
  position: absolute;
  width: 7.5px;
  height: 7.5px;
  border-radius: 7.5px;
  box-shadow: 0px 0px 5% #fff;
  background: #fff;
  /* Pick a color */ }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(2) {
  transform: rotate(-14deg); }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(2) .progress-ring__circle {
  animation-delay: 133.33333333ms; }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(3) {
  transform: rotate(-28deg); }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(3) .progress-ring__circle {
  animation-delay: 266.66666667ms; }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(4) {
  transform: rotate(-42deg); }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(4) .progress-ring__circle {
  animation-delay: 400ms; }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(5) {
  transform: rotate(-56deg); }

.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(5) .progress-ring__circle {
  animation-delay: 533.33333333ms; }

@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out; }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear; }
  35% {
    transform: rotate(495deg);
    animation-timing-function: ease-in-out; }
  42% {
    transform: rotate(690deg);
    animation-timing-function: linear; }
  70% {
    transform: rotate(835deg);
    opacity: 1;
    animation-timing-function: linear; }
  76% {
    opacity: 1; }
  77% {
    transform: rotate(955deg);
    animation-timing-function: ease-in; }
  78% {
    transform: rotate(955deg);
    opacity: 0; }
  100% {
    transform: rotate(955deg);
    opacity: 0; } }

/*Loader*/
.main-content-with-collapse .list-current-post-FAQS {
  border-bottom: 1px solid #e3e2e2; }
  .main-content-with-collapse .list-current-post-FAQS .content-collapse > a {
    border-bottom: 0;
    margin-bottom: 0; }
  .main-content-with-collapse .list-current-post-FAQS .content-collapse .box-item-socail {
    border-bottom: 1px solid #e3e2e2;
    margin-bottom: 20px; }
  .main-content-with-collapse .list-current-post-FAQS .content-collapse .collapse.in {
    margin-bottom: 20px; }

.main-content-with-collapse .content-collapse > a {
  display: inline-block;
  width: 100%;
  padding: 15px 0 20px;
  border-bottom: 1px solid #e3e2e2;
  margin-bottom: 20px;
  color: #177bbe;
  font-size: 20px;
  position: relative;
  padding-right: 60px;
  font-family: "PT Sans"; }
  .main-content-with-collapse .content-collapse > a .button-show-content {
    color: #177bbe;
    border: 1px solid #177bbe;
    height: 26px;
    width: 26px;
    padding: 0;
    position: relative;
    float: right;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 28px;
    transform: translateY(-50%); }
    .main-content-with-collapse .content-collapse > a .button-show-content:before {
      content: "";
      width: 15px;
      height: 2px;
      background-color: #177bbe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .main-content-with-collapse .content-collapse > a .button-show-content:after {
      content: "";
      height: 15px;
      width: 2px;
      background-color: #177bbe;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .main-content-with-collapse .content-collapse > a[aria-expanded="true"] .button-show-content:after {
    display: none; }
  .main-content-with-collapse .content-collapse > a.view-detail {
    padding: 0;
    display: inline;
    border: none;
    font-size: 16px;
    float: left; }

.main-content-with-collapse .content-collapse .box-socical-like a {
  margin-bottom: 5px; }

.main-content-with-collapse .content-collapse .collapse a:hover {
  text-decoration: underline; }

.main-content-with-collapse .content-collapse ul {
  list-style-type: disc;
  margin-left: 0;
  line-height: 26px;
  padding-left: 17px; }

.main-content-with-collapse .content-collapse ol {
  line-height: 26px;
  padding-left: 17px; }

.main-content-with-collapse .content-collapse .box-table-content table {
  margin-bottom: 20px; }

.main-content-with-collapse .content h1 {
  color: #292F35;
  font-family: "PT Sans", sans-serif;
  line-height: 34px;
  font-weight: bold;
  font-size: 20px; }
  .main-content-with-collapse .content h1 strong {
    font-weight: bold; }

.main-content-with-collapse .content h2 {
  color: #292F35;
  font-family: "Lato", sans-serif;
  line-height: 34px;
  font-weight: 900;
  font-size: 16px; }
  .main-content-with-collapse .content h2 strong {
    font-weight: 900; }

.main-content-with-collapse .content h3, .main-content-with-collapse .content h4, .main-content-with-collapse .content h5, .main-content-with-collapse .content h6 {
  font-size: 16px;
  font-weight: normal; }

.main-content-with-collapse .content p a:hover {
  text-decoration: underline; }

.main-content-with-collapse .content ul {
  list-style-type: disc;
  margin-left: 0;
  line-height: 26px;
  padding-left: 17px; }

.main-content-with-collapse .content ol {
  line-height: 26px; }

.main-content-with-collapse h3 {
  margin-bottom: 20px;
  color: #292F35;
  font-size: 20px;
  font-weight: normal; }

.toast {
  background-color: #c2de44; }

.toast-success {
  background-color: #90b900; }

.toast-error {
  background-color: #f1f1f1; }

.toast-info {
  background-color: #017bc1; }

.toast-warning {
  background-color: #dfb81c; }

#toast-container.toast-top-full-width > div, #toast-container.toast-bottom-full-width > div {
  margin: 5px auto; }

.modal-backdrop {
  z-index: 100; }
  .modal-backdrop ~ .modal-backdrop {
    z-index: 1051; }

.modal-content {
  border-radius: 0;
  border: none;
  color: #929497; }
  .modal-content iframe {
    margin-bottom: -4px; }

.modal-header {
  color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
  .modal-header .modal-title {
    color: #929497; }

.modal-buttons {
  margin-top: -12px;
  margin-right: -22px; }
  .modal-buttons button {
    float: left;
    margin-right: 24px;
    margin-top: 12px; }
  .modal-buttons.same-width button {
    width: 160px;
    text-align: center; }

.modal {
  text-align: center; }
  .modal .modal-dialog {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    vertical-align: middle; }
    .modal .modal-dialog .modal-body p, .modal .modal-dialog .modal-body div, .modal .modal-dialog .modal-body span {
      color: #929497; }
  .modal .modal-header {
    border: none; }
    .modal .modal-header .close {
      margin-top: -5px; }
  .modal .modal-footer {
    border: none;
    padding-top: 0; }
  .modal .modal-icon {
    margin-right: 3px; }
  .modal .sn-link-close {
    color: #929497;
    font-size: 30px; }
    .modal .sn-link-close:hover {
      color: #f1f1f1; }

#box_popup_evidence .modal-dialog {
  width: 740px; }
  #box_popup_evidence .modal-dialog .modal-content.modal-favourite {
    padding: 50px;
    position: relative; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite .modal-header {
      position: absolute;
      top: 15px;
      right: 15px; }
      #box_popup_evidence .modal-dialog .modal-content.modal-favourite .modal-header .sn-link-close {
        color: #177bbe; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite .main-content h2 {
      font-family: "PT Sans", Arial;
      font-weight: bold;
      font-size: 28px;
      color: #177bbe; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite .main-content p {
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #f9f9f9; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite .box-evidence .item {
      padding-top: 20px;
      border-bottom: 1px solid #f9f9f9; }
      #box_popup_evidence .modal-dialog .modal-content.modal-favourite .box-evidence .item p {
        margin-bottom: 20px; }

@media only screen and (max-width: 1200px) {
  #box_popup_evidence .modal-dialog {
    width: 660px; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite {
      padding: 50px 30px; } }

@media only screen and (max-width: 767px) {
  #box_popup_evidence .modal-dialog {
    width: 290px; }
    #box_popup_evidence .modal-dialog .modal-content.modal-favourite {
      padding: 50px 15px; } }

.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%; }

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%; } }

.modal-auth .modal-header {
  padding-bottom: 0; }

.modal-auth .modal-body {
  padding: 50px; }
  .modal-auth .modal-body.modal-success .content-auth h2 {
    text-align: left;
    margin-bottom: 30px; }
  .modal-auth .modal-body.modal-success .content-auth p {
    text-align: left;
    margin-bottom: 30px; }
  .modal-auth .modal-body .content-auth h2 {
    color: #177bbe;
    font-size: 24px;
    font-family: "Lato", Arial;
    text-align: center;
    font-weight: bold; }
  .modal-auth .modal-body .content-auth p {
    text-align: center;
    font-size: 16px; }
  .modal-auth .modal-body .content-auth .box-message-reset .message-reset {
    padding: 15px 40px;
    background-color: #a9c600;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    margin-bottom: 15px;
    border-radius: 5px; }
    .modal-auth .modal-body .content-auth .box-message-reset .message-reset.danger {
      background-color: #F00; }
  .modal-auth .modal-body .content-auth .box-message-reset p {
    text-align: left;
    padding: 15px 40px;
    position: relative;
    background-color: #f4f4f4; }
    .modal-auth .modal-body .content-auth .box-message-reset p i {
      position: absolute;
      left: 4%;
      top: 25%; }
  .modal-auth .modal-body .content-auth .alert-messages {
    padding: 15px 0; }
    .modal-auth .modal-body .content-auth .alert-messages span {
      color: #f00;
      position: relative;
      padding-left: 25px; }
      .modal-auth .modal-body .content-auth .alert-messages span:after {
        content: "\00d7";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ff0000;
        color: #ffffff;
        font-size: 23px;
        line-height: 18px;
        text-align: center; }
  .modal-auth .modal-body .content-auth #frmResendVerification .alert-messages {
    position: absolute;
    padding: 7px 0; }
  .modal-auth .modal-body .content-auth .form-group {
    position: relative;
    margin-bottom: 35px; }
    .modal-auth .modal-body .content-auth .form-group label {
      color: #282e34;
      font-size: 14px;
      font-weight: bold; }
      .modal-auth .modal-body .content-auth .form-group label.error {
        position: absolute;
        bottom: -32px;
        color: #f00;
        padding-left: 25px;
        font-weight: normal;
        font-size: 15px; }
        .modal-auth .modal-body .content-auth .form-group label.error .for-default, .modal-auth .modal-body .content-auth .form-group label.error .for-myaccount {
          display: none;
          color: #F00; }
        .modal-auth .modal-body .content-auth .form-group label.error .for-account {
          display: block;
          color: #F00; }
        .modal-auth .modal-body .content-auth .form-group label.error:before {
          content: "\00d7";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #ff0000;
          color: #ffffff;
          font-size: 20px;
          line-height: 18px;
          text-align: center;
          font-weight: normal; }
    .modal-auth .modal-body .content-auth .form-group .form-control {
      height: 50px; }
      .modal-auth .modal-body .content-auth .form-group .form-control:focus {
        border-color: #177bbe; }
  .modal-auth .modal-body .content-auth .checkbox {
    padding: 15px 0; }
    .modal-auth .modal-body .content-auth .checkbox .custom-checkbox span:before, .modal-auth .modal-body .content-auth .checkbox label.custom-radio span:before {
      color: #177bbe;
      border-radius: 4px;
      height: 20px;
      line-height: 18px;
      min-width: 20px; }
    .modal-auth .modal-body .content-auth .checkbox .custom-checkbox span:hover:before, .modal-auth .modal-body .content-auth .checkbox label.custom-radio span:hover:before {
      border-color: #177bbe; }
    .modal-auth .modal-body .content-auth .checkbox .custom-checkbox #closeButton:checked ~ span:before, .modal-auth .modal-body .content-auth .checkbox label.custom-radio #closeButton:checked ~ span:before {
      border-color: #177bbe; }
  .modal-auth .modal-body .content-auth .button-auth .option-auth p {
    text-align: right; }
    .modal-auth .modal-body .content-auth .button-auth .option-auth p a {
      color: #929497; }
      .modal-auth .modal-body .content-auth .button-auth .option-auth p a:hover {
        color: #282e34; }
  @media only screen and (max-width: 547px) {
    .modal-auth .modal-body {
      padding: 20px; }
      .modal-auth .modal-body .content-auth .button-auth .option-auth {
        margin-top: 30px; }
        .modal-auth .modal-body .content-auth .button-auth .option-auth p {
          text-align: left; } }

#my_account .header-page .title-header-page h1:before {
  background-image: url("../../assets/pictures/my-account.png"); }

#my_account .success {
  padding: 15px 40px;
  background-color: #a9c600;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 30px; }
  #my_account .success div {
    background-image: url("../../assets/img/app/effectiveness/icon-success-white.png");
    background-repeat: no-repeat;
    padding-left: 40px; }

#my_account .sub-title-my-account {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  color: #177BBE;
  margin-bottom: 35px;
  font-weight: bold; }

#my_account .box-delete-account {
  text-align: right; }
  #my_account .box-delete-account h5 {
    color: #929497;
    font-size: 14px;
    font-family: "Lato";
    font-weight: normal;
    cursor: pointer; }
    #my_account .box-delete-account h5 i {
      font-size: 20px;
      margin-right: 10px; }

#my_account .form-my-account input.error {
  border-color: #FF0000; }

#my_account .form-my-account #receive_emails-selectized-error .for-default, #my_account .form-my-account #receive_emails-selectized-error .for-account {
  display: none; }

#my_account .form-my-account #subscribe_email-error {
  position: absolute;
  left: 15px;
  bottom: 0; }

#my_account .form-my-account .custom-radio {
  margin-right: 10px;
  position: static;
  margin-bottom: 25px; }

#my_account .form-my-account span.error {
  position: relative;
  bottom: -10px;
  color: #f00;
  padding-left: 25px;
  font-weight: normal;
  font-size: 15px; }
  #my_account .form-my-account span.error:before {
    content: "\00d7";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    font-weight: normal; }

#my_account .form-my-account .line-center {
  height: 1px;
  width: 100%;
  background-color: #EAEBEB;
  margin-bottom: 25px; }

#my_account .form-my-account .selectize-input {
  border: 1px solid #d0d0d0;
  padding: 10px;
  display: inline-block;
  width: 100%;
  overflow: initial;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0px; }
  #my_account .form-my-account .selectize-input.error {
    border-color: #f00; }

#my_account .form-my-account h3 {
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: #000000; }

#my_account .form-my-account input {
  border-radius: 0;
  resize: none; }

#my_account .form-my-account label {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #292F35;
  margin-bottom: 15px; }

#my_account .submit-form-my-account {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #177bbe;
  border: none; }

#modal_delete_user .modal-dialog {
  max-width: 400px; }
  #modal_delete_user .modal-dialog .modal-header {
    padding-bottom: 0; }
  #modal_delete_user .modal-dialog .modal-body {
    padding: 50px;
    padding-top: 0; }
    #modal_delete_user .modal-dialog .modal-body .content-delete-user h2 {
      font-size: 32px;
      font-family: "PT Sans";
      color: #177BBE;
      font-weight: bold;
      margin-bottom: 0; }
    #modal_delete_user .modal-dialog .modal-body .content-delete-user p {
      font-size: 16px;
      font-family: "Lato";
      font-weight: bold;
      color: #6A6E72;
      margin: 45px 0; }
    #modal_delete_user .modal-dialog .modal-body .box-button .btn-delete-user {
      font-size: 16px;
      font-family: "Lato";
      font-weight: bold;
      background: #FF3E39;
      color: #fff;
      padding-left: 17px;
      padding-right: 17px; }
    #modal_delete_user .modal-dialog .modal-body .box-button .btn-close-modal {
      color: #929497;
      font-size: 14px;
      font-family: "Lato";
      font-weight: normal;
      margin-left: 40px;
      cursor: pointer; }

.control {
  margin-bottom: 10px; }
  .control .form-control {
    width: 75%; }
  .control span {
    white-space: pre-line; }

.notification-panel .control,
.notification-panel .radio,
.notification-panel label,
.notification-panel label.custom-checkbox > span,
.notification-panel label.custom-radio > span,
.notification-panel label.custom-radio > span {
  font-weight: 300; }

.radio-controls .custom-radio {
  margin-top: 5px; }

@media (max-width: 991px) {
  .toastr-radio-setup {
    margin-left: 22px; } }

.radio-header {
  margin-bottom: 0; }
  .radio-header.position-header {
    margin-top: 15px; }

.button-row {
  line-height: 37px; }
  .button-row button {
    width: 125px; }

.result-toastr {
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.33);
  border: none; }

.sub-label {
  margin-top: 5px; }

.toast-title {
  font-weight: 400; }

.toast-message {
  font-weight: 300; }

#toast-container.toast-top-center .toast, #toast-container.toast-bottom-center .toast {
  margin-bottom: 5px; }

h3.with-line {
  border-bottom: 1px solid #d6d6d6;
  color: #666666;
  font-weight: 400;
  padding-top: 30px;
  margin-bottom: 14px;
  line-height: 39px;
  width: 100%; }

.panel .panel-body h3.with-line {
  margin-bottom: 14px;
  margin-top: 0; }

.profile-block {
  background: #ffffff; }
  .profile-block .nav-tabs > li > a {
    border-radius: 0;
    transition: background, 0s, ease !important; }
  .profile-block .progress {
    border-radius: 0;
    margin-bottom: 6px; }
  .profile-block .progress-info {
    margin-bottom: 8px;
    margin-top: -2px; }
  .profile-block .tab-content {
    padding: 15px 15px 25px; }
  .profile-block .progress-bar-primary {
    background-color: #d4e87c; }
  .profile-block .form-group {
    margin-bottom: 14px; }
    .profile-block .form-group label {
      text-align: right; }
  .profile-block .form-control {
    font-size: 13px; }

.notification .form-group label {
  text-align: left;
  padding-left: 26px; }

.userpic {
  border: 1px dashed #b8b8b8;
  width: 202px;
  height: 202px;
  position: relative;
  cursor: pointer; }
  .userpic .userpic-wrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; }
  .userpic img {
    max-width: 100%;
    max-height: 100%; }
  .userpic i {
    display: none;
    position: absolute;
    font-size: 32px;
    background: #ffffff;
    cursor: pointer;
    color: #c2de44;
    top: -11px;
    right: -11px;
    height: 26px;
    border-radius: 50%; }
    .userpic i:before {
      line-height: 26px; }
    .userpic i:hover {
      color: #f1f1f1; }
  .userpic a.change-userpic {
    display: none;
    width: 202px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 200ms ease-in-out;
    color: #666666;
    text-decoration: none;
    position: absolute;
    bottom: -1px;
    left: -1px;
    line-height: 32px;
    text-align: center; }
  .userpic:hover i {
    display: block; }
  .userpic:hover .change-userpic {
    display: block; }

.save-profile {
  margin: 23px 0 12px 12px; }

a.sn-link {
  transition: none;
  display: block;
  border-radius: 5px;
  background-color: transparent;
  padding: 8px;
  color: #666666;
  border: 1px solid transparent;
  margin-bottom: 23px;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-decoration: none !important; }
  a.sn-link i.socicon {
    padding: 6px;
    border-radius: 50%;
    width: auto;
    font-size: 17px;
    margin-right: 8px;
    background-color: rgba(0, 0, 0, 0.15);
    color: #666666; }
  a.sn-link span {
    font-size: 14px;
    line-height: 29px; }
  a.sn-link.connected {
    border-color: #c2de44;
    border-width: 2px;
    padding: 7px; }
    a.sn-link.connected span {
      color: #666666; }
    a.sn-link.connected i {
      background: #c2de44; }
    a.sn-link.connected em {
      display: block; }
  a.sn-link:hover {
    border-color: #c2de44;
    border-width: 2px;
    padding: 7px; }
    a.sn-link:hover span {
      color: #666666; }
    a.sn-link:hover i {
      background: #c2de44; }
  a.sn-link .socicon {
    background: transparent; }

.close {
  opacity: 1;
  box-shadow: none;
  outline: none !important; }
  .close:hover, .close:focus, .close:active {
    opacity: 1; }

.connected .sn-link-close {
  position: absolute;
  top: 1px;
  right: 0;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 32px;
  color: #c2de44;
  transition: all 0.1s ease;
  outline: none; }
  .connected .sn-link-close:hover {
    color: #f1f1f1;
    transform: scale(1.2); }

.social-profiles {
  padding-top: 3px;
  margin-bottom: -3px;
  padding-left: 12px; }

.profile-page .form-group label {
  line-height: 34px;
  font-size: 14px;
  font-weight: 300; }

.profile-page .form-group input {
  font-weight: 300; }

/* public padding css */
/*PUBLIC CSS*/
.font-size-8 {
  font-size: 8px !important; }

.font-size-9 {
  font-size: 9px !important; }

.font-size-10 {
  font-size: 10px !important; }

.font-size-11 {
  font-size: 11px !important; }

.font-size-12 {
  font-size: 12px !important; }

.font-size-13 {
  font-size: 13px !important; }

.font-size-14 {
  font-size: 14px !important; }

.font-size-15 {
  font-size: 15px !important; }

.font-size-16 {
  font-size: 16px !important; }

.font-size-17 {
  font-size: 17px !important; }

.font-size-18 {
  font-size: 18px !important; }

.font-size-19 {
  font-size: 19px !important; }

.font-size-20 {
  font-size: 20px !important; }

.font-size-21 {
  font-size: 21px !important; }

.font-size-22 {
  font-size: 22px !important; }

.font-size-23 {
  font-size: 23px !important; }

.font-size-24 {
  font-size: 24px !important; }

.font-size-25 {
  font-size: 25px !important; }

.font-size-26 {
  font-size: 26px !important; }

.font-size-27 {
  font-size: 27px !important; }

.font-size-28 {
  font-size: 28px !important; }

.font-size-29 {
  font-size: 29px !important; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-31 {
  font-size: 31px !important; }

.font-size-32 {
  font-size: 32px !important; }

.font-size-33 {
  font-size: 33px !important; }

.font-size-34 {
  font-size: 34px !important; }

.font-size-35 {
  font-size: 35px !important; }

.font-size-36 {
  font-size: 36px !important; }

.font-size-37 {
  font-size: 37px !important; }

.font-size-38 {
  font-size: 38px !important; }

.font-size-39 {
  font-size: 39px !important; }

.font-size-40 {
  font-size: 40px !important; }

.font-size-41 {
  font-size: 41px !important; }

.font-size-42 {
  font-size: 42px !important; }

.font-size-43 {
  font-size: 43px !important; }

.font-size-44 {
  font-size: 44px !important; }

.font-size-45 {
  font-size: 45px !important; }

.font-size-46 {
  font-size: 46px !important; }

.font-size-47 {
  font-size: 47px !important; }

.font-size-48 {
  font-size: 48px !important; }

.font-size-49 {
  font-size: 49px !important; }

.font-size-50 {
  font-size: 50px !important; }

.font-size-51 {
  font-size: 51px !important; }

.font-size-52 {
  font-size: 52px !important; }

.font-size-53 {
  font-size: 53px !important; }

.font-size-54 {
  font-size: 54px !important; }

.font-size-55 {
  font-size: 55px !important; }

.font-size-56 {
  font-size: 56px !important; }

.font-size-57 {
  font-size: 57px !important; }

.font-size-58 {
  font-size: 58px !important; }

.font-size-59 {
  font-size: 59px !important; }

.font-size-60 {
  font-size: 60px !important; }

.mar-0 {
  margin: 0px !important; }

.mar-top-0 {
  margin-top: 0px !important; }

.mar-rig-0 {
  margin-right: 0px !important; }

.mar-bot-0 {
  margin-bottom: 0px !important; }

.mar-lef-0 {
  margin-left: 0px !important; }

.mar-top-neg-0 {
  margin-top: -0px !important; }

.mar-rig-neg-0 {
  margin-right: -0px !important; }

.mar-bot-neg-0 {
  margin-bottom: -0px !important; }

.mar-lef-neg-0 {
  margin-left: -0px !important; }

.pad-0 {
  padding: 0px !important; }

.pad-top-0 {
  padding-top: 0px !important; }

.pad-rig-0 {
  padding-right: 0px !important; }

.pad-bot-0 {
  padding-bottom: 0px !important; }

.pad-lef-0 {
  padding-left: 0px !important; }

.mar-5 {
  margin: 5px !important; }

.mar-top-5 {
  margin-top: 5px !important; }

.mar-rig-5 {
  margin-right: 5px !important; }

.mar-bot-5 {
  margin-bottom: 5px !important; }

.mar-lef-5 {
  margin-left: 5px !important; }

.mar-top-neg-5 {
  margin-top: -5px !important; }

.mar-rig-neg-5 {
  margin-right: -5px !important; }

.mar-bot-neg-5 {
  margin-bottom: -5px !important; }

.mar-lef-neg-5 {
  margin-left: -5px !important; }

.pad-5 {
  padding: 5px !important; }

.pad-top-5 {
  padding-top: 5px !important; }

.pad-rig-5 {
  padding-right: 5px !important; }

.pad-bot-5 {
  padding-bottom: 5px !important; }

.pad-lef-5 {
  padding-left: 5px !important; }

.mar-10 {
  margin: 10px !important; }

.mar-top-10 {
  margin-top: 10px !important; }

.mar-rig-10 {
  margin-right: 10px !important; }

.mar-bot-10 {
  margin-bottom: 10px !important; }

.mar-lef-10 {
  margin-left: 10px !important; }

.mar-top-neg-10 {
  margin-top: -10px !important; }

.mar-rig-neg-10 {
  margin-right: -10px !important; }

.mar-bot-neg-10 {
  margin-bottom: -10px !important; }

.mar-lef-neg-10 {
  margin-left: -10px !important; }

.pad-10 {
  padding: 10px !important; }

.pad-top-10 {
  padding-top: 10px !important; }

.pad-rig-10 {
  padding-right: 10px !important; }

.pad-bot-10 {
  padding-bottom: 10px !important; }

.pad-lef-10 {
  padding-left: 10px !important; }

.mar-15 {
  margin: 15px !important; }

.mar-top-15 {
  margin-top: 15px !important; }

.mar-rig-15 {
  margin-right: 15px !important; }

.mar-bot-15 {
  margin-bottom: 15px !important; }

.mar-lef-15 {
  margin-left: 15px !important; }

.mar-top-neg-15 {
  margin-top: -15px !important; }

.mar-rig-neg-15 {
  margin-right: -15px !important; }

.mar-bot-neg-15 {
  margin-bottom: -15px !important; }

.mar-lef-neg-15 {
  margin-left: -15px !important; }

.pad-15 {
  padding: 15px !important; }

.pad-top-15 {
  padding-top: 15px !important; }

.pad-rig-15 {
  padding-right: 15px !important; }

.pad-bot-15 {
  padding-bottom: 15px !important; }

.pad-lef-15 {
  padding-left: 15px !important; }

.mar-20 {
  margin: 20px !important; }

.mar-top-20 {
  margin-top: 20px !important; }

.mar-rig-20 {
  margin-right: 20px !important; }

.mar-bot-20 {
  margin-bottom: 20px !important; }

.mar-lef-20 {
  margin-left: 20px !important; }

.mar-top-neg-20 {
  margin-top: -20px !important; }

.mar-rig-neg-20 {
  margin-right: -20px !important; }

.mar-bot-neg-20 {
  margin-bottom: -20px !important; }

.mar-lef-neg-20 {
  margin-left: -20px !important; }

.pad-20 {
  padding: 20px !important; }

.pad-top-20 {
  padding-top: 20px !important; }

.pad-rig-20 {
  padding-right: 20px !important; }

.pad-bot-20 {
  padding-bottom: 20px !important; }

.pad-lef-20 {
  padding-left: 20px !important; }

.mar-25 {
  margin: 25px !important; }

.mar-top-25 {
  margin-top: 25px !important; }

.mar-rig-25 {
  margin-right: 25px !important; }

.mar-bot-25 {
  margin-bottom: 25px !important; }

.mar-lef-25 {
  margin-left: 25px !important; }

.mar-top-neg-25 {
  margin-top: -25px !important; }

.mar-rig-neg-25 {
  margin-right: -25px !important; }

.mar-bot-neg-25 {
  margin-bottom: -25px !important; }

.mar-lef-neg-25 {
  margin-left: -25px !important; }

.pad-25 {
  padding: 25px !important; }

.pad-top-25 {
  padding-top: 25px !important; }

.pad-rig-25 {
  padding-right: 25px !important; }

.pad-bot-25 {
  padding-bottom: 25px !important; }

.pad-lef-25 {
  padding-left: 25px !important; }

.mar-30 {
  margin: 30px !important; }

.mar-top-30 {
  margin-top: 30px !important; }

.mar-rig-30 {
  margin-right: 30px !important; }

.mar-bot-30 {
  margin-bottom: 30px !important; }

.mar-lef-30 {
  margin-left: 30px !important; }

.mar-top-neg-30 {
  margin-top: -30px !important; }

.mar-rig-neg-30 {
  margin-right: -30px !important; }

.mar-bot-neg-30 {
  margin-bottom: -30px !important; }

.mar-lef-neg-30 {
  margin-left: -30px !important; }

.pad-30 {
  padding: 30px !important; }

.pad-top-30 {
  padding-top: 30px !important; }

.pad-rig-30 {
  padding-right: 30px !important; }

.pad-bot-30 {
  padding-bottom: 30px !important; }

.pad-lef-30 {
  padding-left: 30px !important; }

.mar-35 {
  margin: 35px !important; }

.mar-top-35 {
  margin-top: 35px !important; }

.mar-rig-35 {
  margin-right: 35px !important; }

.mar-bot-35 {
  margin-bottom: 35px !important; }

.mar-lef-35 {
  margin-left: 35px !important; }

.mar-top-neg-35 {
  margin-top: -35px !important; }

.mar-rig-neg-35 {
  margin-right: -35px !important; }

.mar-bot-neg-35 {
  margin-bottom: -35px !important; }

.mar-lef-neg-35 {
  margin-left: -35px !important; }

.pad-35 {
  padding: 35px !important; }

.pad-top-35 {
  padding-top: 35px !important; }

.pad-rig-35 {
  padding-right: 35px !important; }

.pad-bot-35 {
  padding-bottom: 35px !important; }

.pad-lef-35 {
  padding-left: 35px !important; }

.mar-40 {
  margin: 40px !important; }

.mar-top-40 {
  margin-top: 40px !important; }

.mar-rig-40 {
  margin-right: 40px !important; }

.mar-bot-40 {
  margin-bottom: 40px !important; }

.mar-lef-40 {
  margin-left: 40px !important; }

.mar-top-neg-40 {
  margin-top: -40px !important; }

.mar-rig-neg-40 {
  margin-right: -40px !important; }

.mar-bot-neg-40 {
  margin-bottom: -40px !important; }

.mar-lef-neg-40 {
  margin-left: -40px !important; }

.pad-40 {
  padding: 40px !important; }

.pad-top-40 {
  padding-top: 40px !important; }

.pad-rig-40 {
  padding-right: 40px !important; }

.pad-bot-40 {
  padding-bottom: 40px !important; }

.pad-lef-40 {
  padding-left: 40px !important; }

.mar-45 {
  margin: 45px !important; }

.mar-top-45 {
  margin-top: 45px !important; }

.mar-rig-45 {
  margin-right: 45px !important; }

.mar-bot-45 {
  margin-bottom: 45px !important; }

.mar-lef-45 {
  margin-left: 45px !important; }

.mar-top-neg-45 {
  margin-top: -45px !important; }

.mar-rig-neg-45 {
  margin-right: -45px !important; }

.mar-bot-neg-45 {
  margin-bottom: -45px !important; }

.mar-lef-neg-45 {
  margin-left: -45px !important; }

.pad-45 {
  padding: 45px !important; }

.pad-top-45 {
  padding-top: 45px !important; }

.pad-rig-45 {
  padding-right: 45px !important; }

.pad-bot-45 {
  padding-bottom: 45px !important; }

.pad-lef-45 {
  padding-left: 45px !important; }

.mar-50 {
  margin: 50px !important; }

.mar-top-50 {
  margin-top: 50px !important; }

.mar-rig-50 {
  margin-right: 50px !important; }

.mar-bot-50 {
  margin-bottom: 50px !important; }

.mar-lef-50 {
  margin-left: 50px !important; }

.mar-top-neg-50 {
  margin-top: -50px !important; }

.mar-rig-neg-50 {
  margin-right: -50px !important; }

.mar-bot-neg-50 {
  margin-bottom: -50px !important; }

.mar-lef-neg-50 {
  margin-left: -50px !important; }

.pad-50 {
  padding: 50px !important; }

.pad-top-50 {
  padding-top: 50px !important; }

.pad-rig-50 {
  padding-right: 50px !important; }

.pad-bot-50 {
  padding-bottom: 50px !important; }

.pad-lef-50 {
  padding-left: 50px !important; }

.mar-55 {
  margin: 55px !important; }

.mar-top-55 {
  margin-top: 55px !important; }

.mar-rig-55 {
  margin-right: 55px !important; }

.mar-bot-55 {
  margin-bottom: 55px !important; }

.mar-lef-55 {
  margin-left: 55px !important; }

.mar-top-neg-55 {
  margin-top: -55px !important; }

.mar-rig-neg-55 {
  margin-right: -55px !important; }

.mar-bot-neg-55 {
  margin-bottom: -55px !important; }

.mar-lef-neg-55 {
  margin-left: -55px !important; }

.pad-55 {
  padding: 55px !important; }

.pad-top-55 {
  padding-top: 55px !important; }

.pad-rig-55 {
  padding-right: 55px !important; }

.pad-bot-55 {
  padding-bottom: 55px !important; }

.pad-lef-55 {
  padding-left: 55px !important; }

.mar-60 {
  margin: 60px !important; }

.mar-top-60 {
  margin-top: 60px !important; }

.mar-rig-60 {
  margin-right: 60px !important; }

.mar-bot-60 {
  margin-bottom: 60px !important; }

.mar-lef-60 {
  margin-left: 60px !important; }

.mar-top-neg-60 {
  margin-top: -60px !important; }

.mar-rig-neg-60 {
  margin-right: -60px !important; }

.mar-bot-neg-60 {
  margin-bottom: -60px !important; }

.mar-lef-neg-60 {
  margin-left: -60px !important; }

.pad-60 {
  padding: 60px !important; }

.pad-top-60 {
  padding-top: 60px !important; }

.pad-rig-60 {
  padding-right: 60px !important; }

.pad-bot-60 {
  padding-bottom: 60px !important; }

.pad-lef-60 {
  padding-left: 60px !important; }

.mar-65 {
  margin: 65px !important; }

.mar-top-65 {
  margin-top: 65px !important; }

.mar-rig-65 {
  margin-right: 65px !important; }

.mar-bot-65 {
  margin-bottom: 65px !important; }

.mar-lef-65 {
  margin-left: 65px !important; }

.mar-top-neg-65 {
  margin-top: -65px !important; }

.mar-rig-neg-65 {
  margin-right: -65px !important; }

.mar-bot-neg-65 {
  margin-bottom: -65px !important; }

.mar-lef-neg-65 {
  margin-left: -65px !important; }

.pad-65 {
  padding: 65px !important; }

.pad-top-65 {
  padding-top: 65px !important; }

.pad-rig-65 {
  padding-right: 65px !important; }

.pad-bot-65 {
  padding-bottom: 65px !important; }

.pad-lef-65 {
  padding-left: 65px !important; }

.mar-70 {
  margin: 70px !important; }

.mar-top-70 {
  margin-top: 70px !important; }

.mar-rig-70 {
  margin-right: 70px !important; }

.mar-bot-70 {
  margin-bottom: 70px !important; }

.mar-lef-70 {
  margin-left: 70px !important; }

.mar-top-neg-70 {
  margin-top: -70px !important; }

.mar-rig-neg-70 {
  margin-right: -70px !important; }

.mar-bot-neg-70 {
  margin-bottom: -70px !important; }

.mar-lef-neg-70 {
  margin-left: -70px !important; }

.pad-70 {
  padding: 70px !important; }

.pad-top-70 {
  padding-top: 70px !important; }

.pad-rig-70 {
  padding-right: 70px !important; }

.pad-bot-70 {
  padding-bottom: 70px !important; }

.pad-lef-70 {
  padding-left: 70px !important; }

.mar-75 {
  margin: 75px !important; }

.mar-top-75 {
  margin-top: 75px !important; }

.mar-rig-75 {
  margin-right: 75px !important; }

.mar-bot-75 {
  margin-bottom: 75px !important; }

.mar-lef-75 {
  margin-left: 75px !important; }

.mar-top-neg-75 {
  margin-top: -75px !important; }

.mar-rig-neg-75 {
  margin-right: -75px !important; }

.mar-bot-neg-75 {
  margin-bottom: -75px !important; }

.mar-lef-neg-75 {
  margin-left: -75px !important; }

.pad-75 {
  padding: 75px !important; }

.pad-top-75 {
  padding-top: 75px !important; }

.pad-rig-75 {
  padding-right: 75px !important; }

.pad-bot-75 {
  padding-bottom: 75px !important; }

.pad-lef-75 {
  padding-left: 75px !important; }

.mar-80 {
  margin: 80px !important; }

.mar-top-80 {
  margin-top: 80px !important; }

.mar-rig-80 {
  margin-right: 80px !important; }

.mar-bot-80 {
  margin-bottom: 80px !important; }

.mar-lef-80 {
  margin-left: 80px !important; }

.mar-top-neg-80 {
  margin-top: -80px !important; }

.mar-rig-neg-80 {
  margin-right: -80px !important; }

.mar-bot-neg-80 {
  margin-bottom: -80px !important; }

.mar-lef-neg-80 {
  margin-left: -80px !important; }

.pad-80 {
  padding: 80px !important; }

.pad-top-80 {
  padding-top: 80px !important; }

.pad-rig-80 {
  padding-right: 80px !important; }

.pad-bot-80 {
  padding-bottom: 80px !important; }

.pad-lef-80 {
  padding-left: 80px !important; }

.mar-85 {
  margin: 85px !important; }

.mar-top-85 {
  margin-top: 85px !important; }

.mar-rig-85 {
  margin-right: 85px !important; }

.mar-bot-85 {
  margin-bottom: 85px !important; }

.mar-lef-85 {
  margin-left: 85px !important; }

.mar-top-neg-85 {
  margin-top: -85px !important; }

.mar-rig-neg-85 {
  margin-right: -85px !important; }

.mar-bot-neg-85 {
  margin-bottom: -85px !important; }

.mar-lef-neg-85 {
  margin-left: -85px !important; }

.pad-85 {
  padding: 85px !important; }

.pad-top-85 {
  padding-top: 85px !important; }

.pad-rig-85 {
  padding-right: 85px !important; }

.pad-bot-85 {
  padding-bottom: 85px !important; }

.pad-lef-85 {
  padding-left: 85px !important; }

.mar-90 {
  margin: 90px !important; }

.mar-top-90 {
  margin-top: 90px !important; }

.mar-rig-90 {
  margin-right: 90px !important; }

.mar-bot-90 {
  margin-bottom: 90px !important; }

.mar-lef-90 {
  margin-left: 90px !important; }

.mar-top-neg-90 {
  margin-top: -90px !important; }

.mar-rig-neg-90 {
  margin-right: -90px !important; }

.mar-bot-neg-90 {
  margin-bottom: -90px !important; }

.mar-lef-neg-90 {
  margin-left: -90px !important; }

.pad-90 {
  padding: 90px !important; }

.pad-top-90 {
  padding-top: 90px !important; }

.pad-rig-90 {
  padding-right: 90px !important; }

.pad-bot-90 {
  padding-bottom: 90px !important; }

.pad-lef-90 {
  padding-left: 90px !important; }

.mar-95 {
  margin: 95px !important; }

.mar-top-95 {
  margin-top: 95px !important; }

.mar-rig-95 {
  margin-right: 95px !important; }

.mar-bot-95 {
  margin-bottom: 95px !important; }

.mar-lef-95 {
  margin-left: 95px !important; }

.mar-top-neg-95 {
  margin-top: -95px !important; }

.mar-rig-neg-95 {
  margin-right: -95px !important; }

.mar-bot-neg-95 {
  margin-bottom: -95px !important; }

.mar-lef-neg-95 {
  margin-left: -95px !important; }

.pad-95 {
  padding: 95px !important; }

.pad-top-95 {
  padding-top: 95px !important; }

.pad-rig-95 {
  padding-right: 95px !important; }

.pad-bot-95 {
  padding-bottom: 95px !important; }

.pad-lef-95 {
  padding-left: 95px !important; }

.mar-100 {
  margin: 100px !important; }

.mar-top-100 {
  margin-top: 100px !important; }

.mar-rig-100 {
  margin-right: 100px !important; }

.mar-bot-100 {
  margin-bottom: 100px !important; }

.mar-lef-100 {
  margin-left: 100px !important; }

.mar-top-neg-100 {
  margin-top: -100px !important; }

.mar-rig-neg-100 {
  margin-right: -100px !important; }

.mar-bot-neg-100 {
  margin-bottom: -100px !important; }

.mar-lef-neg-100 {
  margin-left: -100px !important; }

.pad-100 {
  padding: 100px !important; }

.pad-top-100 {
  padding-top: 100px !important; }

.pad-rig-100 {
  padding-right: 100px !important; }

.pad-bot-100 {
  padding-bottom: 100px !important; }

.pad-lef-100 {
  padding-left: 100px !important; }

.line-height-0 {
  line-height: 0px !important; }

.line-height-1 {
  line-height: 1px !important; }

.line-height-2 {
  line-height: 2px !important; }

.line-height-3 {
  line-height: 3px !important; }

.line-height-4 {
  line-height: 4px !important; }

.line-height-5 {
  line-height: 5px !important; }

.line-height-6 {
  line-height: 6px !important; }

.line-height-7 {
  line-height: 7px !important; }

.line-height-8 {
  line-height: 8px !important; }

.line-height-9 {
  line-height: 9px !important; }

.line-height-10 {
  line-height: 10px !important; }

.line-height-11 {
  line-height: 11px !important; }

.line-height-12 {
  line-height: 12px !important; }

.line-height-13 {
  line-height: 13px !important; }

.line-height-14 {
  line-height: 14px !important; }

.line-height-15 {
  line-height: 15px !important; }

.line-height-16 {
  line-height: 16px !important; }

.line-height-17 {
  line-height: 17px !important; }

.line-height-18 {
  line-height: 18px !important; }

.line-height-19 {
  line-height: 19px !important; }

.line-height-20 {
  line-height: 20px !important; }

.line-height-21 {
  line-height: 21px !important; }

.line-height-22 {
  line-height: 22px !important; }

.line-height-23 {
  line-height: 23px !important; }

.line-height-24 {
  line-height: 24px !important; }

.line-height-25 {
  line-height: 25px !important; }

.line-height-26 {
  line-height: 26px !important; }

.line-height-27 {
  line-height: 27px !important; }

.line-height-28 {
  line-height: 28px !important; }

.line-height-29 {
  line-height: 29px !important; }

.line-height-30 {
  line-height: 30px !important; }

.line-height-31 {
  line-height: 31px !important; }

.line-height-32 {
  line-height: 32px !important; }

.line-height-33 {
  line-height: 33px !important; }

.line-height-34 {
  line-height: 34px !important; }

.line-height-35 {
  line-height: 35px !important; }

.line-height-36 {
  line-height: 36px !important; }

.line-height-37 {
  line-height: 37px !important; }

.line-height-38 {
  line-height: 38px !important; }

.line-height-39 {
  line-height: 39px !important; }

.line-height-40 {
  line-height: 40px !important; }

.line-height-41 {
  line-height: 41px !important; }

.line-height-42 {
  line-height: 42px !important; }

.line-height-43 {
  line-height: 43px !important; }

.line-height-44 {
  line-height: 44px !important; }

.line-height-45 {
  line-height: 45px !important; }

.line-height-46 {
  line-height: 46px !important; }

.line-height-47 {
  line-height: 47px !important; }

.line-height-48 {
  line-height: 48px !important; }

.line-height-49 {
  line-height: 49px !important; }

.line-height-50 {
  line-height: 50px !important; }

.line-height-51 {
  line-height: 51px !important; }

.line-height-52 {
  line-height: 52px !important; }

.line-height-53 {
  line-height: 53px !important; }

.line-height-54 {
  line-height: 54px !important; }

.line-height-55 {
  line-height: 55px !important; }

.line-height-56 {
  line-height: 56px !important; }

.line-height-57 {
  line-height: 57px !important; }

.line-height-58 {
  line-height: 58px !important; }

.line-height-59 {
  line-height: 59px !important; }

.line-height-60 {
  line-height: 60px !important; }

.dis-block {
  display: block !important; }

.dis-none {
  display: none !important; }

.dis-inline {
  display: inline !important; }

.dis-inline-block {
  display: inline-block !important; }

.text-cen {
  text-align: center !important; }

.text-lef {
  text-align: left !important; }

.text-rig {
  text-align: right !important; }

.text-jus {
  text-align: justify !important; }

.pos-re {
  position: relative !important; }

.pos-ab {
  position: absolute !important; }

.pos-st {
  position: static !important; }

@media (min-width: 0px) {
  .font-size-xs-8 {
    font-size: 8px !important; }
  .font-size-xs-9 {
    font-size: 9px !important; }
  .font-size-xs-10 {
    font-size: 10px !important; }
  .font-size-xs-11 {
    font-size: 11px !important; }
  .font-size-xs-12 {
    font-size: 12px !important; }
  .font-size-xs-13 {
    font-size: 13px !important; }
  .font-size-xs-14 {
    font-size: 14px !important; }
  .font-size-xs-15 {
    font-size: 15px !important; }
  .font-size-xs-16 {
    font-size: 16px !important; }
  .font-size-xs-17 {
    font-size: 17px !important; }
  .font-size-xs-18 {
    font-size: 18px !important; }
  .font-size-xs-19 {
    font-size: 19px !important; }
  .font-size-xs-20 {
    font-size: 20px !important; }
  .font-size-xs-21 {
    font-size: 21px !important; }
  .font-size-xs-22 {
    font-size: 22px !important; }
  .font-size-xs-23 {
    font-size: 23px !important; }
  .font-size-xs-24 {
    font-size: 24px !important; }
  .font-size-xs-25 {
    font-size: 25px !important; }
  .font-size-xs-26 {
    font-size: 26px !important; }
  .font-size-xs-27 {
    font-size: 27px !important; }
  .font-size-xs-28 {
    font-size: 28px !important; }
  .font-size-xs-29 {
    font-size: 29px !important; }
  .font-size-xs-30 {
    font-size: 30px !important; }
  .font-size-xs-31 {
    font-size: 31px !important; }
  .font-size-xs-32 {
    font-size: 32px !important; }
  .font-size-xs-33 {
    font-size: 33px !important; }
  .font-size-xs-34 {
    font-size: 34px !important; }
  .font-size-xs-35 {
    font-size: 35px !important; }
  .font-size-xs-36 {
    font-size: 36px !important; }
  .font-size-xs-37 {
    font-size: 37px !important; }
  .font-size-xs-38 {
    font-size: 38px !important; }
  .font-size-xs-39 {
    font-size: 39px !important; }
  .font-size-xs-40 {
    font-size: 40px !important; }
  .font-size-xs-41 {
    font-size: 41px !important; }
  .font-size-xs-42 {
    font-size: 42px !important; }
  .font-size-xs-43 {
    font-size: 43px !important; }
  .font-size-xs-44 {
    font-size: 44px !important; }
  .font-size-xs-45 {
    font-size: 45px !important; }
  .font-size-xs-46 {
    font-size: 46px !important; }
  .font-size-xs-47 {
    font-size: 47px !important; }
  .font-size-xs-48 {
    font-size: 48px !important; }
  .font-size-xs-49 {
    font-size: 49px !important; }
  .font-size-xs-50 {
    font-size: 50px !important; }
  .font-size-xs-51 {
    font-size: 51px !important; }
  .font-size-xs-52 {
    font-size: 52px !important; }
  .font-size-xs-53 {
    font-size: 53px !important; }
  .font-size-xs-54 {
    font-size: 54px !important; }
  .font-size-xs-55 {
    font-size: 55px !important; }
  .font-size-xs-56 {
    font-size: 56px !important; }
  .font-size-xs-57 {
    font-size: 57px !important; }
  .font-size-xs-58 {
    font-size: 58px !important; }
  .font-size-xs-59 {
    font-size: 59px !important; }
  .font-size-xs-60 {
    font-size: 60px !important; }
  .mar-xs-0 {
    margin: 0px !important; }
  .mar-top-xs-0 {
    margin-top: 0px !important; }
  .mar-rig-xs-0 {
    margin-right: 0px !important; }
  .mar-bot-xs-0 {
    margin-bottom: 0px !important; }
  .mar-lef-xs-0 {
    margin-left: 0px !important; }
  .mar-top-neg-xs-0 {
    margin-top: -0px !important; }
  .mar-rig-neg-xs-0 {
    margin-right: -0px !important; }
  .mar-bot-neg-xs-0 {
    margin-bottom: -0px !important; }
  .mar-lef-neg-xs-0 {
    margin-left: -0px !important; }
  .pad-xs-0 {
    padding: 0px !important; }
  .pad-top-xs-0 {
    padding-top: 0px !important; }
  .pad-rig-xs-0 {
    padding-right: 0px !important; }
  .pad-bot-xs-0 {
    padding-bottom: 0px !important; }
  .pad-lef-xs-0 {
    padding-left: 0px !important; }
  .mar-xs-5 {
    margin: 5px !important; }
  .mar-top-xs-5 {
    margin-top: 5px !important; }
  .mar-rig-xs-5 {
    margin-right: 5px !important; }
  .mar-bot-xs-5 {
    margin-bottom: 5px !important; }
  .mar-lef-xs-5 {
    margin-left: 5px !important; }
  .mar-top-neg-xs-5 {
    margin-top: -5px !important; }
  .mar-rig-neg-xs-5 {
    margin-right: -5px !important; }
  .mar-bot-neg-xs-5 {
    margin-bottom: -5px !important; }
  .mar-lef-neg-xs-5 {
    margin-left: -5px !important; }
  .pad-xs-5 {
    padding: 5px !important; }
  .pad-top-xs-5 {
    padding-top: 5px !important; }
  .pad-rig-xs-5 {
    padding-right: 5px !important; }
  .pad-bot-xs-5 {
    padding-bottom: 5px !important; }
  .pad-lef-xs-5 {
    padding-left: 5px !important; }
  .mar-xs-10 {
    margin: 10px !important; }
  .mar-top-xs-10 {
    margin-top: 10px !important; }
  .mar-rig-xs-10 {
    margin-right: 10px !important; }
  .mar-bot-xs-10 {
    margin-bottom: 10px !important; }
  .mar-lef-xs-10 {
    margin-left: 10px !important; }
  .mar-top-neg-xs-10 {
    margin-top: -10px !important; }
  .mar-rig-neg-xs-10 {
    margin-right: -10px !important; }
  .mar-bot-neg-xs-10 {
    margin-bottom: -10px !important; }
  .mar-lef-neg-xs-10 {
    margin-left: -10px !important; }
  .pad-xs-10 {
    padding: 10px !important; }
  .pad-top-xs-10 {
    padding-top: 10px !important; }
  .pad-rig-xs-10 {
    padding-right: 10px !important; }
  .pad-bot-xs-10 {
    padding-bottom: 10px !important; }
  .pad-lef-xs-10 {
    padding-left: 10px !important; }
  .mar-xs-15 {
    margin: 15px !important; }
  .mar-top-xs-15 {
    margin-top: 15px !important; }
  .mar-rig-xs-15 {
    margin-right: 15px !important; }
  .mar-bot-xs-15 {
    margin-bottom: 15px !important; }
  .mar-lef-xs-15 {
    margin-left: 15px !important; }
  .mar-top-neg-xs-15 {
    margin-top: -15px !important; }
  .mar-rig-neg-xs-15 {
    margin-right: -15px !important; }
  .mar-bot-neg-xs-15 {
    margin-bottom: -15px !important; }
  .mar-lef-neg-xs-15 {
    margin-left: -15px !important; }
  .pad-xs-15 {
    padding: 15px !important; }
  .pad-top-xs-15 {
    padding-top: 15px !important; }
  .pad-rig-xs-15 {
    padding-right: 15px !important; }
  .pad-bot-xs-15 {
    padding-bottom: 15px !important; }
  .pad-lef-xs-15 {
    padding-left: 15px !important; }
  .mar-xs-20 {
    margin: 20px !important; }
  .mar-top-xs-20 {
    margin-top: 20px !important; }
  .mar-rig-xs-20 {
    margin-right: 20px !important; }
  .mar-bot-xs-20 {
    margin-bottom: 20px !important; }
  .mar-lef-xs-20 {
    margin-left: 20px !important; }
  .mar-top-neg-xs-20 {
    margin-top: -20px !important; }
  .mar-rig-neg-xs-20 {
    margin-right: -20px !important; }
  .mar-bot-neg-xs-20 {
    margin-bottom: -20px !important; }
  .mar-lef-neg-xs-20 {
    margin-left: -20px !important; }
  .pad-xs-20 {
    padding: 20px !important; }
  .pad-top-xs-20 {
    padding-top: 20px !important; }
  .pad-rig-xs-20 {
    padding-right: 20px !important; }
  .pad-bot-xs-20 {
    padding-bottom: 20px !important; }
  .pad-lef-xs-20 {
    padding-left: 20px !important; }
  .mar-xs-25 {
    margin: 25px !important; }
  .mar-top-xs-25 {
    margin-top: 25px !important; }
  .mar-rig-xs-25 {
    margin-right: 25px !important; }
  .mar-bot-xs-25 {
    margin-bottom: 25px !important; }
  .mar-lef-xs-25 {
    margin-left: 25px !important; }
  .mar-top-neg-xs-25 {
    margin-top: -25px !important; }
  .mar-rig-neg-xs-25 {
    margin-right: -25px !important; }
  .mar-bot-neg-xs-25 {
    margin-bottom: -25px !important; }
  .mar-lef-neg-xs-25 {
    margin-left: -25px !important; }
  .pad-xs-25 {
    padding: 25px !important; }
  .pad-top-xs-25 {
    padding-top: 25px !important; }
  .pad-rig-xs-25 {
    padding-right: 25px !important; }
  .pad-bot-xs-25 {
    padding-bottom: 25px !important; }
  .pad-lef-xs-25 {
    padding-left: 25px !important; }
  .mar-xs-30 {
    margin: 30px !important; }
  .mar-top-xs-30 {
    margin-top: 30px !important; }
  .mar-rig-xs-30 {
    margin-right: 30px !important; }
  .mar-bot-xs-30 {
    margin-bottom: 30px !important; }
  .mar-lef-xs-30 {
    margin-left: 30px !important; }
  .mar-top-neg-xs-30 {
    margin-top: -30px !important; }
  .mar-rig-neg-xs-30 {
    margin-right: -30px !important; }
  .mar-bot-neg-xs-30 {
    margin-bottom: -30px !important; }
  .mar-lef-neg-xs-30 {
    margin-left: -30px !important; }
  .pad-xs-30 {
    padding: 30px !important; }
  .pad-top-xs-30 {
    padding-top: 30px !important; }
  .pad-rig-xs-30 {
    padding-right: 30px !important; }
  .pad-bot-xs-30 {
    padding-bottom: 30px !important; }
  .pad-lef-xs-30 {
    padding-left: 30px !important; }
  .mar-xs-35 {
    margin: 35px !important; }
  .mar-top-xs-35 {
    margin-top: 35px !important; }
  .mar-rig-xs-35 {
    margin-right: 35px !important; }
  .mar-bot-xs-35 {
    margin-bottom: 35px !important; }
  .mar-lef-xs-35 {
    margin-left: 35px !important; }
  .mar-top-neg-xs-35 {
    margin-top: -35px !important; }
  .mar-rig-neg-xs-35 {
    margin-right: -35px !important; }
  .mar-bot-neg-xs-35 {
    margin-bottom: -35px !important; }
  .mar-lef-neg-xs-35 {
    margin-left: -35px !important; }
  .pad-xs-35 {
    padding: 35px !important; }
  .pad-top-xs-35 {
    padding-top: 35px !important; }
  .pad-rig-xs-35 {
    padding-right: 35px !important; }
  .pad-bot-xs-35 {
    padding-bottom: 35px !important; }
  .pad-lef-xs-35 {
    padding-left: 35px !important; }
  .mar-xs-40 {
    margin: 40px !important; }
  .mar-top-xs-40 {
    margin-top: 40px !important; }
  .mar-rig-xs-40 {
    margin-right: 40px !important; }
  .mar-bot-xs-40 {
    margin-bottom: 40px !important; }
  .mar-lef-xs-40 {
    margin-left: 40px !important; }
  .mar-top-neg-xs-40 {
    margin-top: -40px !important; }
  .mar-rig-neg-xs-40 {
    margin-right: -40px !important; }
  .mar-bot-neg-xs-40 {
    margin-bottom: -40px !important; }
  .mar-lef-neg-xs-40 {
    margin-left: -40px !important; }
  .pad-xs-40 {
    padding: 40px !important; }
  .pad-top-xs-40 {
    padding-top: 40px !important; }
  .pad-rig-xs-40 {
    padding-right: 40px !important; }
  .pad-bot-xs-40 {
    padding-bottom: 40px !important; }
  .pad-lef-xs-40 {
    padding-left: 40px !important; }
  .mar-xs-45 {
    margin: 45px !important; }
  .mar-top-xs-45 {
    margin-top: 45px !important; }
  .mar-rig-xs-45 {
    margin-right: 45px !important; }
  .mar-bot-xs-45 {
    margin-bottom: 45px !important; }
  .mar-lef-xs-45 {
    margin-left: 45px !important; }
  .mar-top-neg-xs-45 {
    margin-top: -45px !important; }
  .mar-rig-neg-xs-45 {
    margin-right: -45px !important; }
  .mar-bot-neg-xs-45 {
    margin-bottom: -45px !important; }
  .mar-lef-neg-xs-45 {
    margin-left: -45px !important; }
  .pad-xs-45 {
    padding: 45px !important; }
  .pad-top-xs-45 {
    padding-top: 45px !important; }
  .pad-rig-xs-45 {
    padding-right: 45px !important; }
  .pad-bot-xs-45 {
    padding-bottom: 45px !important; }
  .pad-lef-xs-45 {
    padding-left: 45px !important; }
  .mar-xs-50 {
    margin: 50px !important; }
  .mar-top-xs-50 {
    margin-top: 50px !important; }
  .mar-rig-xs-50 {
    margin-right: 50px !important; }
  .mar-bot-xs-50 {
    margin-bottom: 50px !important; }
  .mar-lef-xs-50 {
    margin-left: 50px !important; }
  .mar-top-neg-xs-50 {
    margin-top: -50px !important; }
  .mar-rig-neg-xs-50 {
    margin-right: -50px !important; }
  .mar-bot-neg-xs-50 {
    margin-bottom: -50px !important; }
  .mar-lef-neg-xs-50 {
    margin-left: -50px !important; }
  .pad-xs-50 {
    padding: 50px !important; }
  .pad-top-xs-50 {
    padding-top: 50px !important; }
  .pad-rig-xs-50 {
    padding-right: 50px !important; }
  .pad-bot-xs-50 {
    padding-bottom: 50px !important; }
  .pad-lef-xs-50 {
    padding-left: 50px !important; }
  .mar-xs-55 {
    margin: 55px !important; }
  .mar-top-xs-55 {
    margin-top: 55px !important; }
  .mar-rig-xs-55 {
    margin-right: 55px !important; }
  .mar-bot-xs-55 {
    margin-bottom: 55px !important; }
  .mar-lef-xs-55 {
    margin-left: 55px !important; }
  .mar-top-neg-xs-55 {
    margin-top: -55px !important; }
  .mar-rig-neg-xs-55 {
    margin-right: -55px !important; }
  .mar-bot-neg-xs-55 {
    margin-bottom: -55px !important; }
  .mar-lef-neg-xs-55 {
    margin-left: -55px !important; }
  .pad-xs-55 {
    padding: 55px !important; }
  .pad-top-xs-55 {
    padding-top: 55px !important; }
  .pad-rig-xs-55 {
    padding-right: 55px !important; }
  .pad-bot-xs-55 {
    padding-bottom: 55px !important; }
  .pad-lef-xs-55 {
    padding-left: 55px !important; }
  .mar-xs-60 {
    margin: 60px !important; }
  .mar-top-xs-60 {
    margin-top: 60px !important; }
  .mar-rig-xs-60 {
    margin-right: 60px !important; }
  .mar-bot-xs-60 {
    margin-bottom: 60px !important; }
  .mar-lef-xs-60 {
    margin-left: 60px !important; }
  .mar-top-neg-xs-60 {
    margin-top: -60px !important; }
  .mar-rig-neg-xs-60 {
    margin-right: -60px !important; }
  .mar-bot-neg-xs-60 {
    margin-bottom: -60px !important; }
  .mar-lef-neg-xs-60 {
    margin-left: -60px !important; }
  .pad-xs-60 {
    padding: 60px !important; }
  .pad-top-xs-60 {
    padding-top: 60px !important; }
  .pad-rig-xs-60 {
    padding-right: 60px !important; }
  .pad-bot-xs-60 {
    padding-bottom: 60px !important; }
  .pad-lef-xs-60 {
    padding-left: 60px !important; }
  .mar-xs-65 {
    margin: 65px !important; }
  .mar-top-xs-65 {
    margin-top: 65px !important; }
  .mar-rig-xs-65 {
    margin-right: 65px !important; }
  .mar-bot-xs-65 {
    margin-bottom: 65px !important; }
  .mar-lef-xs-65 {
    margin-left: 65px !important; }
  .mar-top-neg-xs-65 {
    margin-top: -65px !important; }
  .mar-rig-neg-xs-65 {
    margin-right: -65px !important; }
  .mar-bot-neg-xs-65 {
    margin-bottom: -65px !important; }
  .mar-lef-neg-xs-65 {
    margin-left: -65px !important; }
  .pad-xs-65 {
    padding: 65px !important; }
  .pad-top-xs-65 {
    padding-top: 65px !important; }
  .pad-rig-xs-65 {
    padding-right: 65px !important; }
  .pad-bot-xs-65 {
    padding-bottom: 65px !important; }
  .pad-lef-xs-65 {
    padding-left: 65px !important; }
  .mar-xs-70 {
    margin: 70px !important; }
  .mar-top-xs-70 {
    margin-top: 70px !important; }
  .mar-rig-xs-70 {
    margin-right: 70px !important; }
  .mar-bot-xs-70 {
    margin-bottom: 70px !important; }
  .mar-lef-xs-70 {
    margin-left: 70px !important; }
  .mar-top-neg-xs-70 {
    margin-top: -70px !important; }
  .mar-rig-neg-xs-70 {
    margin-right: -70px !important; }
  .mar-bot-neg-xs-70 {
    margin-bottom: -70px !important; }
  .mar-lef-neg-xs-70 {
    margin-left: -70px !important; }
  .pad-xs-70 {
    padding: 70px !important; }
  .pad-top-xs-70 {
    padding-top: 70px !important; }
  .pad-rig-xs-70 {
    padding-right: 70px !important; }
  .pad-bot-xs-70 {
    padding-bottom: 70px !important; }
  .pad-lef-xs-70 {
    padding-left: 70px !important; }
  .mar-xs-75 {
    margin: 75px !important; }
  .mar-top-xs-75 {
    margin-top: 75px !important; }
  .mar-rig-xs-75 {
    margin-right: 75px !important; }
  .mar-bot-xs-75 {
    margin-bottom: 75px !important; }
  .mar-lef-xs-75 {
    margin-left: 75px !important; }
  .mar-top-neg-xs-75 {
    margin-top: -75px !important; }
  .mar-rig-neg-xs-75 {
    margin-right: -75px !important; }
  .mar-bot-neg-xs-75 {
    margin-bottom: -75px !important; }
  .mar-lef-neg-xs-75 {
    margin-left: -75px !important; }
  .pad-xs-75 {
    padding: 75px !important; }
  .pad-top-xs-75 {
    padding-top: 75px !important; }
  .pad-rig-xs-75 {
    padding-right: 75px !important; }
  .pad-bot-xs-75 {
    padding-bottom: 75px !important; }
  .pad-lef-xs-75 {
    padding-left: 75px !important; }
  .mar-xs-80 {
    margin: 80px !important; }
  .mar-top-xs-80 {
    margin-top: 80px !important; }
  .mar-rig-xs-80 {
    margin-right: 80px !important; }
  .mar-bot-xs-80 {
    margin-bottom: 80px !important; }
  .mar-lef-xs-80 {
    margin-left: 80px !important; }
  .mar-top-neg-xs-80 {
    margin-top: -80px !important; }
  .mar-rig-neg-xs-80 {
    margin-right: -80px !important; }
  .mar-bot-neg-xs-80 {
    margin-bottom: -80px !important; }
  .mar-lef-neg-xs-80 {
    margin-left: -80px !important; }
  .pad-xs-80 {
    padding: 80px !important; }
  .pad-top-xs-80 {
    padding-top: 80px !important; }
  .pad-rig-xs-80 {
    padding-right: 80px !important; }
  .pad-bot-xs-80 {
    padding-bottom: 80px !important; }
  .pad-lef-xs-80 {
    padding-left: 80px !important; }
  .mar-xs-85 {
    margin: 85px !important; }
  .mar-top-xs-85 {
    margin-top: 85px !important; }
  .mar-rig-xs-85 {
    margin-right: 85px !important; }
  .mar-bot-xs-85 {
    margin-bottom: 85px !important; }
  .mar-lef-xs-85 {
    margin-left: 85px !important; }
  .mar-top-neg-xs-85 {
    margin-top: -85px !important; }
  .mar-rig-neg-xs-85 {
    margin-right: -85px !important; }
  .mar-bot-neg-xs-85 {
    margin-bottom: -85px !important; }
  .mar-lef-neg-xs-85 {
    margin-left: -85px !important; }
  .pad-xs-85 {
    padding: 85px !important; }
  .pad-top-xs-85 {
    padding-top: 85px !important; }
  .pad-rig-xs-85 {
    padding-right: 85px !important; }
  .pad-bot-xs-85 {
    padding-bottom: 85px !important; }
  .pad-lef-xs-85 {
    padding-left: 85px !important; }
  .mar-xs-90 {
    margin: 90px !important; }
  .mar-top-xs-90 {
    margin-top: 90px !important; }
  .mar-rig-xs-90 {
    margin-right: 90px !important; }
  .mar-bot-xs-90 {
    margin-bottom: 90px !important; }
  .mar-lef-xs-90 {
    margin-left: 90px !important; }
  .mar-top-neg-xs-90 {
    margin-top: -90px !important; }
  .mar-rig-neg-xs-90 {
    margin-right: -90px !important; }
  .mar-bot-neg-xs-90 {
    margin-bottom: -90px !important; }
  .mar-lef-neg-xs-90 {
    margin-left: -90px !important; }
  .pad-xs-90 {
    padding: 90px !important; }
  .pad-top-xs-90 {
    padding-top: 90px !important; }
  .pad-rig-xs-90 {
    padding-right: 90px !important; }
  .pad-bot-xs-90 {
    padding-bottom: 90px !important; }
  .pad-lef-xs-90 {
    padding-left: 90px !important; }
  .mar-xs-95 {
    margin: 95px !important; }
  .mar-top-xs-95 {
    margin-top: 95px !important; }
  .mar-rig-xs-95 {
    margin-right: 95px !important; }
  .mar-bot-xs-95 {
    margin-bottom: 95px !important; }
  .mar-lef-xs-95 {
    margin-left: 95px !important; }
  .mar-top-neg-xs-95 {
    margin-top: -95px !important; }
  .mar-rig-neg-xs-95 {
    margin-right: -95px !important; }
  .mar-bot-neg-xs-95 {
    margin-bottom: -95px !important; }
  .mar-lef-neg-xs-95 {
    margin-left: -95px !important; }
  .pad-xs-95 {
    padding: 95px !important; }
  .pad-top-xs-95 {
    padding-top: 95px !important; }
  .pad-rig-xs-95 {
    padding-right: 95px !important; }
  .pad-bot-xs-95 {
    padding-bottom: 95px !important; }
  .pad-lef-xs-95 {
    padding-left: 95px !important; }
  .mar-xs-100 {
    margin: 100px !important; }
  .mar-top-xs-100 {
    margin-top: 100px !important; }
  .mar-rig-xs-100 {
    margin-right: 100px !important; }
  .mar-bot-xs-100 {
    margin-bottom: 100px !important; }
  .mar-lef-xs-100 {
    margin-left: 100px !important; }
  .mar-top-neg-xs-100 {
    margin-top: -100px !important; }
  .mar-rig-neg-xs-100 {
    margin-right: -100px !important; }
  .mar-bot-neg-xs-100 {
    margin-bottom: -100px !important; }
  .mar-lef-neg-xs-100 {
    margin-left: -100px !important; }
  .pad-xs-100 {
    padding: 100px !important; }
  .pad-top-xs-100 {
    padding-top: 100px !important; }
  .pad-rig-xs-100 {
    padding-right: 100px !important; }
  .pad-bot-xs-100 {
    padding-bottom: 100px !important; }
  .pad-lef-xs-100 {
    padding-left: 100px !important; }
  .line-height-xs-0 {
    line-height: 0px !important; }
  .line-height-xs-1 {
    line-height: 1px !important; }
  .line-height-xs-2 {
    line-height: 2px !important; }
  .line-height-xs-3 {
    line-height: 3px !important; }
  .line-height-xs-4 {
    line-height: 4px !important; }
  .line-height-xs-5 {
    line-height: 5px !important; }
  .line-height-xs-6 {
    line-height: 6px !important; }
  .line-height-xs-7 {
    line-height: 7px !important; }
  .line-height-xs-8 {
    line-height: 8px !important; }
  .line-height-xs-9 {
    line-height: 9px !important; }
  .line-height-xs-10 {
    line-height: 10px !important; }
  .line-height-xs-11 {
    line-height: 11px !important; }
  .line-height-xs-12 {
    line-height: 12px !important; }
  .line-height-xs-13 {
    line-height: 13px !important; }
  .line-height-xs-14 {
    line-height: 14px !important; }
  .line-height-xs-15 {
    line-height: 15px !important; }
  .line-height-xs-16 {
    line-height: 16px !important; }
  .line-height-xs-17 {
    line-height: 17px !important; }
  .line-height-xs-18 {
    line-height: 18px !important; }
  .line-height-xs-19 {
    line-height: 19px !important; }
  .line-height-xs-20 {
    line-height: 20px !important; }
  .line-height-xs-21 {
    line-height: 21px !important; }
  .line-height-xs-22 {
    line-height: 22px !important; }
  .line-height-xs-23 {
    line-height: 23px !important; }
  .line-height-xs-24 {
    line-height: 24px !important; }
  .line-height-xs-25 {
    line-height: 25px !important; }
  .line-height-xs-26 {
    line-height: 26px !important; }
  .line-height-xs-27 {
    line-height: 27px !important; }
  .line-height-xs-28 {
    line-height: 28px !important; }
  .line-height-xs-29 {
    line-height: 29px !important; }
  .line-height-xs-30 {
    line-height: 30px !important; }
  .line-height-xs-31 {
    line-height: 31px !important; }
  .line-height-xs-32 {
    line-height: 32px !important; }
  .line-height-xs-33 {
    line-height: 33px !important; }
  .line-height-xs-34 {
    line-height: 34px !important; }
  .line-height-xs-35 {
    line-height: 35px !important; }
  .line-height-xs-36 {
    line-height: 36px !important; }
  .line-height-xs-37 {
    line-height: 37px !important; }
  .line-height-xs-38 {
    line-height: 38px !important; }
  .line-height-xs-39 {
    line-height: 39px !important; }
  .line-height-xs-40 {
    line-height: 40px !important; }
  .line-height-xs-41 {
    line-height: 41px !important; }
  .line-height-xs-42 {
    line-height: 42px !important; }
  .line-height-xs-43 {
    line-height: 43px !important; }
  .line-height-xs-44 {
    line-height: 44px !important; }
  .line-height-xs-45 {
    line-height: 45px !important; }
  .line-height-xs-46 {
    line-height: 46px !important; }
  .line-height-xs-47 {
    line-height: 47px !important; }
  .line-height-xs-48 {
    line-height: 48px !important; }
  .line-height-xs-49 {
    line-height: 49px !important; }
  .line-height-xs-50 {
    line-height: 50px !important; }
  .line-height-xs-51 {
    line-height: 51px !important; }
  .line-height-xs-52 {
    line-height: 52px !important; }
  .line-height-xs-53 {
    line-height: 53px !important; }
  .line-height-xs-54 {
    line-height: 54px !important; }
  .line-height-xs-55 {
    line-height: 55px !important; }
  .line-height-xs-56 {
    line-height: 56px !important; }
  .line-height-xs-57 {
    line-height: 57px !important; }
  .line-height-xs-58 {
    line-height: 58px !important; }
  .line-height-xs-59 {
    line-height: 59px !important; }
  .line-height-xs-60 {
    line-height: 60px !important; }
  .dis-block-xs {
    display: block !important; }
  .dis-none-xs {
    display: none !important; }
  .dis-inline-xs {
    display: inline !important; }
  .dis-inline-block-xs {
    display: inline-block !important; }
  .text-cen-xs {
    text-align: center !important; }
  .text-lef-xs {
    text-align: left !important; }
  .text-rig-xs {
    text-align: right !important; }
  .text-jus-xs {
    text-align: justify !important; }
  .pos-re-xs {
    position: relative !important; }
  .pos-ab-xs {
    position: absolute !important; }
  .pos-st-xs {
    position: static !important; } }

@media (min-width: 768px) {
  .font-size-sm-8 {
    font-size: 8px !important; }
  .font-size-sm-9 {
    font-size: 9px !important; }
  .font-size-sm-10 {
    font-size: 10px !important; }
  .font-size-sm-11 {
    font-size: 11px !important; }
  .font-size-sm-12 {
    font-size: 12px !important; }
  .font-size-sm-13 {
    font-size: 13px !important; }
  .font-size-sm-14 {
    font-size: 14px !important; }
  .font-size-sm-15 {
    font-size: 15px !important; }
  .font-size-sm-16 {
    font-size: 16px !important; }
  .font-size-sm-17 {
    font-size: 17px !important; }
  .font-size-sm-18 {
    font-size: 18px !important; }
  .font-size-sm-19 {
    font-size: 19px !important; }
  .font-size-sm-20 {
    font-size: 20px !important; }
  .font-size-sm-21 {
    font-size: 21px !important; }
  .font-size-sm-22 {
    font-size: 22px !important; }
  .font-size-sm-23 {
    font-size: 23px !important; }
  .font-size-sm-24 {
    font-size: 24px !important; }
  .font-size-sm-25 {
    font-size: 25px !important; }
  .font-size-sm-26 {
    font-size: 26px !important; }
  .font-size-sm-27 {
    font-size: 27px !important; }
  .font-size-sm-28 {
    font-size: 28px !important; }
  .font-size-sm-29 {
    font-size: 29px !important; }
  .font-size-sm-30 {
    font-size: 30px !important; }
  .font-size-sm-31 {
    font-size: 31px !important; }
  .font-size-sm-32 {
    font-size: 32px !important; }
  .font-size-sm-33 {
    font-size: 33px !important; }
  .font-size-sm-34 {
    font-size: 34px !important; }
  .font-size-sm-35 {
    font-size: 35px !important; }
  .font-size-sm-36 {
    font-size: 36px !important; }
  .font-size-sm-37 {
    font-size: 37px !important; }
  .font-size-sm-38 {
    font-size: 38px !important; }
  .font-size-sm-39 {
    font-size: 39px !important; }
  .font-size-sm-40 {
    font-size: 40px !important; }
  .font-size-sm-41 {
    font-size: 41px !important; }
  .font-size-sm-42 {
    font-size: 42px !important; }
  .font-size-sm-43 {
    font-size: 43px !important; }
  .font-size-sm-44 {
    font-size: 44px !important; }
  .font-size-sm-45 {
    font-size: 45px !important; }
  .font-size-sm-46 {
    font-size: 46px !important; }
  .font-size-sm-47 {
    font-size: 47px !important; }
  .font-size-sm-48 {
    font-size: 48px !important; }
  .font-size-sm-49 {
    font-size: 49px !important; }
  .font-size-sm-50 {
    font-size: 50px !important; }
  .font-size-sm-51 {
    font-size: 51px !important; }
  .font-size-sm-52 {
    font-size: 52px !important; }
  .font-size-sm-53 {
    font-size: 53px !important; }
  .font-size-sm-54 {
    font-size: 54px !important; }
  .font-size-sm-55 {
    font-size: 55px !important; }
  .font-size-sm-56 {
    font-size: 56px !important; }
  .font-size-sm-57 {
    font-size: 57px !important; }
  .font-size-sm-58 {
    font-size: 58px !important; }
  .font-size-sm-59 {
    font-size: 59px !important; }
  .font-size-sm-60 {
    font-size: 60px !important; }
  .mar-sm-0 {
    margin: 0px !important; }
  .mar-top-sm-0 {
    margin-top: 0px !important; }
  .mar-rig-sm-0 {
    margin-right: 0px !important; }
  .mar-bot-sm-0 {
    margin-bottom: 0px !important; }
  .mar-lef-sm-0 {
    margin-left: 0px !important; }
  .mar-top-neg-sm-0 {
    margin-top: -0px !important; }
  .mar-rig-neg-sm-0 {
    margin-right: -0px !important; }
  .mar-bot-neg-sm-0 {
    margin-bottom: -0px !important; }
  .mar-lef-neg-sm-0 {
    margin-left: -0px !important; }
  .pad-sm-0 {
    padding: 0px !important; }
  .pad-top-sm-0 {
    padding-top: 0px !important; }
  .pad-rig-sm-0 {
    padding-right: 0px !important; }
  .pad-bot-sm-0 {
    padding-bottom: 0px !important; }
  .pad-lef-sm-0 {
    padding-left: 0px !important; }
  .mar-sm-5 {
    margin: 5px !important; }
  .mar-top-sm-5 {
    margin-top: 5px !important; }
  .mar-rig-sm-5 {
    margin-right: 5px !important; }
  .mar-bot-sm-5 {
    margin-bottom: 5px !important; }
  .mar-lef-sm-5 {
    margin-left: 5px !important; }
  .mar-top-neg-sm-5 {
    margin-top: -5px !important; }
  .mar-rig-neg-sm-5 {
    margin-right: -5px !important; }
  .mar-bot-neg-sm-5 {
    margin-bottom: -5px !important; }
  .mar-lef-neg-sm-5 {
    margin-left: -5px !important; }
  .pad-sm-5 {
    padding: 5px !important; }
  .pad-top-sm-5 {
    padding-top: 5px !important; }
  .pad-rig-sm-5 {
    padding-right: 5px !important; }
  .pad-bot-sm-5 {
    padding-bottom: 5px !important; }
  .pad-lef-sm-5 {
    padding-left: 5px !important; }
  .mar-sm-10 {
    margin: 10px !important; }
  .mar-top-sm-10 {
    margin-top: 10px !important; }
  .mar-rig-sm-10 {
    margin-right: 10px !important; }
  .mar-bot-sm-10 {
    margin-bottom: 10px !important; }
  .mar-lef-sm-10 {
    margin-left: 10px !important; }
  .mar-top-neg-sm-10 {
    margin-top: -10px !important; }
  .mar-rig-neg-sm-10 {
    margin-right: -10px !important; }
  .mar-bot-neg-sm-10 {
    margin-bottom: -10px !important; }
  .mar-lef-neg-sm-10 {
    margin-left: -10px !important; }
  .pad-sm-10 {
    padding: 10px !important; }
  .pad-top-sm-10 {
    padding-top: 10px !important; }
  .pad-rig-sm-10 {
    padding-right: 10px !important; }
  .pad-bot-sm-10 {
    padding-bottom: 10px !important; }
  .pad-lef-sm-10 {
    padding-left: 10px !important; }
  .mar-sm-15 {
    margin: 15px !important; }
  .mar-top-sm-15 {
    margin-top: 15px !important; }
  .mar-rig-sm-15 {
    margin-right: 15px !important; }
  .mar-bot-sm-15 {
    margin-bottom: 15px !important; }
  .mar-lef-sm-15 {
    margin-left: 15px !important; }
  .mar-top-neg-sm-15 {
    margin-top: -15px !important; }
  .mar-rig-neg-sm-15 {
    margin-right: -15px !important; }
  .mar-bot-neg-sm-15 {
    margin-bottom: -15px !important; }
  .mar-lef-neg-sm-15 {
    margin-left: -15px !important; }
  .pad-sm-15 {
    padding: 15px !important; }
  .pad-top-sm-15 {
    padding-top: 15px !important; }
  .pad-rig-sm-15 {
    padding-right: 15px !important; }
  .pad-bot-sm-15 {
    padding-bottom: 15px !important; }
  .pad-lef-sm-15 {
    padding-left: 15px !important; }
  .mar-sm-20 {
    margin: 20px !important; }
  .mar-top-sm-20 {
    margin-top: 20px !important; }
  .mar-rig-sm-20 {
    margin-right: 20px !important; }
  .mar-bot-sm-20 {
    margin-bottom: 20px !important; }
  .mar-lef-sm-20 {
    margin-left: 20px !important; }
  .mar-top-neg-sm-20 {
    margin-top: -20px !important; }
  .mar-rig-neg-sm-20 {
    margin-right: -20px !important; }
  .mar-bot-neg-sm-20 {
    margin-bottom: -20px !important; }
  .mar-lef-neg-sm-20 {
    margin-left: -20px !important; }
  .pad-sm-20 {
    padding: 20px !important; }
  .pad-top-sm-20 {
    padding-top: 20px !important; }
  .pad-rig-sm-20 {
    padding-right: 20px !important; }
  .pad-bot-sm-20 {
    padding-bottom: 20px !important; }
  .pad-lef-sm-20 {
    padding-left: 20px !important; }
  .mar-sm-25 {
    margin: 25px !important; }
  .mar-top-sm-25 {
    margin-top: 25px !important; }
  .mar-rig-sm-25 {
    margin-right: 25px !important; }
  .mar-bot-sm-25 {
    margin-bottom: 25px !important; }
  .mar-lef-sm-25 {
    margin-left: 25px !important; }
  .mar-top-neg-sm-25 {
    margin-top: -25px !important; }
  .mar-rig-neg-sm-25 {
    margin-right: -25px !important; }
  .mar-bot-neg-sm-25 {
    margin-bottom: -25px !important; }
  .mar-lef-neg-sm-25 {
    margin-left: -25px !important; }
  .pad-sm-25 {
    padding: 25px !important; }
  .pad-top-sm-25 {
    padding-top: 25px !important; }
  .pad-rig-sm-25 {
    padding-right: 25px !important; }
  .pad-bot-sm-25 {
    padding-bottom: 25px !important; }
  .pad-lef-sm-25 {
    padding-left: 25px !important; }
  .mar-sm-30 {
    margin: 30px !important; }
  .mar-top-sm-30 {
    margin-top: 30px !important; }
  .mar-rig-sm-30 {
    margin-right: 30px !important; }
  .mar-bot-sm-30 {
    margin-bottom: 30px !important; }
  .mar-lef-sm-30 {
    margin-left: 30px !important; }
  .mar-top-neg-sm-30 {
    margin-top: -30px !important; }
  .mar-rig-neg-sm-30 {
    margin-right: -30px !important; }
  .mar-bot-neg-sm-30 {
    margin-bottom: -30px !important; }
  .mar-lef-neg-sm-30 {
    margin-left: -30px !important; }
  .pad-sm-30 {
    padding: 30px !important; }
  .pad-top-sm-30 {
    padding-top: 30px !important; }
  .pad-rig-sm-30 {
    padding-right: 30px !important; }
  .pad-bot-sm-30 {
    padding-bottom: 30px !important; }
  .pad-lef-sm-30 {
    padding-left: 30px !important; }
  .mar-sm-35 {
    margin: 35px !important; }
  .mar-top-sm-35 {
    margin-top: 35px !important; }
  .mar-rig-sm-35 {
    margin-right: 35px !important; }
  .mar-bot-sm-35 {
    margin-bottom: 35px !important; }
  .mar-lef-sm-35 {
    margin-left: 35px !important; }
  .mar-top-neg-sm-35 {
    margin-top: -35px !important; }
  .mar-rig-neg-sm-35 {
    margin-right: -35px !important; }
  .mar-bot-neg-sm-35 {
    margin-bottom: -35px !important; }
  .mar-lef-neg-sm-35 {
    margin-left: -35px !important; }
  .pad-sm-35 {
    padding: 35px !important; }
  .pad-top-sm-35 {
    padding-top: 35px !important; }
  .pad-rig-sm-35 {
    padding-right: 35px !important; }
  .pad-bot-sm-35 {
    padding-bottom: 35px !important; }
  .pad-lef-sm-35 {
    padding-left: 35px !important; }
  .mar-sm-40 {
    margin: 40px !important; }
  .mar-top-sm-40 {
    margin-top: 40px !important; }
  .mar-rig-sm-40 {
    margin-right: 40px !important; }
  .mar-bot-sm-40 {
    margin-bottom: 40px !important; }
  .mar-lef-sm-40 {
    margin-left: 40px !important; }
  .mar-top-neg-sm-40 {
    margin-top: -40px !important; }
  .mar-rig-neg-sm-40 {
    margin-right: -40px !important; }
  .mar-bot-neg-sm-40 {
    margin-bottom: -40px !important; }
  .mar-lef-neg-sm-40 {
    margin-left: -40px !important; }
  .pad-sm-40 {
    padding: 40px !important; }
  .pad-top-sm-40 {
    padding-top: 40px !important; }
  .pad-rig-sm-40 {
    padding-right: 40px !important; }
  .pad-bot-sm-40 {
    padding-bottom: 40px !important; }
  .pad-lef-sm-40 {
    padding-left: 40px !important; }
  .mar-sm-45 {
    margin: 45px !important; }
  .mar-top-sm-45 {
    margin-top: 45px !important; }
  .mar-rig-sm-45 {
    margin-right: 45px !important; }
  .mar-bot-sm-45 {
    margin-bottom: 45px !important; }
  .mar-lef-sm-45 {
    margin-left: 45px !important; }
  .mar-top-neg-sm-45 {
    margin-top: -45px !important; }
  .mar-rig-neg-sm-45 {
    margin-right: -45px !important; }
  .mar-bot-neg-sm-45 {
    margin-bottom: -45px !important; }
  .mar-lef-neg-sm-45 {
    margin-left: -45px !important; }
  .pad-sm-45 {
    padding: 45px !important; }
  .pad-top-sm-45 {
    padding-top: 45px !important; }
  .pad-rig-sm-45 {
    padding-right: 45px !important; }
  .pad-bot-sm-45 {
    padding-bottom: 45px !important; }
  .pad-lef-sm-45 {
    padding-left: 45px !important; }
  .mar-sm-50 {
    margin: 50px !important; }
  .mar-top-sm-50 {
    margin-top: 50px !important; }
  .mar-rig-sm-50 {
    margin-right: 50px !important; }
  .mar-bot-sm-50 {
    margin-bottom: 50px !important; }
  .mar-lef-sm-50 {
    margin-left: 50px !important; }
  .mar-top-neg-sm-50 {
    margin-top: -50px !important; }
  .mar-rig-neg-sm-50 {
    margin-right: -50px !important; }
  .mar-bot-neg-sm-50 {
    margin-bottom: -50px !important; }
  .mar-lef-neg-sm-50 {
    margin-left: -50px !important; }
  .pad-sm-50 {
    padding: 50px !important; }
  .pad-top-sm-50 {
    padding-top: 50px !important; }
  .pad-rig-sm-50 {
    padding-right: 50px !important; }
  .pad-bot-sm-50 {
    padding-bottom: 50px !important; }
  .pad-lef-sm-50 {
    padding-left: 50px !important; }
  .mar-sm-55 {
    margin: 55px !important; }
  .mar-top-sm-55 {
    margin-top: 55px !important; }
  .mar-rig-sm-55 {
    margin-right: 55px !important; }
  .mar-bot-sm-55 {
    margin-bottom: 55px !important; }
  .mar-lef-sm-55 {
    margin-left: 55px !important; }
  .mar-top-neg-sm-55 {
    margin-top: -55px !important; }
  .mar-rig-neg-sm-55 {
    margin-right: -55px !important; }
  .mar-bot-neg-sm-55 {
    margin-bottom: -55px !important; }
  .mar-lef-neg-sm-55 {
    margin-left: -55px !important; }
  .pad-sm-55 {
    padding: 55px !important; }
  .pad-top-sm-55 {
    padding-top: 55px !important; }
  .pad-rig-sm-55 {
    padding-right: 55px !important; }
  .pad-bot-sm-55 {
    padding-bottom: 55px !important; }
  .pad-lef-sm-55 {
    padding-left: 55px !important; }
  .mar-sm-60 {
    margin: 60px !important; }
  .mar-top-sm-60 {
    margin-top: 60px !important; }
  .mar-rig-sm-60 {
    margin-right: 60px !important; }
  .mar-bot-sm-60 {
    margin-bottom: 60px !important; }
  .mar-lef-sm-60 {
    margin-left: 60px !important; }
  .mar-top-neg-sm-60 {
    margin-top: -60px !important; }
  .mar-rig-neg-sm-60 {
    margin-right: -60px !important; }
  .mar-bot-neg-sm-60 {
    margin-bottom: -60px !important; }
  .mar-lef-neg-sm-60 {
    margin-left: -60px !important; }
  .pad-sm-60 {
    padding: 60px !important; }
  .pad-top-sm-60 {
    padding-top: 60px !important; }
  .pad-rig-sm-60 {
    padding-right: 60px !important; }
  .pad-bot-sm-60 {
    padding-bottom: 60px !important; }
  .pad-lef-sm-60 {
    padding-left: 60px !important; }
  .mar-sm-65 {
    margin: 65px !important; }
  .mar-top-sm-65 {
    margin-top: 65px !important; }
  .mar-rig-sm-65 {
    margin-right: 65px !important; }
  .mar-bot-sm-65 {
    margin-bottom: 65px !important; }
  .mar-lef-sm-65 {
    margin-left: 65px !important; }
  .mar-top-neg-sm-65 {
    margin-top: -65px !important; }
  .mar-rig-neg-sm-65 {
    margin-right: -65px !important; }
  .mar-bot-neg-sm-65 {
    margin-bottom: -65px !important; }
  .mar-lef-neg-sm-65 {
    margin-left: -65px !important; }
  .pad-sm-65 {
    padding: 65px !important; }
  .pad-top-sm-65 {
    padding-top: 65px !important; }
  .pad-rig-sm-65 {
    padding-right: 65px !important; }
  .pad-bot-sm-65 {
    padding-bottom: 65px !important; }
  .pad-lef-sm-65 {
    padding-left: 65px !important; }
  .mar-sm-70 {
    margin: 70px !important; }
  .mar-top-sm-70 {
    margin-top: 70px !important; }
  .mar-rig-sm-70 {
    margin-right: 70px !important; }
  .mar-bot-sm-70 {
    margin-bottom: 70px !important; }
  .mar-lef-sm-70 {
    margin-left: 70px !important; }
  .mar-top-neg-sm-70 {
    margin-top: -70px !important; }
  .mar-rig-neg-sm-70 {
    margin-right: -70px !important; }
  .mar-bot-neg-sm-70 {
    margin-bottom: -70px !important; }
  .mar-lef-neg-sm-70 {
    margin-left: -70px !important; }
  .pad-sm-70 {
    padding: 70px !important; }
  .pad-top-sm-70 {
    padding-top: 70px !important; }
  .pad-rig-sm-70 {
    padding-right: 70px !important; }
  .pad-bot-sm-70 {
    padding-bottom: 70px !important; }
  .pad-lef-sm-70 {
    padding-left: 70px !important; }
  .mar-sm-75 {
    margin: 75px !important; }
  .mar-top-sm-75 {
    margin-top: 75px !important; }
  .mar-rig-sm-75 {
    margin-right: 75px !important; }
  .mar-bot-sm-75 {
    margin-bottom: 75px !important; }
  .mar-lef-sm-75 {
    margin-left: 75px !important; }
  .mar-top-neg-sm-75 {
    margin-top: -75px !important; }
  .mar-rig-neg-sm-75 {
    margin-right: -75px !important; }
  .mar-bot-neg-sm-75 {
    margin-bottom: -75px !important; }
  .mar-lef-neg-sm-75 {
    margin-left: -75px !important; }
  .pad-sm-75 {
    padding: 75px !important; }
  .pad-top-sm-75 {
    padding-top: 75px !important; }
  .pad-rig-sm-75 {
    padding-right: 75px !important; }
  .pad-bot-sm-75 {
    padding-bottom: 75px !important; }
  .pad-lef-sm-75 {
    padding-left: 75px !important; }
  .mar-sm-80 {
    margin: 80px !important; }
  .mar-top-sm-80 {
    margin-top: 80px !important; }
  .mar-rig-sm-80 {
    margin-right: 80px !important; }
  .mar-bot-sm-80 {
    margin-bottom: 80px !important; }
  .mar-lef-sm-80 {
    margin-left: 80px !important; }
  .mar-top-neg-sm-80 {
    margin-top: -80px !important; }
  .mar-rig-neg-sm-80 {
    margin-right: -80px !important; }
  .mar-bot-neg-sm-80 {
    margin-bottom: -80px !important; }
  .mar-lef-neg-sm-80 {
    margin-left: -80px !important; }
  .pad-sm-80 {
    padding: 80px !important; }
  .pad-top-sm-80 {
    padding-top: 80px !important; }
  .pad-rig-sm-80 {
    padding-right: 80px !important; }
  .pad-bot-sm-80 {
    padding-bottom: 80px !important; }
  .pad-lef-sm-80 {
    padding-left: 80px !important; }
  .mar-sm-85 {
    margin: 85px !important; }
  .mar-top-sm-85 {
    margin-top: 85px !important; }
  .mar-rig-sm-85 {
    margin-right: 85px !important; }
  .mar-bot-sm-85 {
    margin-bottom: 85px !important; }
  .mar-lef-sm-85 {
    margin-left: 85px !important; }
  .mar-top-neg-sm-85 {
    margin-top: -85px !important; }
  .mar-rig-neg-sm-85 {
    margin-right: -85px !important; }
  .mar-bot-neg-sm-85 {
    margin-bottom: -85px !important; }
  .mar-lef-neg-sm-85 {
    margin-left: -85px !important; }
  .pad-sm-85 {
    padding: 85px !important; }
  .pad-top-sm-85 {
    padding-top: 85px !important; }
  .pad-rig-sm-85 {
    padding-right: 85px !important; }
  .pad-bot-sm-85 {
    padding-bottom: 85px !important; }
  .pad-lef-sm-85 {
    padding-left: 85px !important; }
  .mar-sm-90 {
    margin: 90px !important; }
  .mar-top-sm-90 {
    margin-top: 90px !important; }
  .mar-rig-sm-90 {
    margin-right: 90px !important; }
  .mar-bot-sm-90 {
    margin-bottom: 90px !important; }
  .mar-lef-sm-90 {
    margin-left: 90px !important; }
  .mar-top-neg-sm-90 {
    margin-top: -90px !important; }
  .mar-rig-neg-sm-90 {
    margin-right: -90px !important; }
  .mar-bot-neg-sm-90 {
    margin-bottom: -90px !important; }
  .mar-lef-neg-sm-90 {
    margin-left: -90px !important; }
  .pad-sm-90 {
    padding: 90px !important; }
  .pad-top-sm-90 {
    padding-top: 90px !important; }
  .pad-rig-sm-90 {
    padding-right: 90px !important; }
  .pad-bot-sm-90 {
    padding-bottom: 90px !important; }
  .pad-lef-sm-90 {
    padding-left: 90px !important; }
  .mar-sm-95 {
    margin: 95px !important; }
  .mar-top-sm-95 {
    margin-top: 95px !important; }
  .mar-rig-sm-95 {
    margin-right: 95px !important; }
  .mar-bot-sm-95 {
    margin-bottom: 95px !important; }
  .mar-lef-sm-95 {
    margin-left: 95px !important; }
  .mar-top-neg-sm-95 {
    margin-top: -95px !important; }
  .mar-rig-neg-sm-95 {
    margin-right: -95px !important; }
  .mar-bot-neg-sm-95 {
    margin-bottom: -95px !important; }
  .mar-lef-neg-sm-95 {
    margin-left: -95px !important; }
  .pad-sm-95 {
    padding: 95px !important; }
  .pad-top-sm-95 {
    padding-top: 95px !important; }
  .pad-rig-sm-95 {
    padding-right: 95px !important; }
  .pad-bot-sm-95 {
    padding-bottom: 95px !important; }
  .pad-lef-sm-95 {
    padding-left: 95px !important; }
  .mar-sm-100 {
    margin: 100px !important; }
  .mar-top-sm-100 {
    margin-top: 100px !important; }
  .mar-rig-sm-100 {
    margin-right: 100px !important; }
  .mar-bot-sm-100 {
    margin-bottom: 100px !important; }
  .mar-lef-sm-100 {
    margin-left: 100px !important; }
  .mar-top-neg-sm-100 {
    margin-top: -100px !important; }
  .mar-rig-neg-sm-100 {
    margin-right: -100px !important; }
  .mar-bot-neg-sm-100 {
    margin-bottom: -100px !important; }
  .mar-lef-neg-sm-100 {
    margin-left: -100px !important; }
  .pad-sm-100 {
    padding: 100px !important; }
  .pad-top-sm-100 {
    padding-top: 100px !important; }
  .pad-rig-sm-100 {
    padding-right: 100px !important; }
  .pad-bot-sm-100 {
    padding-bottom: 100px !important; }
  .pad-lef-sm-100 {
    padding-left: 100px !important; }
  .line-height-sm-0 {
    line-height: 0px !important; }
  .line-height-sm-1 {
    line-height: 1px !important; }
  .line-height-sm-2 {
    line-height: 2px !important; }
  .line-height-sm-3 {
    line-height: 3px !important; }
  .line-height-sm-4 {
    line-height: 4px !important; }
  .line-height-sm-5 {
    line-height: 5px !important; }
  .line-height-sm-6 {
    line-height: 6px !important; }
  .line-height-sm-7 {
    line-height: 7px !important; }
  .line-height-sm-8 {
    line-height: 8px !important; }
  .line-height-sm-9 {
    line-height: 9px !important; }
  .line-height-sm-10 {
    line-height: 10px !important; }
  .line-height-sm-11 {
    line-height: 11px !important; }
  .line-height-sm-12 {
    line-height: 12px !important; }
  .line-height-sm-13 {
    line-height: 13px !important; }
  .line-height-sm-14 {
    line-height: 14px !important; }
  .line-height-sm-15 {
    line-height: 15px !important; }
  .line-height-sm-16 {
    line-height: 16px !important; }
  .line-height-sm-17 {
    line-height: 17px !important; }
  .line-height-sm-18 {
    line-height: 18px !important; }
  .line-height-sm-19 {
    line-height: 19px !important; }
  .line-height-sm-20 {
    line-height: 20px !important; }
  .line-height-sm-21 {
    line-height: 21px !important; }
  .line-height-sm-22 {
    line-height: 22px !important; }
  .line-height-sm-23 {
    line-height: 23px !important; }
  .line-height-sm-24 {
    line-height: 24px !important; }
  .line-height-sm-25 {
    line-height: 25px !important; }
  .line-height-sm-26 {
    line-height: 26px !important; }
  .line-height-sm-27 {
    line-height: 27px !important; }
  .line-height-sm-28 {
    line-height: 28px !important; }
  .line-height-sm-29 {
    line-height: 29px !important; }
  .line-height-sm-30 {
    line-height: 30px !important; }
  .line-height-sm-31 {
    line-height: 31px !important; }
  .line-height-sm-32 {
    line-height: 32px !important; }
  .line-height-sm-33 {
    line-height: 33px !important; }
  .line-height-sm-34 {
    line-height: 34px !important; }
  .line-height-sm-35 {
    line-height: 35px !important; }
  .line-height-sm-36 {
    line-height: 36px !important; }
  .line-height-sm-37 {
    line-height: 37px !important; }
  .line-height-sm-38 {
    line-height: 38px !important; }
  .line-height-sm-39 {
    line-height: 39px !important; }
  .line-height-sm-40 {
    line-height: 40px !important; }
  .line-height-sm-41 {
    line-height: 41px !important; }
  .line-height-sm-42 {
    line-height: 42px !important; }
  .line-height-sm-43 {
    line-height: 43px !important; }
  .line-height-sm-44 {
    line-height: 44px !important; }
  .line-height-sm-45 {
    line-height: 45px !important; }
  .line-height-sm-46 {
    line-height: 46px !important; }
  .line-height-sm-47 {
    line-height: 47px !important; }
  .line-height-sm-48 {
    line-height: 48px !important; }
  .line-height-sm-49 {
    line-height: 49px !important; }
  .line-height-sm-50 {
    line-height: 50px !important; }
  .line-height-sm-51 {
    line-height: 51px !important; }
  .line-height-sm-52 {
    line-height: 52px !important; }
  .line-height-sm-53 {
    line-height: 53px !important; }
  .line-height-sm-54 {
    line-height: 54px !important; }
  .line-height-sm-55 {
    line-height: 55px !important; }
  .line-height-sm-56 {
    line-height: 56px !important; }
  .line-height-sm-57 {
    line-height: 57px !important; }
  .line-height-sm-58 {
    line-height: 58px !important; }
  .line-height-sm-59 {
    line-height: 59px !important; }
  .line-height-sm-60 {
    line-height: 60px !important; }
  .dis-block-sm {
    display: block !important; }
  .dis-none-sm {
    display: none !important; }
  .dis-inline-sm {
    display: inline !important; }
  .dis-inline-block-sm {
    display: inline-block !important; }
  .text-cen-sm {
    text-align: center !important; }
  .text-lef-sm {
    text-align: left !important; }
  .text-rig-sm {
    text-align: right !important; }
  .text-jus-sm {
    text-align: justify !important; }
  .pos-re-sm {
    position: relative !important; }
  .pos-ab-sm {
    position: absolute !important; }
  .pos-st-sm {
    position: static !important; } }

@media (min-width: 992px) {
  .font-size-md-8 {
    font-size: 8px !important; }
  .font-size-md-9 {
    font-size: 9px !important; }
  .font-size-md-10 {
    font-size: 10px !important; }
  .font-size-md-11 {
    font-size: 11px !important; }
  .font-size-md-12 {
    font-size: 12px !important; }
  .font-size-md-13 {
    font-size: 13px !important; }
  .font-size-md-14 {
    font-size: 14px !important; }
  .font-size-md-15 {
    font-size: 15px !important; }
  .font-size-md-16 {
    font-size: 16px !important; }
  .font-size-md-17 {
    font-size: 17px !important; }
  .font-size-md-18 {
    font-size: 18px !important; }
  .font-size-md-19 {
    font-size: 19px !important; }
  .font-size-md-20 {
    font-size: 20px !important; }
  .font-size-md-21 {
    font-size: 21px !important; }
  .font-size-md-22 {
    font-size: 22px !important; }
  .font-size-md-23 {
    font-size: 23px !important; }
  .font-size-md-24 {
    font-size: 24px !important; }
  .font-size-md-25 {
    font-size: 25px !important; }
  .font-size-md-26 {
    font-size: 26px !important; }
  .font-size-md-27 {
    font-size: 27px !important; }
  .font-size-md-28 {
    font-size: 28px !important; }
  .font-size-md-29 {
    font-size: 29px !important; }
  .font-size-md-30 {
    font-size: 30px !important; }
  .font-size-md-31 {
    font-size: 31px !important; }
  .font-size-md-32 {
    font-size: 32px !important; }
  .font-size-md-33 {
    font-size: 33px !important; }
  .font-size-md-34 {
    font-size: 34px !important; }
  .font-size-md-35 {
    font-size: 35px !important; }
  .font-size-md-36 {
    font-size: 36px !important; }
  .font-size-md-37 {
    font-size: 37px !important; }
  .font-size-md-38 {
    font-size: 38px !important; }
  .font-size-md-39 {
    font-size: 39px !important; }
  .font-size-md-40 {
    font-size: 40px !important; }
  .font-size-md-41 {
    font-size: 41px !important; }
  .font-size-md-42 {
    font-size: 42px !important; }
  .font-size-md-43 {
    font-size: 43px !important; }
  .font-size-md-44 {
    font-size: 44px !important; }
  .font-size-md-45 {
    font-size: 45px !important; }
  .font-size-md-46 {
    font-size: 46px !important; }
  .font-size-md-47 {
    font-size: 47px !important; }
  .font-size-md-48 {
    font-size: 48px !important; }
  .font-size-md-49 {
    font-size: 49px !important; }
  .font-size-md-50 {
    font-size: 50px !important; }
  .font-size-md-51 {
    font-size: 51px !important; }
  .font-size-md-52 {
    font-size: 52px !important; }
  .font-size-md-53 {
    font-size: 53px !important; }
  .font-size-md-54 {
    font-size: 54px !important; }
  .font-size-md-55 {
    font-size: 55px !important; }
  .font-size-md-56 {
    font-size: 56px !important; }
  .font-size-md-57 {
    font-size: 57px !important; }
  .font-size-md-58 {
    font-size: 58px !important; }
  .font-size-md-59 {
    font-size: 59px !important; }
  .font-size-md-60 {
    font-size: 60px !important; }
  .mar-md-0 {
    margin: 0px !important; }
  .mar-top-md-0 {
    margin-top: 0px !important; }
  .mar-rig-md-0 {
    margin-right: 0px !important; }
  .mar-bot-md-0 {
    margin-bottom: 0px !important; }
  .mar-lef-md-0 {
    margin-left: 0px !important; }
  .mar-top-neg-md-0 {
    margin-top: -0px !important; }
  .mar-rig-neg-md-0 {
    margin-right: -0px !important; }
  .mar-bot-neg-md-0 {
    margin-bottom: -0px !important; }
  .mar-lef-neg-md-0 {
    margin-left: -0px !important; }
  .pad-md-0 {
    padding: 0px !important; }
  .pad-top-md-0 {
    padding-top: 0px !important; }
  .pad-rig-md-0 {
    padding-right: 0px !important; }
  .pad-bot-md-0 {
    padding-bottom: 0px !important; }
  .pad-lef-md-0 {
    padding-left: 0px !important; }
  .mar-md-5 {
    margin: 5px !important; }
  .mar-top-md-5 {
    margin-top: 5px !important; }
  .mar-rig-md-5 {
    margin-right: 5px !important; }
  .mar-bot-md-5 {
    margin-bottom: 5px !important; }
  .mar-lef-md-5 {
    margin-left: 5px !important; }
  .mar-top-neg-md-5 {
    margin-top: -5px !important; }
  .mar-rig-neg-md-5 {
    margin-right: -5px !important; }
  .mar-bot-neg-md-5 {
    margin-bottom: -5px !important; }
  .mar-lef-neg-md-5 {
    margin-left: -5px !important; }
  .pad-md-5 {
    padding: 5px !important; }
  .pad-top-md-5 {
    padding-top: 5px !important; }
  .pad-rig-md-5 {
    padding-right: 5px !important; }
  .pad-bot-md-5 {
    padding-bottom: 5px !important; }
  .pad-lef-md-5 {
    padding-left: 5px !important; }
  .mar-md-10 {
    margin: 10px !important; }
  .mar-top-md-10 {
    margin-top: 10px !important; }
  .mar-rig-md-10 {
    margin-right: 10px !important; }
  .mar-bot-md-10 {
    margin-bottom: 10px !important; }
  .mar-lef-md-10 {
    margin-left: 10px !important; }
  .mar-top-neg-md-10 {
    margin-top: -10px !important; }
  .mar-rig-neg-md-10 {
    margin-right: -10px !important; }
  .mar-bot-neg-md-10 {
    margin-bottom: -10px !important; }
  .mar-lef-neg-md-10 {
    margin-left: -10px !important; }
  .pad-md-10 {
    padding: 10px !important; }
  .pad-top-md-10 {
    padding-top: 10px !important; }
  .pad-rig-md-10 {
    padding-right: 10px !important; }
  .pad-bot-md-10 {
    padding-bottom: 10px !important; }
  .pad-lef-md-10 {
    padding-left: 10px !important; }
  .mar-md-15 {
    margin: 15px !important; }
  .mar-top-md-15 {
    margin-top: 15px !important; }
  .mar-rig-md-15 {
    margin-right: 15px !important; }
  .mar-bot-md-15 {
    margin-bottom: 15px !important; }
  .mar-lef-md-15 {
    margin-left: 15px !important; }
  .mar-top-neg-md-15 {
    margin-top: -15px !important; }
  .mar-rig-neg-md-15 {
    margin-right: -15px !important; }
  .mar-bot-neg-md-15 {
    margin-bottom: -15px !important; }
  .mar-lef-neg-md-15 {
    margin-left: -15px !important; }
  .pad-md-15 {
    padding: 15px !important; }
  .pad-top-md-15 {
    padding-top: 15px !important; }
  .pad-rig-md-15 {
    padding-right: 15px !important; }
  .pad-bot-md-15 {
    padding-bottom: 15px !important; }
  .pad-lef-md-15 {
    padding-left: 15px !important; }
  .mar-md-20 {
    margin: 20px !important; }
  .mar-top-md-20 {
    margin-top: 20px !important; }
  .mar-rig-md-20 {
    margin-right: 20px !important; }
  .mar-bot-md-20 {
    margin-bottom: 20px !important; }
  .mar-lef-md-20 {
    margin-left: 20px !important; }
  .mar-top-neg-md-20 {
    margin-top: -20px !important; }
  .mar-rig-neg-md-20 {
    margin-right: -20px !important; }
  .mar-bot-neg-md-20 {
    margin-bottom: -20px !important; }
  .mar-lef-neg-md-20 {
    margin-left: -20px !important; }
  .pad-md-20 {
    padding: 20px !important; }
  .pad-top-md-20 {
    padding-top: 20px !important; }
  .pad-rig-md-20 {
    padding-right: 20px !important; }
  .pad-bot-md-20 {
    padding-bottom: 20px !important; }
  .pad-lef-md-20 {
    padding-left: 20px !important; }
  .mar-md-25 {
    margin: 25px !important; }
  .mar-top-md-25 {
    margin-top: 25px !important; }
  .mar-rig-md-25 {
    margin-right: 25px !important; }
  .mar-bot-md-25 {
    margin-bottom: 25px !important; }
  .mar-lef-md-25 {
    margin-left: 25px !important; }
  .mar-top-neg-md-25 {
    margin-top: -25px !important; }
  .mar-rig-neg-md-25 {
    margin-right: -25px !important; }
  .mar-bot-neg-md-25 {
    margin-bottom: -25px !important; }
  .mar-lef-neg-md-25 {
    margin-left: -25px !important; }
  .pad-md-25 {
    padding: 25px !important; }
  .pad-top-md-25 {
    padding-top: 25px !important; }
  .pad-rig-md-25 {
    padding-right: 25px !important; }
  .pad-bot-md-25 {
    padding-bottom: 25px !important; }
  .pad-lef-md-25 {
    padding-left: 25px !important; }
  .mar-md-30 {
    margin: 30px !important; }
  .mar-top-md-30 {
    margin-top: 30px !important; }
  .mar-rig-md-30 {
    margin-right: 30px !important; }
  .mar-bot-md-30 {
    margin-bottom: 30px !important; }
  .mar-lef-md-30 {
    margin-left: 30px !important; }
  .mar-top-neg-md-30 {
    margin-top: -30px !important; }
  .mar-rig-neg-md-30 {
    margin-right: -30px !important; }
  .mar-bot-neg-md-30 {
    margin-bottom: -30px !important; }
  .mar-lef-neg-md-30 {
    margin-left: -30px !important; }
  .pad-md-30 {
    padding: 30px !important; }
  .pad-top-md-30 {
    padding-top: 30px !important; }
  .pad-rig-md-30 {
    padding-right: 30px !important; }
  .pad-bot-md-30 {
    padding-bottom: 30px !important; }
  .pad-lef-md-30 {
    padding-left: 30px !important; }
  .mar-md-35 {
    margin: 35px !important; }
  .mar-top-md-35 {
    margin-top: 35px !important; }
  .mar-rig-md-35 {
    margin-right: 35px !important; }
  .mar-bot-md-35 {
    margin-bottom: 35px !important; }
  .mar-lef-md-35 {
    margin-left: 35px !important; }
  .mar-top-neg-md-35 {
    margin-top: -35px !important; }
  .mar-rig-neg-md-35 {
    margin-right: -35px !important; }
  .mar-bot-neg-md-35 {
    margin-bottom: -35px !important; }
  .mar-lef-neg-md-35 {
    margin-left: -35px !important; }
  .pad-md-35 {
    padding: 35px !important; }
  .pad-top-md-35 {
    padding-top: 35px !important; }
  .pad-rig-md-35 {
    padding-right: 35px !important; }
  .pad-bot-md-35 {
    padding-bottom: 35px !important; }
  .pad-lef-md-35 {
    padding-left: 35px !important; }
  .mar-md-40 {
    margin: 40px !important; }
  .mar-top-md-40 {
    margin-top: 40px !important; }
  .mar-rig-md-40 {
    margin-right: 40px !important; }
  .mar-bot-md-40 {
    margin-bottom: 40px !important; }
  .mar-lef-md-40 {
    margin-left: 40px !important; }
  .mar-top-neg-md-40 {
    margin-top: -40px !important; }
  .mar-rig-neg-md-40 {
    margin-right: -40px !important; }
  .mar-bot-neg-md-40 {
    margin-bottom: -40px !important; }
  .mar-lef-neg-md-40 {
    margin-left: -40px !important; }
  .pad-md-40 {
    padding: 40px !important; }
  .pad-top-md-40 {
    padding-top: 40px !important; }
  .pad-rig-md-40 {
    padding-right: 40px !important; }
  .pad-bot-md-40 {
    padding-bottom: 40px !important; }
  .pad-lef-md-40 {
    padding-left: 40px !important; }
  .mar-md-45 {
    margin: 45px !important; }
  .mar-top-md-45 {
    margin-top: 45px !important; }
  .mar-rig-md-45 {
    margin-right: 45px !important; }
  .mar-bot-md-45 {
    margin-bottom: 45px !important; }
  .mar-lef-md-45 {
    margin-left: 45px !important; }
  .mar-top-neg-md-45 {
    margin-top: -45px !important; }
  .mar-rig-neg-md-45 {
    margin-right: -45px !important; }
  .mar-bot-neg-md-45 {
    margin-bottom: -45px !important; }
  .mar-lef-neg-md-45 {
    margin-left: -45px !important; }
  .pad-md-45 {
    padding: 45px !important; }
  .pad-top-md-45 {
    padding-top: 45px !important; }
  .pad-rig-md-45 {
    padding-right: 45px !important; }
  .pad-bot-md-45 {
    padding-bottom: 45px !important; }
  .pad-lef-md-45 {
    padding-left: 45px !important; }
  .mar-md-50 {
    margin: 50px !important; }
  .mar-top-md-50 {
    margin-top: 50px !important; }
  .mar-rig-md-50 {
    margin-right: 50px !important; }
  .mar-bot-md-50 {
    margin-bottom: 50px !important; }
  .mar-lef-md-50 {
    margin-left: 50px !important; }
  .mar-top-neg-md-50 {
    margin-top: -50px !important; }
  .mar-rig-neg-md-50 {
    margin-right: -50px !important; }
  .mar-bot-neg-md-50 {
    margin-bottom: -50px !important; }
  .mar-lef-neg-md-50 {
    margin-left: -50px !important; }
  .pad-md-50 {
    padding: 50px !important; }
  .pad-top-md-50 {
    padding-top: 50px !important; }
  .pad-rig-md-50 {
    padding-right: 50px !important; }
  .pad-bot-md-50 {
    padding-bottom: 50px !important; }
  .pad-lef-md-50 {
    padding-left: 50px !important; }
  .mar-md-55 {
    margin: 55px !important; }
  .mar-top-md-55 {
    margin-top: 55px !important; }
  .mar-rig-md-55 {
    margin-right: 55px !important; }
  .mar-bot-md-55 {
    margin-bottom: 55px !important; }
  .mar-lef-md-55 {
    margin-left: 55px !important; }
  .mar-top-neg-md-55 {
    margin-top: -55px !important; }
  .mar-rig-neg-md-55 {
    margin-right: -55px !important; }
  .mar-bot-neg-md-55 {
    margin-bottom: -55px !important; }
  .mar-lef-neg-md-55 {
    margin-left: -55px !important; }
  .pad-md-55 {
    padding: 55px !important; }
  .pad-top-md-55 {
    padding-top: 55px !important; }
  .pad-rig-md-55 {
    padding-right: 55px !important; }
  .pad-bot-md-55 {
    padding-bottom: 55px !important; }
  .pad-lef-md-55 {
    padding-left: 55px !important; }
  .mar-md-60 {
    margin: 60px !important; }
  .mar-top-md-60 {
    margin-top: 60px !important; }
  .mar-rig-md-60 {
    margin-right: 60px !important; }
  .mar-bot-md-60 {
    margin-bottom: 60px !important; }
  .mar-lef-md-60 {
    margin-left: 60px !important; }
  .mar-top-neg-md-60 {
    margin-top: -60px !important; }
  .mar-rig-neg-md-60 {
    margin-right: -60px !important; }
  .mar-bot-neg-md-60 {
    margin-bottom: -60px !important; }
  .mar-lef-neg-md-60 {
    margin-left: -60px !important; }
  .pad-md-60 {
    padding: 60px !important; }
  .pad-top-md-60 {
    padding-top: 60px !important; }
  .pad-rig-md-60 {
    padding-right: 60px !important; }
  .pad-bot-md-60 {
    padding-bottom: 60px !important; }
  .pad-lef-md-60 {
    padding-left: 60px !important; }
  .mar-md-65 {
    margin: 65px !important; }
  .mar-top-md-65 {
    margin-top: 65px !important; }
  .mar-rig-md-65 {
    margin-right: 65px !important; }
  .mar-bot-md-65 {
    margin-bottom: 65px !important; }
  .mar-lef-md-65 {
    margin-left: 65px !important; }
  .mar-top-neg-md-65 {
    margin-top: -65px !important; }
  .mar-rig-neg-md-65 {
    margin-right: -65px !important; }
  .mar-bot-neg-md-65 {
    margin-bottom: -65px !important; }
  .mar-lef-neg-md-65 {
    margin-left: -65px !important; }
  .pad-md-65 {
    padding: 65px !important; }
  .pad-top-md-65 {
    padding-top: 65px !important; }
  .pad-rig-md-65 {
    padding-right: 65px !important; }
  .pad-bot-md-65 {
    padding-bottom: 65px !important; }
  .pad-lef-md-65 {
    padding-left: 65px !important; }
  .mar-md-70 {
    margin: 70px !important; }
  .mar-top-md-70 {
    margin-top: 70px !important; }
  .mar-rig-md-70 {
    margin-right: 70px !important; }
  .mar-bot-md-70 {
    margin-bottom: 70px !important; }
  .mar-lef-md-70 {
    margin-left: 70px !important; }
  .mar-top-neg-md-70 {
    margin-top: -70px !important; }
  .mar-rig-neg-md-70 {
    margin-right: -70px !important; }
  .mar-bot-neg-md-70 {
    margin-bottom: -70px !important; }
  .mar-lef-neg-md-70 {
    margin-left: -70px !important; }
  .pad-md-70 {
    padding: 70px !important; }
  .pad-top-md-70 {
    padding-top: 70px !important; }
  .pad-rig-md-70 {
    padding-right: 70px !important; }
  .pad-bot-md-70 {
    padding-bottom: 70px !important; }
  .pad-lef-md-70 {
    padding-left: 70px !important; }
  .mar-md-75 {
    margin: 75px !important; }
  .mar-top-md-75 {
    margin-top: 75px !important; }
  .mar-rig-md-75 {
    margin-right: 75px !important; }
  .mar-bot-md-75 {
    margin-bottom: 75px !important; }
  .mar-lef-md-75 {
    margin-left: 75px !important; }
  .mar-top-neg-md-75 {
    margin-top: -75px !important; }
  .mar-rig-neg-md-75 {
    margin-right: -75px !important; }
  .mar-bot-neg-md-75 {
    margin-bottom: -75px !important; }
  .mar-lef-neg-md-75 {
    margin-left: -75px !important; }
  .pad-md-75 {
    padding: 75px !important; }
  .pad-top-md-75 {
    padding-top: 75px !important; }
  .pad-rig-md-75 {
    padding-right: 75px !important; }
  .pad-bot-md-75 {
    padding-bottom: 75px !important; }
  .pad-lef-md-75 {
    padding-left: 75px !important; }
  .mar-md-80 {
    margin: 80px !important; }
  .mar-top-md-80 {
    margin-top: 80px !important; }
  .mar-rig-md-80 {
    margin-right: 80px !important; }
  .mar-bot-md-80 {
    margin-bottom: 80px !important; }
  .mar-lef-md-80 {
    margin-left: 80px !important; }
  .mar-top-neg-md-80 {
    margin-top: -80px !important; }
  .mar-rig-neg-md-80 {
    margin-right: -80px !important; }
  .mar-bot-neg-md-80 {
    margin-bottom: -80px !important; }
  .mar-lef-neg-md-80 {
    margin-left: -80px !important; }
  .pad-md-80 {
    padding: 80px !important; }
  .pad-top-md-80 {
    padding-top: 80px !important; }
  .pad-rig-md-80 {
    padding-right: 80px !important; }
  .pad-bot-md-80 {
    padding-bottom: 80px !important; }
  .pad-lef-md-80 {
    padding-left: 80px !important; }
  .mar-md-85 {
    margin: 85px !important; }
  .mar-top-md-85 {
    margin-top: 85px !important; }
  .mar-rig-md-85 {
    margin-right: 85px !important; }
  .mar-bot-md-85 {
    margin-bottom: 85px !important; }
  .mar-lef-md-85 {
    margin-left: 85px !important; }
  .mar-top-neg-md-85 {
    margin-top: -85px !important; }
  .mar-rig-neg-md-85 {
    margin-right: -85px !important; }
  .mar-bot-neg-md-85 {
    margin-bottom: -85px !important; }
  .mar-lef-neg-md-85 {
    margin-left: -85px !important; }
  .pad-md-85 {
    padding: 85px !important; }
  .pad-top-md-85 {
    padding-top: 85px !important; }
  .pad-rig-md-85 {
    padding-right: 85px !important; }
  .pad-bot-md-85 {
    padding-bottom: 85px !important; }
  .pad-lef-md-85 {
    padding-left: 85px !important; }
  .mar-md-90 {
    margin: 90px !important; }
  .mar-top-md-90 {
    margin-top: 90px !important; }
  .mar-rig-md-90 {
    margin-right: 90px !important; }
  .mar-bot-md-90 {
    margin-bottom: 90px !important; }
  .mar-lef-md-90 {
    margin-left: 90px !important; }
  .mar-top-neg-md-90 {
    margin-top: -90px !important; }
  .mar-rig-neg-md-90 {
    margin-right: -90px !important; }
  .mar-bot-neg-md-90 {
    margin-bottom: -90px !important; }
  .mar-lef-neg-md-90 {
    margin-left: -90px !important; }
  .pad-md-90 {
    padding: 90px !important; }
  .pad-top-md-90 {
    padding-top: 90px !important; }
  .pad-rig-md-90 {
    padding-right: 90px !important; }
  .pad-bot-md-90 {
    padding-bottom: 90px !important; }
  .pad-lef-md-90 {
    padding-left: 90px !important; }
  .mar-md-95 {
    margin: 95px !important; }
  .mar-top-md-95 {
    margin-top: 95px !important; }
  .mar-rig-md-95 {
    margin-right: 95px !important; }
  .mar-bot-md-95 {
    margin-bottom: 95px !important; }
  .mar-lef-md-95 {
    margin-left: 95px !important; }
  .mar-top-neg-md-95 {
    margin-top: -95px !important; }
  .mar-rig-neg-md-95 {
    margin-right: -95px !important; }
  .mar-bot-neg-md-95 {
    margin-bottom: -95px !important; }
  .mar-lef-neg-md-95 {
    margin-left: -95px !important; }
  .pad-md-95 {
    padding: 95px !important; }
  .pad-top-md-95 {
    padding-top: 95px !important; }
  .pad-rig-md-95 {
    padding-right: 95px !important; }
  .pad-bot-md-95 {
    padding-bottom: 95px !important; }
  .pad-lef-md-95 {
    padding-left: 95px !important; }
  .mar-md-100 {
    margin: 100px !important; }
  .mar-top-md-100 {
    margin-top: 100px !important; }
  .mar-rig-md-100 {
    margin-right: 100px !important; }
  .mar-bot-md-100 {
    margin-bottom: 100px !important; }
  .mar-lef-md-100 {
    margin-left: 100px !important; }
  .mar-top-neg-md-100 {
    margin-top: -100px !important; }
  .mar-rig-neg-md-100 {
    margin-right: -100px !important; }
  .mar-bot-neg-md-100 {
    margin-bottom: -100px !important; }
  .mar-lef-neg-md-100 {
    margin-left: -100px !important; }
  .pad-md-100 {
    padding: 100px !important; }
  .pad-top-md-100 {
    padding-top: 100px !important; }
  .pad-rig-md-100 {
    padding-right: 100px !important; }
  .pad-bot-md-100 {
    padding-bottom: 100px !important; }
  .pad-lef-md-100 {
    padding-left: 100px !important; }
  .line-height-md-0 {
    line-height: 0px !important; }
  .line-height-md-1 {
    line-height: 1px !important; }
  .line-height-md-2 {
    line-height: 2px !important; }
  .line-height-md-3 {
    line-height: 3px !important; }
  .line-height-md-4 {
    line-height: 4px !important; }
  .line-height-md-5 {
    line-height: 5px !important; }
  .line-height-md-6 {
    line-height: 6px !important; }
  .line-height-md-7 {
    line-height: 7px !important; }
  .line-height-md-8 {
    line-height: 8px !important; }
  .line-height-md-9 {
    line-height: 9px !important; }
  .line-height-md-10 {
    line-height: 10px !important; }
  .line-height-md-11 {
    line-height: 11px !important; }
  .line-height-md-12 {
    line-height: 12px !important; }
  .line-height-md-13 {
    line-height: 13px !important; }
  .line-height-md-14 {
    line-height: 14px !important; }
  .line-height-md-15 {
    line-height: 15px !important; }
  .line-height-md-16 {
    line-height: 16px !important; }
  .line-height-md-17 {
    line-height: 17px !important; }
  .line-height-md-18 {
    line-height: 18px !important; }
  .line-height-md-19 {
    line-height: 19px !important; }
  .line-height-md-20 {
    line-height: 20px !important; }
  .line-height-md-21 {
    line-height: 21px !important; }
  .line-height-md-22 {
    line-height: 22px !important; }
  .line-height-md-23 {
    line-height: 23px !important; }
  .line-height-md-24 {
    line-height: 24px !important; }
  .line-height-md-25 {
    line-height: 25px !important; }
  .line-height-md-26 {
    line-height: 26px !important; }
  .line-height-md-27 {
    line-height: 27px !important; }
  .line-height-md-28 {
    line-height: 28px !important; }
  .line-height-md-29 {
    line-height: 29px !important; }
  .line-height-md-30 {
    line-height: 30px !important; }
  .line-height-md-31 {
    line-height: 31px !important; }
  .line-height-md-32 {
    line-height: 32px !important; }
  .line-height-md-33 {
    line-height: 33px !important; }
  .line-height-md-34 {
    line-height: 34px !important; }
  .line-height-md-35 {
    line-height: 35px !important; }
  .line-height-md-36 {
    line-height: 36px !important; }
  .line-height-md-37 {
    line-height: 37px !important; }
  .line-height-md-38 {
    line-height: 38px !important; }
  .line-height-md-39 {
    line-height: 39px !important; }
  .line-height-md-40 {
    line-height: 40px !important; }
  .line-height-md-41 {
    line-height: 41px !important; }
  .line-height-md-42 {
    line-height: 42px !important; }
  .line-height-md-43 {
    line-height: 43px !important; }
  .line-height-md-44 {
    line-height: 44px !important; }
  .line-height-md-45 {
    line-height: 45px !important; }
  .line-height-md-46 {
    line-height: 46px !important; }
  .line-height-md-47 {
    line-height: 47px !important; }
  .line-height-md-48 {
    line-height: 48px !important; }
  .line-height-md-49 {
    line-height: 49px !important; }
  .line-height-md-50 {
    line-height: 50px !important; }
  .line-height-md-51 {
    line-height: 51px !important; }
  .line-height-md-52 {
    line-height: 52px !important; }
  .line-height-md-53 {
    line-height: 53px !important; }
  .line-height-md-54 {
    line-height: 54px !important; }
  .line-height-md-55 {
    line-height: 55px !important; }
  .line-height-md-56 {
    line-height: 56px !important; }
  .line-height-md-57 {
    line-height: 57px !important; }
  .line-height-md-58 {
    line-height: 58px !important; }
  .line-height-md-59 {
    line-height: 59px !important; }
  .line-height-md-60 {
    line-height: 60px !important; }
  .dis-block-md {
    display: block !important; }
  .dis-none-md {
    display: none !important; }
  .dis-inline-md {
    display: inline !important; }
  .dis-inline-block-md {
    display: inline-block !important; }
  .text-cen-md {
    text-align: center !important; }
  .text-lef-md {
    text-align: left !important; }
  .text-rig-md {
    text-align: right !important; }
  .text-jus-md {
    text-align: justify !important; }
  .pos-re-md {
    position: relative !important; }
  .pos-ab-md {
    position: absolute !important; }
  .pos-st-md {
    position: static !important; } }

@media (min-width: 1200px) {
  .font-size-lg-8 {
    font-size: 8px !important; }
  .font-size-lg-9 {
    font-size: 9px !important; }
  .font-size-lg-10 {
    font-size: 10px !important; }
  .font-size-lg-11 {
    font-size: 11px !important; }
  .font-size-lg-12 {
    font-size: 12px !important; }
  .font-size-lg-13 {
    font-size: 13px !important; }
  .font-size-lg-14 {
    font-size: 14px !important; }
  .font-size-lg-15 {
    font-size: 15px !important; }
  .font-size-lg-16 {
    font-size: 16px !important; }
  .font-size-lg-17 {
    font-size: 17px !important; }
  .font-size-lg-18 {
    font-size: 18px !important; }
  .font-size-lg-19 {
    font-size: 19px !important; }
  .font-size-lg-20 {
    font-size: 20px !important; }
  .font-size-lg-21 {
    font-size: 21px !important; }
  .font-size-lg-22 {
    font-size: 22px !important; }
  .font-size-lg-23 {
    font-size: 23px !important; }
  .font-size-lg-24 {
    font-size: 24px !important; }
  .font-size-lg-25 {
    font-size: 25px !important; }
  .font-size-lg-26 {
    font-size: 26px !important; }
  .font-size-lg-27 {
    font-size: 27px !important; }
  .font-size-lg-28 {
    font-size: 28px !important; }
  .font-size-lg-29 {
    font-size: 29px !important; }
  .font-size-lg-30 {
    font-size: 30px !important; }
  .font-size-lg-31 {
    font-size: 31px !important; }
  .font-size-lg-32 {
    font-size: 32px !important; }
  .font-size-lg-33 {
    font-size: 33px !important; }
  .font-size-lg-34 {
    font-size: 34px !important; }
  .font-size-lg-35 {
    font-size: 35px !important; }
  .font-size-lg-36 {
    font-size: 36px !important; }
  .font-size-lg-37 {
    font-size: 37px !important; }
  .font-size-lg-38 {
    font-size: 38px !important; }
  .font-size-lg-39 {
    font-size: 39px !important; }
  .font-size-lg-40 {
    font-size: 40px !important; }
  .font-size-lg-41 {
    font-size: 41px !important; }
  .font-size-lg-42 {
    font-size: 42px !important; }
  .font-size-lg-43 {
    font-size: 43px !important; }
  .font-size-lg-44 {
    font-size: 44px !important; }
  .font-size-lg-45 {
    font-size: 45px !important; }
  .font-size-lg-46 {
    font-size: 46px !important; }
  .font-size-lg-47 {
    font-size: 47px !important; }
  .font-size-lg-48 {
    font-size: 48px !important; }
  .font-size-lg-49 {
    font-size: 49px !important; }
  .font-size-lg-50 {
    font-size: 50px !important; }
  .font-size-lg-51 {
    font-size: 51px !important; }
  .font-size-lg-52 {
    font-size: 52px !important; }
  .font-size-lg-53 {
    font-size: 53px !important; }
  .font-size-lg-54 {
    font-size: 54px !important; }
  .font-size-lg-55 {
    font-size: 55px !important; }
  .font-size-lg-56 {
    font-size: 56px !important; }
  .font-size-lg-57 {
    font-size: 57px !important; }
  .font-size-lg-58 {
    font-size: 58px !important; }
  .font-size-lg-59 {
    font-size: 59px !important; }
  .font-size-lg-60 {
    font-size: 60px !important; }
  .mar-lg-0 {
    margin: 0px !important; }
  .mar-top-lg-0 {
    margin-top: 0px !important; }
  .mar-rig-lg-0 {
    margin-right: 0px !important; }
  .mar-bot-lg-0 {
    margin-bottom: 0px !important; }
  .mar-lef-lg-0 {
    margin-left: 0px !important; }
  .mar-top-neg-lg-0 {
    margin-top: -0px !important; }
  .mar-rig-neg-lg-0 {
    margin-right: -0px !important; }
  .mar-bot-neg-lg-0 {
    margin-bottom: -0px !important; }
  .mar-lef-neg-lg-0 {
    margin-left: -0px !important; }
  .pad-lg-0 {
    padding: 0px !important; }
  .pad-top-lg-0 {
    padding-top: 0px !important; }
  .pad-rig-lg-0 {
    padding-right: 0px !important; }
  .pad-bot-lg-0 {
    padding-bottom: 0px !important; }
  .pad-lef-lg-0 {
    padding-left: 0px !important; }
  .mar-lg-5 {
    margin: 5px !important; }
  .mar-top-lg-5 {
    margin-top: 5px !important; }
  .mar-rig-lg-5 {
    margin-right: 5px !important; }
  .mar-bot-lg-5 {
    margin-bottom: 5px !important; }
  .mar-lef-lg-5 {
    margin-left: 5px !important; }
  .mar-top-neg-lg-5 {
    margin-top: -5px !important; }
  .mar-rig-neg-lg-5 {
    margin-right: -5px !important; }
  .mar-bot-neg-lg-5 {
    margin-bottom: -5px !important; }
  .mar-lef-neg-lg-5 {
    margin-left: -5px !important; }
  .pad-lg-5 {
    padding: 5px !important; }
  .pad-top-lg-5 {
    padding-top: 5px !important; }
  .pad-rig-lg-5 {
    padding-right: 5px !important; }
  .pad-bot-lg-5 {
    padding-bottom: 5px !important; }
  .pad-lef-lg-5 {
    padding-left: 5px !important; }
  .mar-lg-10 {
    margin: 10px !important; }
  .mar-top-lg-10 {
    margin-top: 10px !important; }
  .mar-rig-lg-10 {
    margin-right: 10px !important; }
  .mar-bot-lg-10 {
    margin-bottom: 10px !important; }
  .mar-lef-lg-10 {
    margin-left: 10px !important; }
  .mar-top-neg-lg-10 {
    margin-top: -10px !important; }
  .mar-rig-neg-lg-10 {
    margin-right: -10px !important; }
  .mar-bot-neg-lg-10 {
    margin-bottom: -10px !important; }
  .mar-lef-neg-lg-10 {
    margin-left: -10px !important; }
  .pad-lg-10 {
    padding: 10px !important; }
  .pad-top-lg-10 {
    padding-top: 10px !important; }
  .pad-rig-lg-10 {
    padding-right: 10px !important; }
  .pad-bot-lg-10 {
    padding-bottom: 10px !important; }
  .pad-lef-lg-10 {
    padding-left: 10px !important; }
  .mar-lg-15 {
    margin: 15px !important; }
  .mar-top-lg-15 {
    margin-top: 15px !important; }
  .mar-rig-lg-15 {
    margin-right: 15px !important; }
  .mar-bot-lg-15 {
    margin-bottom: 15px !important; }
  .mar-lef-lg-15 {
    margin-left: 15px !important; }
  .mar-top-neg-lg-15 {
    margin-top: -15px !important; }
  .mar-rig-neg-lg-15 {
    margin-right: -15px !important; }
  .mar-bot-neg-lg-15 {
    margin-bottom: -15px !important; }
  .mar-lef-neg-lg-15 {
    margin-left: -15px !important; }
  .pad-lg-15 {
    padding: 15px !important; }
  .pad-top-lg-15 {
    padding-top: 15px !important; }
  .pad-rig-lg-15 {
    padding-right: 15px !important; }
  .pad-bot-lg-15 {
    padding-bottom: 15px !important; }
  .pad-lef-lg-15 {
    padding-left: 15px !important; }
  .mar-lg-20 {
    margin: 20px !important; }
  .mar-top-lg-20 {
    margin-top: 20px !important; }
  .mar-rig-lg-20 {
    margin-right: 20px !important; }
  .mar-bot-lg-20 {
    margin-bottom: 20px !important; }
  .mar-lef-lg-20 {
    margin-left: 20px !important; }
  .mar-top-neg-lg-20 {
    margin-top: -20px !important; }
  .mar-rig-neg-lg-20 {
    margin-right: -20px !important; }
  .mar-bot-neg-lg-20 {
    margin-bottom: -20px !important; }
  .mar-lef-neg-lg-20 {
    margin-left: -20px !important; }
  .pad-lg-20 {
    padding: 20px !important; }
  .pad-top-lg-20 {
    padding-top: 20px !important; }
  .pad-rig-lg-20 {
    padding-right: 20px !important; }
  .pad-bot-lg-20 {
    padding-bottom: 20px !important; }
  .pad-lef-lg-20 {
    padding-left: 20px !important; }
  .mar-lg-25 {
    margin: 25px !important; }
  .mar-top-lg-25 {
    margin-top: 25px !important; }
  .mar-rig-lg-25 {
    margin-right: 25px !important; }
  .mar-bot-lg-25 {
    margin-bottom: 25px !important; }
  .mar-lef-lg-25 {
    margin-left: 25px !important; }
  .mar-top-neg-lg-25 {
    margin-top: -25px !important; }
  .mar-rig-neg-lg-25 {
    margin-right: -25px !important; }
  .mar-bot-neg-lg-25 {
    margin-bottom: -25px !important; }
  .mar-lef-neg-lg-25 {
    margin-left: -25px !important; }
  .pad-lg-25 {
    padding: 25px !important; }
  .pad-top-lg-25 {
    padding-top: 25px !important; }
  .pad-rig-lg-25 {
    padding-right: 25px !important; }
  .pad-bot-lg-25 {
    padding-bottom: 25px !important; }
  .pad-lef-lg-25 {
    padding-left: 25px !important; }
  .mar-lg-30 {
    margin: 30px !important; }
  .mar-top-lg-30 {
    margin-top: 30px !important; }
  .mar-rig-lg-30 {
    margin-right: 30px !important; }
  .mar-bot-lg-30 {
    margin-bottom: 30px !important; }
  .mar-lef-lg-30 {
    margin-left: 30px !important; }
  .mar-top-neg-lg-30 {
    margin-top: -30px !important; }
  .mar-rig-neg-lg-30 {
    margin-right: -30px !important; }
  .mar-bot-neg-lg-30 {
    margin-bottom: -30px !important; }
  .mar-lef-neg-lg-30 {
    margin-left: -30px !important; }
  .pad-lg-30 {
    padding: 30px !important; }
  .pad-top-lg-30 {
    padding-top: 30px !important; }
  .pad-rig-lg-30 {
    padding-right: 30px !important; }
  .pad-bot-lg-30 {
    padding-bottom: 30px !important; }
  .pad-lef-lg-30 {
    padding-left: 30px !important; }
  .mar-lg-35 {
    margin: 35px !important; }
  .mar-top-lg-35 {
    margin-top: 35px !important; }
  .mar-rig-lg-35 {
    margin-right: 35px !important; }
  .mar-bot-lg-35 {
    margin-bottom: 35px !important; }
  .mar-lef-lg-35 {
    margin-left: 35px !important; }
  .mar-top-neg-lg-35 {
    margin-top: -35px !important; }
  .mar-rig-neg-lg-35 {
    margin-right: -35px !important; }
  .mar-bot-neg-lg-35 {
    margin-bottom: -35px !important; }
  .mar-lef-neg-lg-35 {
    margin-left: -35px !important; }
  .pad-lg-35 {
    padding: 35px !important; }
  .pad-top-lg-35 {
    padding-top: 35px !important; }
  .pad-rig-lg-35 {
    padding-right: 35px !important; }
  .pad-bot-lg-35 {
    padding-bottom: 35px !important; }
  .pad-lef-lg-35 {
    padding-left: 35px !important; }
  .mar-lg-40 {
    margin: 40px !important; }
  .mar-top-lg-40 {
    margin-top: 40px !important; }
  .mar-rig-lg-40 {
    margin-right: 40px !important; }
  .mar-bot-lg-40 {
    margin-bottom: 40px !important; }
  .mar-lef-lg-40 {
    margin-left: 40px !important; }
  .mar-top-neg-lg-40 {
    margin-top: -40px !important; }
  .mar-rig-neg-lg-40 {
    margin-right: -40px !important; }
  .mar-bot-neg-lg-40 {
    margin-bottom: -40px !important; }
  .mar-lef-neg-lg-40 {
    margin-left: -40px !important; }
  .pad-lg-40 {
    padding: 40px !important; }
  .pad-top-lg-40 {
    padding-top: 40px !important; }
  .pad-rig-lg-40 {
    padding-right: 40px !important; }
  .pad-bot-lg-40 {
    padding-bottom: 40px !important; }
  .pad-lef-lg-40 {
    padding-left: 40px !important; }
  .mar-lg-45 {
    margin: 45px !important; }
  .mar-top-lg-45 {
    margin-top: 45px !important; }
  .mar-rig-lg-45 {
    margin-right: 45px !important; }
  .mar-bot-lg-45 {
    margin-bottom: 45px !important; }
  .mar-lef-lg-45 {
    margin-left: 45px !important; }
  .mar-top-neg-lg-45 {
    margin-top: -45px !important; }
  .mar-rig-neg-lg-45 {
    margin-right: -45px !important; }
  .mar-bot-neg-lg-45 {
    margin-bottom: -45px !important; }
  .mar-lef-neg-lg-45 {
    margin-left: -45px !important; }
  .pad-lg-45 {
    padding: 45px !important; }
  .pad-top-lg-45 {
    padding-top: 45px !important; }
  .pad-rig-lg-45 {
    padding-right: 45px !important; }
  .pad-bot-lg-45 {
    padding-bottom: 45px !important; }
  .pad-lef-lg-45 {
    padding-left: 45px !important; }
  .mar-lg-50 {
    margin: 50px !important; }
  .mar-top-lg-50 {
    margin-top: 50px !important; }
  .mar-rig-lg-50 {
    margin-right: 50px !important; }
  .mar-bot-lg-50 {
    margin-bottom: 50px !important; }
  .mar-lef-lg-50 {
    margin-left: 50px !important; }
  .mar-top-neg-lg-50 {
    margin-top: -50px !important; }
  .mar-rig-neg-lg-50 {
    margin-right: -50px !important; }
  .mar-bot-neg-lg-50 {
    margin-bottom: -50px !important; }
  .mar-lef-neg-lg-50 {
    margin-left: -50px !important; }
  .pad-lg-50 {
    padding: 50px !important; }
  .pad-top-lg-50 {
    padding-top: 50px !important; }
  .pad-rig-lg-50 {
    padding-right: 50px !important; }
  .pad-bot-lg-50 {
    padding-bottom: 50px !important; }
  .pad-lef-lg-50 {
    padding-left: 50px !important; }
  .mar-lg-55 {
    margin: 55px !important; }
  .mar-top-lg-55 {
    margin-top: 55px !important; }
  .mar-rig-lg-55 {
    margin-right: 55px !important; }
  .mar-bot-lg-55 {
    margin-bottom: 55px !important; }
  .mar-lef-lg-55 {
    margin-left: 55px !important; }
  .mar-top-neg-lg-55 {
    margin-top: -55px !important; }
  .mar-rig-neg-lg-55 {
    margin-right: -55px !important; }
  .mar-bot-neg-lg-55 {
    margin-bottom: -55px !important; }
  .mar-lef-neg-lg-55 {
    margin-left: -55px !important; }
  .pad-lg-55 {
    padding: 55px !important; }
  .pad-top-lg-55 {
    padding-top: 55px !important; }
  .pad-rig-lg-55 {
    padding-right: 55px !important; }
  .pad-bot-lg-55 {
    padding-bottom: 55px !important; }
  .pad-lef-lg-55 {
    padding-left: 55px !important; }
  .mar-lg-60 {
    margin: 60px !important; }
  .mar-top-lg-60 {
    margin-top: 60px !important; }
  .mar-rig-lg-60 {
    margin-right: 60px !important; }
  .mar-bot-lg-60 {
    margin-bottom: 60px !important; }
  .mar-lef-lg-60 {
    margin-left: 60px !important; }
  .mar-top-neg-lg-60 {
    margin-top: -60px !important; }
  .mar-rig-neg-lg-60 {
    margin-right: -60px !important; }
  .mar-bot-neg-lg-60 {
    margin-bottom: -60px !important; }
  .mar-lef-neg-lg-60 {
    margin-left: -60px !important; }
  .pad-lg-60 {
    padding: 60px !important; }
  .pad-top-lg-60 {
    padding-top: 60px !important; }
  .pad-rig-lg-60 {
    padding-right: 60px !important; }
  .pad-bot-lg-60 {
    padding-bottom: 60px !important; }
  .pad-lef-lg-60 {
    padding-left: 60px !important; }
  .mar-lg-65 {
    margin: 65px !important; }
  .mar-top-lg-65 {
    margin-top: 65px !important; }
  .mar-rig-lg-65 {
    margin-right: 65px !important; }
  .mar-bot-lg-65 {
    margin-bottom: 65px !important; }
  .mar-lef-lg-65 {
    margin-left: 65px !important; }
  .mar-top-neg-lg-65 {
    margin-top: -65px !important; }
  .mar-rig-neg-lg-65 {
    margin-right: -65px !important; }
  .mar-bot-neg-lg-65 {
    margin-bottom: -65px !important; }
  .mar-lef-neg-lg-65 {
    margin-left: -65px !important; }
  .pad-lg-65 {
    padding: 65px !important; }
  .pad-top-lg-65 {
    padding-top: 65px !important; }
  .pad-rig-lg-65 {
    padding-right: 65px !important; }
  .pad-bot-lg-65 {
    padding-bottom: 65px !important; }
  .pad-lef-lg-65 {
    padding-left: 65px !important; }
  .mar-lg-70 {
    margin: 70px !important; }
  .mar-top-lg-70 {
    margin-top: 70px !important; }
  .mar-rig-lg-70 {
    margin-right: 70px !important; }
  .mar-bot-lg-70 {
    margin-bottom: 70px !important; }
  .mar-lef-lg-70 {
    margin-left: 70px !important; }
  .mar-top-neg-lg-70 {
    margin-top: -70px !important; }
  .mar-rig-neg-lg-70 {
    margin-right: -70px !important; }
  .mar-bot-neg-lg-70 {
    margin-bottom: -70px !important; }
  .mar-lef-neg-lg-70 {
    margin-left: -70px !important; }
  .pad-lg-70 {
    padding: 70px !important; }
  .pad-top-lg-70 {
    padding-top: 70px !important; }
  .pad-rig-lg-70 {
    padding-right: 70px !important; }
  .pad-bot-lg-70 {
    padding-bottom: 70px !important; }
  .pad-lef-lg-70 {
    padding-left: 70px !important; }
  .mar-lg-75 {
    margin: 75px !important; }
  .mar-top-lg-75 {
    margin-top: 75px !important; }
  .mar-rig-lg-75 {
    margin-right: 75px !important; }
  .mar-bot-lg-75 {
    margin-bottom: 75px !important; }
  .mar-lef-lg-75 {
    margin-left: 75px !important; }
  .mar-top-neg-lg-75 {
    margin-top: -75px !important; }
  .mar-rig-neg-lg-75 {
    margin-right: -75px !important; }
  .mar-bot-neg-lg-75 {
    margin-bottom: -75px !important; }
  .mar-lef-neg-lg-75 {
    margin-left: -75px !important; }
  .pad-lg-75 {
    padding: 75px !important; }
  .pad-top-lg-75 {
    padding-top: 75px !important; }
  .pad-rig-lg-75 {
    padding-right: 75px !important; }
  .pad-bot-lg-75 {
    padding-bottom: 75px !important; }
  .pad-lef-lg-75 {
    padding-left: 75px !important; }
  .mar-lg-80 {
    margin: 80px !important; }
  .mar-top-lg-80 {
    margin-top: 80px !important; }
  .mar-rig-lg-80 {
    margin-right: 80px !important; }
  .mar-bot-lg-80 {
    margin-bottom: 80px !important; }
  .mar-lef-lg-80 {
    margin-left: 80px !important; }
  .mar-top-neg-lg-80 {
    margin-top: -80px !important; }
  .mar-rig-neg-lg-80 {
    margin-right: -80px !important; }
  .mar-bot-neg-lg-80 {
    margin-bottom: -80px !important; }
  .mar-lef-neg-lg-80 {
    margin-left: -80px !important; }
  .pad-lg-80 {
    padding: 80px !important; }
  .pad-top-lg-80 {
    padding-top: 80px !important; }
  .pad-rig-lg-80 {
    padding-right: 80px !important; }
  .pad-bot-lg-80 {
    padding-bottom: 80px !important; }
  .pad-lef-lg-80 {
    padding-left: 80px !important; }
  .mar-lg-85 {
    margin: 85px !important; }
  .mar-top-lg-85 {
    margin-top: 85px !important; }
  .mar-rig-lg-85 {
    margin-right: 85px !important; }
  .mar-bot-lg-85 {
    margin-bottom: 85px !important; }
  .mar-lef-lg-85 {
    margin-left: 85px !important; }
  .mar-top-neg-lg-85 {
    margin-top: -85px !important; }
  .mar-rig-neg-lg-85 {
    margin-right: -85px !important; }
  .mar-bot-neg-lg-85 {
    margin-bottom: -85px !important; }
  .mar-lef-neg-lg-85 {
    margin-left: -85px !important; }
  .pad-lg-85 {
    padding: 85px !important; }
  .pad-top-lg-85 {
    padding-top: 85px !important; }
  .pad-rig-lg-85 {
    padding-right: 85px !important; }
  .pad-bot-lg-85 {
    padding-bottom: 85px !important; }
  .pad-lef-lg-85 {
    padding-left: 85px !important; }
  .mar-lg-90 {
    margin: 90px !important; }
  .mar-top-lg-90 {
    margin-top: 90px !important; }
  .mar-rig-lg-90 {
    margin-right: 90px !important; }
  .mar-bot-lg-90 {
    margin-bottom: 90px !important; }
  .mar-lef-lg-90 {
    margin-left: 90px !important; }
  .mar-top-neg-lg-90 {
    margin-top: -90px !important; }
  .mar-rig-neg-lg-90 {
    margin-right: -90px !important; }
  .mar-bot-neg-lg-90 {
    margin-bottom: -90px !important; }
  .mar-lef-neg-lg-90 {
    margin-left: -90px !important; }
  .pad-lg-90 {
    padding: 90px !important; }
  .pad-top-lg-90 {
    padding-top: 90px !important; }
  .pad-rig-lg-90 {
    padding-right: 90px !important; }
  .pad-bot-lg-90 {
    padding-bottom: 90px !important; }
  .pad-lef-lg-90 {
    padding-left: 90px !important; }
  .mar-lg-95 {
    margin: 95px !important; }
  .mar-top-lg-95 {
    margin-top: 95px !important; }
  .mar-rig-lg-95 {
    margin-right: 95px !important; }
  .mar-bot-lg-95 {
    margin-bottom: 95px !important; }
  .mar-lef-lg-95 {
    margin-left: 95px !important; }
  .mar-top-neg-lg-95 {
    margin-top: -95px !important; }
  .mar-rig-neg-lg-95 {
    margin-right: -95px !important; }
  .mar-bot-neg-lg-95 {
    margin-bottom: -95px !important; }
  .mar-lef-neg-lg-95 {
    margin-left: -95px !important; }
  .pad-lg-95 {
    padding: 95px !important; }
  .pad-top-lg-95 {
    padding-top: 95px !important; }
  .pad-rig-lg-95 {
    padding-right: 95px !important; }
  .pad-bot-lg-95 {
    padding-bottom: 95px !important; }
  .pad-lef-lg-95 {
    padding-left: 95px !important; }
  .mar-lg-100 {
    margin: 100px !important; }
  .mar-top-lg-100 {
    margin-top: 100px !important; }
  .mar-rig-lg-100 {
    margin-right: 100px !important; }
  .mar-bot-lg-100 {
    margin-bottom: 100px !important; }
  .mar-lef-lg-100 {
    margin-left: 100px !important; }
  .mar-top-neg-lg-100 {
    margin-top: -100px !important; }
  .mar-rig-neg-lg-100 {
    margin-right: -100px !important; }
  .mar-bot-neg-lg-100 {
    margin-bottom: -100px !important; }
  .mar-lef-neg-lg-100 {
    margin-left: -100px !important; }
  .pad-lg-100 {
    padding: 100px !important; }
  .pad-top-lg-100 {
    padding-top: 100px !important; }
  .pad-rig-lg-100 {
    padding-right: 100px !important; }
  .pad-bot-lg-100 {
    padding-bottom: 100px !important; }
  .pad-lef-lg-100 {
    padding-left: 100px !important; }
  .line-height-lg-0 {
    line-height: 0px !important; }
  .line-height-lg-1 {
    line-height: 1px !important; }
  .line-height-lg-2 {
    line-height: 2px !important; }
  .line-height-lg-3 {
    line-height: 3px !important; }
  .line-height-lg-4 {
    line-height: 4px !important; }
  .line-height-lg-5 {
    line-height: 5px !important; }
  .line-height-lg-6 {
    line-height: 6px !important; }
  .line-height-lg-7 {
    line-height: 7px !important; }
  .line-height-lg-8 {
    line-height: 8px !important; }
  .line-height-lg-9 {
    line-height: 9px !important; }
  .line-height-lg-10 {
    line-height: 10px !important; }
  .line-height-lg-11 {
    line-height: 11px !important; }
  .line-height-lg-12 {
    line-height: 12px !important; }
  .line-height-lg-13 {
    line-height: 13px !important; }
  .line-height-lg-14 {
    line-height: 14px !important; }
  .line-height-lg-15 {
    line-height: 15px !important; }
  .line-height-lg-16 {
    line-height: 16px !important; }
  .line-height-lg-17 {
    line-height: 17px !important; }
  .line-height-lg-18 {
    line-height: 18px !important; }
  .line-height-lg-19 {
    line-height: 19px !important; }
  .line-height-lg-20 {
    line-height: 20px !important; }
  .line-height-lg-21 {
    line-height: 21px !important; }
  .line-height-lg-22 {
    line-height: 22px !important; }
  .line-height-lg-23 {
    line-height: 23px !important; }
  .line-height-lg-24 {
    line-height: 24px !important; }
  .line-height-lg-25 {
    line-height: 25px !important; }
  .line-height-lg-26 {
    line-height: 26px !important; }
  .line-height-lg-27 {
    line-height: 27px !important; }
  .line-height-lg-28 {
    line-height: 28px !important; }
  .line-height-lg-29 {
    line-height: 29px !important; }
  .line-height-lg-30 {
    line-height: 30px !important; }
  .line-height-lg-31 {
    line-height: 31px !important; }
  .line-height-lg-32 {
    line-height: 32px !important; }
  .line-height-lg-33 {
    line-height: 33px !important; }
  .line-height-lg-34 {
    line-height: 34px !important; }
  .line-height-lg-35 {
    line-height: 35px !important; }
  .line-height-lg-36 {
    line-height: 36px !important; }
  .line-height-lg-37 {
    line-height: 37px !important; }
  .line-height-lg-38 {
    line-height: 38px !important; }
  .line-height-lg-39 {
    line-height: 39px !important; }
  .line-height-lg-40 {
    line-height: 40px !important; }
  .line-height-lg-41 {
    line-height: 41px !important; }
  .line-height-lg-42 {
    line-height: 42px !important; }
  .line-height-lg-43 {
    line-height: 43px !important; }
  .line-height-lg-44 {
    line-height: 44px !important; }
  .line-height-lg-45 {
    line-height: 45px !important; }
  .line-height-lg-46 {
    line-height: 46px !important; }
  .line-height-lg-47 {
    line-height: 47px !important; }
  .line-height-lg-48 {
    line-height: 48px !important; }
  .line-height-lg-49 {
    line-height: 49px !important; }
  .line-height-lg-50 {
    line-height: 50px !important; }
  .line-height-lg-51 {
    line-height: 51px !important; }
  .line-height-lg-52 {
    line-height: 52px !important; }
  .line-height-lg-53 {
    line-height: 53px !important; }
  .line-height-lg-54 {
    line-height: 54px !important; }
  .line-height-lg-55 {
    line-height: 55px !important; }
  .line-height-lg-56 {
    line-height: 56px !important; }
  .line-height-lg-57 {
    line-height: 57px !important; }
  .line-height-lg-58 {
    line-height: 58px !important; }
  .line-height-lg-59 {
    line-height: 59px !important; }
  .line-height-lg-60 {
    line-height: 60px !important; }
  .dis-block-lg {
    display: block !important; }
  .dis-none-lg {
    display: none !important; }
  .dis-inline-lg {
    display: inline !important; }
  .dis-inline-block-lg {
    display: inline-block !important; }
  .text-cen-lg {
    text-align: center !important; }
  .text-lef-lg {
    text-align: left !important; }
  .text-rig-lg {
    text-align: right !important; }
  .text-jus-lg {
    text-align: justify !important; }
  .pos-re-lg {
    position: relative !important; }
  .pos-ab-lg {
    position: absolute !important; }
  .pos-st-lg {
    position: static !important; } }

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@keyframes spinAround {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes toLeftFromRight {
  0% {
    opacity: 0; }
  49% {
    -webkit-transform: translate(0%); }
  50% {
    opacity: 0;
    -webkit-transform: translate(100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes toLeftFromRight {
  0% {
    opacity: 0; }
  49% {
    -moz-transform: translate(0%); }
  50% {
    opacity: 0;
    -moz-transform: translate(100%); }
  51% {
    opacity: 1; } }

@keyframes toLeftFromRight {
  0% {
    opacity: 0; }
  49% {
    transform: translate(0%); }
  50% {
    opacity: 0;
    transform: translate(100%); }
  51% {
    opacity: 1; } }

@-webkit-keyframes toRightFromLeft {
  0% {
    opacity: 0; }
  49% {
    -webkit-transform: translate(0%); }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes toRightFromLeft {
  0% {
    opacity: 0; }
  49% {
    -moz-transform: translate(0%); }
  50% {
    opacity: 0;
    -moz-transform: translate(-100%); }
  51% {
    opacity: 1; } }

@keyframes toRightFromLeft {
  0% {
    opacity: 0; }
  49% {
    transform: translate(0%); }
  50% {
    opacity: 0;
    transform: translate(-100%); }
  51% {
    opacity: 1; } }

@-webkit-keyframes toTopFromBottom {
  0% {
    opacity: 0; }
  49% {
    -webkit-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes toTopFromBottom {
  0% {
    opacity: 0; }
  49% {
    -moz-transform: translateY(-100%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(100%); }
  51% {
    opacity: 1; } }

@keyframes toTopFromBottom {
  0% {
    opacity: 0; }
  49% {
    transform: translateY(-100%); }
  50% {
    opacity: 0;
    transform: translateY(100%); }
  51% {
    opacity: 1; } }

@keyframes toBottomFromTop {
  0% {
    opacity: 0; }
  49% {
    transform: translateY(200%); }
  50% {
    opacity: 0;
    transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@-webkit-keyframes toBottomFromTop {
  0% {
    opacity: 0; }
  49% {
    -webkit-transform: translateY(200%); }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@-moz-keyframes toBottomFromTop {
  0% {
    opacity: 0; }
  49% {
    -moz-transform: translateY(200%); }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%); }
  51% {
    opacity: 1; } }

@keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/*@-webkit-viewport { width: device-width; } @-moz-viewport { width: device-width; } @-ms-viewport { width: device-width; } @-o-viewport { width: device-width; } @viewport { width: device-width; }*/
.page-section {
  width: 100%;
  float: left; }

*[data-animation="toLeftFromRight"] {
  -o-animation: toLeftFromRight 0.9s forwards;
  -webkit-animation: toLeftFromRight 0.9s forwards;
  -moz-animation: toLeftFromRight 0.9s forwards;
  -ms-animation: toLeftFromRight 0.9s forwards;
  animation: toLeftFromRight 0.9s forwards; }

*[data-animation="toRightFromLeft"] {
  -o-animation: toRightFromLeft 0.9s forwards;
  -webkit-animation: toRightFromLeft 0.9s forwards;
  -moz-animation: toRightFromLeft 0.9s forwards;
  -ms-animation: toRightFromLeft 0.9s forwards;
  animation: toRightFromLeft 0.9s forwards; }

*[data-animation="toTopFromBottom"] {
  -o-animation: toTopFromBottom 0.9s forwards;
  -webkit-animation: toTopFromBottom 0.9s forwards;
  -moz-animation: toTopFromBottom 0.9s forwards;
  -ms-animation: toTopFromBottom 0.9s forwards;
  animation: toTopFromBottom 0.9s forwards; }

*[data-animation="toBottomFromTop"] {
  -o-animation: toBottomFromTop 0.9s forwards;
  -webkit-animation: toBottomFromTop 0.9s forwards;
  -moz-animation: toBottomFromTop 0.9s forwards;
  -ms-animation: toBottomFromTop 0.9s forwards;
  animation: toBottomFromTop 0.9s forwards; }

.width-auto {
  width: auto !important; }

.middle-auto {
  margin-left: auto;
  margin-right: auto; }

*:focus, a:focus, a:hover {
  outline: 0 !important;
  text-decoration: none; }

.w-normal {
  font-weight: 400; }

.w-bold {
  font-weight: 700; }

.w-lighter {
  font-weight: 300; }

.underline {
  text-decoration: underline; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

.no-border {
  border: 0 none !important; }

.no-radius {
  border-radius: 0 !important; }

/*PUBLIC CSS*/
/* public padding css */
.tooltip {
  white-space: nowrap;
  z-index: 999; }

.top-menu-wrapper {
  height: 70px;
  width: 100%; }
  .top-menu-wrapper .container {
    height: 70px; }
    .top-menu-wrapper .container:before {
      display: none; }
    .top-menu-wrapper .container:after {
      display: none; }

@media (min-width: 1200px) {
  .container {
    width: 1200px; }
  .box-shadow-left-right-homepage {
    box-shadow: 7px 12px 9px -7px rgba(0, 0, 0, 0.2), -9px 15px 9px -7px rgba(0, 0, 0, 0.2); }
  .top-menu-wrapper .container, .al-footer .container {
    padding-left: 30px;
    padding-right: 30px; }
  .box-main-content-page .container {
    background-color: #fff; }
    .box-main-content-page .container.dis-flex {
      display: flex;
      flex-wrap: wrap; }
      .box-main-content-page .container.dis-flex .main-content-video {
        width: calc(100% - 281px); } }

/*@media (min-width: 768px) {
  .page-top, .top-menu-wrapper, .al-footer {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .box-main-content-page {
    .container {
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
    }
  }
}*/
/*.safari {
  .header-about, .header-page, .header-videos {
    margin-top: -5px;
  }
}*/
.box-shadow-left-right {
  box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
  padding: 60px; }

@media only screen and (max-width: 1200px) {
  .box-shadow-left-right {
    box-shadow: none;
    padding: 40px 20px; } }

.modal-dialog {
  margin: 15px !important; }
  @media only screen and (max-width: 413px) {
    .modal-dialog {
      margin: 0 auto !important; } }

#quiz .header-page .title-header-page h1:before {
  background-image: url("../../assets/pictures/icon-quiz.png"); }

.header-page {
  background-image: url("../../assets/img/theme/background-title.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*height: 176px;*/
  /*@media only screen and (max-width: 1023px) {
    height: 154px;
  }*/ }
  .header-page .title-header-page {
    padding: 65px 0;
    text-align: center; }
    .header-page .title-header-page h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center; }
      @media (max-width: 767px) {
        .header-page .title-header-page h1 {
          font-size: 22px; } }
      .header-page .title-header-page h1:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("../../assets/pictures/icon-about-pain.png");
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px; }
  .header-page .title-terms h1:before {
    height: 0;
    width: 0; }

.box-main-content-page {
  /*background-color: #fff;*/
  color: #6b6b6b; }
  .box-main-content-page .container {
    background-color: #fff; }
    .box-main-content-page .container.container-flex {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2); }
      .box-main-content-page .container.container-flex aside {
        width: 280px; }
        @media only screen and (min-width: 768px) {
          .box-main-content-page .container.container-flex aside {
            position: relative;
            z-index: 1;
            top: 0; } }
      .box-main-content-page .container.container-flex article {
        width: calc(100% - 281px); }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .box-main-content-page .container.container-flex {
          padding-left: 0;
          padding-right: 0; }
          .box-main-content-page .container.container-flex aside {
            width: 280px; }
          .box-main-content-page .container.container-flex article {
            width: calc(100% - 281px); } }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .box-main-content-page .container.container-flex {
          margin-left: 0;
          margin-right: 0;
          width: 100%; }
          .box-main-content-page .container.container-flex aside {
            width: 220px; }
          .box-main-content-page .container.container-flex article {
            width: calc(100% - 221px); } }
      @media only screen and (max-width: 767px) {
        .box-main-content-page .container.container-flex {
          margin-left: 0;
          margin-right: 0;
          width: 100%; }
          .box-main-content-page .container.container-flex aside {
            width: 100%;
            background-color: #bed74b; }
          .box-main-content-page .container.container-flex article {
            width: 100%; } }
  @media only screen and (max-width: 1023px) {
    .box-main-content-page .container {
      padding: 0;
      box-shadow: none; } }
  .box-main-content-page .left-aside {
    float: left;
    width: 280px;
    background-color: #f2f3f3; }
    .box-main-content-page .left-aside h4 {
      display: none; }
    .box-main-content-page .left-aside .aside-main-menu {
      padding: 0;
      display: block;
      margin-bottom: 0; }
      @media only screen and (min-width: 768px) {
        .box-main-content-page .left-aside .aside-main-menu {
          display: block !important; } }
      .box-main-content-page .left-aside .aside-main-menu > li {
        border-bottom: 1px solid #ffffff; }
        .box-main-content-page .left-aside .aside-main-menu > li.tab-custom-menu-item.last-child {
          margin-bottom: 30px; }
      .box-main-content-page .left-aside .aside-main-menu li {
        position: relative;
        cursor: pointer;
        background-color: #f2f3f3;
        display: table;
        width: 100%; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub:hover {
          background-color: #ebebeb;
          color: #282e34;
          /*.arrow {
              border-right: 1px solid #FFF;
              border-top: 1px solid #FFF;
            }*/ }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub.selected .arrow {
          border-right: 1px solid #FFF;
          border-top: 1px solid #FFF; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub.toggle {
          padding-bottom: 0;
          background-color: #bbbdbf; }
          .box-main-content-page .left-aside .aside-main-menu li.has-sub.toggle a {
            color: #ffffff; }
            .box-main-content-page .left-aside .aside-main-menu li.has-sub.toggle a span {
              -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
            .box-main-content-page .left-aside .aside-main-menu li.has-sub.toggle a:hover {
              background-color: transparent;
              color: #fff; }
          .box-main-content-page .left-aside .aside-main-menu li.has-sub.toggle .arrow {
            border-right: 1px solid #ffffff;
            border-top: 1px solid #ffffff; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub .arrow {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #83878a;
          border-top: 1px solid #83878a;
          -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
          margin-left: 10px;
          cursor: pointer;
          position: absolute;
          right: 15px;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          top: 30px;
          left: initial;
          background-image: none; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub.tab-custom-menu-item-dropdown .arrow {
          -webkit-transform: rotate(135deg) !important;
          transform: rotate(135deg) !important;
          top: 25px; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub .drop-menu-sub {
          padding-left: 0;
          display: none; }
          .box-main-content-page .left-aside .aside-main-menu li.has-sub .drop-menu-sub li {
            border-top: 1px solid #ffffff; }
            .box-main-content-page .left-aside .aside-main-menu li.has-sub .drop-menu-sub li:last-child {
              border-bottom: 1px solid #bbbdbf; }
            .box-main-content-page .left-aside .aside-main-menu li.has-sub .drop-menu-sub li a {
              color: #FFF; }
              .box-main-content-page .left-aside .aside-main-menu li.has-sub .drop-menu-sub li a:hover {
                background-color: #7b7d83;
                /*span {
                    filter: brightness(0) invert(1);
                    -o-filter: brightness(0) invert(1);
                    -moz-filter: brightness(0) invert(1);
                    -webkit-filter: brightness(0) invert(1);
                  }*/ }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub a {
          color: #282e34; }
          .box-main-content-page .left-aside .aside-main-menu li.has-sub a:hover {
            background-color: #ebebeb;
            /*span {
                filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1);
              }*/ }
          .box-main-content-page .left-aside .aside-main-menu li.has-sub a.active {
            background-color: #bbbdbf;
            color: #282e34; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub.active:after {
          background-color: transparent; }
        .box-main-content-page .left-aside .aside-main-menu li.has-sub.active .arrow {
          -webkit-transform: translateY(-50%) rotate(135deg);
          transform: translateY(-50%) rotate(135deg); }
        .box-main-content-page .left-aside .aside-main-menu li a {
          padding-left: 60px;
          top: 50%;
          padding-right: 25px;
          position: relative;
          font-size: 14px;
          font-family: "Lato", Arial;
          color: #282e34;
          height: 60px;
          display: table-cell;
          vertical-align: middle;
          width: 100%; }
          .box-main-content-page .left-aside .aside-main-menu li a span {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            content: "";
            background-image: url("../../assets/img/app/menu-icons/library.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            width: 25px;
            height: 25px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease; }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              .box-main-content-page .left-aside .aside-main-menu li a span {
                margin-top: 20px; } }
          .box-main-content-page .left-aside .aside-main-menu li a:hover {
            background-color: #ebebeb;
            /*span {
              filter: brightness(0) invert(1);
              -o-filter: brightness(0) invert(1);
              -moz-filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            }*/ }
        .box-main-content-page .left-aside .aside-main-menu li:hover {
          background-color: #e8e9e9; }
      .box-main-content-page .left-aside .aside-main-menu > li.active {
        /*background-color: #e8e9e9;*/ }
        .box-main-content-page .left-aside .aside-main-menu > li.active .drop-menu-sub {
          display: block; }
  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    .box-main-content-page .left-aside {
      width: 220px; }
    .box-main-content-page .container {
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    .box-main-content-page .container {
      padding: 0; }
    .box-main-content-page .left-aside {
      float: none;
      width: 100%; }
      .box-main-content-page .left-aside.fix-menu {
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 999; }
      .box-main-content-page .left-aside h4 {
        display: block;
        padding: 15px;
        background-color: #bed74b;
        color: #ffffff;
        font-size: 16px;
        position: relative;
        margin: 0;
        z-index: 5; }
        .box-main-content-page .left-aside h4 .arrow-drop-menu-page {
          cursor: pointer;
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid #ffffff; }
      .box-main-content-page .left-aside .aside-main-menu {
        position: absolute;
        width: 100%;
        height: 0;
        top: 47px;
        left: 0;
        overflow-y: scroll;
        display: none;
        padding-bottom: 20px;
        background-color: #f2f3f3;
        -webkit-overflow-scrolling: touch; }
        .box-main-content-page .left-aside .aside-main-menu::-webkit-scrollbar {
          display: none; } }
  .box-main-content-page .main-content-article {
    padding: 60px; }
    .box-main-content-page .main-content-article .box-content-page .box-title-page {
      padding-bottom: 30px; }
      @media (max-width: 991px) {
        .box-main-content-page .main-content-article .box-content-page .box-title-page {
          padding-bottom: 20px; } }
      .box-main-content-page .main-content-article .box-content-page .box-title-page .box-social {
        text-align: right; }
        @media (max-width: 1024px) {
          .box-main-content-page .main-content-article .box-content-page .box-title-page .box-social {
            width: 100%;
            text-align: left;
            margin-top: 15px; } }
      .box-main-content-page .main-content-article .box-content-page .box-title-page .box-title h2 {
        font-size: 28px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        color: #177bbe; }
        .box-main-content-page .main-content-article .box-content-page .box-title-page .box-title h2 a {
          font-size: 32px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: #177bbe; }
      @media (max-width: 1024px) {
        .box-main-content-page .main-content-article .box-content-page .box-title-page .box-title {
          width: 100%;
          text-align: left; } }
      @media only screen and (max-width: 767px) {
        .box-main-content-page .main-content-article .box-content-page .box-title-page .box-social {
          text-align: left; } }
    .box-main-content-page .main-content-article .box-content-page .content-page {
      margin-bottom: 50px; }
      .box-main-content-page .main-content-article .box-content-page .content-page.logo .list-sub-image img {
        display: inline-block;
        height: 40px; }
      .box-main-content-page .main-content-article .box-content-page .content-page p, .box-main-content-page .main-content-article .box-content-page .content-page span {
        font-size: 16px;
        font-family: "Lato", Arial;
        color: #6a6e72; }
        .box-main-content-page .main-content-article .box-content-page .content-page p a, .box-main-content-page .main-content-article .box-content-page .content-page span a {
          color: #177bbe; }
          .box-main-content-page .main-content-article .box-content-page .content-page p a:hover, .box-main-content-page .main-content-article .box-content-page .content-page span a:hover {
            text-decoration: underline !important; }
      .box-main-content-page .main-content-article .box-content-page .content-page h4 {
        font-size: 20px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        color: #292f35; }
    .box-main-content-page .main-content-article .box-content-page .box-feedback {
      border-bottom: 2px solid #e3e2e2; }
      .box-main-content-page .main-content-article .box-content-page .box-feedback h5 {
        padding: 25px 0;
        margin-bottom: 0;
        color: #bdbebf;
        font-size: 15px;
        font-family: "PT Sans", sans-serif;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 3px; }
    .box-main-content-page .main-content-article .box-content-page .box-helpful {
      border-bottom: 2px solid #e3e2e2; }
      .box-main-content-page .main-content-article .box-content-page .box-helpful h5 {
        color: #808385;
        font-size: 16px;
        font-family: "Lato", Arial;
        font-weight: bold;
        padding: 35px 0;
        margin-bottom: 0;
        display: inline-block; }
      .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-yes, .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-no {
        padding: 8px 35px;
        background-color: transparent;
        border: 1px solid #177bbe;
        font-size: 14px;
        font-family: "Lato", Arial;
        font-weight: bold;
        color: #177bbe;
        margin-left: 30px;
        margin-right: 0; }
        .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-yes:hover, .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-no:hover {
          background-color: #177bbe;
          color: #ffffff; }
      @media only screen and (max-width: 767px) {
        .box-main-content-page .main-content-article .box-content-page .box-helpful h5 {
          width: 100%;
          padding: 30px 0; }
        .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-yes, .box-main-content-page .main-content-article .box-content-page .box-helpful .btn-no {
          margin-left: 0;
          margin-right: 30px;
          margin-bottom: 30px; } }
    .box-main-content-page .main-content-article .box-content-page .box-socical-like {
      max-width: 192px;
      display: inline-block; }
      .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share {
        float: left;
        margin-right: 35px; }
        .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul {
          padding: 0; }
          .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li {
            display: inline-block; }
            .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li a {
              color: #ffffff;
              display: block;
              /*background-color: rgba(180, 182, 184, .8);*/
              background-color: #b4b6b8;
              margin-right: 10px;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              position: relative;
              font-size: 20px;
              transition: 1s;
              padding: 0; }
              .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li a.facebook:hover {
                background-color: #3b5998; }
              .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li a.twitter:hover {
                background-color: #55acee; }
              .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li a.envelope:hover {
                background-color: #282e34; }
              .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li a i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%); }
            .box-main-content-page .main-content-article .box-content-page .box-socical-like .socical-share ul li:last-child a {
              font-size: 16px; }
      .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like {
        float: right; }
        .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like .tooltip {
          white-space: nowrap; }
        .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like span {
          cursor: pointer;
          height: 30px;
          width: 30px;
          display: inline-block;
          background-image: url("../../assets/img/app/video-img/heart.png");
          background-size: 30px 30px;
          background-position: center center;
          background-repeat: no-repeat no-repeat;
          -webkit-transition: all .8s;
          transition: all .8s;
          -moz-transition: all .7s; }
          .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like span:hover, .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like span.active {
            background-image: url("../../assets/img/app/video-img/heart-active.png");
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s; }
        .box-main-content-page .main-content-article .box-content-page .box-socical-like .box-like.active span {
          background-image: url("../../assets/img/app/video-img/heart-active.png");
          -webkit-transition: all .8s;
          transition: all .8s;
          -moz-transition: all .7s; }
    @media (max-width: 1023px) {
      .box-main-content-page .main-content-article .box-content-page .box-title-page .box-social {
        padding-right: 15px; } }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      .box-main-content-page .main-content-article {
        padding: 30px; } }
    @media only screen and (max-width: 480px) {
      .box-main-content-page .main-content-article {
        padding: 15px; } }

#modal_form_login_ajax {
  z-index: 99999; }

#modal_favourite {
  z-index: 99999; }
  #modal_favourite .modal-dialog {
    width: 400px; }
    @media (max-width: 420px) {
      #modal_favourite .modal-dialog {
        width: 92%; } }
    #modal_favourite .modal-dialog .modal-favourite .modal-header {
      padding-bottom: 0; }
    #modal_favourite .modal-dialog .modal-favourite .modal-body {
      padding: 0 50px 50px 50px; }
      @media (max-width: 767px) {
        #modal_favourite .modal-dialog .modal-favourite .modal-body {
          padding: 0 20px 20px 20px; } }
      #modal_favourite .modal-dialog .modal-favourite .modal-body .main-content h2 {
        color: #177bbe;
        font-size: 32px;
        font-family: "PT Sans";
        font-weight: bold; }
      #modal_favourite .modal-dialog .modal-favourite .modal-body .main-content p {
        color: #6a6e72;
        font-size: 16px;
        font-weight: bold;
        padding: 20px 0;
        border-bottom: 1px solid #f9f9f9; }
      #modal_favourite .modal-dialog .modal-favourite .modal-body .button-auth .option-auth p {
        text-align: center;
        margin-top: 10px; }
        #modal_favourite .modal-dialog .modal-favourite .modal-body .button-auth .option-auth p a {
          color: #929497; }

#message_comment-error {
  color: #ff0000;
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: -10px;
  margin-bottom: 15px; }
  #message_comment-error:before {
    content: "\00d7";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    font-weight: normal; }

#dashboard .box-main-content-page .container {
  padding: 0;
  box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2); }

@media (max-width: 1200px) and (min-width: 320px) {
  .container {
    width: 100%; } }

#page_question .modal-dialog {
  width: 500px; }
  #page_question .modal-dialog .modal-signup-success .modal-body {
    padding: 50px; }
    #page_question .modal-dialog .modal-signup-success .modal-body .content-signup-success h2 {
      color: #177bbe;
      font-size: 32px;
      font-family: "PT Sans";
      font-weight: bold; }
    #page_question .modal-dialog .modal-signup-success .modal-body .content-signup-success p {
      font-size: 16px;
      font-family: "Lato";
      font-weight: normal;
      padding-top: 20px;
      padding-bottom: 30px; }

#page_question .header-guide-me .title-header-page h1:before {
  background-image: url("../../assets/pictures/icon-faq.png"); }

#page_question .main-content-question {
  padding-top: 60px; }
  #page_question .main-content-question .box-content-question {
    padding-bottom: 30px; }
  #page_question .main-content-question .box-question h2 {
    font-size: 28px;
    font-family: "PT Sans";
    font-weight: bold;
    color: #177bbe;
    margin-bottom: 0; }
  #page_question .main-content-question .box-question p {
    font-size: 16px;
    font-family: "Lato";
    font-weight: bold;
    color: #292f35;
    padding: 30px 0; }
  #page_question .main-content-question .box-question .question .item-question {
    padding: 20px 0;
    position: relative;
    border-top: 1px solid #f4f4f5;
    display: none; }
    #page_question .main-content-question .box-question .question .item-question#question_item_8_1 {
      padding-bottom: 30px; }
      #page_question .main-content-question .box-question .question .item-question#question_item_8_1 .box-radio {
        position: relative;
        padding-bottom: 35px; }
        #page_question .main-content-question .box-question .question .item-question#question_item_8_1 .box-radio span.error {
          bottom: 10px;
          left: 0px; }
      #page_question .main-content-question .box-question .question .item-question#question_item_8_1 .box-time-frame span.error {
        bottom: -20px;
        left: 15px; }
    #page_question .main-content-question .box-question .question .item-question#question_item_8_1_0 {
      padding-bottom: 30px; }
      #page_question .main-content-question .box-question .question .item-question#question_item_8_1_0 span.error {
        bottom: -20px;
        left: 15px; }
    #page_question .main-content-question .box-question .question .item-question span.error {
      position: absolute;
      bottom: 10px;
      left: 0;
      color: #f00;
      padding-left: 25px;
      font-weight: normal;
      font-size: 15px; }
      #page_question .main-content-question .box-question .question .item-question span.error .for-default {
        display: block; }
      #page_question .main-content-question .box-question .question .item-question span.error .for-account, #page_question .main-content-question .box-question .question .item-question span.error .for-myaccount {
        display: none; }
      #page_question .main-content-question .box-question .question .item-question span.error:before {
        content: "\00d7";
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ff0000;
        color: #ffffff;
        font-size: 20px;
        line-height: 18px;
        text-align: center;
        font-weight: normal; }
    #page_question .main-content-question .box-question .question .item-question #select_error span.error {
      position: relative;
      bottom: 0;
      display: inline-block;
      white-space: nowrap; }
    #page_question .main-content-question .box-question .question .item-question h4 {
      font-size: 20px;
      font-family: "Lato";
      font-weight: bold;
      color: #292f35; }
    #page_question .main-content-question .box-question .question .item-question h5 {
      font-size: 20px;
      font-family: "PT Sans";
      color: #177bbe; }
      #page_question .main-content-question .box-question .question .item-question h5.middle-title {
        font-size: 26px;
        line-height: 1.4;
        font-weight: bold;
        color: #292f35;
        margin: 30px 0 10px 0; }
    #page_question .main-content-question .box-question .question .item-question p {
      padding: 0;
      font-weight: normal;
      font-style: italic;
      color: #6a6e72; }
    #page_question .main-content-question .box-question .question .item-question .box-input label {
      font-size: 16px;
      font-family: "Lato";
      color: #292f35;
      font-weight: normal;
      margin-bottom: 15px; }
    #page_question .main-content-question .box-question .question .item-question .box-input input {
      border-radius: 0;
      height: 50px; }
    #page_question .main-content-question .box-question .question .item-question .box-time h6, #page_question .main-content-question .box-question .question .item-question .box-select h6 {
      font-size: 16px;
      font-family: "Lato";
      font-weight: bold;
      color: #292f35; }
    #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input {
      padding: 10px;
      border-radius: 0px;
      height: 41px; }
      #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input.dropdown-active, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input.dropdown-active {
        padding-top: 9px; }
      #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input input, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input input {
        font-size: 16px;
        font-family: "Lato"; }
        #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input input::-webkit-input-placeholder, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          margin-top: -1px; }
        #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input input::-moz-placeholder, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input input::-moz-placeholder {
          /* Firefox 19+ */
          margin-top: -1px; }
        #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input input:-ms-input-placeholder, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input input:-ms-input-placeholder {
          /* IE 10+ */
          margin-top: -1px; }
        #page_question .main-content-question .box-question .question .item-question .box-time .selectize-input input:-moz-placeholder, #page_question .main-content-question .box-question .question .item-question .box-select .selectize-input input:-moz-placeholder {
          /* Firefox 18- */
          margin-top: -1px; }
    #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-checkbox > span:hover:before, #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-radio > span:hover:before, #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-radio > span:hover:before {
      border-color: #177bbe; }
    #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-checkbox span, #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-radio span {
      color: #6a6e72; }
      #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-checkbox span a, #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-radio span a {
        color: #6a6e72;
        text-decoration: underline !important; }
    #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-checkbox #closeButton:checked ~ span:before, #page_question .main-content-question .box-question .question .item-question .box-checkbox label.custom-radio #closeButton:checked ~ span:before {
      border-color: #177bbe;
      color: #177bbe; }
    #page_question .main-content-question .box-question .question .item-question .box-time-frame h6 {
      font-size: 16px;
      font-family: "Lato";
      color: #292f35;
      font-weight: normal;
      margin-bottom: 25px; }
    #page_question .main-content-question .box-question .question .item-question .box-radio {
      padding: 20px 0; }
      #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio {
        margin-right: 10px;
        position: static; }
        #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio input[type=radio]:checked ~ span:before {
          content: '';
          border-color: transparent;
          background-image: url("../../assets/img/check.png"); }
        #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio input[type=radio]:checked ~ label ~ span:before {
          content: '';
          border-color: transparent;
          background-image: url("../../assets/img/check.png"); }
        #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio > span {
          color: #6a6e72;
          font-family: "Lato";
          font-size: 16px;
          font-weight: normal; }
          #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio > span:before {
            color: #177bbe;
            border-color: #939598; }
      @media (max-width: 767px) {
        #page_question .main-content-question .box-question .question .item-question .box-radio label.custom-radio {
          width: 100%;
          padding: 10px 0; }
        #page_question .main-content-question .box-question .question .item-question .box-radio label.radio-inline + .radio-inline {
          margin-left: 0; } }
    #page_question .main-content-question .box-question .question .item-question .box-slider {
      margin: 0 20px 0 20px;
      padding: 0 0 50px; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider-input {
        opacity: 0;
        top: 0;
        position: absolute; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom {
        background-color: #D1D3D4;
        height: 2px;
        margin: 40px 0;
        border: none; }
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-label {
          white-space: nowrap;
          padding-top: 30px; }
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-pip-first .ui-slider-label {
          width: 6em;
          text-align: left;
          padding-left: 5px; }
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-handle {
          width: 40px;
          height: 40px;
          background-color: #D1D3D4;
          color: #D1D3D4;
          text-align: center;
          padding-top: 7px;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          margin-left: -17px;
          top: -20px;
          border: 2px solid #fff;
          -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3); }
          #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-handle:active, #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-handle:focus, #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-handle.selected {
            background-color: #177bbe;
            color: #ffffff; }
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-pip {
          color: #6a6e72;
          font-family: "Lato";
          font-size: 14px;
          font-weight: normal; }
          #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-pip .ui-slider-line {
            background: #D1D3D4;
            width: 6px;
            height: 6px;
            position: absolute;
            left: 48%;
            top: -22px;
            border-radius: 50%; }
            #page_question .main-content-question .box-question .question .item-question .box-slider .slider-custom .ui-slider-pip .ui-slider-line:before {
              content: "";
              height: 40px;
              width: 70px;
              position: absolute;
              top: -17px;
              left: -30px; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .ui-slider-pips:not(.ui-slider-disabled) .ui-slider-pip:hover .ui-slider-label {
        color: #6a6e72;
        font-weight: normal; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .ui-slider-pips [class*=ui-slider-pip-selected], #page_question .main-content-question .box-question .question .item-question .box-slider .ui-slider-pips [class*=ui-slider-pip-initial] {
        color: #6a6e72;
        font-weight: normal; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_1 .ui-slider-label {
        white-space: nowrap; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_1 .ui-slider-pip-first .ui-slider-label {
        width: 6em;
        text-align: left;
        padding-left: 5px; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_1 .ui-slider-pip-last .ui-slider-label {
        margin-left: -4em; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_2 .ui-slider-pip-last .ui-slider-label {
        margin-left: -5em; }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_3 .ui-slider-pip-last .ui-slider-label {
        margin-left: -7em; }
        @media (max-width: 767px) {
          #page_question .main-content-question .box-question .question .item-question .box-slider .slider_3 .ui-slider-pip-last .ui-slider-label {
            margin-left: -4em; } }
      @media (max-width: 767px) {
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider_3 .ui-slider-label {
          white-space: normal; } }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_4 .ui-slider-label {
        margin-left: -4em; }
      @media (max-width: 767px) {
        #page_question .main-content-question .box-question .question .item-question .box-slider .slider_4 .ui-slider-label {
          white-space: normal;
          width: 3.5em !important;
          margin-left: -1em; } }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_4 .ui-slider-pip-last .ui-slider-label {
        margin-left: -4em; }
        @media (max-width: 767px) {
          #page_question .main-content-question .box-question .question .item-question .box-slider .slider_4 .ui-slider-pip-last .ui-slider-label {
            margin-left: -2em; } }
      #page_question .main-content-question .box-question .question .item-question .box-slider .slider_4 .ui-slider-pip-first .ui-slider-label {
        margin-left: -1em; }
  #page_question .main-content-question .box-question .question .box-note {
    padding: 30px;
    background-color: #b2c946; }
    #page_question .main-content-question .box-question .question .box-note h6 {
      font-size: 18px;
      font-family: "Lato";
      font-weight: bold;
      color: #282e34; }
    #page_question .main-content-question .box-question .question .box-note p {
      margin-bottom: 0;
      padding: 0;
      padding-top: 10px;
      color: #282e34;
      font-weight: normal;
      font-style: normal; }
      #page_question .main-content-question .box-question .question .box-note p a:hover {
        text-decoration: underline; }
  #page_question .main-content-question .box-question.account .item-question label.error {
    bottom: -25px; }
    #page_question .main-content-question .box-question.account .item-question label.error .for-default {
      display: none; }
    #page_question .main-content-question .box-question.account .item-question label.error .for-account {
      display: block; }
  #page_question .main-content-question .box-question.account .item-question .form-group {
    position: relative; }
    #page_question .main-content-question .box-question.account .item-question .form-group span.error {
      bottom: -27px; }
      #page_question .main-content-question .box-question.account .item-question .form-group span.error span {
        display: none; }
        #page_question .main-content-question .box-question.account .item-question .form-group span.error span.for-account {
          display: block; }
        #page_question .main-content-question .box-question.account .item-question .form-group span.error span.for-myaccount {
          display: none; }
        #page_question .main-content-question .box-question.account .item-question .form-group span.error span.for-default {
          display: none; }
  #page_question .main-content-question .box-question.account #question_item_term_conditions {
    padding-bottom: 30px; }
    #page_question .main-content-question .box-question.account #question_item_term_conditions span.error {
      bottom: 4px; }
      #page_question .main-content-question .box-question.account #question_item_term_conditions span.error span {
        display: none; }
        #page_question .main-content-question .box-question.account #question_item_term_conditions span.error span.for-account {
          display: block; }
        #page_question .main-content-question .box-question.account #question_item_term_conditions span.error span.for-myaccount {
          display: none; }
        #page_question .main-content-question .box-question.account #question_item_term_conditions span.error span.for-default {
          display: none; }
  #page_question .main-content-question .box-continue {
    padding: 60px 0;
    background-color: #f1f2f2;
    display: none; }
  #page_question .main-content-question .box-process {
    text-align: center;
    background-color: #F2F3F3;
    padding-bottom: 30px;
    padding-top: 25px;
    display: none; }
    #page_question .main-content-question .box-process h3 {
      font-size: 28px;
      font-family: "PT Sans";
      color: #292F35; }
    #page_question .main-content-question .box-process #bluecircle {
      margin: 0 auto;
      margin-top: 20px;
      float: none;
      display: inline-block; }
      #page_question .main-content-question .box-process #bluecircle span {
        transform: translateY(-50%); }
        #page_question .main-content-question .box-process #bluecircle span h2 {
          font-size: 52px;
          font-family: "Lato";
          font-weight: bold;
          color: #177bbe;
          line-height: 0;
          display: inline-block; }
          #page_question .main-content-question .box-process #bluecircle span h2 sup {
            font-size: 20px;
            font-family: "Lato";
            font-weight: bold;
            color: #177bbe;
            opacity: .8;
            top: -20px; }
        #page_question .main-content-question .box-process #bluecircle span span {
          font-size: 12px;
          font-family: "Lato";
          font-weight: bold;
          color: #177bbe;
          display: block;
          margin-top: 15px; }
    #page_question .main-content-question .box-process #question_percircle {
      position: relative;
      height: 277px; }
      #page_question .main-content-question .box-process #question_percircle svg {
        width: 274px; }
        #page_question .main-content-question .box-process #question_percircle svg path {
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s; }
      #page_question .main-content-question .box-process #question_percircle.active svg path {
        display: block; }
      #page_question .main-content-question .box-process #question_percircle .text {
        position: absolute;
        top: 50%;
        text-align: center;
        width: 100%;
        display: block;
        margin-top: -20px; }
        #page_question .main-content-question .box-process #question_percircle .text h2 {
          font-size: 60px;
          font-family: "Lato";
          font-weight: bold;
          color: #177bbe;
          line-height: 0;
          display: inline-block; }
          #page_question .main-content-question .box-process #question_percircle .text h2 sup {
            font-size: 20px;
            font-family: "Lato";
            font-weight: bold;
            color: #177bbe;
            opacity: .8;
            top: -20px; }
        #page_question .main-content-question .box-process #question_percircle .text span {
          font-size: 12px;
          font-family: "Lato";
          font-weight: bold;
          color: #177bbe;
          display: block;
          margin-top: 15px; }

#page_question .question-progress {
  background-color: #F2F3F3;
  padding: 30px 0; }
  #page_question .question-progress .title {
    font-size: 20px;
    color: #177bbe;
    font-weight: bold;
    margin-bottom: 20px;
    height: 24px;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }
    #page_question .question-progress .title .left {
      height: 24px; }
    #page_question .question-progress .title .right {
      height: 24px; }
  #page_question .question-progress .question-progress-bar-full {
    height: 20px;
    background-color: #CCC;
    border-radius: 10px;
    position: relative; }
    #page_question .question-progress .question-progress-bar-full .question-progress-bar {
      position: absolute;
      height: 20px;
      background-color: #177bbe;
      border-radius: 10px;
      width: 0;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s; }
  #page_question .question-progress.fixedsticky {
    z-index: 3;
    top: 140px; }
    @media (max-width: 767px) {
      #page_question .question-progress.fixedsticky {
        top: 50px; } }
    #page_question .question-progress.fixedsticky .title {
      margin-bottom: 0px;
      height: 0px;
      opacity: 0;
      visibility: hidden; }
      #page_question .question-progress.fixedsticky .title .left {
        height: 0px; }
      #page_question .question-progress.fixedsticky .title .right {
        height: 0px; }

.header-quiz {
  background-image: url("../../assets/img/theme/background-title.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .header-quiz .title-header-page {
    padding: 70px 0;
    text-align: center; }
    .header-quiz .title-header-page h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      text-align: center; }
      .header-quiz .title-header-page h1:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background-image: url("../../assets/pictures/icon-quiz.png");
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px; }

.box-content-quiz {
  min-height: 400px; }

.box-main-content-search .main-content {
  padding: 50px; }
  .box-main-content-search .main-content .box-header-search h2 {
    font-size: 26px;
    font-family: "PT Sans";
    font-weight: bold;
    color: #177BBE;
    padding-bottom: 15px;
    border-bottom: 1px solid #E9EAEA;
    margin-bottom: 0; }
  .box-main-content-search .main-content .content-search .item-search {
    margin-top: 40px; }
    .box-main-content-search .main-content .content-search .item-search h3 {
      font-size: 20px;
      font-family: "PT Sans";
      font-weight: bold;
      color: #177BBE; }
      .box-main-content-search .main-content .content-search .item-search h3 a:hover {
        text-decoration: underline; }
    .box-main-content-search .main-content .content-search .item-search p {
      font-size: 16px;
      font-family: "Lato";
      font-weight: normal;
      color: #6A6E72; }
      .box-main-content-search .main-content .content-search .item-search p b {
        color: #177BBE; }

@media (max-width: 1100px) {
  .sitemap .box-shadow-left-right {
    padding: 30px; } }

@media (max-width: 480px) {
  .sitemap .box-shadow-left-right {
    padding: 15px; } }

.sitemap .sitemap-box {
  margin-bottom: 30px; }
  .sitemap .sitemap-box .title {
    font-size: 25px;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    color: #177bbe;
    cursor: pointer; }
  .sitemap .sitemap-box .content ul {
    padding-left: 0;
    margin-left: 35px;
    line-height: 25px;
    list-style-type: disc; }
    .sitemap .sitemap-box .content ul li a:hover {
      text-decoration: underline; }

.slider-box {
  min-height: 86px; }

.irs-bar,
.irs-bar-edge,
.irs-line-left,
.irs-line-mid,
.irs-line-right,
.irs-slider {
  background-image: url(../assets/img/theme/vendor/ionrangeslider/img/sprite-skin-flat.png);
  background-repeat: repeat-x; }

@media only screen and (max-width: 767px) {
  body.overflow-hidden {
    overflow: hidden; } }

.tab-custom-menu {
  cursor: pointer;
  /*> ul {
    .scrollBar {
      content: "";
      height: 60px;
      width: 7px;
      background-color: #bed74b;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: all .3s; // Chrome
      -moz-transition: all .3s; // Mozilla
      -o-transition: all .3s; // Opera
      transition: all .3s;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }*/
  /*&.sticky {
    position: fixed;
    left: 0px;
    top: 50px;
    z-index: 2;
    .aside-main-menu {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }*/ }
  .tab-custom-menu ul {
    position: relative; }
    .tab-custom-menu ul li.has-sub .tab-custom-menu-item-parent {
      padding-left: 0; }
    .tab-custom-menu ul li.has-sub:hover .tab-custom-menu-item-parent .item-has-sub {
      background-color: #ebebeb;
      /*color: #FFF;*/
      /*span {
                filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1);
              }*/ }
    .tab-custom-menu ul li.has-sub:hover .drop-menu-sub li a {
      background-color: #e7e8e9; }
    .tab-custom-menu ul li.has-sub.selected {
      /*border-bottom: 1px solid $color-bg-gray-hover;*/ }
      .tab-custom-menu ul li.has-sub.selected .tab-custom-menu-item-parent .item-has-sub {
        background-color: #50565a;
        color: #FFF; }
        .tab-custom-menu ul li.has-sub.selected .tab-custom-menu-item-parent .item-has-sub span {
          filter: brightness(0) invert(1);
          -o-filter: brightness(0) invert(1);
          -moz-filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1); }
      .tab-custom-menu ul li.has-sub.selected .drop-menu-sub li a {
        background-color: #8e9193; }
    .tab-custom-menu ul li.tab-custom-menu-item.active::before {
      content: "";
      height: 60px;
      width: 7px;
      background-color: #bed74b;
      position: absolute;
      right: 0;
      z-index: 5; }

.tab-custom-content {
  transition: initial;
  -webkit-transition: initial;
  -ms-transition: initial;
  -moz-transition: initial; }
  .tab-custom-content .tab-custom-content-item {
    display: none; }
    .tab-custom-content .tab-custom-content-item.active {
      display: block; }
  @media only screen and (max-width: 767px) {
    .tab-custom-content {
      padding: 35px 20px 20px 20px !important; } }
  @media only screen and (min-width: 768px) {
    .tab-custom-content {
      padding: 40px 20px 20px 30px !important; } }

.table-panel {
  height: 295px; }

.tabset-group > div + div {
  margin-top: 15px; }

.panel.tabs-panel .panel-body {
  padding: 0; }

.panel.tabs-panel .dropdown-menu {
  min-width: 132px;
  top: auto;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.panel.tabs-panel.with-scroll .panel-body {
  height: 100%; }

.xsmall-panel .nav-tabs, .xsmall-panel .tab-content {
  height: 100%; }

.inline-icon {
  display: inline-block;
  margin: 20px 5px; }

.panel.horizontal-tabs .tab-content {
  height: calc(100% - 40px); }

.terms-wrapper {
  width: 100%;
  padding: 100px 100px 100px 100px; }
  .terms-wrapper h1 {
    color: #017bc1;
    font-family: "PT Sans", sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 40px 0px 40px 0px; }
  .terms-wrapper h2 {
    color: #292F35;
    font-family: "PT Sans", sans-serif;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 30px 0px 10px 0px; }
  .terms-wrapper h3 {
    color: #292F35;
    font-family: "PT Sans", sans-serif;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.2px;
    font-weight: normal;
    margin: 40px 0px 20px 0px; }
  .terms-wrapper p, .terms-wrapper li {
    font-family: "Lato", Arial;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px; }

.tpl-skin-panel {
  width: 300px; }
  .tpl-skin-panel .tpl-skin-option {
    padding: 10px;
    line-height: 83px;
    color: #666666; }
    .tpl-skin-panel .tpl-skin-option:hover {
      cursor: pointer;
      background-color: #c2de44; }
    .tpl-skin-panel .tpl-skin-option + .tpl-skin-option {
      border-top: 1px solid gainsboro; }
  .tpl-skin-panel .skin-thumbnail {
    width: 100%; }

#page_treatment {
  padding-right: 0 !important; }
  #page_treatment .box-popup-evidence .modal-dialog {
    width: 740px; }
    @media (max-width: 767px) {
      #page_treatment .box-popup-evidence .modal-dialog {
        width: 92%; } }
  #page_treatment .box-popup-evidence .box-evidence .item {
    border-bottom: 1px solid #f9f9f9;
    margin-bottom: 20px; }
  #page_treatment .header-treatment .title-header-page h1:before {
    background-image: url("../../assets/pictures/icon-treatments.png"); }
  #page_treatment .main-content-treatment .box-effectiveness-one, #page_treatment .main-content-treatment .box-effectiveness-tow {
    border: 1px solid #e1e1e2;
    border-radius: 5px;
    margin-bottom: 30px; }
    #page_treatment .main-content-treatment .box-effectiveness-one h4, #page_treatment .main-content-treatment .box-effectiveness-tow h4 {
      color: #808385;
      padding: 25px 15px;
      padding-right: 0;
      font-size: 18px;
      font-family: "Lato";
      font-weight: bold;
      margin-bottom: 0;
      position: relative;
      display: inline-block;
      white-space: nowrap; }
      @media (max-width: 767px) {
        #page_treatment .main-content-treatment .box-effectiveness-one h4, #page_treatment .main-content-treatment .box-effectiveness-tow h4 {
          padding-bottom: 5px; } }
      #page_treatment .main-content-treatment .box-effectiveness-one h4:after, #page_treatment .main-content-treatment .box-effectiveness-tow h4:after {
        content: "";
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        height: 20px;
        width: 20px;
        background-image: url("../../assets/img/app/effectiveness/icon-question.png");
        background-repeat: no-repeat;
        cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #page_treatment .main-content-treatment .box-effectiveness-one .box-status, #page_treatment .main-content-treatment .box-effectiveness-tow .box-status {
        text-align: left; } }
    #page_treatment .main-content-treatment .box-effectiveness-one .box-status img, #page_treatment .main-content-treatment .box-effectiveness-tow .box-status img {
      display: inline-block; }
  #page_treatment .main-content-treatment .box-effectiveness-one .box-status {
    text-align: right; }
    @media (max-width: 767px) {
      #page_treatment .main-content-treatment .box-effectiveness-one .box-status {
        text-align: left; } }
    #page_treatment .main-content-treatment .box-effectiveness-one .box-status img {
      padding: 15px; }
  #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow {
    padding: 15px 30px;
    text-align: left; }
    #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status {
      text-align: left;
      float: left;
      margin-bottom: 15px;
      margin-left: 15px; }
      #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status:first-child {
        margin-right: 20px; }
        @media (max-width: 420px) {
          #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status:first-child {
            margin-right: 0px; } }
      #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status .item-text {
        width: 80px;
        float: left; }
      #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status .item-img {
        float: left;
        vertical-align: bottom; }
        #page_treatment .main-content-treatment .box-effectiveness-one .effectiveness-tow .box-status .item-img img {
          padding: 0;
          padding-left: 5px;
          margin-top: 3px; }
  #page_treatment .main-content-treatment .box-content-feedback {
    border-bottom: 2px solid #e3e2e2; }
    #page_treatment .main-content-treatment .box-content-feedback button:hover {
      background-color: #177bbe;
      color: #ffffff; }
    #page_treatment .main-content-treatment .box-content-feedback button.active {
      background-color: #177bbe;
      color: #ffffff; }
    #page_treatment .main-content-treatment .box-content-feedback .box-helpful {
      border: none; }
    #page_treatment .main-content-treatment .box-content-feedback .box-thank h6 {
      padding: 35px 0 0 0;
      font-size: 16px;
      font-family: "Lato", Arial;
      color: #6a6e72; }
    @media (max-width: 767px) {
      #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-feedback-success h6 {
        padding: 35px 0 35px 35px; } }
    #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn {
      display: block;
      text-align: right; }
      #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me {
        margin: 20px 0;
        padding: 8px 15px;
        padding-left: 40px;
        background-color: transparent;
        border: 1px solid #177bbe;
        font-size: 14px;
        font-family: "Lato", Arial;
        font-weight: bold;
        color: #177bbe;
        margin-right: 0;
        position: relative; }
        #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me:hover {
          background-color: #177bbe;
          color: #ffffff; }
          #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me:hover:before {
            background-image: url("../../assets/img/app/effectiveness/icon-feed-active.png"); }
        #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me.active {
          background-color: #177bbe;
          color: #ffffff; }
          #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me.active:before {
            background-image: url("../../assets/img/app/effectiveness/icon-feed-active.png"); }
        #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 13px;
          height: 11px;
          background-image: url("../../assets/img/app/effectiveness/icon-feed.png");
          background-repeat: no-repeat;
          background-size: contain; }
    #page_treatment .main-content-treatment .box-content-feedback .box-comment {
      padding: 20px;
      background: rgba(16, 18, 20, 0.05); }
      #page_treatment .main-content-treatment .box-content-feedback .box-comment h4 {
        font-size: 18px;
        font-family: "Lato";
        font-weight: bold;
        color: #292f35;
        display: inline-block; }
        #page_treatment .main-content-treatment .box-content-feedback .box-comment h4:before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 15px;
          background-image: url("../../assets/img/app/effectiveness/icon-feed-black.png");
          background-repeat: no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-right: 15px; }
        #page_treatment .main-content-treatment .box-content-feedback .box-comment h4:after {
          content: "";
          display: inline-block;
          width: 13px;
          height: 8px;
          background-image: url("../../assets/img/app/effectiveness/icon-arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
          vertical-align: middle;
          margin-left: 15px; }
      #page_treatment .main-content-treatment .box-content-feedback .box-comment form input, #page_treatment .main-content-treatment .box-content-feedback .box-comment form textarea {
        margin-bottom: 15px; }
      #page_treatment .main-content-treatment .box-content-feedback .box-comment form .btn-info {
        padding: 8px 20px; }
    #page_treatment .main-content-treatment .box-content-feedback .box-feedback-success h6 {
      padding: 35px 0 35px 35px;
      font-size: 16px;
      font-family: "Lato", Arial;
      color: #6a6e72;
      background-image: url("../../assets/img/app/effectiveness/icon-success.png");
      background-repeat: no-repeat;
      background-size: 25px;
      background-position-y: 32px; }
    @media only screen and (max-width: 767px) {
      #page_treatment .main-content-treatment .box-content-feedback .box-thank h6 {
        padding: 30px 0 10px 0;
        background-position-y: 30px; }
      #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn {
        text-align: left; }
        #page_treatment .main-content-treatment .box-content-feedback .box-thank .box-btn .btn-tell-me {
          margin: 0 0 30px; } }
  #page_treatment .box-table-content table h2 {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #FFF;
    font-family: "Lato";
    font-size: 18px;
    font-weight: 900; }
  #page_treatment .box-table-content table tr:first-child {
    background: #247BBB; }
  #page_treatment .box-table-content table tr td {
    line-height: 20px;
    padding: 15px;
    text-align: left;
    width: 50%; }

.tree-node {
  line-height: 25px;
  cursor: pointer; }
  .tree-node.selected {
    background-color: gainsboro; }
  .tree-node .control {
    cursor: pointer;
    font-size: 16px;
    padding-left: 5px; }
  .tree-node:hover {
    background-color: #cbcbcb; }

.jstree-default a.jstree-clicked, .jstree-default a.jstree-hovered {
  background-color: rgba(0, 0, 0, 0.25); }

.jstree-default a.jstree-anchor:hover, .jstree-default a.jstree-wholerow:hover {
  background-color: rgba(0, 0, 0, 0.15); }

.control-side > div {
  margin-top: 5px;
  margin-bottom: 10px; }
  .control-side > div .btn {
    width: 100px; }

#tree-root {
  border-left: 1px solid gainsboro;
  padding-left: 10px; }

.tree-panel {
  height: 500px; }

.search-container {
  margin-top: 10px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  margin-top: 0; }

h1.color, h2.color, h3.color, h4.color, h5.color, h6.color {
  color: #f1f1f1; }

body a {
  color: #177bbe;
  text-decoration: none;
  transition: color 0.2s ease; }
  body a:hover {
    color: #037bc0; }
  body a.underline {
    text-decoration: underline !important; }

h1 {
  font-size: 32px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 20px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 15px; }

.typography-document-samples p {
  margin: 0; }

.typography-document-samples .typography-widget {
  height: 100%; }
  .typography-document-samples .typography-widget .panel {
    height: 620px; }
  .typography-document-samples .typography-widget .panel-title {
    text-align: center;
    width: 100%; }
  .typography-document-samples .typography-widget .panel.with-scroll .panel-body {
    height: calc(100% - 45px); }
  .typography-document-samples .typography-widget .panel-content {
    padding: 15px 22px 5px 22px; }

.heading-widget h1, .heading-widget h2, .heading-widget h3, .heading-widget h4, .heading-widget h5, .heading-widget h6 {
  width: 100%;
  font-weight: 300;
  text-align: center; }

.heading-widget p {
  line-height: 16px;
  font-weight: 400;
  text-align: center; }

.more-text-widget {
  text-align: center;
  font-size: 14px; }
  .more-text-widget p {
    line-height: 17px; }
  .more-text-widget .gray {
    color: #767676; }
  .more-text-widget .black {
    color: #585858; }
  .more-text-widget .light-text {
    font-weight: 300; }
  .more-text-widget .regular-text {
    font-weight: 400; }
  .more-text-widget .upper-text {
    text-transform: uppercase; }
  .more-text-widget .bold-text {
    font-weight: 700; }
  .more-text-widget .small-text {
    padding: 5px 0 0 0; }
    .more-text-widget .small-text p {
      font-size: 9px;
      font-weight: 300;
      line-height: 10px; }

.color-widget {
  text-align: center;
  font-size: 14px;
  font-weight: 400; }
  .color-widget p {
    line-height: 17px; }
  .color-widget .section-block {
    margin: 14px 0; }
  .color-widget .yellow-text p {
    color: #dfb81c; }
  .color-widget .red-text p {
    color: #f1f1f1; }
  .color-widget .links h3 {
    margin-bottom: 10px; }
  .color-widget .links p {
    margin-bottom: 0; }
    .color-widget .links p.hovered a {
      color: #037bc0; }

.lists-widget {
  font-weight: 400; }
  .lists-widget .list-header {
    width: 100%;
    text-align: center; }
  .lists-widget .accent {
    margin-top: 30px;
    color: #e5c649;
    line-height: 14px;
    font-size: 14px;
    padding-left: 11px;
    border-left: 4px solid #e5c649;
    margin-left: 13px; }
  .lists-widget ul.blur, .lists-widget ol.blur {
    padding-left: 13px;
    margin-bottom: 19px;
    list-style: none;
    padding-top: 1px; }
    .lists-widget ul.blur li, .lists-widget ol.blur li {
      margin-top: 5px;
      font-size: 14px; }
      .lists-widget ul.blur li ul, .lists-widget ul.blur li ol, .lists-widget ol.blur li ul, .lists-widget ol.blur li ol {
        padding-left: 20px;
        margin-bottom: 0;
        list-style: none; }
  .lists-widget ul.blur li:before {
    content: "• ";
    color: #e5c649;
    width: 10px;
    display: inline-block; }
  .lists-widget ol.blur {
    counter-reset: section; }
    .lists-widget ol.blur li {
      color: #e5c649;
      padding-left: 0;
      line-height: 14px;
      position: relative; }
      .lists-widget ol.blur li span {
        color: #666666;
        display: block; }
      .lists-widget ol.blur li ol {
        padding-left: 0;
        margin-left: 12px; }
      .lists-widget ol.blur li:before {
        content: counters(section, ".") ".";
        counter-increment: section;
        width: 19px;
        position: absolute;
        left: 0;
        top: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .lists-widget ol.blur > li span {
      padding-left: 14px; }
    .lists-widget ol.blur ol {
      counter-reset: section; }
      .lists-widget ol.blur ol > li:before {
        width: 30px; }
      .lists-widget ol.blur ol > li span {
        padding-left: 27px; }
      .lists-widget ol.blur ol ol > li:before {
        width: 40px; }
      .lists-widget ol.blur ol ol > li span {
        padding-left: 40px; }

.columns-section {
  background-color: #ffffff; }

h1 {
  font-size: 52px; }

h2 {
  font-size: 34px; }

h3 {
  font-size: 32px;
  line-height: 1.4;
  color: #017bc1;
  font-weight: bold; }

h4 {
  font-size: 28px; }

h5 {
  font-size: 22px; }

p {
  margin-bottom: 12px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.5; }

p.small-text {
  color: #949494;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px; }

.cols-two {
  margin-bottom: 50px; }
  .cols-two > div {
    float: left;
    width: 350px;
    margin-left: 40px; }
    .cols-two > div:first-child {
      margin-left: 0; }

.cols-three {
  margin-bottom: 50px; }
  .cols-three > div {
    float: left;
    width: 222px;
    margin-left: 40px; }
    .cols-three > div:first-child {
      margin-left: 0; }

a.learn-more {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  line-height: 24px; }

.img-wrapper {
  margin-bottom: 19px;
  margin-top: 5px;
  overflow: hidden;
  height: 180px; }
  .img-wrapper img {
    width: 100%; }

.cols-three p {
  margin-bottom: 10px; }

.banner {
  position: relative;
  margin-bottom: 20px; }

.large-banner-wrapper {
  overflow: hidden;
  height: 400px; }
  .large-banner-wrapper img {
    height: 100%;
    width: 100%;
    display: block; }

.banner-text-wrapper {
  margin-top: -400px;
  height: 400px;
  text-align: center; }

.banner-text {
  padding: 85px 90px 60px;
  display: inline-block;
  margin: 67px auto;
  background: #ffffff;
  min-width: 432px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.75); }
  .banner-text h1 {
    font-weight: 700;
    width: 100%;
    color: #ffffff;
    margin-bottom: 10px; }
  .banner-text p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    color: #c2de44;
    margin-bottom: 0px; }

@media (max-width: 600px) {
  .banner-text {
    padding: 55px 60px 30px;
    min-width: 0; }
    .banner-text h1 {
      font-size: 24px; }
    .banner-text p {
      font-size: 16px; } }

@media (max-width: 400px) {
  .banner-text {
    min-width: 0;
    width: 100%;
    height: 100%;
    margin: 0; } }

.photo-desc {
  margin-top: 12px;
  text-align: center; }

.text-info {
  width: 90%; }
  .text-info p {
    margin-bottom: 10px; }

.section-block {
  padding-bottom: 12px; }

.separator {
  height: 1px;
  background: gainsboro;
  width: 100%;
  margin-bottom: 19px;
  margin-top: 16px; }

.section {
  padding: 0 20px 50px 20px; }

.panel.banner-column-panel {
  padding: 0;
  margin-bottom: 90px; }
  .panel.banner-column-panel .panel-body {
    padding: 0; }

@media screen and (min-width: 1620px) {
  .col-xlg-1 {
    width: 8.33333333%; }
  .col-xlg-2 {
    width: 16.66666667%; }
  .col-xlg-3 {
    width: 25%; }
  .col-xlg-4 {
    width: 33.33333333%; }
  .col-xlg-5 {
    width: 41.66666667%; }
  .col-xlg-6 {
    width: 50%; }
  .col-xlg-7 {
    width: 58.33333333%; }
  .col-xlg-8 {
    width: 66.66666667%; }
  .col-xlg-9 {
    width: 75%; }
  .col-xlg-10 {
    width: 83.33333333%; }
  .col-xlg-11 {
    width: 91.66666667%; }
  .col-xlg-12 {
    width: 100%; } }

.edit-user-profile-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  height: 500px;
  background-image: url("../../assets/pictures/abp-bg.png"); }

.edit-user-profile-upper {
  width: 100%;
  height: 500px;
  padding: 330px 0 0 0px;
  text-align: center; }
  .edit-user-profile-upper p {
    font-family: "PT Sans", sans-serif;
    color: #ffffff; }
    .edit-user-profile-upper p.highlight, .edit-user-profile-upper p .highlight {
      color: #c2de44; }
    .edit-user-profile-upper p.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
      .edit-user-profile-upper p.big:last-child {
        margin: 0 0 70px 0; }
    .edit-user-profile-upper p.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
      .edit-user-profile-upper p.title:last-child {
        margin: 0 0 70px 0; }

.edit-user-profile.sections {
  padding: 40px 30px 100px 120px; }
  .edit-user-profile.sections a {
    color: #017bc1; }
  .edit-user-profile.sections form {
    overflow: hidden; }
  .edit-user-profile.sections .row p {
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.2px; }
    .edit-user-profile.sections .row p.title {
      font-size: 32px;
      line-height: 1.4;
      color: #017bc1;
      font-weight: bold; }
      .edit-user-profile.sections .row p.title.small {
        font-size: 28px;
        line-height: 1.4;
        color: #000000;
        font-weight: bold;
        margin-bottom: 20px; }
    .edit-user-profile.sections .row p.subtitle {
      font-weight: bold;
      padding: 0px 0px 20px 0px; }
  .edit-user-profile.sections .row.text {
    padding: 60px 0px 20px 0px;
    display: table; }
    .edit-user-profile.sections .row.text .lead {
      font-size: 18px;
      font-weight: bold; }
    .edit-user-profile.sections .row.text .more-or-less {
      padding: 30px 30px 30px 30px;
      display: table-cell;
      float: none; }
      .edit-user-profile.sections .row.text .more-or-less .title {
        font-size: 32px;
        line-height: 40px;
        color: #017bc1;
        font-weight: bold; }
        .edit-user-profile.sections .row.text .more-or-less .title.small {
          font-size: 28px;
          line-height: 40px;
          color: #000000;
          font-weight: bold;
          margin-bottom: 0px; }
        .edit-user-profile.sections .row.text .more-or-less .title.narrow {
          font-size: 28px;
          line-height: 40px;
          color: #017bc1;
          font-weight: bold;
          margin-bottom: 20px; }
      .edit-user-profile.sections .row.text .more-or-less .icon-calendar-plus {
        width: 120px;
        height: 100px;
        background-size: 120px 100px;
        margin-bottom: 40px;
        background-image: url("../../assets/img/app/icon-calendar-plus.png"); }
      .edit-user-profile.sections .row.text .more-or-less .icon-calendar-minus {
        width: 120px;
        height: 100px;
        background-size: 120px 100px;
        margin-bottom: 40px;
        background-image: url("../../assets/img/app/icon-calendar-minus.png"); }

.edit-user-profile .table-col {
  display: table-cell;
  float: none;
  border: 1px solid #cccccc; }

.edit-user-profile .table-spacer {
  display: table-cell;
  width: 1%;
  height: 1px; }

.label {
  border-radius: 0; }

.label-primary {
  background: #c2de44; }

.label-info {
  background: #d4e87c; }

.label-success {
  background: #90b900; }

.label-warning {
  background: #dfb81c; }

.label-danger {
  background: #f1f1f1; }

.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none; }

.btn {
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 24px;
  font-weight: bold;
  padding: 12px 20px;
  cursor: pointer;
  /* margin-right: 16px; */ }
  .btn.big {
    font-size: 22px;
    line-height: 40px;
    font-weight: bold; }
  .btn.btn-info .gizmo-play {
    background-size: 40px 40px;
    background-repeat: no-repeat;
    color: transparent;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background-image: url("../../assets/img/app/play.png"); }
  .btn.btn-info:hover .gizmo-play {
    background-image: url("../../assets/img/app/play-hover.png"); }
  .btn .highlight {
    color: #c2de44; }

.open > .btn.dropdown-toggle.btn.btn-primary {
  background: #c2de44;
  border-color: #aac62c;
  background-color: #a5bd3a;
  border-color: #a5bd3a; }

.open > .btn.dropdown-toggle.btn-success {
  background: #90b900;
  border-color: #78a100;
  background-color: #7a9d00;
  border-color: #7a9d00; }

.open > .btn.dropdown-toggle.btn-info {
  background: #017bc1;
  border-color: #0063a9;
  background-color: #0169a4;
  border-color: #0169a4; }

.open > .btn.dropdown-toggle.btn-warning {
  background: #dfb81c;
  border-color: #c7a004;
  background-color: #be9c18;
  border-color: #be9c18; }

.open > .btn.dropdown-toggle.btn-danger {
  background: #f1f1f1;
  border-color: #d9d9d9;
  background-color: #cdcdcd;
  border-color: #cdcdcd; }

button.btn.btn-primary {
  background: #c2de44;
  border-color: #c2de44; }
  button.btn.btn-primary.disabled, button.btn.btn-primary[disabled], fieldset[disabled] button.btn.btn-primary, button.btn.btn-primary.disabled:hover, button.btn.btn-primary[disabled]:hover,
  fieldset[disabled] button.btn.btn-primary:hover, button.btn.btn-primary.disabled:focus, button.btn.btn-primary[disabled]:focus, fieldset[disabled] button.btn.btn-primary:focus, button.btn.btn-primary.disabled.focus, button.btn.btn-primary[disabled].focus, fieldset[disabled] button.btn.btn-primary.focus, button.btn.btn-primary.disabled:active, button.btn.btn-primary[disabled]:active, fieldset[disabled] button.btn.btn-primary:active, button.btn.btn-primary.disabled.active, button.btn.btn-primary[disabled].active,
  fieldset[disabled] button.btn.btn-primary.active {
    background: #c2de44;
    border-color: #ceea50; }
    button.btn.btn-primary.disabled:hover, button.btn.btn-primary[disabled]:hover, fieldset[disabled] button.btn.btn-primary:hover, button.btn.btn-primary.disabled:hover:hover, button.btn.btn-primary[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-primary:hover:hover, button.btn.btn-primary.disabled:focus:hover, button.btn.btn-primary[disabled]:focus:hover, fieldset[disabled] button.btn.btn-primary:focus:hover, button.btn.btn-primary.disabled.focus:hover, button.btn.btn-primary[disabled].focus:hover, fieldset[disabled] button.btn.btn-primary.focus:hover, button.btn.btn-primary.disabled:active:hover, button.btn.btn-primary[disabled]:active:hover, fieldset[disabled] button.btn.btn-primary:active:hover, button.btn.btn-primary.disabled.active:hover, button.btn.btn-primary[disabled].active:hover,
    fieldset[disabled] button.btn.btn-primary.active:hover {
      transform: none; }
  button.btn.btn-primary:hover, button.btn.btn-primary:focus, button.btn.btn-primary.focus, button.btn.btn-primary:active, button.btn.btn-primary.active {
    background: #c2de44;
    border-color: #aac62c; }
  button.btn.btn-primary:active, button.btn.btn-primary:target {
    background-color: #a5bd3a; }

button.btn.btn-default {
  border-width: 1px;
  color: #ffffff;
  background: #272e33;
  border-color: #272e33; }
  button.btn.btn-default.disabled, button.btn.btn-default[disabled], fieldset[disabled] button.btn.btn-default, button.btn.btn-default.disabled:hover, button.btn.btn-default[disabled]:hover,
  fieldset[disabled] button.btn.btn-default:hover, button.btn.btn-default.disabled:focus, button.btn.btn-default[disabled]:focus, fieldset[disabled] button.btn.btn-default:focus, button.btn.btn-default.disabled.focus, button.btn.btn-default[disabled].focus, fieldset[disabled] button.btn.btn-default.focus, button.btn.btn-default.disabled:active, button.btn.btn-default[disabled]:active, fieldset[disabled] button.btn.btn-default:active, button.btn.btn-default.disabled.active, button.btn.btn-default[disabled].active,
  fieldset[disabled] button.btn.btn-default.active {
    background: #272e33;
    border-color: #333a3f; }
    button.btn.btn-default.disabled:hover, button.btn.btn-default[disabled]:hover, fieldset[disabled] button.btn.btn-default:hover, button.btn.btn-default.disabled:hover:hover, button.btn.btn-default[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-default:hover:hover, button.btn.btn-default.disabled:focus:hover, button.btn.btn-default[disabled]:focus:hover, fieldset[disabled] button.btn.btn-default:focus:hover, button.btn.btn-default.disabled.focus:hover, button.btn.btn-default[disabled].focus:hover, fieldset[disabled] button.btn.btn-default.focus:hover, button.btn.btn-default.disabled:active:hover, button.btn.btn-default[disabled]:active:hover, fieldset[disabled] button.btn.btn-default:active:hover, button.btn.btn-default.disabled.active:hover, button.btn.btn-default[disabled].active:hover,
    fieldset[disabled] button.btn.btn-default.active:hover {
      transform: none; }
  button.btn.btn-default:hover, button.btn.btn-default:focus, button.btn.btn-default.focus, button.btn.btn-default:active, button.btn.btn-default.active {
    background: #272e33;
    border-color: #0f161b; }
  button.btn.btn-default:active, button.btn.btn-default:target {
    background-color: #272e33;
    color: #666666; }
  button.btn.btn-default:hover {
    background-color: #1e2327; }

button.btn.btn-success {
  background: #90b900;
  border-color: #90b900; }
  button.btn.btn-success.disabled, button.btn.btn-success[disabled], fieldset[disabled] button.btn.btn-success, button.btn.btn-success.disabled:hover, button.btn.btn-success[disabled]:hover,
  fieldset[disabled] button.btn.btn-success:hover, button.btn.btn-success.disabled:focus, button.btn.btn-success[disabled]:focus, fieldset[disabled] button.btn.btn-success:focus, button.btn.btn-success.disabled.focus, button.btn.btn-success[disabled].focus, fieldset[disabled] button.btn.btn-success.focus, button.btn.btn-success.disabled:active, button.btn.btn-success[disabled]:active, fieldset[disabled] button.btn.btn-success:active, button.btn.btn-success.disabled.active, button.btn.btn-success[disabled].active,
  fieldset[disabled] button.btn.btn-success.active {
    background: #90b900;
    border-color: #9cc50c; }
    button.btn.btn-success.disabled:hover, button.btn.btn-success[disabled]:hover, fieldset[disabled] button.btn.btn-success:hover, button.btn.btn-success.disabled:hover:hover, button.btn.btn-success[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-success:hover:hover, button.btn.btn-success.disabled:focus:hover, button.btn.btn-success[disabled]:focus:hover, fieldset[disabled] button.btn.btn-success:focus:hover, button.btn.btn-success.disabled.focus:hover, button.btn.btn-success[disabled].focus:hover, fieldset[disabled] button.btn.btn-success.focus:hover, button.btn.btn-success.disabled:active:hover, button.btn.btn-success[disabled]:active:hover, fieldset[disabled] button.btn.btn-success:active:hover, button.btn.btn-success.disabled.active:hover, button.btn.btn-success[disabled].active:hover,
    fieldset[disabled] button.btn.btn-success.active:hover {
      transform: none; }
  button.btn.btn-success:hover, button.btn.btn-success:focus, button.btn.btn-success.focus, button.btn.btn-success:active, button.btn.btn-success.active {
    background: #90b900;
    border-color: #78a100; }
  button.btn.btn-success:active, button.btn.btn-success:target {
    background-color: #7a9d00; }

button.btn.btn-info {
  background: #017bc1;
  border-color: #017bc1; }
  button.btn.btn-info.disabled, button.btn.btn-info[disabled], fieldset[disabled] button.btn.btn-info, button.btn.btn-info.disabled:hover, button.btn.btn-info[disabled]:hover,
  fieldset[disabled] button.btn.btn-info:hover, button.btn.btn-info.disabled:focus, button.btn.btn-info[disabled]:focus, fieldset[disabled] button.btn.btn-info:focus, button.btn.btn-info.disabled.focus, button.btn.btn-info[disabled].focus, fieldset[disabled] button.btn.btn-info.focus, button.btn.btn-info.disabled:active, button.btn.btn-info[disabled]:active, fieldset[disabled] button.btn.btn-info:active, button.btn.btn-info.disabled.active, button.btn.btn-info[disabled].active,
  fieldset[disabled] button.btn.btn-info.active {
    background: #017bc1;
    border-color: #0d87cd; }
    button.btn.btn-info.disabled:hover, button.btn.btn-info[disabled]:hover, fieldset[disabled] button.btn.btn-info:hover, button.btn.btn-info.disabled:hover:hover, button.btn.btn-info[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-info:hover:hover, button.btn.btn-info.disabled:focus:hover, button.btn.btn-info[disabled]:focus:hover, fieldset[disabled] button.btn.btn-info:focus:hover, button.btn.btn-info.disabled.focus:hover, button.btn.btn-info[disabled].focus:hover, fieldset[disabled] button.btn.btn-info.focus:hover, button.btn.btn-info.disabled:active:hover, button.btn.btn-info[disabled]:active:hover, fieldset[disabled] button.btn.btn-info:active:hover, button.btn.btn-info.disabled.active:hover, button.btn.btn-info[disabled].active:hover,
    fieldset[disabled] button.btn.btn-info.active:hover {
      transform: none; }
  button.btn.btn-info:hover, button.btn.btn-info:focus, button.btn.btn-info.focus, button.btn.btn-info:active, button.btn.btn-info.active {
    background: #017bc1;
    border-color: #0063a9; }
  button.btn.btn-info:active, button.btn.btn-info:target {
    background-color: #0169a4; }
  button.btn.btn-info:hover {
    background-color: #0169a4; }

button.btn.btn-warning {
  background: #dfb81c;
  border-color: #dfb81c; }
  button.btn.btn-warning.disabled, button.btn.btn-warning[disabled], fieldset[disabled] button.btn.btn-warning, button.btn.btn-warning.disabled:hover, button.btn.btn-warning[disabled]:hover,
  fieldset[disabled] button.btn.btn-warning:hover, button.btn.btn-warning.disabled:focus, button.btn.btn-warning[disabled]:focus, fieldset[disabled] button.btn.btn-warning:focus, button.btn.btn-warning.disabled.focus, button.btn.btn-warning[disabled].focus, fieldset[disabled] button.btn.btn-warning.focus, button.btn.btn-warning.disabled:active, button.btn.btn-warning[disabled]:active, fieldset[disabled] button.btn.btn-warning:active, button.btn.btn-warning.disabled.active, button.btn.btn-warning[disabled].active,
  fieldset[disabled] button.btn.btn-warning.active {
    background: #dfb81c;
    border-color: #ebc428; }
    button.btn.btn-warning.disabled:hover, button.btn.btn-warning[disabled]:hover, fieldset[disabled] button.btn.btn-warning:hover, button.btn.btn-warning.disabled:hover:hover, button.btn.btn-warning[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-warning:hover:hover, button.btn.btn-warning.disabled:focus:hover, button.btn.btn-warning[disabled]:focus:hover, fieldset[disabled] button.btn.btn-warning:focus:hover, button.btn.btn-warning.disabled.focus:hover, button.btn.btn-warning[disabled].focus:hover, fieldset[disabled] button.btn.btn-warning.focus:hover, button.btn.btn-warning.disabled:active:hover, button.btn.btn-warning[disabled]:active:hover, fieldset[disabled] button.btn.btn-warning:active:hover, button.btn.btn-warning.disabled.active:hover, button.btn.btn-warning[disabled].active:hover,
    fieldset[disabled] button.btn.btn-warning.active:hover {
      transform: none; }
  button.btn.btn-warning:hover, button.btn.btn-warning:focus, button.btn.btn-warning.focus, button.btn.btn-warning:active, button.btn.btn-warning.active {
    background: #dfb81c;
    border-color: #c7a004; }
  button.btn.btn-warning:active, button.btn.btn-warning:target {
    background-color: #be9c18; }

button.btn.btn-danger {
  background-color: #f1f1f1;
  color: #017bc1;
  border: none; }
  button.btn.btn-danger:active, button.btn.btn-danger:target {
    background-color: #EBF2F7 !important;
    color: #017bc1;
    box-shadow: none; }
  button.btn.btn-danger:hover {
    color: #017bc1;
    background-color: #EBF2F7 !important; }
  button.btn.btn-danger i {
    margin-left: 10px; }

button.btn.btn-inverse {
  background: #949494;
  border-color: #949494;
  color: #ffffff; }
  button.btn.btn-inverse.disabled, button.btn.btn-inverse[disabled], fieldset[disabled] button.btn.btn-inverse, button.btn.btn-inverse.disabled:hover, button.btn.btn-inverse[disabled]:hover,
  fieldset[disabled] button.btn.btn-inverse:hover, button.btn.btn-inverse.disabled:focus, button.btn.btn-inverse[disabled]:focus, fieldset[disabled] button.btn.btn-inverse:focus, button.btn.btn-inverse.disabled.focus, button.btn.btn-inverse[disabled].focus, fieldset[disabled] button.btn.btn-inverse.focus, button.btn.btn-inverse.disabled:active, button.btn.btn-inverse[disabled]:active, fieldset[disabled] button.btn.btn-inverse:active, button.btn.btn-inverse.disabled.active, button.btn.btn-inverse[disabled].active,
  fieldset[disabled] button.btn.btn-inverse.active {
    background: #949494;
    border-color: #a0a0a0; }
    button.btn.btn-inverse.disabled:hover, button.btn.btn-inverse[disabled]:hover, fieldset[disabled] button.btn.btn-inverse:hover, button.btn.btn-inverse.disabled:hover:hover, button.btn.btn-inverse[disabled]:hover:hover,
    fieldset[disabled] button.btn.btn-inverse:hover:hover, button.btn.btn-inverse.disabled:focus:hover, button.btn.btn-inverse[disabled]:focus:hover, fieldset[disabled] button.btn.btn-inverse:focus:hover, button.btn.btn-inverse.disabled.focus:hover, button.btn.btn-inverse[disabled].focus:hover, fieldset[disabled] button.btn.btn-inverse.focus:hover, button.btn.btn-inverse.disabled:active:hover, button.btn.btn-inverse[disabled]:active:hover, fieldset[disabled] button.btn.btn-inverse:active:hover, button.btn.btn-inverse.disabled.active:hover, button.btn.btn-inverse[disabled].active:hover,
    fieldset[disabled] button.btn.btn-inverse.active:hover {
      transform: none; }
  button.btn.btn-inverse:hover, button.btn.btn-inverse:focus, button.btn.btn-inverse.focus, button.btn.btn-inverse:active, button.btn.btn-inverse.active {
    background: #949494;
    border-color: #7c7c7c; }
  button.btn.btn-inverse:active, button.btn.btn-inverse:target, button.btn.btn-inverse:hover {
    background-color: #949494;
    color: #ffffff; }

.btn-with-icon i {
  margin-right: 10px;
  font-size: 32px;
  line-height: 32px;
  vertical-align: sub; }

.btn-group :hover, .btn-toolbar :hover {
  transform: none; }

.btn-group button.btn.btn-primary {
  border-color: #b6d238; }
  .btn-group button.btn.btn-primary:hover {
    border-color: #aac62c; }

.btn-group button.btn.btn-danger {
  border-color: #e5e5e5; }
  .btn-group button.btn.btn-danger:hover {
    border-color: #d9d9d9; }

.btn-group button.btn.btn-info {
  border-color: #006fb5; }
  .btn-group button.btn.btn-info:hover {
    border-color: #0063a9; }

.btn-group button.btn.btn-success {
  border-color: #84ad00; }
  .btn-group button.btn.btn-success:hover {
    border-color: #78a100; }

.btn-group button.btn.btn-warning {
  border-color: #d3ac10; }
  .btn-group button.btn.btn-warning:hover {
    border-color: #c7a004; }

.btn-group .dropdown-menu {
  margin-top: 0px; }

.btn-toolbar {
  display: inline-block; }

.btn .caret {
  margin-left: 2px; }

button.progress-button .progress {
  margin-bottom: 0;
  border-radius: 0; }

button.progress-button:hover {
  transform: none; }

button.progress-button.progress-button-style-shrink.btn.disabled.progress-button-dir-horizontal:hover {
  transform: scaleY(0.3); }

button.progress-button.progress-button-style-shrink.btn.disabled.progress-button-dir-vertical:hover {
  transform: scaleX(0.1); }

button.progress-button.btn.btn-primary {
  border-radius: 0; }
  button.progress-button.btn.btn-primary .content:after, button.progress-button.btn.btn-primary .content:before {
    color: #3e490d; }
  button.progress-button.btn.btn-primary.progress-button-style-move-up .content, button.progress-button.btn.btn-primary.progress-button-style-slide-down .content {
    background-color: #adcb24; }
  button.progress-button.btn.btn-primary.progress-button-style-lateral-lines .progress-inner {
    border-color: #adcb24;
    background: 0 0; }
  button.progress-button.btn.btn-primary .progress {
    background-color: #adcb24;
    box-shadow: 0 1px 0 #adcb24; }
  button.progress-button.btn.btn-primary .progress-inner {
    background-color: #88a01c; }
  button.progress-button.btn.btn-primary.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-primary.progress-button-perspective .content {
      background-color: #c2de44; }

button.progress-button.btn.btn-default {
  border-radius: 0; }
  button.progress-button.btn.btn-default .content:after, button.progress-button.btn.btn-default .content:before {
    color: #999999; }
  button.progress-button.btn.btn-default.progress-button-style-move-up .content, button.progress-button.btn.btn-default.progress-button-style-slide-down .content {
    background-color: #e6e6e6; }
  button.progress-button.btn.btn-default.progress-button-style-lateral-lines .progress-inner {
    border-color: #e6e6e6;
    background: 0 0; }
  button.progress-button.btn.btn-default .progress {
    background-color: #e6e6e6;
    box-shadow: 0 1px 0 #e6e6e6; }
  button.progress-button.btn.btn-default .progress-inner {
    background-color: #cccccc; }
  button.progress-button.btn.btn-default.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-default.progress-button-perspective .content {
      background-color: #ffffff; }

button.progress-button.btn.btn-success {
  border-radius: 0; }
  button.progress-button.btn.btn-success .content:after, button.progress-button.btn.btn-success .content:before {
    color: black; }
  button.progress-button.btn.btn-success.progress-button-style-move-up .content, button.progress-button.btn.btn-success.progress-button-style-slide-down .content {
    background-color: #688600; }
  button.progress-button.btn.btn-success.progress-button-style-lateral-lines .progress-inner {
    border-color: #688600;
    background: 0 0; }
  button.progress-button.btn.btn-success .progress {
    background-color: #688600;
    box-shadow: 0 1px 0 #688600; }
  button.progress-button.btn.btn-success .progress-inner {
    background-color: #415300; }
  button.progress-button.btn.btn-success.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-success.progress-button-perspective .content {
      background-color: #90b900; }

button.progress-button.btn.btn-info {
  border-radius: 0; }
  button.progress-button.btn.btn-info .content:after, button.progress-button.btn.btn-info .content:before {
    color: black; }
  button.progress-button.btn.btn-info.progress-button-style-move-up .content, button.progress-button.btn.btn-info.progress-button-style-slide-down .content {
    background-color: #015b8e; }
  button.progress-button.btn.btn-info.progress-button-style-lateral-lines .progress-inner {
    border-color: #015b8e;
    background: 0 0; }
  button.progress-button.btn.btn-info .progress {
    background-color: #015b8e;
    box-shadow: 0 1px 0 #015b8e; }
  button.progress-button.btn.btn-info .progress-inner {
    background-color: #003a5c; }
  button.progress-button.btn.btn-info.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-info.progress-button-perspective .content {
      background-color: #017bc1; }

button.progress-button.btn.btn-warning {
  border-radius: 0; }
  button.progress-button.btn.btn-warning .content:after, button.progress-button.btn.btn-warning .content:before {
    color: #2a2205; }
  button.progress-button.btn.btn-warning.progress-button-style-move-up .content, button.progress-button.btn.btn-warning.progress-button-style-slide-down .content {
    background-color: #b29316; }
  button.progress-button.btn.btn-warning.progress-button-style-lateral-lines .progress-inner {
    border-color: #b29316;
    background: 0 0; }
  button.progress-button.btn.btn-warning .progress {
    background-color: #b29316;
    box-shadow: 0 1px 0 #b29316; }
  button.progress-button.btn.btn-warning .progress-inner {
    background-color: #846d11; }
  button.progress-button.btn.btn-warning.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-warning.progress-button-perspective .content {
      background-color: #dfb81c; }

button.progress-button.btn.btn-danger {
  border-radius: 0; }
  button.progress-button.btn.btn-danger .content:after, button.progress-button.btn.btn-danger .content:before {
    color: #8b8b8b; }
  button.progress-button.btn.btn-danger.progress-button-style-move-up .content, button.progress-button.btn.btn-danger.progress-button-style-slide-down .content {
    background-color: #d8d8d8; }
  button.progress-button.btn.btn-danger.progress-button-style-lateral-lines .progress-inner {
    border-color: #d8d8d8;
    background: 0 0; }
  button.progress-button.btn.btn-danger .progress {
    background-color: #d8d8d8;
    box-shadow: 0 1px 0 #d8d8d8; }
  button.progress-button.btn.btn-danger .progress-inner {
    background-color: #bebebe; }
  button.progress-button.btn.btn-danger.progress-button-perspective {
    background: none; }
    button.progress-button.btn.btn-danger.progress-button-perspective .content {
      background-color: #f1f1f1; }

.btn-raised {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35); }

.btn-mm {
  padding: 5px 11px;
  font-size: 13px; }

.btn-xm {
  padding: 8px 14px;
  font-size: 16px; }

.dropdown button.btn.btn-default.dropdown-toggle {
  color: #666666;
  border: 1px solid #d6d6d6;
  background-color: transparent; }
  .dropdown button.btn.btn-default.dropdown-toggle:focus, .dropdown button.btn.btn-default.dropdown-toggle:active {
    background-color: #ffffff; }

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important; }

.bootstrap-select button.btn-default:focus {
  color: #ffffff; }

.bootstrap-select .btn {
  transition: none; }

.i-face {
  display: inline-block;
  background: url("../../assets/img/face.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px; }

.i-money {
  display: inline-block;
  background: url("../../assets/img/money.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px; }

.i-person {
  display: inline-block;
  background: url("../../assets/img/person.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px; }

.i-refresh {
  display: inline-block;
  background: url("../../assets/img/refresh.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  width: 80px;
  height: 80px; }

/*@font-face {
  font-family: 'Lato';
  src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
  //src: url('../../assets/fonts/PT_Sans-Web-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Lato Black';
  src: url('../../assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
}*/
@font-face {
  font-family: 'Lato';
  src: url("../../assets/fonts//Lato/Lato-Regular.eot");
  src: url("../../assets/fonts//Lato/Lato-Regular.woff2") format("woff2"), url("../../assets/fonts//Lato/Lato-Regular.woff") format("woff"), url("../../assets/fonts//Lato/Lato-Regular.otf") format("embedded-opentype"), url("../../assets/fonts//Lato/Lato-Regular.ttf") format("truetype"), url("../../assets/fonts//Lato/Lato-Regular.svg#Lato-Regular") format("svg"), url("../../assets/fonts//Lato/Lato-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato-Bold';
  src: url("../../assets/fonts//Lato/Lato-Bold.eot");
  src: url("../../assets/fonts//Lato/Lato-Bold.woff2") format("woff2"), url("../../assets/fonts//Lato/Lato-Bold.woff") format("woff"), url("../../assets/fonts//Lato/Lato-Bold.ttf") format("truetype"), url("../../assets/fonts//Lato/Lato-Bold.svg#Lato-Bold") format("svg"), url("../../assets/fonts//Lato/Lato-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato-Black';
  src: url("../../assets/fonts//Lato/Lato-Black.eot");
  src: url("../../assets/fonts//Lato/Lato-Black.woff2") format("woff2"), url("../../assets/fonts//Lato/Lato-Black.woff") format("woff"), url("../../assets/fonts//Lato/Lato-Black.ttf") format("truetype"), url("../../assets/fonts//Lato/Lato-Black.svg#Lato-Black") format("svg"), url("../../assets/fonts//Lato/Lato-Black.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT_Sans-Web-Bold';
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Bold.eot");
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Bold.woff2") format("woff2"), url("../../assets/fonts//PT-Sans/PT_Sans-Web-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT_Sans-Web-Italic';
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Italic.eot");
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Italic.woff2") format("woff2"), url("../../assets/fonts//PT-Sans/PT_Sans-Web-Italic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans';
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Regular.eot");
  src: url("../../assets/fonts//PT-Sans/PT_Sans-Web-Regular.woff2") format("woff2"), url("../../assets/fonts//PT-Sans/PT_Sans-Web-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

html {
  position: relative;
  min-width: 320px; }

html, body {
  /*min-height: 100%;*/
  min-width: 320px; }

body {
  font: 16px "Lato", sans-serif;
  color: #666666;
  /*background-color: $body-bg;*/
  background-color: #fbfbfb; }
  body.blur-theme::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("../../assets/img/blur-bg.jpg") no-repeat center center;
    background-size: cover;
    will-change: transform;
    z-index: -1; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow: hidden;
    height: 100%; }
  body {
    overflow: auto;
    height: 100%; } }

a {
  transition: color 0.5s ease;
  outline: 0 !important; }

.panel {
  box-shadow: none; }

.body-bg {
  display: none; }

.al-header {
  display: block;
  height: 49px;
  margin: 0;
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
  color: #444444; }

.al-content p.title {
  font-family: 'PT Sans', sans-serif; }

.al-main {
  margin-left: 0px; }

.al-footer-main {
  float: left;
  margin-left: 15px; }

.al-copy {
  float: left; }

.al-footer-right {
  float: right;
  margin-right: 12px; }
  .al-footer-right i {
    margin: 0 4px;
    color: #f1f1f1;
    font-size: 12px; }
  .al-footer-right a {
    margin-left: 4px;
    color: #666666; }
    .al-footer-right a:hover {
      color: #f1f1f1; }

.al-share {
  margin: -6px 0 0 12px;
  padding: 0;
  list-style: none;
  float: left; }
  .al-share li {
    list-style: none;
    float: left;
    margin-left: 16px; }
    .al-share li i {
      cursor: pointer;
      transition: all 0.1s ease;
      color: white;
      padding: 6px;
      box-sizing: content-box;
      font-size: 16px; }
      .al-share li i:hover {
        transform: scale(1.2); }
    .al-share li i.fa-facebook-square {
      color: #3b5998; }
    .al-share li i.fa-twitter-square {
      color: #55acee; }
    .al-share li i.fa-google-plus-square {
      color: #dd4b39; }

.vis-hidden {
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px; }

.icon-up, .icon-down {
  width: 5px;
  height: 13px;
  display: block; }

.icon-up {
  background: url("../../assets/img/arrow-green-up.svg") no-repeat 0 0; }

.icon-down {
  background: url("../../assets/img/arrow-red-down.svg") no-repeat 0 0; }

.disable-text-selection {
  -webkit-touch-callout: none;
  user-select: none; }

.align-right {
  text-align: right; }

.amcharts-chart-div > a {
  font-size: 6px !important; }

.content-panel {
  padding-left: 22px;
  padding-top: 26px; }

.full-invisible {
  visibility: hidden !important; }
  .full-invisible * {
    visibility: hidden !important; }

.irs-grid-text {
  color: #666666; }

.loading-icon {
  position: absolute;
  top: 400px;
  color: #017bc1;
  font-size: 42px;
  width: 100%;
  text-align: center;
  padding: 90px 30px;
  display: block; }
  .loading-icon i {
    animation-name: spin360;
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: 9999;
    display: block; }

@-webkit-keyframes spin360 {
  from {
    -webkit-transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg); } }

@-o-keyframes spin360 {
  from {
    -o-transform: rotate(0); }
  to {
    -o-transform: rotate(360deg); } }

@-ms-keyframes spin360 {
  from {
    -ms-transform: rotate(0); }
  to {
    -ms-transform: rotate(360deg); } }

@keyframes spin360 {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

ul {
  list-style: none; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    /* Firefox 16+*/ }
  100% {
    -moz-transform: rotate(360deg);
    /* Firefox 16+*/ } }

@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000000;
  backface-visibility: hidden; }
  #preloader > div {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #017bc1;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    animation: spin 2s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */ }
    #preloader > div:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #c2de44;
      -webkit-animation: spin 3s linear infinite;
      /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite;
      /* Chrome, Firefox 16+, IE 10+, Opera */ }
    #preloader > div:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #fff;
      animation: spin 1.5s linear infinite;
      /* Chrome, Firefox 16+, IE 10+, Opera */ }

@font-face {
  font-family: 'socicon';
  src: url("../../assets/fonts/socicon.eot");
  src: url("../../assets/fonts/socicon.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/socicon.woff") format("woff"), url("../../assets/fonts/socicon.woff2") format("woff2"), url("../../assets/fonts/socicon.ttf") format("truetype"), url("../../assets/fonts/socicon.svg#sociconregular") format("svg");
  font-weight: 400;
  font-style: normal;
  text-transform: initial; }

.socicon {
  font-family: 'socicon' !important; }

.socicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'socicon';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.socicon:empty {
  width: 1em; }

.socicon-twitter {
  background-color: #55acee; }
  .socicon-twitter:before {
    content: "a"; }

.socicon-facebook {
  background-color: #3b5998; }
  .socicon-facebook:before {
    content: "b"; }

.socicon-google {
  background-color: #dd4b39; }
  .socicon-google:before {
    content: "c"; }

.socicon-linkedin {
  background-color: #0177B5; }
  .socicon-linkedin:before {
    content: "j"; }

.socicon-github {
  background-color: #6b6b6b; }
  .socicon-github:before {
    content: "Q"; }

.socicon-stackoverflow {
  background-color: #2F96E8; }
  .socicon-stackoverflow:before {
    content: "("; }

.socicon-dribble {
  background-color: #F26798; }
  .socicon-dribble:before {
    content: "D"; }

.socicon-behace {
  background-color: #0093FA; }
  .socicon-behace:before {
    content: "H"; }

@font-face {
  font-family: 'SS Gizmo';
  src: url("../../assets/fonts/ss-gizmo.otf") format("opentype");
  font-weight: normal; }

@font-face {
  font-family: 'SS Social';
  src: url("../../assets/fonts/ss-social-regular.otf") format("opentype");
  font-weight: bold; }

.gizmo-icon {
  font-family: 'SS Gizmo';
  vertical-align: middle; }

.gizmo-play {
  font-family: 'SS Gizmo'; }

.table {
  margin-bottom: 0px; }
  .table > thead > tr > th {
    border-bottom: 1px solid gainsboro;
    white-space: nowrap; }
    .table > thead > tr > th:first-child {
      text-align: center; }
    .table > thead > tr > th:last-child {
      padding-right: 16px; }
  .table > tbody > tr > tr:first-child {
    padding-top: 1px; }
  .table > tbody > tr > td {
    padding: 0px 8px;
    line-height: 35px;
    border-top: 1px solid gainsboro; }
    .table > tbody > tr > td:first-child {
      text-align: center; }
    .table > tbody > tr > td:last-child {
      padding-right: 16px !important; }

.table-id {
  text-align: left !important;
  width: 40px; }

.table-arr {
  width: 5px;
  padding: 10px 8px 8px 0 !important; }

.table-no-borders {
  border: none; }
  .table-no-borders td, .table-no-borders th, .table-no-borders tr {
    border: none !important; }

.editable-wrap .btn-group.form-control {
  background-color: transparent; }

.editable-tr-wrap .editable-wrap {
  vertical-align: super; }

.editable-tr-wrap .editable-controls input.editable-input {
  width: 110px; }

.editable-tr-wrap td {
  width: 20%; }

.editable-table-button {
  width: 70px; }

.add-row-editable-table {
  margin-bottom: 10px; }

.add-row-editable-table + table {
  margin-bottom: 5px; }

.select-page-size-wrap {
  width: 150px; }

.table .header-row th {
  vertical-align: middle;
  padding: 0 8px; }

tr.editable-row input.form-control {
  vertical-align: middle; }

.select-td .editable-select {
  margin-bottom: 1px; }

@media screen and (max-width: 1199px) {
  .editable-tr-wrap .editable-wrap {
    vertical-align: middle; } }

.browser-icons {
  width: 41px; }

.st-sort-ascent, .st-sort-descent {
  position: relative; }

.st-sort-ascent:after, .st-sort-descent:after {
  width: 0;
  height: 0;
  border-bottom: 4px solid #666666;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-bottom: 2px; }

.st-sort-descent:after {
  transform: rotate(-180deg);
  margin-bottom: -2px; }

.sortable th {
  cursor: pointer; }
  .sortable th:after {
    content: '';
    display: inline-block;
    width: 8px;
    margin-left: 8px; }

a.email-link {
  color: #666666; }
  a.email-link:hover {
    color: #f1f1f1; }

input.search-input {
  margin-left: -8px;
  padding-left: 8px; }

.table .pagination {
  margin: 4px 0 -12px 0; }
  .table .pagination a {
    cursor: pointer; }

.vertical-scroll {
  max-height: 214px; }

.pagination > li > a, .pagination > li > span {
  background: transparent; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.status-button {
  width: 60px; }

.table .editable-wrap .editable-controls, .table .editable-wrap .editable-error {
  vertical-align: sub; }
  .table .editable-wrap .editable-controls .btn, .table .editable-wrap .editable-error .btn {
    padding: 3px 8px; }
    .table .editable-wrap .editable-controls .btn.dropdown-toggle, .table .editable-wrap .editable-error .btn.dropdown-toggle {
      padding: 3px 20px;
      margin-top: 3px; }
  .table .editable-wrap .editable-controls input, .table .editable-wrap .editable-error input {
    line-height: 1px;
    height: 30px; }

.form-inline button[type="submit"].editable-table-button {
  margin-left: 0; }

.table > thead > tr > th {
  border-bottom: none; }

.table > tbody > tr.no-top-border:first-child > td {
  border-top: none; }

.black-muted-bg {
  background-color: rgba(0, 0, 0, 0.1); }

.table-hover > tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.1); }

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid gainsboro; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1); }

.table > tbody > tr.primary > td {
  background-color: rgba(194, 222, 68, 0.7);
  color: #ffffff;
  border: none; }
  .table > tbody > tr.primary > td a.email-link {
    color: #ffffff; }
    .table > tbody > tr.primary > td a.email-link:hover {
      color: #f1f1f1; }

.table > tbody > tr.success > td {
  background-color: rgba(144, 185, 0, 0.7);
  color: #ffffff;
  border: none; }
  .table > tbody > tr.success > td a.email-link {
    color: #ffffff; }
    .table > tbody > tr.success > td a.email-link:hover {
      color: #f1f1f1; }

.table > tbody > tr.warning > td {
  background-color: rgba(223, 184, 28, 0.7);
  color: #ffffff;
  border: none; }
  .table > tbody > tr.warning > td a.email-link {
    color: #ffffff; }
    .table > tbody > tr.warning > td a.email-link:hover {
      color: #f1f1f1; }

.table > tbody > tr.danger > td {
  background-color: rgba(241, 241, 241, 0.7);
  color: #ffffff;
  border: none; }
  .table > tbody > tr.danger > td a.email-link {
    color: #ffffff; }
    .table > tbody > tr.danger > td a.email-link:hover {
      color: #f1f1f1; }

.table > tbody > tr.info > td {
  background-color: rgba(1, 123, 193, 0.7);
  color: #ffffff;
  border: none; }
  .table > tbody > tr.info > td a.email-link {
    color: #ffffff; }
    .table > tbody > tr.info > td a.email-link:hover {
      color: #f1f1f1; }

.editable-click, a.editable-click {
  color: #666666;
  border-bottom: dashed 1px #d6d6d6; }

th {
  font-weight: 400; }

.editable-empty {
  color: #cdcdcd; }

.table > tbody > tr > th {
  border: none; }

.table-striped > tbody > tr > td {
  border: none; }

.pagination > li > a,
.pagination > li > span {
  color: #666666;
  border-color: #d6d6d6; }

.pagination > li:first-of-type > a,
.pagination > li:first-of-type > span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.pagination > li:last-of-type > a,
.pagination > li:last-of-type > span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #c2de44;
  border-color: #d6d6d6; }

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #666666; }

.editable-buttons .btn-with-icon i {
  margin-right: 0; }

.table-responsive {
  margin-top: 10px; }

#google-maps {
  width: 100%;
  height: calc(100vh - 283px); }

#leaflet-map {
  height: calc(100vh - 283px); }

/* required styles */
.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
  max-width: none !important; }

/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important; }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-tile-pane {
  z-index: 2; }

.leaflet-objects-pane {
  z-index: 3; }

.leaflet-overlay-pane {
  z-index: 4; }

.leaflet-shadow-pane {
  z-index: 5; }

.leaflet-marker-pane {
  z-index: 6; }

.leaflet-popup-pane {
  z-index: 7; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-clickable {
  cursor: pointer; }

.leaflet-container {
  cursor: -webkit-grab;
  cursor: -moz-grab; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078A8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65); }
  .leaflet-bar a {
    background-color: #a5bd3a;
    border-bottom: 1px solid #ccc;
    display: block;
    text-align: center;
    text-decoration: none;
    opacity: 0.8;
    color: #666666;
    font-size: 17px;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-family: Lato;
    font-weight: 100; }
    .leaflet-bar a:hover {
      background-color: #f1f1f1; }
    .leaflet-bar a:last-child {
      border-bottom: none; }
    .leaflet-bar a.leaflet-control-zoom-out {
      line-height: 16px; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #e2e2e2;
  color: #dddddd; }

/* zoom control */
.leaflet-bar .leaflet-control-zoom-in,
.leaflet-bar .leaflet-control-zoom-out {
  line-height: 18px; }
  .leaflet-bar .leaflet-control-zoom-in:hover,
  .leaflet-bar .leaflet-control-zoom-out:hover {
    width: 20px;
    height: 20px; }

.leaflet-control-zoom-out {
  font-size: 20px; }

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px; }

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #ffffff; }

.leaflet-control-layers-toggle {
  background-image: url(../img/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(../img/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #ffffff; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #ffffff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left; }

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
  color: #929497; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: 700;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #ffffff;
  border: 1px solid #666; }

#map-bubbles {
  width: 100%;
  height: calc(100vh - 283px);
  font-size: 11px; }

/*
#chartdiv1 {
  background: #3f3f4f;
  color:#ffffff;
  width		: 100%;
  height		: 500px;
  font-size	: 11px;
}*/
#map-lines {
  width: 100%;
  height: calc(100vh - 283px); }

.panel {
  color: #666666;
  background-color: #ffffff;
  border: none;
  position: relative;
  margin-bottom: 24px; }
  .panel ::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em; }
  .panel ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer; }
  .panel ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.7); }
  .panel body {
    scrollbar-face-color: rgba(0, 0, 0, 0.6);
    scrollbar-track-color: rgba(255, 255, 255, 0.7); }
  .panel.animated {
    animation-duration: 0.5s; }
  .panel.small-panel {
    height: 114px; }
  .panel.xsmall-panel {
    height: 187px; }
  .panel.medium-panel {
    height: 400px; }
  .panel.xmedium-panel {
    height: 550px; }
  .panel.large-panel {
    height: 974px; }
  .panel.viewport100 {
    height: calc(100vh - 218px); }
  .panel.with-scroll .panel-body {
    height: calc(100% - 44px);
    overflow-y: auto; }

.panel.panel-blur {
  background: url("../../assets/img/blur-bg-blurred.jpg");
  transition: none;
  background-attachment: fixed; }

.panel > .panel-body {
  padding: 0px 0px !important;
  height: 100%; }

.panel > .panel-heading {
  color: #666666;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.panel > .panel-footer {
  color: #666666; }

.panel-heading, .panel-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
  font-size: 16px;
  padding: 14px 22px; }

.panel-title {
  font-weight: 400;
  font-family: "PT Sans", sans-serif;
  color: #017bc1;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.2px; }
  .panel-title a:hover {
    color: #017bc1; }

.panel-default > .panel-heading {
  color: #7d7d7d; }

.panel-default > .panel-footer {
  color: #7d7d7d; }

.panel-primary > .panel-heading {
  background-color: #cee569;
  border-color: #cee569; }

.panel-success > .panel-heading {
  background-color: #a6c733;
  border-color: #a6c733; }

.panel-info > .panel-heading {
  background-color: #3495cd;
  border-color: #3495cd; }

.panel-warning > .panel-heading {
  background-color: #e5c649;
  border-color: #e5c649; }

.panel-danger > .panel-heading {
  background-color: #f4f4f4;
  border-color: #f4f4f4; }

.bootstrap-panel {
  background-color: #ffffff; }
  .bootstrap-panel .panel-body {
    color: #7d7d7d; }

.accordion-panel.panel.panel-primary .panel-heading p, .accordion-panel.panel.panel-primary .panel-heading div, .accordion-panel.panel.panel-primary .panel-heading span, .accordion-panel.panel.panel-success .panel-heading p, .accordion-panel.panel.panel-success .panel-heading div, .accordion-panel.panel.panel-success .panel-heading span, .accordion-panel.panel.panel-info .panel-heading p, .accordion-panel.panel.panel-info .panel-heading div, .accordion-panel.panel.panel-info .panel-heading span, .accordion-panel.panel.panel-warning .panel-heading p, .accordion-panel.panel.panel-warning .panel-heading div, .accordion-panel.panel.panel-warning .panel-heading span, .accordion-panel.panel.panel-danger .panel-heading p, .accordion-panel.panel.panel-danger .panel-heading div, .accordion-panel.panel.panel-danger .panel-heading span {
  color: rgba(255, 255, 255, 0.8); }

.panel-group .panel.accordion-panel .panel-heading {
  border-bottom: 0; }

.panel-group .panel .panel-heading {
  border-bottom: 1px solid #ddd; }

.p-with-code {
  line-height: 1.5em; }

.contextual-example-panel {
  height: 120px; }

.footer-panel {
  height: 142px; }

.light-text {
  font-weight: 300; }

/*
.panel-group .panel {
  border-radius: 0;
}*/
.panel-group .panel .panel-body {
  padding: 0px 0px 0px 0px;
  border: none; }

/** Different tabs positions, which were removed from bootstrap */
.tabs-below .nav-tabs, .tabs-right .nav-tabs, .tabs-left .nav-tabs {
  border-bottom: 0; }

.tabs-right .nav-tabs, .tabs-left .nav-tabs {
  min-width: 100px; }

.tabs-right .tab-content, .tabs-left .tab-content {
  width: calc(100% - 100px);
  overflow-y: auto; }

.tabs-right .tab-content {
  margin-right: 100px; }

.tabs-left .tab-content {
  margin-left: 100px; }

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none; }

.tab-content > .active,
.pill-content > .active {
  display: block; }

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0; }

.tabs-left, .tabs-right {
  height: 100%; }
  .tabs-left > .nav-tabs > li, .tabs-right > .nav-tabs > li {
    float: none;
    margin-bottom: 0; }
    .tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
      min-width: 74px;
      margin-right: 0;
      margin-bottom: 3px; }

.tabs-left > .nav-tabs {
  float: left;
  border-bottom-left-radius: 5px; }
  .tabs-left > .nav-tabs > li > a {
    margin-right: -1px; }

.tabs-right > .nav.nav-tabs {
  float: right;
  border-top-left-radius: 0;
  border-bottom-right-radius: 5px; }
  .tabs-right > .nav.nav-tabs > li:first-of-type a {
    border-top-left-radius: 0; }

/** /Different tabs positions, which were removed from bootstrap */
.nav-tabs > li.with-dropdown > a {
  padding: 0; }

.nav-tabs > li.with-dropdown .dropdown-toggle {
  padding: 10px 15px;
  display: inline-block;
  cursor: pointer; }

.tab-content {
  padding: 15px 15px 5px 15px;
  background: transparent;
  color: #666666; }
  .tab-content .tab-pane p {
    color: #666666; }

.nav.nav-tabs {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid transparent;
  background-color: #d4e87c; }
  .nav.nav-tabs a {
    color: #ffffff; }
    .nav.nav-tabs a:hover {
      color: #ffffff; }
  .nav.nav-tabs > li > a {
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
    border: none; }
    .nav.nav-tabs > li > a:hover, .nav.nav-tabs > li > a:focus {
      border: none;
      background-color: #d4e87c; }
  .nav.nav-tabs > li.active > a {
    color: #ffffff;
    background-color: #c2de44; }
  .nav.nav-tabs > li:first-of-type a {
    border-top-left-radius: 5px; }
  .nav.nav-tabs .dropdown-menu > li > a {
    color: #929497; }
    .nav.nav-tabs .dropdown-menu > li > a:hover {
      color: #929497; }

.blur-theme .nav.nav-tabs {
  background-color: rgba(0, 0, 0, 0.2); }
  .blur-theme .nav.nav-tabs a {
    color: #666666; }
    .blur-theme .nav.nav-tabs a:hover {
      color: #666666; }
  .blur-theme .nav.nav-tabs > li > a:hover {
    background-color: rgba(0, 0, 0, 0.2); }
  .blur-theme .nav.nav-tabs > li.active > a {
    color: #666666;
    background-color: rgba(0, 0, 0, 0.25); }

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border: none; }

.accordion-panel .panel-heading {
  border-radius: 3px; }

.accordion-panel.panel-open .panel-heading {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion-panel:not(.panel-open) .panel-heading {
  transition-delay: .3s; }

.accordion-panel > .panel-heading + .panel-collapse > .panel-body {
  border-top: none; }

.accordion-panel .panel-heading {
  background-color: transparent;
  padding: 0; }
  .accordion-panel .panel-heading .accordion-toggle {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    padding: 60px 0px 20px 0px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px; }

/*
.panel-group {
  > .panel {
    > .panel-heading {
      padding: 0;

      > h4.panel-title {
        height: 50px;
        width: 100%;
        padding: 0;

        > a {
          display: block;
          padding: 15px 22px;
          width: 100%;
        }
      }
    }
  }

}

.panel-collapse {
  transition: height $default-animation-duration $default-animation-style;
  .panel-body {
    padding: 15px;
  }
}*/
.ba-wizard-progress {
  margin-top: 10px;
  height: 10px; }

.ba-wizard-pager .previous {
  float: left; }

.ba-wizard-pager .next {
  float: right; }

.ba-wizard-navigation-container {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 15px 0; }
  .ba-wizard-navigation-container .ba-wizard-navigation {
    cursor: pointer;
    display: table-cell;
    line-height: 1;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    opacity: 0.7; }
    .ba-wizard-navigation-container .ba-wizard-navigation.active {
      opacity: 0.95; }

.back-top {
  width: 52px;
  height: 52px;
  cursor: pointer;
  z-index: 9999;
  display: none;
  text-decoration: none;
  right: 64px;
  bottom: 40px !important;
  font-size: 45px;
  text-align: center;
  opacity: 0.4;
  color: #c2de44;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  line-height: 46px; }
  .back-top:hover {
    opacity: 0.8; }

.content-top {
  padding-top: 13px;
  padding-bottom: 27px; }

h1.al-title {
  font-weight: 700;
  color: #666666;
  float: left;
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 24px;
  text-transform: uppercase;
  opacity: 0.9; }

.al-breadcrumb {
  background: none;
  color: #666666;
  padding: 0;
  margin: 0;
  float: right;
  padding-top: 11px; }
  .al-breadcrumb li {
    font-size: 18px;
    font-weight: 400; }

.al-look {
  float: right;
  margin-right: 10px;
  padding-top: 10px; }
  .al-look > a {
    font-size: 19px; }

/* 
* new header 
*/
.header-upper-wrapper {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat no-repeat;
  background-color: transparent;
  width: 100%;
  background-image: url("../../assets/pictures/blue-bg.png"); }

.header-upper {
  width: 100%;
  padding: 20px 0 20px 0px;
  text-align: center; }
  .header-upper p {
    font-family: "PT Sans", sans-serif;
    color: #ffffff; }
    .header-upper p.highlight, .header-upper p .highlight {
      color: #c2de44; }
    .header-upper p.big {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
    .header-upper p.title {
      font-size: 52px;
      line-height: 60px;
      font-weight: bold; }
      .header-upper p.title:last-child {
        margin: 0 0 70px 0; }
  .header-upper .icon {
    /*@include bg-picture('icon-treatments.png');*/
    background-size: 100%;
    /*border:1px solid red;*/
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 20px; }
  .header-upper span {
    display: inline-block;
    vertical-align: middle; }

.al-footer .bottom-wrapper {
  background-color: #3e4349;
  height: 60px; }
  .al-footer .bottom-wrapper .footer-copyright {
    text-align: left; }
    .al-footer .bottom-wrapper .footer-copyright p {
      line-height: 60px;
      color: #b2b4b6;
      margin: 0 auto;
      font-size: 12px; }
      .al-footer .bottom-wrapper .footer-copyright p a {
        color: #b2b4b6; }
        .al-footer .bottom-wrapper .footer-copyright p a:hover {
          color: #ffffff; }
  .al-footer .bottom-wrapper .footer-term {
    text-align: right; }
    .al-footer .bottom-wrapper .footer-term p {
      line-height: 58px;
      color: #b2b4b6;
      margin: 0 auto; }
      .al-footer .bottom-wrapper .footer-term p a {
        color: #b2b4b6;
        font-size: 12px; }
        .al-footer .bottom-wrapper .footer-term p a:hover {
          color: #ffffff; }
  @media (max-width: 992px) {
    .al-footer .bottom-wrapper {
      height: auto;
      padding: 10px 0; }
      .al-footer .bottom-wrapper .footer-copyright p {
        line-height: 24px;
        font-size: 12px; }
      .al-footer .bottom-wrapper .footer-term {
        text-align: left; }
        .al-footer .bottom-wrapper .footer-term p {
          line-height: 24px; } }

.al-footer .logo-footer {
  background-color: #282E34;
  height: 75px;
  line-height: 75px; }
  @media (max-width: 767px) {
    .al-footer .logo-footer {
      line-height: normal;
      height: auto; } }
  .al-footer .logo-footer .box-logo-footer .mbplogo {
    max-width: 220px; }
  .al-footer .logo-footer .box-logo-footer .arthritislogo {
    max-width: 73px; }
  @media (max-width: 767px) {
    .al-footer .logo-footer .box-logo-footer {
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 767px) {
    .al-footer .logo-footer .box-logo-footer .mbplogo {
      padding: 10px; } }
  .al-footer .logo-footer .box-logo-footer .mbplogo img {
    border-right: 1px solid #ccc;
    padding-right: 15px; }
    @media (max-width: 767px) {
      .al-footer .logo-footer .box-logo-footer .mbplogo img {
        padding-right: 20px;
        padding-left: 20px; } }
  .al-footer .logo-footer .box-logo-footer .arthritislogo {
    padding: 0; }
    @media (max-width: 767px) {
      .al-footer .logo-footer .box-logo-footer .arthritislogo {
        padding: 10px; } }
    @media (max-width: 767px) {
      .al-footer .logo-footer .box-logo-footer .arthritislogo img {
        padding-right: 10px; } }
  .al-footer .logo-footer .box-logo-footer img {
    width: 100%; }
  .al-footer .logo-footer .box-contact {
    float: right; }
    @media (max-width: 767px) {
      .al-footer .logo-footer .box-contact {
        float: none;
        clear: both;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #31363c;
        margin: 0 -15px; } }
    .al-footer .logo-footer .box-contact a {
      color: #ffffff;
      position: relative;
      padding-left: 35px; }
      .al-footer .logo-footer .box-contact a:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        background-image: url("../../assets/img/app/icon-contact.png");
        background-size: 20px 15px;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
        display: inline-block;
        height: 30px;
        width: 30px; }
        @media (max-width: 767px) {
          .al-footer .logo-footer .box-contact a:before {
            background-color: transparent;
            background-image: url("../../assets/img/app/icon-contact-res.png"); } }

@media (max-width: 992px) {
  .al-footer .menu-footer {
    display: none !important; } }

.al-footer .menu-footer {
  background-color: #282E34;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #53585d; }
  .al-footer .menu-footer .col-menu {
    float: left;
    margin-right: 40px; }
    .al-footer .menu-footer .col-menu:last-child {
      margin-right: 0; }
  .al-footer .menu-footer .box-menu-footer .title-menu {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 10px; }
  .al-footer .menu-footer .box-menu-footer .menu ul {
    padding: 0; }
    .al-footer .menu-footer .box-menu-footer .menu ul li {
      padding: 5px 0; }
      .al-footer .menu-footer .box-menu-footer .menu ul li:before {
        content: "";
        margin: 0 auto;
        padding: 0; }
      .al-footer .menu-footer .box-menu-footer .menu ul li a {
        color: #94979a;
        font-family: "Lato", Arial;
        font-size: 12px;
        font-weight: normal;
        position: relative;
        padding-right: 20px; }
        .al-footer .menu-footer .box-menu-footer .menu ul li a:hover {
          color: #ffffff; }
        .al-footer .menu-footer .box-menu-footer .menu ul li a:after {
          content: "";
          position: absolute;
          right: 0;
          top: 60%;
          background-image: url("../../assets/img/app/arrow/arrow-right.png");
          background-repeat: no-repeat;
          display: inline-block;
          height: 10px;
          width: 10px;
          background-size: contain;
          transform: translateY(-50%); }

@media only screen and (max-width: 767px) {
  .al-footer .bottom-wrapper .footer-copyright p {
    font-size: 12px; } }

/* msg center */
@-webkit-keyframes pulsate {
  30% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0; }
  35% {
    opacity: 1.0; }
  40% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0; } }

.al-msg-center {
  float: right;
  padding: 0;
  list-style: none;
  margin: 13px 47px 0 0; }
  .al-msg-center li {
    list-style: none;
    float: left;
    margin-left: 30px; }
    .al-msg-center li:first-child {
      margin-left: 0; }
    .al-msg-center li > a {
      color: #aaaaaa;
      text-decoration: none;
      font-size: 13px;
      position: relative; }
      .al-msg-center li > a span {
        display: inline-block;
        min-width: 10px;
        padding: 2px 4px 2px 4px;
        color: #aaaaaa;
        vertical-align: baseline;
        white-space: nowrap;
        text-align: center;
        border-radius: 13px;
        text-shadow: none;
        line-height: 11px;
        background-color: #f1f1f1;
        position: absolute;
        top: -5px;
        right: -14px;
        font-size: 11px; }
      .al-msg-center li > a .notification-ring {
        border: 1px solid #f1f1f1;
        border-radius: 100px;
        height: 40px;
        width: 40px;
        position: absolute;
        top: -18px;
        right: -27px;
        animation: pulsate 8s ease-out;
        animation-iteration-count: infinite;
        opacity: 0.0; }
      .al-msg-center li > a:hover {
        color: #f1f1f1; }
        .al-msg-center li > a:hover.msg {
          color: #c2de44; }
      .al-msg-center li > a.msg span {
        background-color: #c2de44; }
      .al-msg-center li > a.msg .notification-ring {
        border-color: #c2de44; }
    .al-msg-center li.open > a {
      color: #f1f1f1; }
      .al-msg-center li.open > a.msg {
        color: #c2de44; }

@media (max-width: 435px) {
  .al-msg-center {
    margin-right: 20px; }
    .al-msg-center li {
      margin-left: 20px; }
      .al-msg-center li:first-child {
        margin-left: 0; } }

.msg-block-header {
  display: inline-block;
  padding: 0;
  font-size: 13px;
  margin: 0 0 0 6px; }

.fixedsticky {
  top: 50px;
  z-index: 99; }

body {
  overflow-y: scroll; }
  @media (max-width: 992px) {
    body.is-active {
      overflow: hidden; } }

.master-content {
  padding-top: 140px; }
  @media (max-width: 767px) {
    .master-content {
      padding-top: 50px; } }

#site-container {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s; }
  @media (max-width: 992px) {
    #site-container.is-active {
      position: fixed;
      transform: translateX(-270px); } }

.modal .sn-link-close {
  color: #177bbe;
  font-size: 40px; }
  .modal .sn-link-close:hover {
    color: #177bbe; }

.modal .modal-header .close {
  margin-top: -5px;
  text-shadow: none; }

.page-top {
  width: 100%;
  margin: 0 auto;
  height: 70px;
  line-height: 70px;
  position: relative;
  /*overflow: hidden;*/ }
  @media only screen and (min-width: 993px) {
    .page-top .container {
      padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .page-top {
      height: 50px;
      line-height: 50px; } }
  .page-top .box-logo-header {
    float: left;
    width: 30%; }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      .page-top .box-logo-header {
        width: 36%; } }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .page-top .box-logo-header {
        width: 40%; } }
    @media only screen and (max-width: 419px) {
      .page-top .box-logo-header {
        width: 70%; } }
    .page-top .box-logo-header .page-top-logo img {
      width: 100%; }
    .page-top .box-logo-header .page-top-logo.mbp-logo {
      padding-right: 0; }
      @media only screen and (min-width: 768px) {
        .page-top .box-logo-header .page-top-logo.mbp-logo {
          width: 192px; } }
      .page-top .box-logo-header .page-top-logo.mbp-logo img {
        border-right: 1px solid #cbcaca;
        padding-right: 15px; }
    @media only screen and (min-width: 768px) {
      .page-top .box-logo-header .page-top-logo.arthritis-logo {
        width: 96px; } }
  .page-top .box-search-donate {
    float: left;
    width: 45%; }
    @media only screen and (max-width: 992px) {
      .page-top .box-search-donate {
        display: none; } }
    .page-top .box-search-donate.auth {
      width: 60%; }
      @media only screen and (min-width: 768px) {
        .page-top .box-search-donate.auth {
          width: 55%; } }
    .page-top .box-search-donate .box-action-search .box-search {
      float: right;
      width: 55%; }
      .page-top .box-search-donate .box-action-search .box-search .search {
        line-height: 70px;
        padding: 0 30px;
        text-align: right;
        margin-left: 15px; }
        .page-top .box-search-donate .box-action-search .box-search .search i {
          top: 0;
          right: 15px; }
          .page-top .box-search-donate .box-action-search .box-search .search i.left {
            -webkit-transition: all .5s;
            transition: all .5s;
            right: 85%; }
        .page-top .box-search-donate .box-action-search .box-search .search input {
          background: transparent;
          padding-left: 15px; }
    .page-top .box-search-donate .box-action-search .box-donate {
      float: left;
      width: 45%; }
      .page-top .box-search-donate .box-action-search .box-donate .top-menu {
        text-align: center; }
        .page-top .box-search-donate .box-action-search .box-donate .top-menu p {
          line-height: 70px;
          margin-bottom: 0; }
          .page-top .box-search-donate .box-action-search .box-donate .top-menu p a {
            font-family: "Lato", Sans-Serif;
            font-size: 16px;
            color: #037bc0; }
      .page-top .box-search-donate .box-action-search .box-donate .community {
        padding-right: 0; }
      .page-top .box-search-donate .box-action-search .box-donate .donate {
        padding-left: 0; }
  .page-top .box-user-profile {
    float: left;
    width: 25%;
    position: relative;
    line-height: normal;
    height: 70px; }
    .page-top .box-user-profile.auth {
      width: 15%;
      padding: 0 20px;
      background-color: #f3f4f4; }
    @media only screen and (max-width: 992px) {
      .page-top .box-user-profile {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      .page-top .box-user-profile {
        width: 65%; } }
    .page-top .box-user-profile .top-dropdown-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background: #ffffff;
      z-index: 99;
      padding: 0;
      width: 100%; }
      .page-top .box-user-profile .top-dropdown-menu li {
        line-height: normal; }
        .page-top .box-user-profile .top-dropdown-menu li:hover {
          background: #177bbe; }
          .page-top .box-user-profile .top-dropdown-menu li:hover a {
            color: #ffffff; }
            .page-top .box-user-profile .top-dropdown-menu li:hover a i {
              -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
        .page-top .box-user-profile .top-dropdown-menu li a {
          font-size: 14px;
          font-family: "Lato";
          color: #282e34;
          position: relative;
          padding: 20px 10px 20px 40px;
          display: block; }
          .page-top .box-user-profile .top-dropdown-menu li a:hover {
            color: #ffffff; }
          .page-top .box-user-profile .top-dropdown-menu li a i {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat; }
            .page-top .box-user-profile .top-dropdown-menu li a i.icon-edit-account {
              height: 18px;
              width: 18px;
              background-image: url("../../assets/img/app/menu-icons/edit-profile.png"); }
            .page-top .box-user-profile .top-dropdown-menu li a i.icon-logout {
              height: 18px;
              width: 23px;
              background-image: url("../../assets/img/app/menu-icons/icon-logout.png"); }
    .page-top .box-user-profile .al-user-profile {
      line-height: 70px; }
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        .page-top .box-user-profile .al-user-profile {
          line-height: 50px; } }
      .page-top .box-user-profile .al-user-profile .user-drop-down {
        color: #282e34;
        font-size: 16px;
        font-family: "Lato";
        display: inline-block;
        cursor: pointer;
        line-height: normal;
        width: 100%;
        position: relative; }
        .page-top .box-user-profile .al-user-profile .user-drop-down:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 3.5px 0 3.5px;
          border-color: #797d81 transparent transparent transparent;
          margin: 0 auto;
          position: absolute;
          top: 50%;
          right: 0;
          cursor: pointer; }
    .page-top .box-user-profile .box-action-user {
      display: inline-block;
      width: 100%;
      line-height: 70px;
      background: #f3f4f4; }
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        .page-top .box-user-profile .box-action-user {
          background: transparent; } }
      .page-top .box-user-profile .box-action-user .action-login {
        float: left;
        width: 30%; }
        @media only screen and (max-width: 992px) {
          .page-top .box-user-profile .box-action-user .action-login {
            display: none; } }
        .page-top .box-user-profile .box-action-user .action-login p {
          margin-bottom: 0;
          line-height: 70px;
          text-align: center; }
          .page-top .box-user-profile .box-action-user .action-login p a {
            font-family: "Lato", Sans-Serif;
            font-size: 16px;
            color: #037bc0; }
      .page-top .box-user-profile .box-action-user .action-signup-free {
        float: left;
        width: 70%; }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
          .page-top .box-user-profile .box-action-user .action-signup-free {
            width: 80%;
            text-align: right; } }
        @media only screen and (max-width: 767px) {
          .page-top .box-user-profile .box-action-user .action-signup-free {
            display: none; } }
        .page-top .box-user-profile .box-action-user .action-signup-free .btn {
          padding: 10px 31px;
          font-family: "Lato", Sans-Serif;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff; }
          @media only screen and (min-width: 993px) and (max-width: 1199px) {
            .page-top .box-user-profile .box-action-user .action-signup-free .btn {
              padding: 10px 20px; } }

.search {
  text-shadow: none;
  color: #017bc1;
  font-size: 24px;
  line-height: 24px;
  transition: all 0.5s ease;
  white-space: nowrap;
  position: relative; }
  .search p {
    line-height: 40px;
    height: 100px;
    margin: 0px; }
  .search label {
    cursor: pointer; }
  .search i {
    width: 16px;
    display: inline-block;
    cursor: pointer;
    padding-left: 1px;
    font-size: 24px;
    margin-right: 13px;
    transition: all .5s;
    position: absolute;
    top: 10px;
    right: 0; }
    .search i.left {
      transition: all .5s;
      right: 100%; }
  .search input {
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    line-height: 30px;
    width: 0;
    transition: all .5s; }
    .search input.expand {
      width: 100%;
      border-bottom: 1px solid; }
    .search input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #4da3d4; }
    .search input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #4da3d4;
      opacity: 1; }
    .search input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #4da3d4;
      opacity: 1; }
    .search input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #4da3d4; }

/*.safari {
  .top-menu-wrapper {
    .menu {
      float: right;
      height: auto;
      ul {
        float: none;
        line-height: 1.2;
        > li {
          a {
            //padding: 25px 20px 26px;
            padding: 25px 10px;
          }
          li {
            a {
              padding: 25px 20px !important;
            }
          }
        }
      }
    }
  }
}*/
.logged .top-menu-wrapper .menu ul {
  margin: 0px auto; }

.top-menu-wrapper {
  background-color: #282E34; }
  .top-menu-wrapper .menu {
    display: block;
    height: 70px; }
    .top-menu-wrapper .menu > ul > li {
      float: left; }
      .top-menu-wrapper .menu > ul > li:hover, .top-menu-wrapper .menu > ul > li.active {
        border-bottom: 3px solid #bfd554;
        height: 70px; }
    .top-menu-wrapper .menu ul {
      margin: 0;
      line-height: 70px;
      float: right;
      z-index: 2;
      padding-left: 0; }
      .top-menu-wrapper .menu ul > li {
        list-style: none;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        position: relative;
        height: 70px;
        /*&.active, &:hover {
          > a {
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0;
              height: 3px;
              !*background-color: #bfd554;*!
              !*transition: all .1s ease;*!
              transition: all 0.6s;
              -webkit-transition: all;
              -ms-transition: all 0.6s;
              -moz-transition: all 0.6s;
            }
          }
        }*/ }
        .top-menu-wrapper .menu ul > li .sub-menu {
          position: absolute;
          top: 0;
          opacity: 0;
          left: 0;
          display: none;
          min-width: 100%;
          padding: 0;
          background-color: #ffffff;
          transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -ms-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s; }
          .top-menu-wrapper .menu ul > li .sub-menu li {
            display: block;
            text-align: left;
            margin: 0;
            cursor: pointer; }
            .top-menu-wrapper .menu ul > li .sub-menu li:hover, .top-menu-wrapper .menu ul > li .sub-menu li:active {
              background-color: #f2f3f3; }
            .top-menu-wrapper .menu ul > li .sub-menu li a {
              width: 100%;
              padding: 0 20px 0 20px;
              /*@media only screen and (min-width: 810px) and (max-width: 1026px) {
                padding: 0 15px 0 15px;
              }
              @media only screen and (min-width: 768px) and (max-width: 790px) {
                padding: 0 10px 0 10px;
              }*/ }
              .top-menu-wrapper .menu ul > li .sub-menu li a i {
                color: #bfd554;
                padding-right: 0px;
                height: 20px;
                width: 20px;
                display: inline-block;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
                background-size: contain;
                vertical-align: middle; }
                .top-menu-wrapper .menu ul > li .sub-menu li a i:before {
                  content: ' '; }
        .top-menu-wrapper .menu ul > li:hover {
          background-color: #33393e;
          /*border-bottom: 3px solid #bfd554;*/ }
          .top-menu-wrapper .menu ul > li:hover .sub-menu {
            top: 100%;
            opacity: 1;
            left: 0;
            z-index: 1;
            margin-top: 3px;
            /*border-top: 3px solid #bfd554;*/
            display: inline-block;
            transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -ms-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68);
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68); }
            .top-menu-wrapper .menu ul > li:hover .sub-menu li a {
              color: #000; }
              .top-menu-wrapper .menu ul > li:hover .sub-menu li a:before {
                content: "";
                height: 0; }
            .top-menu-wrapper .menu ul > li:hover .sub-menu.last {
              right: 0;
              left: auto; }
        .top-menu-wrapper .menu ul > li a {
          display: inline-block;
          color: #ffffff;
          white-space: nowrap;
          position: relative;
          padding: 0 20px 0 20px;
          height: 70px;
          font-size: 14px;
          font-family: "Lato", Arial; }
          @media only screen and (min-width: 810px) and (max-width: 1026px) {
            .top-menu-wrapper .menu ul > li a {
              padding: 0 13px 0 13px; } }
          @media only screen and (min-width: 768px) and (max-width: 810px) {
            .top-menu-wrapper .menu ul > li a {
              padding: 0 9px 0 9px; } }
          .top-menu-wrapper .menu ul > li a.sub:after {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-right: 2px solid #bfd554;
            border-top: 2px solid #bfd554;
            transform: rotate(135deg);
            margin-left: 10px; }
          .top-menu-wrapper .menu ul > li a i {
            /*color: $primary;*/
            color: #bfd554;
            padding-right: 20px;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            display: inline;
            background-size: contain;
            background-repeat: no-repeat; }
            .top-menu-wrapper .menu ul > li a i:before {
              content: ' '; }
          .top-menu-wrapper .menu ul > li a i.ion-android-home {
            background-image: url("../../assets/img/app/menu-icons/home.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-dashboard {
            background-image: url("../../assets/img/app/menu-icons/dashboard.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-information-circled {
            background-image: url("../../assets/img/app/menu-icons/abp.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-ionic {
            background-image: url("../../assets/img/app/menu-icons/treatments.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-ios-play {
            background-image: url("../../assets/img/app/menu-icons/library.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-treatment {
            background-image: url("../../assets/img/app/menu-icons/treatment.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-hcp {
            background-image: url("../../assets/img/app/menu-icons/hcp.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-guide {
            background-image: url("../../assets/img/app/menu-icons/guide.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-ios-alarm-outline {
            background-image: url("../../assets/img/app/menu-icons/aboutus.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-ios-albums {
            background-image: url("../../assets/img/app/menu-icons/help.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-guide-me {
            background-image: url("../../assets/img/app/menu-icons/guide-me.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-donate {
            background-image: url("../../assets/img/app/menu-icons/donate.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-contact {
            background-image: url("../../assets/img/app/menu-icons/contact.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-do-it-yourself {
            background-image: url("../../assets/img/app/menu-icons/do-it-yourself.png"); }
          .top-menu-wrapper .menu ul > li a i.ion-for-family-and-friends {
            background-image: url("../../assets/img/app/menu-icons/for-family-and-friends.png"); }
      @media only screen and (min-width: 992px) and (max-width: 1220px) {
        .top-menu-wrapper .menu ul > li {
          /*margin: 0px 10px;*/ } }
  @media (max-width: 767px) {
    .top-menu-wrapper {
      display: none; } }

.fixed {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 89;
  background: #ffffff;
  transform: translateX(0); }
  @media (max-width: 992px) {
    .fixed.is-active {
      transform: translateX(-270px); } }

@media (max-width: 767px) {
  .fixed {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px; } }

.navbar {
  position: fixed;
  z-index: 91;
  width: 310px;
  height: 100%;
  border-radius: 0;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border: none; }
  @media (max-width: 480px) {
    .navbar {
      width: calc(100% - 50px); } }
  @media (max-width: 992px) {
    .navbar.is-active {
      transform: translateX(0); } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .navbar.is-active .navbar-logo {
      display: block; } }
  .navbar #navbar-nav {
    background-color: #282e34;
    padding-left: 0px;
    margin-bottom: 0; }
    .navbar #navbar-nav li {
      line-height: 50px;
      position: relative;
      border-bottom: 1px solid #535858; }
      .navbar #navbar-nav li.click > a {
        background-color: #464a50; }
      .navbar #navbar-nav li a:active, .navbar #navbar-nav li a:focus, .navbar #navbar-nav li a:hover {
        background-color: #464a50; }
      .navbar #navbar-nav li.has-sub.click > a {
        background-color: transparent; }
      .navbar #navbar-nav li.has-sub span {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 15px;
        width: 10px;
        height: 10px;
        border-right: 2px solid #bed74b;
        border-top: 2px solid #bed74b;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: all 0.6s;
        -webkit-transition: all 0.6s;
        -ms-transition: all 0.6s;
        -moz-transition: all 0.6s;
        margin-left: 10px; }
        .navbar #navbar-nav li.has-sub span.active {
          -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
          top: 18px; }
      .navbar #navbar-nav li a {
        color: #fff;
        display: inline-block;
        padding-left: 15px;
        width: 100%; }
      .navbar #navbar-nav li ul {
        padding: 0;
        padding-left: 0px; }
        .navbar #navbar-nav li ul.sub-menu {
          background: #33383e;
          max-height: 0;
          overflow: hidden;
          transition: all 0.6s;
          -webkit-transition: all 0.6s; }
          .navbar #navbar-nav li ul.sub-menu li {
            position: relative; }
            .navbar #navbar-nav li ul.sub-menu li:active, .navbar #navbar-nav li ul.sub-menu li:focus {
              background: #464a50; }
            .navbar #navbar-nav li ul.sub-menu li.click {
              background: #464a50; }
              .navbar #navbar-nav li ul.sub-menu li.click:after {
                content: "";
                height: 100%;
                width: 4px;
                background: #bed74b;
                position: absolute;
                right: 0;
                top: 0; }
            .navbar #navbar-nav li ul.sub-menu li:last-child {
              border-bottom: none; }
          .navbar #navbar-nav li ul.sub-menu i {
            color: #c2de44;
            padding-right: 0px;
            height: 20px;
            width: 20px;
            display: inline-block;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            vertical-align: middle; }
            .navbar #navbar-nav li ul.sub-menu i:before {
              content: ' '; }
        .navbar #navbar-nav li ul.active {
          display: block;
          height: auto;
          max-height: 500px;
          border-top: 1px solid #535858; }
    .navbar #navbar-nav i {
      color: #c2de44;
      padding-right: 20px;
      margin-right: 10px;
      height: 50px;
      width: 50px;
      display: inline;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat no-repeat; }
      .navbar #navbar-nav i:before {
        content: ' '; }
    .navbar #navbar-nav i.ion-android-home {
      background-image: url("../../assets/img/app/menu-icons/home.png"); }
    .navbar #navbar-nav i.ion-information-circled {
      background-image: url("../../assets/img/app/menu-icons/abp.png"); }
    .navbar #navbar-nav i.ion-ionic {
      background-image: url("../../assets/img/app/menu-icons/treatments.png"); }
    .navbar #navbar-nav i.ion-dashboard {
      background-image: url("../../assets/img/app/menu-icons/dashboard.png"); }
    .navbar #navbar-nav i.ion-ios-play {
      background-image: url("../../assets/img/app/menu-icons/library.png"); }
    .navbar #navbar-nav i.ion-treatment {
      background-image: url("../../assets/img/app/menu-icons/treatment.png"); }
    .navbar #navbar-nav i.ion-donate {
      background-image: url("../../assets/img/app/menu-icons/donate.png"); }
    .navbar #navbar-nav i.ion-contact {
      background-image: url("../../assets/img/app/menu-icons/contact.png"); }
    .navbar #navbar-nav i.ion-hcp {
      background-image: url("../../assets/img/app/menu-icons/hcp.png"); }
    .navbar #navbar-nav i.ion-guide {
      background-image: url("../../assets/img/app/menu-icons/guide.png"); }
    .navbar #navbar-nav i.ion-ios-alarm-outline {
      background-image: url("../../assets/img/app/menu-icons/aboutus.png"); }
    .navbar #navbar-nav i.ion-ios-albums {
      background-image: url("../../assets/img/app/menu-icons/help.png"); }
    .navbar #navbar-nav i.ion-guide-me {
      background-image: url("../../assets/img/app/menu-icons/guide-me.png"); }
    .navbar #navbar-nav i.ion-do-it-yourself {
      background-image: url("../../assets/img/app/menu-icons/do-it-yourself.png"); }
    .navbar #navbar-nav i.ion-for-family-and-friends {
      background-image: url("../../assets/img/app/menu-icons/for-family-and-friends.png"); }
  .navbar .navbar-logo {
    position: fixed;
    top: 0;
    right: 0;
    width: 310px;
    display: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .navbar .navbar-logo {
        display: none; } }
    .navbar .navbar-logo .box-logo {
      background-color: #f2f3f3; }
      .navbar .navbar-logo .box-logo .box-logo-menures {
        height: 70px;
        line-height: 70px;
        width: 100%; }
        @media (max-width: 767px) {
          .navbar .navbar-logo .box-logo .box-logo-menures {
            height: 50px;
            line-height: 50px; } }
        .navbar .navbar-logo .box-logo .box-logo-menures .mbp-logo {
          padding-right: 0;
          float: left;
          display: none; }
          .navbar .navbar-logo .box-logo .box-logo-menures .mbp-logo img {
            width: 158px;
            margin-left: 15px;
            border-right: 1px solid #cbcaca;
            padding-right: 15px;
            padding-left: 15px; }
        .navbar .navbar-logo .box-logo .box-logo-menures .arthritis-logo {
          float: left;
          display: none; }
          .navbar .navbar-logo .box-logo .box-logo-menures .arthritis-logo img {
            width: 58px;
            margin-left: 15px; }
  .navbar .navbar-black {
    margin-top: 0px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 100px;
    position: relative;
    background-color: #282e34;
    height: 100%; }
    .navbar .navbar-black::-webkit-scrollbar {
      display: none; }
    .navbar .navbar-black .box-action-dobate {
      background-color: #282e34;
      padding: 20px; }
      .navbar .navbar-black .box-action-dobate a {
        padding: 10px;
        color: #ffffff;
        border: 1px solid #535858;
        width: 100%;
        display: block;
        text-align: center;
        line-height: normal;
        font-size: 14px;
        font-family: "Lato";
        font-weight: normal; }
    .navbar .navbar-black .box-action-menures {
      background: #3e4349;
      position: relative;
      line-height: 70px;
      border-bottom: 1px solid #535858; }
      @media (max-width: 767px) {
        .navbar .navbar-black .box-action-menures {
          line-height: 50px; } }
      .navbar .navbar-black .box-action-menures .user-none-login {
        width: auto; }
        @media (max-width: 480px) {
          .navbar .navbar-black .box-action-menures .user-none-login {
            width: calc(100% - 50px); } }
      .navbar .navbar-black .box-action-menures .action-signup {
        float: left;
        width: 145px;
        background-color: #177bbe; }
        @media (max-width: 480px) {
          .navbar .navbar-black .box-action-menures .action-signup {
            width: 55%; } }
        .navbar .navbar-black .box-action-menures .action-signup button {
          border-radius: 0;
          width: 100%;
          background-color: #177bbe;
          font-size: 14px;
          text-transform: uppercase;
          height: 70px;
          padding: 0;
          vertical-align: inherit; }
          @media (max-width: 767px) {
            .navbar .navbar-black .box-action-menures .action-signup button {
              height: 50px; } }
      .navbar .navbar-black .box-action-menures .action-login {
        float: left;
        width: 115px;
        background-color: #1a88cc; }
        @media (max-width: 480px) {
          .navbar .navbar-black .box-action-menures .action-login {
            width: 45%; } }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .navbar .navbar-black .box-action-menures .action-login {
            /* IE10+ CSS styles go here */
            width: 80px; } }
        .navbar .navbar-black .box-action-menures .action-login button {
          border-radius: 0;
          width: 100%;
          background-color: #1a88cc;
          font-size: 14px;
          text-transform: uppercase;
          height: 70px;
          padding: 0;
          vertical-align: inherit; }
          @media (max-width: 767px) {
            .navbar .navbar-black .box-action-menures .action-login button {
              height: 50px; } }
      .navbar .navbar-black .box-action-menures .user-logined {
        float: left;
        width: 260px;
        padding-left: 20px;
        background: #282e34; }
        @media (max-width: 480px) {
          .navbar .navbar-black .box-action-menures .user-logined {
            width: calc(100% - 50px); } }
        .navbar .navbar-black .box-action-menures .user-logined .user-drop-down-res {
          color: #ffffff;
          font-size: 16px;
          font-family: "Lato";
          display: inline-block;
          position: relative;
          cursor: pointer;
          width: 100%;
          line-height: normal; }
          .navbar .navbar-black .box-action-menures .user-logined .user-drop-down-res i {
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translateY(-50%);
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1); }
            .navbar .navbar-black .box-action-menures .user-logined .user-drop-down-res i.icon-logout {
              height: 12px;
              width: 15px;
              background-image: url("../../assets/img/app/menu-icons/icon-logout.png"); }
      .navbar .navbar-black .box-action-menures .top-dropdown-menu-res {
        display: none;
        background: #3e4349;
        z-index: 99;
        padding: 0;
        margin-bottom: 0; }
        .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li {
          line-height: normal;
          padding: 15px 20px;
          border-bottom: 1px solid #535858; }
          .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li:first-child {
            border-top: 1px solid #535858; }
          .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li:hover {
            background: #177bbe; }
            .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li:hover a {
              color: #ffffff; }
              .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li:hover a i {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1); }
          .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li a {
            font-size: 14px;
            font-family: "Lato";
            color: #ffffff;
            position: relative;
            padding-left: 30px; }
            .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li a:hover {
              color: #ffffff; }
            .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li a i {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              margin-right: 10px;
              background-size: contain;
              background-repeat: no-repeat; }
              .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li a i.icon-edit-account {
                height: 18px;
                width: 18px;
                background-image: url("../../assets/img/app/menu-icons/edit-profile.png"); }
              .navbar .navbar-black .box-action-menures .top-dropdown-menu-res li a i.icon-logout {
                height: 18px;
                width: 23px;
                background-image: url("../../assets/img/app/menu-icons/icon-logout.png"); }
      .navbar .navbar-black .box-action-menures .action-search {
        float: left;
        width: 50px;
        transition: all .5s;
        text-align: center;
        position: absolute;
        right: 0; }
        .navbar .navbar-black .box-action-menures .action-search .search-res {
          color: #ffffff;
          height: 70px; }
          @media (max-width: 767px) {
            .navbar .navbar-black .box-action-menures .action-search .search-res {
              height: 50px; } }
          .navbar .navbar-black .box-action-menures .action-search .search-res i {
            width: 16px;
            display: inline-block;
            cursor: pointer;
            padding-left: 1px;
            font-size: 24px;
            transition: all .5s; }
      .navbar .navbar-black .box-action-menures .box-form-search-res {
        display: none;
        height: 70px;
        background: #3e4349;
        margin: 0 10px; }
        @media (max-width: 767px) {
          .navbar .navbar-black .box-action-menures .box-form-search-res {
            height: 50px; } }
        .navbar .navbar-black .box-action-menures .box-form-search-res input {
          border: none;
          outline: none;
          font-size: 16px;
          line-height: 25px;
          color: #ffffff;
          background: transparent;
          width: 100%; }

@media (max-width: 992px) {
  .overlay-bg.is-active {
    position: fixed;
    margin-top: 70px;
    top: 0;
    left: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    overflow: hidden; } }
  @media (max-width: 992px) and (max-width: 767px) {
    .overlay-bg.is-active {
      margin-top: 50px; } }

.icon-menu-responsive .navbar-toggle {
  position: absolute;
  z-index: 999;
  top: 0;
  left: -70px;
  margin: 0;
  height: 70px;
  width: 70px;
  background: #f1f1f2;
  padding: 20px;
  cursor: pointer;
  display: none;
  border-radius: 0;
  /*transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;*/ }
  @media (max-width: 992px) {
    .icon-menu-responsive .navbar-toggle {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .icon-menu-responsive .navbar-toggle {
      height: 50px;
      width: 50px;
      padding: 10px;
      left: -50px; } }
  .icon-menu-responsive .navbar-toggle .icon-bar {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;
    background-color: #1b8bcf;
    margin-top: 5px;
    width: 25px; }
  .icon-menu-responsive .navbar-toggle.is-active {
    background: #ffffff;
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2); }
    .icon-menu-responsive .navbar-toggle.is-active .icon-bar {
      background-color: #1b8bcf; }
      .icon-menu-responsive .navbar-toggle.is-active .icon-bar:nth-child(1) {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        margin-top: 13px;
        margin-left: 2px;
        position: absolute; }
      .icon-menu-responsive .navbar-toggle.is-active .icon-bar:nth-child(2) {
        display: none; }
      .icon-menu-responsive .navbar-toggle.is-active .icon-bar:nth-child(3) {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        margin-top: 13px;
        margin-left: 3px; }

#frmUpdatetPassword .box-password {
  position: relative; }
  #frmUpdatetPassword .box-password strong {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-family: "Lato";
    font-weight: normal; }

#frmUpdatetPassword .form-group {
  margin-bottom: 10px; }

input[type=text], input[type=email], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.al-sidebar {
  width: 432px;
  top: 70px;
  left: 0;
  z-index: 904;
  display: block;
  min-height: 100%;
  background-color: #272e33;
  height: 100%;
  position: fixed; }

.al-sidebar-list {
  margin: 0;
  padding: 18px 0 0 0;
  list-style: none; }

.al-sidebar-sublist .subitem-submenu-list {
  padding-left: 15px; }

.subitem-submenu-link .fa {
  top: 7px; }

.al-sidebar-list-item {
  display: block;
  position: relative;
  float: none;
  padding: 7px 0px 7px 0px;
  margin: 20px 0px 20px 0px; }
  .al-sidebar-list-item i.ion-android-home {
    background-image: url("../../assets/img/app/menu-icons/home.png"); }
  .al-sidebar-list-item i.ion-information-circled {
    background-image: url("../../assets/img/app/menu-icons/abp.png"); }
  .al-sidebar-list-item i.ion-ionic {
    background-image: url("../../assets/img/app/menu-icons/treatments.png"); }
  .al-sidebar-list-item i.ion-ios-alarm {
    background-image: url("../../assets/img/app/menu-icons/library.png"); }
  .al-sidebar-list-item i.ion-ios-alarm-outline {
    background-image: url("../../assets/img/app/menu-icons/hcp.png"); }
  .al-sidebar-list-item i.ion-ios-albums {
    background-image: url("../../assets/img/app/menu-icons/help.png"); }
  .al-sidebar-list-item.selected:not(.with-sub-menu) {
    background-color: #32383d; }
    .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link {
      color: #ffffff; }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i {
        color: #c2de44; }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-android-home {
        background-image: url("../../assets/img/app/menu-icons/home-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-information-circled {
        background-image: url("../../assets/img/app/menu-icons/abp-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-ionic {
        background-image: url("../../assets/img/app/menu-icons/treatments-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-ios-alarm {
        background-image: url("../../assets/img/app/menu-icons/library-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-ios-alarm-outline {
        background-image: url("../../assets/img/app/menu-icons/hcp-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link i.ion-ios-albums {
        background-image: url("../../assets/img/app/menu-icons/help-selected.png"); }
      .al-sidebar-list-item.selected:not(.with-sub-menu) a.al-sidebar-list-link b {
        color: #ffffff; }

.al-sidebar-list-item.ba-sidebar-item-expanded > .al-sidebar-list-link b, .ba-sidebar-sublist-item.ba-sidebar-item-expanded > .al-sidebar-list-link b {
  transform: rotate(180deg); }

.al-sidebar-list-item.ba-sidebar-item-expanded > .al-sidebar-sublist, .ba-sidebar-sublist-item.ba-sidebar-item-expanded > .al-sidebar-sublist {
  display: block; }

a.al-sidebar-list-link {
  display: block;
  height: 64px;
  padding-left: 95px;
  text-shadow: none;
  font-size: 20px;
  text-decoration: none;
  color: #aaaaaa;
  line-height: 64px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer; }
  a.al-sidebar-list-link:hover {
    color: #c2de44; }
    a.al-sidebar-list-link:hover b, a.al-sidebar-list-link:hover i {
      color: #c2de44; }
  a.al-sidebar-list-link i {
    margin-right: 18px;
    width: 50px;
    display: inline-block;
    color: #c2de44;
    font-size: 18px;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat no-repeat; }
    a.al-sidebar-list-link i:before {
      content: ' '; }
  a.al-sidebar-list-link b {
    display: block;
    opacity: 1;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-shadow: none;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 12px;
    padding: 0;
    text-align: center;
    color: #aaaaaa;
    transition: transform 0.2s linear; }

.slimScrollBar, .slimScrollRail {
  border-radius: 0px !important;
  width: 4px !important;
  left: 176px; }

.al-sidebar-sublist {
  padding: 0;
  list-style: none;
  position: relative;
  display: none; }
  .al-sidebar-sublist.expanded {
    display: block; }
  .al-sidebar-sublist > li {
    display: block;
    float: none;
    padding: 0;
    border-bottom: none;
    position: relative; }
    .al-sidebar-sublist > li a {
      display: block;
      text-shadow: none;
      font-size: 13px;
      text-decoration: none;
      color: #aaaaaa;
      padding-left: 52px;
      height: auto;
      line-height: 29px; }
      .al-sidebar-sublist > li a:hover {
        color: #c2de44; }
    .al-sidebar-sublist > li.selected:not(.with-sub-menu) > a {
      border: none;
      background-color: #c2de44; }
      .al-sidebar-sublist > li.selected:not(.with-sub-menu) > a:hover {
        color: #aaaaaa; }

.sidebar-hover-elem {
  width: 9px;
  background: #c2de44;
  position: absolute;
  top: -70px;
  left: 423px;
  transition: all 0.5s ease;
  transition-property: top, height;
  height: 162px;
  display: block; }

.sidebar-select-elem {
  display: block;
  top: 94px; }

@media (min-width: 501px) {
  .menu-collapsed .al-sidebar {
    width: 50px; }
    .menu-collapsed .al-sidebar .fa-angle-down, .menu-collapsed .al-sidebar .fa-angle-up {
      opacity: 0; }
    .menu-collapsed .al-sidebar .al-sidebar-sublist {
      position: absolute;
      top: -1px;
      left: 52px;
      background: rgba(0, 0, 0, 0.8);
      width: 0;
      display: block;
      overflow: hidden;
      transition: width 0.5s ease; }
      .menu-collapsed .al-sidebar .al-sidebar-sublist.slide-right {
        width: 135px; }
      .menu-collapsed .al-sidebar .al-sidebar-sublist:before {
        display: none; }
      .menu-collapsed .al-sidebar .al-sidebar-sublist li:before {
        display: none; }
      .menu-collapsed .al-sidebar .al-sidebar-sublist li a {
        padding-left: 18px;
        padding-right: 18px;
        min-width: 130px;
        white-space: nowrap; }
    .menu-collapsed .al-sidebar .sidebar-hover-elem, .menu-collapsed .al-sidebar .sidebar-select-elem {
      left: 42px; }
    .menu-collapsed .al-sidebar a.al-sidebar-list-link {
      padding-left: 18px; } }

@media (max-width: 1200px) {
  .al-sidebar {
    width: 432px;
    background: rgba(0, 0, 0, 0.75);
    transition: width 0.5s ease; }
    .al-sidebar .fa-angle-down, .al-sidebar .fa-angle-up {
      opacity: 1; }
    .al-sidebar .al-sidebar-sublist {
      padding: 0;
      list-style: none;
      position: relative;
      display: none;
      top: auto;
      left: auto;
      background: none;
      width: auto;
      overflow: visible;
      transition: none; }
      .al-sidebar .al-sidebar-sublist.expanded {
        display: block; }
      .al-sidebar .al-sidebar-sublist > li {
        display: block;
        float: none;
        padding: 0;
        border-bottom: none;
        position: relative; }
        .al-sidebar .al-sidebar-sublist > li a {
          display: block;
          text-shadow: none;
          font-size: 13px;
          text-decoration: none;
          color: #aaaaaa;
          padding-left: 52px;
          height: auto;
          line-height: 29px; }
          .al-sidebar .al-sidebar-sublist > li a:hover {
            color: #c2de44; }
        .al-sidebar .al-sidebar-sublist > li.selected:not(.with-sub-menu) > a {
          border: none;
          background-color: #c2de44; }
          .al-sidebar .al-sidebar-sublist > li.selected:not(.with-sub-menu) > a:hover {
            color: #aaaaaa; }
    .al-sidebar .sidebar-hover-elem, .al-sidebar .sidebar-select-elem {
      left: 428px;
      transition: left 0.5s ease; } }

@media (max-width: 500px) {
  .menu-collapsed .al-sidebar {
    width: 0; }
  .menu-collapsed .sidebar-hover-elem, .menu-collapsed .sidebar-select-elem {
    display: none; }
  .al-main {
    margin-left: 0; }
  .al-footer {
    padding-left: 0; } }

.widgets {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden; }

.full-width {
  margin: 0 -22px -16px; }

.horizontal-scroll {
  overflow-x: auto;
  overflow-y: visible; }

.panel-subtitle {
  font-size: 15px;
  margin-bottom: 16px; }

.panel-nav {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 2; }
  .panel-nav i {
    cursor: pointer;
    font-size: 14px;
    color: #bfbfbf;
    margin-left: 11px;
    display: inline-block; }
    .panel-nav i.fa-minus {
      margin-bottom: -1px;
      vertical-align: bottom; }
    .panel-nav i:hover {
      color: #666666; }

#amchart {
  width: 100%;
  height: 350px;
  margin-top: -20px; }

#amChartMap {
  width: 100%;
  height: 315px; }

.feed-panel .panel-body {
  padding: 10px 0; }

.feed-message {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12); }
  .feed-message:first-child {
    padding-top: 0; }
  .feed-message .message-icon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    float: left;
    position: relative;
    margin-left: 20px; }
    .feed-message .message-icon > img, .feed-message .message-icon .media-icon {
      border-radius: 30px;
      width: 100%;
      height: 100%; }
    .feed-message .message-icon .sub-photo-icon {
      display: inline-block;
      padding: 4px; }
      .feed-message .message-icon .sub-photo-icon:after {
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        background-size: contain; }
      .feed-message .message-icon .sub-photo-icon.video-message {
        background: #f1f1f1; }
        .feed-message .message-icon .sub-photo-icon.video-message:after {
          background-image: url("../../assets/img/theme/icon/feed/feed-video.svg"); }
      .feed-message .message-icon .sub-photo-icon.image-message {
        background: #90b900; }
        .feed-message .message-icon .sub-photo-icon.image-message:after {
          width: 21px;
          height: 21px;
          margin-top: 1px;
          margin-left: 1px;
          border-radius: 5px;
          background-image: url("../../assets/img/theme/icon/feed/feed-image.svg"); }
      .feed-message .message-icon .sub-photo-icon.geo-message {
        background: #c2de44; }
        .feed-message .message-icon .sub-photo-icon.geo-message:after {
          width: 22px;
          height: 22px;
          background-image: url("../../assets/img/theme/icon/feed/feed-location.svg"); }
    .feed-message .message-icon .sub-photo-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      right: -2px;
      bottom: -4px;
      border-radius: 15px; }
  .feed-message .text-block {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    margin: 0 0 0 80px;
    padding: 5px 20px;
    color: #666666;
    width: 280px;
    height: 70px; }
    .feed-message .text-block.text-message {
      font-size: 12px;
      width: inherit;
      max-width: calc(100% - 80px);
      height: inherit;
      min-height: 60px; }
      .feed-message .text-block.text-message:before {
        display: block; }
      .feed-message .text-block.text-message .message-content {
        font-size: 12px;
        line-height: 15px;
        font-weight: 300; }
    .feed-message .text-block.small-message {
      width: 155px;
      height: 145px; }
      .feed-message .text-block.small-message .preview {
        bottom: 0;
        top: initial;
        height: 87px; }
        .feed-message .text-block.small-message .preview img {
          width: 155px;
          height: 87px;
          border-radius: 0 0 5px 5px; }
  .feed-message .message-header {
    font-size: 12px;
    padding-bottom: 5px; }
    .feed-message .message-header .author {
      font-size: 13px;
      padding-right: 5px; }
  .feed-message .message-content {
    font-size: 18px;
    line-height: 20px; }
  .feed-message .preview {
    transition: 0s linear all;
    display: inline-block; }
    .feed-message .preview img {
      padding-top: 10px;
      width: 100%;
      height: auto;
      float: none !important; }
  .feed-message .message-time {
    width: 100%;
    left: 0;
    font-size: 11px;
    padding-top: 10px;
    color: #949494;
    margin-bottom: 5px; }
    .feed-message .message-time .post-time {
      float: left; }
    .feed-message .message-time .ago-time {
      float: right; }

.line-clamp {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .line-clamp:after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.2); } }

@supports (-webkit-line-clamp: 1) {
  .line-clamp:after {
    display: none !important; } }

.line-clamp-1 {
  -webkit-line-clamp: 1;
  height: calc(1em * 1.2 * 1); }

.line-clamp-2 {
  -webkit-line-clamp: 2;
  height: calc(1em * 1.2 * 2); }

.line-clamp-3 {
  -webkit-line-clamp: 3;
  height: calc(1em * 1.2 * 3); }

.line-clamp-4 {
  -webkit-line-clamp: 4;
  height: calc(1em * 1.2 * 4); }

.line-clamp-5 {
  -webkit-line-clamp: 5;
  height: calc(1em * 1.2 * 5); }

div.blurCalendar {
  font-size: 12px; }

.fc {
  direction: ltr;
  text-align: left; }
  .fc button {
    box-sizing: border-box;
    margin: 0;
    height: 2.1em;
    padding: 0 .6em;
    font-size: 1em;
    white-space: nowrap;
    cursor: pointer; }
    .fc button::-moz-focus-inner {
      margin: 0;
      padding: 0; }
    .fc button .fc-icon {
      position: relative;
      top: 0;
      margin: 0; }
  .fc .fc-button-group > * {
    float: left;
    margin: 0 0 0 -1px; }
  .fc .fc-button-group > :first-child {
    margin-left: 0; }
  .fc hr {
    height: 0;
    margin: 0;
    padding: 0 0 2px;
    border-style: solid;
    border-width: 1px 0; }
  .fc table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em; }
  .fc th {
    text-align: center; }
  .fc th, .fc td {
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0;
    vertical-align: top; }
  .fc td.fc-today {
    border-style: double; }
  .fc .fc-row {
    border: 0 solid; }
  .fc .fc-toolbar > * > * {
    float: left;
    margin-left: .75em; }
  .fc .fc-toolbar > * > :first-child {
    margin-left: 0; }
  .fc .fc-axis {
    vertical-align: middle;
    padding: 0 4px;
    white-space: nowrap; }

.fc-rtl {
  text-align: right; }

.fc-unthemed th, .fc-unthemed td, .fc-unthemed hr, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: rgba(214, 214, 214, 0.3); }

.fc-unthemed .fc-popover {
  background-color: #ffffff;
  border: 1px solid; }
  .fc-unthemed .fc-popover .fc-header {
    background: #eee; }
    .fc-unthemed .fc-popover .fc-header .fc-close {
      color: #666666;
      font-size: 25px;
      margin-top: 4px; }

.fc-unthemed hr {
  background: #eee; }

.fc-unthemed .fc-today {
  background: rgba(255, 255, 255, 0.15); }

.fc-highlight {
  background: rgba(255, 255, 255, 0.25);
  opacity: .3; }

.fc-icon {
  display: inline-block;
  font-size: 2em;
  font-family: "Courier New", Courier, monospace; }

.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: 700;
  font-size: 100%; }

.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: 700;
  font-size: 100%; }

.fc-icon-left-double-arrow:after {
  content: "\000AB"; }

.fc-icon-right-double-arrow:after {
  content: "\000BB"; }

.fc-icon-x:after {
  content: "\000D7"; }

.fc-state-default {
  border: 1px solid;
  outline: none;
  background: #f5f5f5 repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  color: #333333; }
  .fc-state-default.fc-corner-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .fc-state-default.fc-corner-right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e2e2e2; }

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  transition: background-position 0.1s linear; }

.fc-state-down,
.fc-state-active {
  background: #cccccc none; }

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none; }

.fc-button-group {
  display: inline-block; }

.fc-popover {
  position: absolute; }
  .fc-popover .fc-header {
    padding: 2px 4px; }
  .fc-popover .fc-header .fc-title {
    margin: 0 2px; }
  .fc-popover .fc-header .fc-close {
    cursor: pointer; }

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right; }

.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0; }

.fc-clear {
  clear: both; }

.fc-bg,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-bg {
  bottom: 0; }

.fc-bg table {
  height: 100%; }

.fc-row {
  position: relative; }
  .fc-row table {
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
    border-bottom: 0 hidden transparent; }
  .fc-row:first-child table {
    border-top: 0 hidden transparent; }
  .fc-row .fc-bg {
    z-index: 1; }
  .fc-row .fc-highlight-skeleton {
    z-index: 2;
    bottom: 0; }
    .fc-row .fc-highlight-skeleton table {
      height: 100%; }
    .fc-row .fc-highlight-skeleton td {
      border-color: transparent; }
  .fc-row .fc-content-skeleton {
    position: relative;
    z-index: 3;
    padding-bottom: 2px; }
  .fc-row .fc-helper-skeleton {
    z-index: 4; }
  .fc-row .fc-content-skeleton td,
  .fc-row .fc-helper-skeleton td {
    background: none;
    border-color: transparent;
    border-bottom: 0; }
  .fc-row .fc-content-skeleton tbody td,
  .fc-row .fc-helper-skeleton tbody td {
    border-top: 0; }

.fc-event {
  position: relative;
  display: block;
  font-size: .85em;
  line-height: 1.3;
  border: 1px solid #c2de44;
  background-color: #c2de44;
  font-weight: 400; }

.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #ffffff;
  text-decoration: none; }

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer; }

.fc-day-grid-event {
  margin: 1px 2px 0;
  padding: 0 1px; }

.fc-ltr .fc-day-grid-event.fc-not-start,
.fc-rtl .fc-day-grid-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-day-grid-event.fc-not-end,
.fc-rtl .fc-day-grid-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.fc-day-grid-event > .fc-content {
  white-space: nowrap;
  overflow: hidden; }

.fc-day-grid-event .fc-time {
  font-weight: 700; }

.fc-day-grid-event .fc-resizer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 7px; }

.fc-ltr .fc-day-grid-event .fc-resizer {
  right: -3px;
  cursor: e-resize; }

.fc-rtl .fc-day-grid-event .fc-resizer {
  left: -3px;
  cursor: w-resize; }

a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none; }
  a.fc-more:hover {
    text-decoration: underline; }

.fc-limited {
  display: none; }

.fc-day-grid .fc-row {
  z-index: 1; }

.fc-more-popover {
  z-index: 2;
  width: 220px; }
  .fc-more-popover .fc-event-container {
    padding: 10px; }

.fc-toolbar {
  text-align: center;
  margin-bottom: 1em; }
  .fc-toolbar .fc-left {
    float: left; }
  .fc-toolbar .fc-right {
    float: right; }
  .fc-toolbar .fc-center {
    display: inline-block; }
  .fc-toolbar h2 {
    margin: 0;
    font-size: 24px;
    width: 100%;
    line-height: 26px; }
  .fc-toolbar button {
    position: relative; }
  .fc-toolbar .fc-state-hover, .fc-toolbar .ui-state-hover {
    z-index: 2; }
  .fc-toolbar .fc-state-down {
    z-index: 3; }
  .fc-toolbar .fc-state-active,
  .fc-toolbar .ui-state-active {
    z-index: 4; }
  .fc-toolbar button:focus {
    z-index: 5; }

.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  box-sizing: content-box; }

.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1; }

.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-top: 1px;
  padding-bottom: 1em; }

.fc-basic-view tbody .fc-row {
  min-height: 4em;
  max-height: 70px; }

.fc-row.fc-rigid {
  overflow: hidden; }

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 0 2px; }

.fc-basic-view td.fc-week-number span,
.fc-basic-view td.fc-day-number {
  padding-top: 2px;
  padding-bottom: 2px; }

.fc-basic-view .fc-week-number {
  text-align: center; }

.fc-basic-view .fc-week-number span {
  display: inline-block;
  min-width: 1.25em; }

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: right; }

.fc-rtl .fc-basic-view .fc-day-number {
  text-align: left; }

.fc-day-number.fc-other-month {
  opacity: 0.3; }

.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2; }

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em; }

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 1px;
  padding-bottom: 1em; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

.ui-widget td.fc-axis {
  font-weight: 400; }

.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1; }

.fc-time-grid {
  min-height: 100%; }

.fc-time-grid table {
  border: 0 hidden transparent; }

.fc-time-grid > .fc-bg {
  z-index: 1; }

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-highlight-skeleton {
  z-index: 3; }

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0; }

.fc-time-grid > .fc-helper-skeleton {
  z-index: 5; }

.fc-slats td {
  height: 1.5em;
  border-bottom: 0; }

.fc-slats .fc-minor td {
  border-top-style: dotted; }

.fc-slats .ui-widget-content {
  background: none; }

.fc-time-grid .fc-highlight-container {
  position: relative; }

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0; }

.fc-time-grid .fc-event-container {
  position: relative; }

.fc-ltr .fc-time-grid .fc-event-container {
  margin: 0 2.5% 0 2px; }

.fc-rtl .fc-time-grid .fc-event-container {
  margin: 0 2px 0 2.5%; }

.fc-time-grid .fc-event {
  position: absolute;
  z-index: 1; }

.fc-time-grid-event {
  overflow: hidden; }
  .fc-time-grid-event.fc-not-start {
    border-top-width: 0;
    padding-top: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .fc-time-grid-event.fc-not-end {
    border-bottom-width: 0;
    padding-bottom: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .fc-time-grid-event > .fc-content {
    position: relative;
    z-index: 2; }
  .fc-time-grid-event .fc-title {
    padding: 0 1px; }
  .fc-time-grid-event .fc-time {
    padding: 0 1px;
    font-size: .85em;
    white-space: nowrap; }
  .fc-time-grid-event .fc-bg {
    z-index: 1;
    background: #ffffff;
    opacity: .25;
    filter: alpha(opacity=25); }
  .fc-time-grid-event.fc-short .fc-content {
    white-space: nowrap; }
  .fc-time-grid-event.fc-short .fc-time {
    display: inline-block;
    vertical-align: top; }
    .fc-time-grid-event.fc-short .fc-time span {
      display: none; }
    .fc-time-grid-event.fc-short .fc-time:before {
      content: attr(data-start); }
    .fc-time-grid-event.fc-short .fc-time:after {
      content: "\000A0-\000A0"; }
  .fc-time-grid-event.fc-short .fc-title {
    display: inline-block;
    vertical-align: top;
    font-size: .85em;
    padding: 0; }
  .fc-time-grid-event .fc-resizer {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    overflow: hidden;
    line-height: 8px;
    font-size: 11px;
    font-family: monospace;
    text-align: center;
    cursor: s-resize; }
    .fc-time-grid-event .fc-resizer:after {
      content: "="; }

.fc-day-grid-container.fc-scroller {
  height: auto !important; }

.calendar-panel.panel .panel-body {
  padding: 0; }

.fc-body > tr > .fc-widget-content {
  border: none; }

.fc-head {
  color: #ffffff;
  background-color: #c2de44; }
  .fc-head td, .fc-head th {
    border: none; }
  .fc-head div.fc-widget-header {
    padding: 5px 0; }

.fc-today-button, .fc-month-button, .fc-agendaWeek-button, .fc-agendaDay-button {
  display: none; }

.blurCalendar {
  margin-top: 15px; }

.fc-prev-button, .fc-next-button {
  position: absolute;
  background: transparent;
  box-shadow: none;
  border: none;
  color: #666666; }

.fc-next-button {
  left: 30px; }

.fc-day-number {
  color: #666666;
  opacity: 0.9; }

.pie-charts {
  color: #666666; }
  .pie-charts .pie-chart-item-container {
    position: relative;
    padding: 0 15px;
    float: left;
    box-sizing: border-box; }
    .pie-charts .pie-chart-item-container .panel {
      height: 114px; }
  @media screen and (min-width: 1325px) {
    .pie-charts .pie-chart-item-container {
      width: 25%; } }
  @media screen and (min-width: 700px) and (max-width: 1325px) {
    .pie-charts .pie-chart-item-container {
      width: 50%; } }
  @media screen and (max-width: 700px) {
    .pie-charts .pie-chart-item-container {
      width: 100%; } }
  .pie-charts .pie-chart-item {
    position: relative; }
    .pie-charts .pie-chart-item .chart-icon {
      position: absolute;
      right: 0px;
      top: 3px;
      z-index: -1; }
  @media screen and (min-width: 1325px) and (max-width: 1650px), (min-width: 700px) and (max-width: 830px), (max-width: 400px) {
    .pie-charts .chart-icon {
      display: none; } }
  .pie-charts .chart {
    position: relative;
    display: inline-block;
    width: 84px;
    height: 84px;
    text-align: center;
    float: left; }
  .pie-charts .chart canvas {
    position: absolute;
    top: 0;
    left: 0; }
  .pie-charts .percent {
    display: inline-block;
    line-height: 84px;
    z-index: 2;
    font-size: 16px; }
  .pie-charts .percent:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em; }
  .pie-charts .description {
    display: inline-block;
    padding: 20px 0 0 20px;
    font-size: 18px;
    opacity: 0.9; }
    .pie-charts .description .description-stats {
      padding-top: 8px;
      font-size: 24px; }
  .pie-charts .angular {
    margin-top: 100px; }
  .pie-charts .angular .chart {
    margin-top: 0; }

.panel.popular-app > .panel-body {
  padding: 0; }

.panel.popular-app .popular-app-img-container {
  position: relative;
  padding: 30px 0;
  height: 260px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px; }
  .panel.popular-app .popular-app-img-container .popular-app-img {
    width: 260px;
    position: absolute;
    transform: translateY(-50%) translate(-50%);
    top: 50%;
    left: 50%; }
    .panel.popular-app .popular-app-img-container .popular-app-img .logo-text {
      display: inline-block;
      margin: 10px;
      font-size: 26px;
      color: #666666; }

.panel.popular-app .popular-app-cost {
  font-size: 20px;
  padding: 20px 22px;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro; }

.panel.popular-app .popular-app-info {
  padding: 20px 22px;
  font-size: 20px;
  text-align: center; }
  .panel.popular-app .popular-app-info .info-label {
    font-size: 12px; }

.panel.popular-app .row {
  margin: 0; }
  .panel.popular-app .row > div {
    padding: 0; }

.blur-theme .panel.popular-app .popular-app-img-container {
  background: rgba(0, 0, 0, 0.5); }

#cd-timeline {
  margin-top: 15px;
  position: relative; }
  #cd-timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed; }

.cd-timeline-block {
  position: relative;
  margin: 2em 0; }
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both; }
  .cd-timeline-block:first-child {
    margin-top: 0; }
  .cd-timeline-block:last-child {
    margin-bottom: 0; }

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%; }

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden; }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s; } }

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  60% {
    opacity: 1;
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #c2de44;
  padding: 1em;
  border-radius: 5px; }
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both; }
  .cd-timeline-content h2 {
    color: #303e49;
    margin: 0;
    font-size: 18px; }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 13px;
    display: inline-block; }
  .cd-timeline-content p {
    font-size: 13px;
    margin-bottom: 0; }
  .cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: white;
    border-radius: 0.25em; }
  .cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7; }
  .cd-timeline-content:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid white; }

.cd-timeline-content.warning {
  background: rgba(223, 184, 28, 0.2); }

.cd-timeline-content.danger {
  background: rgba(241, 241, 241, 0.2); }

.cd-timeline-content.primary {
  background: rgba(194, 222, 68, 0.2); }

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #d6d6d6; }

@media only screen and (min-width: 1170px) {
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px; }
  .cd-timeline-block {
    margin: 4em 0; }
    .cd-timeline-block:first-child {
      margin-top: 0; }
    .cd-timeline-block:last-child {
      margin-bottom: 0; }
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 42%; }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white; }
    .cd-timeline-content .cd-read-more {
      float: left; }
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 137%;
      top: 6px;
      font-size: 16px; }
  .cd-timeline-content.warning:before {
    border-left-color: rgba(223, 184, 28, 0.2); }
  .cd-timeline-content.danger:before {
    border-left-color: rgba(241, 241, 241, 0.2); }
  .cd-timeline-content.primary:before {
    border-left-color: rgba(194, 222, 68, 0.2); }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.warning::before {
    border-right-color: rgba(223, 184, 28, 0.2); }
  .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.danger::before {
    border-right-color: rgba(241, 241, 241, 0.2); }
  .cd-timeline-block:nth-child(even) .cd-timeline-content.cd-timeline-content.primary::before {
    border-right-color: rgba(194, 222, 68, 0.2); }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 137%;
    text-align: right; }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden; }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    animation: cd-bounce-2 0.6s; } }

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    animation: cd-bounce-2-inverse 0.6s; } }

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px); }
  60% {
    opacity: 1;
    transform: translateX(20px); }
  100% {
    transform: translateX(0); } }

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px); }
  60% {
    opacity: 1;
    transform: translateX(-20px); }
  100% {
    transform: translateX(0); } }

input.task-todo {
  margin-bottom: 8px; }

ul.todo-list {
  margin: 0;
  padding: 0; }
  ul.todo-list li {
    margin: 0 0 -1px 0;
    padding: 12px;
    list-style: none;
    position: relative;
    border: 1px solid #cbcbcb;
    cursor: grab;
    height: 42px; }
    ul.todo-list li i.remove-todo {
      position: absolute;
      cursor: pointer;
      top: 0px;
      right: 12px;
      font-size: 32px;
      transition: color 0.2s;
      color: rgba(203, 203, 203, 0.5);
      visibility: hidden;
      line-height: 42px; }
      ul.todo-list li i.remove-todo:hover {
        color: #cbcbcb; }
    ul.todo-list li:hover i.remove-todo {
      visibility: visible; }
    ul.todo-list li.checked .todo-text {
      color: #666666; }
    ul.todo-list li.checked:before {
      background: #cbcbcb !important; }
    ul.todo-list li i.mark {
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      height: 42px;
      min-width: 4px;
      background: #cbcbcb;
      cursor: pointer;
      transition: min-width 0.3s ease-out; }
    ul.todo-list li.active i.mark {
      min-width: 40px; }
    ul.todo-list li.active label.todo-checkbox > span:before {
      color: white;
      content: '\f10c';
      margin-right: 20px;
      transition: margin-right 0.1s ease-out;
      transition-delay: 0.2s;
      float: none; }
    ul.todo-list li.active label.todo-checkbox > input:checked + span:before {
      content: '\f00c'; }

label.todo-checkbox {
  width: 100%;
  padding-right: 25px;
  min-height: 16px;
  cursor: pointer; }
  label.todo-checkbox > span {
    white-space: nowrap;
    height: 16px; }
    label.todo-checkbox > span:before {
      border: none;
      color: #949494;
      transition: all 0.15s ease-out; }

.add-item-icon {
  display: none; }

.task-todo-container.transparent .todo-panel.panel {
  color: white;
  opacity: 0.9; }

.task-todo-container.transparent input.task-todo {
  color: white;
  width: calc(100% - 25px);
  border-radius: 0;
  border: none;
  background: transparent; }
  .task-todo-container.transparent input.task-todo:focus {
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12); }

.task-todo-container.transparent .add-item-icon {
  display: block;
  float: right;
  margin-top: -45px;
  margin-right: 5px;
  font-size: 25px;
  cursor: pointer; }

.task-todo-container.transparent ul.todo-list li {
  margin: 0;
  border: none;
  font-weight: 300; }
  .task-todo-container.transparent ul.todo-list li .blur-container {
    height: 40px;
    position: absolute;
    width: calc(100% + 40px);
    top: 0;
    left: -25px;
    overflow-y: hidden; }
  .task-todo-container.transparent ul.todo-list li:hover .blur-container {
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12); }
  .task-todo-container.transparent ul.todo-list li:hover .blur-box {
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-filter: blur(3px); }
  .task-todo-container.transparent ul.todo-list li i.remove-todo {
    color: white;
    opacity: 0.4; }
    .task-todo-container.transparent ul.todo-list li i.remove-todo:hover {
      color: white;
      opacity: 0.95; }
  .task-todo-container.transparent ul.todo-list li i.mark {
    min-width: 40px;
    display: none; }
  .task-todo-container.transparent ul.todo-list li label.todo-checkbox > span:before {
    position: absolute;
    color: #666666;
    content: '\f10c';
    float: none;
    margin-right: 6px;
    transition: none; }
  .task-todo-container.transparent ul.todo-list li.checked label.todo-checkbox > span:before {
    content: '\f00c'; }

.task-todo-container.transparent .box-shadow-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.12);
  width: calc(100% + 44px);
  margin-left: -22px; }

.channels-block {
  width: 100%;
  position: relative; }

.channels-block.transparent .traffic-chart canvas {
  border: 10px solid rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 5px 0 black inset;
  border-radius: 150px; }

.channels-block.transparent .chart-bg {
  background-color: rgba(0, 0, 0, 0.35); }

.chart-bg {
  position: absolute;
  width: 180px;
  height: 180px;
  left: 60px;
  top: 60px;
  border-radius: 100px;
  background-color: transparent; }

.channels-info {
  display: inline-block;
  width: calc(100% - 370px);
  margin-left: 70px;
  margin-top: -20px; }

.small-container .channels-info {
  display: none; }

.channels-info-item p {
  margin-bottom: 9px;
  font-size: 18px;
  opacity: 0.9; }

.channels-info-item .channel-number {
  display: inline-block;
  float: right; }

.traffic-chart {
  width: 300px;
  position: relative;
  min-height: 300px;
  float: left; }

.traffic-legend {
  display: inline-block;
  padding: 70px 0 0 0px;
  width: 160px; }

.traffic-legend ul.doughnut-legend li {
  list-style: none;
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px; }
  .traffic-legend ul.doughnut-legend li span {
    float: left;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px; }

.canvas-holder {
  display: inline-block;
  width: 300px;
  height: 300px;
  position: relative;
  float: left; }

.traffic-text {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px; }
  .traffic-text span {
    display: block;
    font-size: 18px;
    color: #666666; }

.channel-change {
  display: block;
  margin-bottom: 12px; }

.channel-progress {
  height: 4px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.07);
  box-shadow: none; }
  .channel-progress .progress-bar {
    height: 4px;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: none; }

.legend-color {
  width: 30px;
  height: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  top: 27px;
  border-radius: 15px;
  left: -45px; }

@media (max-width: 768px) {
  div.channels-info {
    display: block;
    width: calc(100% - 88px);
    margin-top: -65px;
    margin-bottom: 10px; }
  .panel.medium-panel.traffic-panel {
    height: auto; }
  .traffic-chart {
    position: inherit;
    float: none;
    margin: 0 auto; }
  .chart-bg {
    left: calc(50% - 90px); } }

@media (max-width: 1465px) and (min-width: 1199px) {
  .channels-info {
    display: none; }
  .traffic-chart {
    position: inherit;
    float: none;
    margin: 0 auto; }
  .chart-bg {
    left: calc(50% - 90px); } }

@media (max-width: 380px) {
  .traffic-chart {
    width: 240px; }
  .canvas-holder {
    width: 240px;
    height: 240px; }
  .chart-bg {
    top: 30px; } }

@media (max-width: 320px) {
  .chart-bg {
    left: 50px;
    top: 50px;
    width: 142px;
    height: 142px; } }

.weather-wrapper {
  height: 100%; }
  .weather-wrapper .weather-main-info {
    min-height: 140px; }
    .weather-wrapper .weather-main-info div i {
      display: inline-block;
      width: 48px;
      text-align: center; }
    .weather-wrapper .weather-main-info .city-date {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
  .weather-wrapper .temp-by-time {
    height: calc(100% - 210px); }
  .weather-wrapper .select-day {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 70px; }
    .weather-wrapper .select-day .day {
      display: table-cell;
      line-height: 1;
      text-align: center;
      cursor: pointer; }
      .weather-wrapper .select-day .day i.weatherIcon {
        transition: color 0.5s ease; }
      .weather-wrapper .select-day .day .select-day-info {
        vertical-align: super; }
    .weather-wrapper .select-day .day:hover i.weatherIcon {
      color: #40BDE8; }
  .weather-wrapper .rightHeading {
    display: block;
    float: right; }
  .weather-wrapper .weather-info {
    display: inline-block;
    vertical-align: super; }
  .weather-wrapper .font-x1dot25 {
    font-size: 1.5em; }
  .weather-wrapper .font-x1dot5 {
    font-size: 1.5em; }
  .weather-wrapper .font-x2 {
    font-size: 2em; }
  .weather-wrapper .font-x3 {
    font-size: 3em; }
