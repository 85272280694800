.sitemap {
  .box-shadow-left-right{
    @media (max-width: 1100px) {
      padding: 30px;
    }
    @media (max-width: 480px) {
      padding: 15px;
    }
  }
  .sitemap-box {
    margin-bottom: 30px;
    .title {
      font-size: 25px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      color: $color-strong-blue;
      cursor: pointer;
    }
    .content {
      ul {
        padding-left: 0;
        margin-left: 35px;
        line-height: 25px;
        list-style-type: disc;
        li {
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}