$font-family: 'Lato', sans-serif;

$activelink: $primary;
$hoverlink: #037bc0;

$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 70px;
$menu-height: 50px;
$color-strong-blue: #177bbe;
$color-very-dark: #282e34;
$color-bg-gray-hover: #bbbdbf;
$color-dark-grayish-blue: #6a6e72;
$small-panel-height: 114px;
$xsmall-panel-height: 187px;
$medium-panel-height: 400px;
$extra-medium-panel-height: 550px;
$large-panel-height: 974px;

$background-strong-blue: #278CCB;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '../../assets/';
$images-root: $assets-root + 'img/';
$pictures-root: $assets-root + 'pictures/';
$fonts-root: $assets-root + 'fonts/';
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-ultraBold: 900;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;
$color-text-gray : #97989a;
$gray-color: #94979a;
