#my_account {
  .header-page{
    .title-header-page{
      h1{
        &:before{
          @include bg-picture('my-account.png');
        }
      }
    }
  }
  .success {
    padding: 15px 40px;
    background-color: #a9c600;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 30px;
    div {
      @include bg-image('app/effectiveness/icon-success-white.png');
      background-repeat: no-repeat;
      padding-left: 40px;
    }
  }
  .sub-title-my-account {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    color: #177BBE;
    margin-bottom: 35px;
    font-weight: bold;
  }
  .box-delete-account {
    text-align: right;
    h5 {
      color: #929497;
      font-size: 14px;
      font-family: "Lato";
      font-weight: normal;
      cursor: pointer;
      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }
  .form-my-account {
    input {
      &.error {
        border-color: #FF0000;
      }
    }
    #receive_emails-selectized-error{
      .for-default,.for-account{
        display: none;
      }
    }
    #subscribe_email-error {
      position: absolute;
      left: 15px;
      bottom: 0;
    }
    .custom-radio {
      margin-right: 10px;
      position: static;
      margin-bottom: 25px;
    }
    span {
      &.error {
        position: relative;
        bottom: -10px;
        color: #f00;
        padding-left: 25px;
        font-weight: normal;
        font-size: 15px;
        &:before {
          content: "\00d7";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #ff0000;
          color: #ffffff;
          font-size: 20px;
          line-height: 18px;
          text-align: center;
          font-weight: normal;
        }
      }
    }
    .line-center {
      height: 1px;
      width: 100%;
      background-color: #EAEBEB;
      margin-bottom: 25px;
    }
    .selectize-input {
      border: 1px solid #d0d0d0;
      padding: 10px;
      display: inline-block;
      width: 100%;
      overflow: initial;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      box-shadow: none;
      border-radius: 0px;
      &.error{
        border-color: #f00;
      }
    }
    h3 {
      font-size: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: bold;
      color: #000000;
    }
    input {
      border-radius: 0;
      resize: none;
    }
    label {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: normal;
      color: #292F35;
      margin-bottom: 15px;
    }
  }
  .submit-form-my-account {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #177bbe;
    border: none;
  }
}

#modal_delete_user {
  .modal-dialog {
    max-width: 400px;
    .modal-header {
      padding-bottom: 0;
    }
    .modal-body {
      padding: 50px;
      padding-top: 0;
      .content-delete-user {
        h2 {
          font-size: 32px;
          font-family: "PT Sans";
          color: #177BBE;
          font-weight: bold;
          margin-bottom: 0;
        }
        p {
          font-size: 16px;
          font-family: "Lato";
          font-weight: bold;
          color: #6A6E72;
          margin: 45px 0;
        }
      }
      .box-button {
        .btn-delete-user {
          font-size: 16px;
          font-family: "Lato";
          font-weight: bold;
          background: #FF3E39;
          color: #fff;
          padding-left: 17px;
          padding-right: 17px;
        }
        .btn-close-modal {
          color: #929497;
          font-size: 14px;
          font-family: "Lato";
          font-weight: normal;
          margin-left: 40px;
          cursor: pointer;
        }
      }
    }
  }
}