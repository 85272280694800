/*@font-face {
  font-family: 'Lato';
  src: url('#{$fonts-root}Lato-Regular.ttf') format('truetype');
  //src: url('#{$fonts-root}PT_Sans-Web-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('#{$fonts-root}Lato-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Lato Black';
  src: url('#{$fonts-root}Lato-Black.ttf') format('truetype');
  font-weight: 900;
}*/
@font-face {
  font-family: 'Lato';
  src: url('#{$fonts-root}/Lato/Lato-Regular.eot');
  src: url('#{$fonts-root}/Lato/Lato-Regular.woff2') format('woff2'),
  url('#{$fonts-root}/Lato/Lato-Regular.woff') format('woff'),
  url('#{$fonts-root}/Lato/Lato-Regular.otf') format('embedded-opentype'),
  url('#{$fonts-root}/Lato/Lato-Regular.ttf') format('truetype'),
  url('#{$fonts-root}/Lato/Lato-Regular.svg#Lato-Regular') format('svg'),
  url('#{$fonts-root}/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('#{$fonts-root}/Lato/Lato-Bold.eot');
  src: url('#{$fonts-root}/Lato/Lato-Bold.woff2') format('woff2'),
  url('#{$fonts-root}/Lato/Lato-Bold.woff') format('woff'),
  url('#{$fonts-root}/Lato/Lato-Bold.ttf') format('truetype'),
  url('#{$fonts-root}/Lato/Lato-Bold.svg#Lato-Bold') format('svg'),
  url('#{$fonts-root}/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('#{$fonts-root}/Lato/Lato-Black.eot');
  src: url('#{$fonts-root}/Lato/Lato-Black.woff2') format('woff2'),
  url('#{$fonts-root}/Lato/Lato-Black.woff') format('woff'),
  url('#{$fonts-root}/Lato/Lato-Black.ttf') format('truetype'),
  url('#{$fonts-root}/Lato/Lato-Black.svg#Lato-Black') format('svg'),
  url('#{$fonts-root}/Lato/Lato-Black.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT_Sans-Web-Bold';
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Bold.eot');
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Bold.woff2') format('woff2'),
  url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT_Sans-Web-Italic';
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Italic.eot');
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Italic.woff2') format('woff2'),
  url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PT Sans';
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Regular.eot');
  src: url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Regular.woff2') format('woff2'),
  url('#{$fonts-root}/PT-Sans/PT_Sans-Web-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
