/* public padding css */
/*PUBLIC CSS*/

.font-size-8 {
  font-size: 8px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-27 {
  font-size: 27px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-29 {
  font-size: 29px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-31 {
  font-size: 31px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-33 {
  font-size: 33px !important;
}

.font-size-34 {
  font-size: 34px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-37 {
  font-size: 37px !important;
}

.font-size-38 {
  font-size: 38px !important;
}

.font-size-39 {
  font-size: 39px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-41 {
  font-size: 41px !important;
}

.font-size-42 {
  font-size: 42px !important;
}

.font-size-43 {
  font-size: 43px !important;
}

.font-size-44 {
  font-size: 44px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-46 {
  font-size: 46px !important;
}

.font-size-47 {
  font-size: 47px !important;
}

.font-size-48 {
  font-size: 48px !important;
}

.font-size-49 {
  font-size: 49px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-51 {
  font-size: 51px !important;
}

.font-size-52 {
  font-size: 52px !important;
}

.font-size-53 {
  font-size: 53px !important;
}

.font-size-54 {
  font-size: 54px !important;
}

.font-size-55 {
  font-size: 55px !important;
}

.font-size-56 {
  font-size: 56px !important;
}

.font-size-57 {
  font-size: 57px !important;
}

.font-size-58 {
  font-size: 58px !important;
}

.font-size-59 {
  font-size: 59px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.mar-0 {
  margin: 0px !important;
}

.mar-top-0 {
  margin-top: 0px !important;
}

.mar-rig-0 {
  margin-right: 0px !important;
}

.mar-bot-0 {
  margin-bottom: 0px !important;
}

.mar-lef-0 {
  margin-left: 0px !important;
}

.mar-top-neg-0 {
  margin-top: -0px !important;
}

.mar-rig-neg-0 {
  margin-right: -0px !important;
}

.mar-bot-neg-0 {
  margin-bottom: -0px !important;
}

.mar-lef-neg-0 {
  margin-left: -0px !important;
}

.pad-0 {
  padding: 0px !important;
}

.pad-top-0 {
  padding-top: 0px !important;
}

.pad-rig-0 {
  padding-right: 0px !important;
}

.pad-bot-0 {
  padding-bottom: 0px !important;
}

.pad-lef-0 {
  padding-left: 0px !important;
}

.mar-5 {
  margin: 5px !important;
}

.mar-top-5 {
  margin-top: 5px !important;
}

.mar-rig-5 {
  margin-right: 5px !important;
}

.mar-bot-5 {
  margin-bottom: 5px !important;
}

.mar-lef-5 {
  margin-left: 5px !important;
}

.mar-top-neg-5 {
  margin-top: -5px !important;
}

.mar-rig-neg-5 {
  margin-right: -5px !important;
}

.mar-bot-neg-5 {
  margin-bottom: -5px !important;
}

.mar-lef-neg-5 {
  margin-left: -5px !important;
}

.pad-5 {
  padding: 5px !important;
}

.pad-top-5 {
  padding-top: 5px !important;
}

.pad-rig-5 {
  padding-right: 5px !important;
}

.pad-bot-5 {
  padding-bottom: 5px !important;
}

.pad-lef-5 {
  padding-left: 5px !important;
}

.mar-10 {
  margin: 10px !important;
}

.mar-top-10 {
  margin-top: 10px !important;
}

.mar-rig-10 {
  margin-right: 10px !important;
}

.mar-bot-10 {
  margin-bottom: 10px !important;
}

.mar-lef-10 {
  margin-left: 10px !important;
}

.mar-top-neg-10 {
  margin-top: -10px !important;
}

.mar-rig-neg-10 {
  margin-right: -10px !important;
}

.mar-bot-neg-10 {
  margin-bottom: -10px !important;
}

.mar-lef-neg-10 {
  margin-left: -10px !important;
}

.pad-10 {
  padding: 10px !important;
}

.pad-top-10 {
  padding-top: 10px !important;
}

.pad-rig-10 {
  padding-right: 10px !important;
}

.pad-bot-10 {
  padding-bottom: 10px !important;
}

.pad-lef-10 {
  padding-left: 10px !important;
}

.mar-15 {
  margin: 15px !important;
}

.mar-top-15 {
  margin-top: 15px !important;
}

.mar-rig-15 {
  margin-right: 15px !important;
}

.mar-bot-15 {
  margin-bottom: 15px !important;
}

.mar-lef-15 {
  margin-left: 15px !important;
}

.mar-top-neg-15 {
  margin-top: -15px !important;
}

.mar-rig-neg-15 {
  margin-right: -15px !important;
}

.mar-bot-neg-15 {
  margin-bottom: -15px !important;
}

.mar-lef-neg-15 {
  margin-left: -15px !important;
}

.pad-15 {
  padding: 15px !important;
}

.pad-top-15 {
  padding-top: 15px !important;
}

.pad-rig-15 {
  padding-right: 15px !important;
}

.pad-bot-15 {
  padding-bottom: 15px !important;
}

.pad-lef-15 {
  padding-left: 15px !important;
}

.mar-20 {
  margin: 20px !important;
}

.mar-top-20 {
  margin-top: 20px !important;
}

.mar-rig-20 {
  margin-right: 20px !important;
}

.mar-bot-20 {
  margin-bottom: 20px !important;
}

.mar-lef-20 {
  margin-left: 20px !important;
}

.mar-top-neg-20 {
  margin-top: -20px !important;
}

.mar-rig-neg-20 {
  margin-right: -20px !important;
}

.mar-bot-neg-20 {
  margin-bottom: -20px !important;
}

.mar-lef-neg-20 {
  margin-left: -20px !important;
}

.pad-20 {
  padding: 20px !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}

.pad-rig-20 {
  padding-right: 20px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}

.pad-lef-20 {
  padding-left: 20px !important;
}

.mar-25 {
  margin: 25px !important;
}

.mar-top-25 {
  margin-top: 25px !important;
}

.mar-rig-25 {
  margin-right: 25px !important;
}

.mar-bot-25 {
  margin-bottom: 25px !important;
}

.mar-lef-25 {
  margin-left: 25px !important;
}

.mar-top-neg-25 {
  margin-top: -25px !important;
}

.mar-rig-neg-25 {
  margin-right: -25px !important;
}

.mar-bot-neg-25 {
  margin-bottom: -25px !important;
}

.mar-lef-neg-25 {
  margin-left: -25px !important;
}

.pad-25 {
  padding: 25px !important;
}

.pad-top-25 {
  padding-top: 25px !important;
}

.pad-rig-25 {
  padding-right: 25px !important;
}

.pad-bot-25 {
  padding-bottom: 25px !important;
}

.pad-lef-25 {
  padding-left: 25px !important;
}

.mar-30 {
  margin: 30px !important;
}

.mar-top-30 {
  margin-top: 30px !important;
}

.mar-rig-30 {
  margin-right: 30px !important;
}

.mar-bot-30 {
  margin-bottom: 30px !important;
}

.mar-lef-30 {
  margin-left: 30px !important;
}

.mar-top-neg-30 {
  margin-top: -30px !important;
}

.mar-rig-neg-30 {
  margin-right: -30px !important;
}

.mar-bot-neg-30 {
  margin-bottom: -30px !important;
}

.mar-lef-neg-30 {
  margin-left: -30px !important;
}

.pad-30 {
  padding: 30px !important;
}

.pad-top-30 {
  padding-top: 30px !important;
}

.pad-rig-30 {
  padding-right: 30px !important;
}

.pad-bot-30 {
  padding-bottom: 30px !important;
}

.pad-lef-30 {
  padding-left: 30px !important;
}

.mar-35 {
  margin: 35px !important;
}

.mar-top-35 {
  margin-top: 35px !important;
}

.mar-rig-35 {
  margin-right: 35px !important;
}

.mar-bot-35 {
  margin-bottom: 35px !important;
}

.mar-lef-35 {
  margin-left: 35px !important;
}

.mar-top-neg-35 {
  margin-top: -35px !important;
}

.mar-rig-neg-35 {
  margin-right: -35px !important;
}

.mar-bot-neg-35 {
  margin-bottom: -35px !important;
}

.mar-lef-neg-35 {
  margin-left: -35px !important;
}

.pad-35 {
  padding: 35px !important;
}

.pad-top-35 {
  padding-top: 35px !important;
}

.pad-rig-35 {
  padding-right: 35px !important;
}

.pad-bot-35 {
  padding-bottom: 35px !important;
}

.pad-lef-35 {
  padding-left: 35px !important;
}

.mar-40 {
  margin: 40px !important;
}

.mar-top-40 {
  margin-top: 40px !important;
}

.mar-rig-40 {
  margin-right: 40px !important;
}

.mar-bot-40 {
  margin-bottom: 40px !important;
}

.mar-lef-40 {
  margin-left: 40px !important;
}

.mar-top-neg-40 {
  margin-top: -40px !important;
}

.mar-rig-neg-40 {
  margin-right: -40px !important;
}

.mar-bot-neg-40 {
  margin-bottom: -40px !important;
}

.mar-lef-neg-40 {
  margin-left: -40px !important;
}

.pad-40 {
  padding: 40px !important;
}

.pad-top-40 {
  padding-top: 40px !important;
}

.pad-rig-40 {
  padding-right: 40px !important;
}

.pad-bot-40 {
  padding-bottom: 40px !important;
}

.pad-lef-40 {
  padding-left: 40px !important;
}

.mar-45 {
  margin: 45px !important;
}

.mar-top-45 {
  margin-top: 45px !important;
}

.mar-rig-45 {
  margin-right: 45px !important;
}

.mar-bot-45 {
  margin-bottom: 45px !important;
}

.mar-lef-45 {
  margin-left: 45px !important;
}

.mar-top-neg-45 {
  margin-top: -45px !important;
}

.mar-rig-neg-45 {
  margin-right: -45px !important;
}

.mar-bot-neg-45 {
  margin-bottom: -45px !important;
}

.mar-lef-neg-45 {
  margin-left: -45px !important;
}

.pad-45 {
  padding: 45px !important;
}

.pad-top-45 {
  padding-top: 45px !important;
}

.pad-rig-45 {
  padding-right: 45px !important;
}

.pad-bot-45 {
  padding-bottom: 45px !important;
}

.pad-lef-45 {
  padding-left: 45px !important;
}

.mar-50 {
  margin: 50px !important;
}

.mar-top-50 {
  margin-top: 50px !important;
}

.mar-rig-50 {
  margin-right: 50px !important;
}

.mar-bot-50 {
  margin-bottom: 50px !important;
}

.mar-lef-50 {
  margin-left: 50px !important;
}

.mar-top-neg-50 {
  margin-top: -50px !important;
}

.mar-rig-neg-50 {
  margin-right: -50px !important;
}

.mar-bot-neg-50 {
  margin-bottom: -50px !important;
}

.mar-lef-neg-50 {
  margin-left: -50px !important;
}

.pad-50 {
  padding: 50px !important;
}

.pad-top-50 {
  padding-top: 50px !important;
}

.pad-rig-50 {
  padding-right: 50px !important;
}

.pad-bot-50 {
  padding-bottom: 50px !important;
}

.pad-lef-50 {
  padding-left: 50px !important;
}

.mar-55 {
  margin: 55px !important;
}

.mar-top-55 {
  margin-top: 55px !important;
}

.mar-rig-55 {
  margin-right: 55px !important;
}

.mar-bot-55 {
  margin-bottom: 55px !important;
}

.mar-lef-55 {
  margin-left: 55px !important;
}

.mar-top-neg-55 {
  margin-top: -55px !important;
}

.mar-rig-neg-55 {
  margin-right: -55px !important;
}

.mar-bot-neg-55 {
  margin-bottom: -55px !important;
}

.mar-lef-neg-55 {
  margin-left: -55px !important;
}

.pad-55 {
  padding: 55px !important;
}

.pad-top-55 {
  padding-top: 55px !important;
}

.pad-rig-55 {
  padding-right: 55px !important;
}

.pad-bot-55 {
  padding-bottom: 55px !important;
}

.pad-lef-55 {
  padding-left: 55px !important;
}

.mar-60 {
  margin: 60px !important;
}

.mar-top-60 {
  margin-top: 60px !important;
}

.mar-rig-60 {
  margin-right: 60px !important;
}

.mar-bot-60 {
  margin-bottom: 60px !important;
}

.mar-lef-60 {
  margin-left: 60px !important;
}

.mar-top-neg-60 {
  margin-top: -60px !important;
}

.mar-rig-neg-60 {
  margin-right: -60px !important;
}

.mar-bot-neg-60 {
  margin-bottom: -60px !important;
}

.mar-lef-neg-60 {
  margin-left: -60px !important;
}

.pad-60 {
  padding: 60px !important;
}

.pad-top-60 {
  padding-top: 60px !important;
}

.pad-rig-60 {
  padding-right: 60px !important;
}

.pad-bot-60 {
  padding-bottom: 60px !important;
}

.pad-lef-60 {
  padding-left: 60px !important;
}

.mar-65 {
  margin: 65px !important;
}

.mar-top-65 {
  margin-top: 65px !important;
}

.mar-rig-65 {
  margin-right: 65px !important;
}

.mar-bot-65 {
  margin-bottom: 65px !important;
}

.mar-lef-65 {
  margin-left: 65px !important;
}

.mar-top-neg-65 {
  margin-top: -65px !important;
}

.mar-rig-neg-65 {
  margin-right: -65px !important;
}

.mar-bot-neg-65 {
  margin-bottom: -65px !important;
}

.mar-lef-neg-65 {
  margin-left: -65px !important;
}

.pad-65 {
  padding: 65px !important;
}

.pad-top-65 {
  padding-top: 65px !important;
}

.pad-rig-65 {
  padding-right: 65px !important;
}

.pad-bot-65 {
  padding-bottom: 65px !important;
}

.pad-lef-65 {
  padding-left: 65px !important;
}

.mar-70 {
  margin: 70px !important;
}

.mar-top-70 {
  margin-top: 70px !important;
}

.mar-rig-70 {
  margin-right: 70px !important;
}

.mar-bot-70 {
  margin-bottom: 70px !important;
}

.mar-lef-70 {
  margin-left: 70px !important;
}

.mar-top-neg-70 {
  margin-top: -70px !important;
}

.mar-rig-neg-70 {
  margin-right: -70px !important;
}

.mar-bot-neg-70 {
  margin-bottom: -70px !important;
}

.mar-lef-neg-70 {
  margin-left: -70px !important;
}

.pad-70 {
  padding: 70px !important;
}

.pad-top-70 {
  padding-top: 70px !important;
}

.pad-rig-70 {
  padding-right: 70px !important;
}

.pad-bot-70 {
  padding-bottom: 70px !important;
}

.pad-lef-70 {
  padding-left: 70px !important;
}

.mar-75 {
  margin: 75px !important;
}

.mar-top-75 {
  margin-top: 75px !important;
}

.mar-rig-75 {
  margin-right: 75px !important;
}

.mar-bot-75 {
  margin-bottom: 75px !important;
}

.mar-lef-75 {
  margin-left: 75px !important;
}

.mar-top-neg-75 {
  margin-top: -75px !important;
}

.mar-rig-neg-75 {
  margin-right: -75px !important;
}

.mar-bot-neg-75 {
  margin-bottom: -75px !important;
}

.mar-lef-neg-75 {
  margin-left: -75px !important;
}

.pad-75 {
  padding: 75px !important;
}

.pad-top-75 {
  padding-top: 75px !important;
}

.pad-rig-75 {
  padding-right: 75px !important;
}

.pad-bot-75 {
  padding-bottom: 75px !important;
}

.pad-lef-75 {
  padding-left: 75px !important;
}

.mar-80 {
  margin: 80px !important;
}

.mar-top-80 {
  margin-top: 80px !important;
}

.mar-rig-80 {
  margin-right: 80px !important;
}

.mar-bot-80 {
  margin-bottom: 80px !important;
}

.mar-lef-80 {
  margin-left: 80px !important;
}

.mar-top-neg-80 {
  margin-top: -80px !important;
}

.mar-rig-neg-80 {
  margin-right: -80px !important;
}

.mar-bot-neg-80 {
  margin-bottom: -80px !important;
}

.mar-lef-neg-80 {
  margin-left: -80px !important;
}

.pad-80 {
  padding: 80px !important;
}

.pad-top-80 {
  padding-top: 80px !important;
}

.pad-rig-80 {
  padding-right: 80px !important;
}

.pad-bot-80 {
  padding-bottom: 80px !important;
}

.pad-lef-80 {
  padding-left: 80px !important;
}

.mar-85 {
  margin: 85px !important;
}

.mar-top-85 {
  margin-top: 85px !important;
}

.mar-rig-85 {
  margin-right: 85px !important;
}

.mar-bot-85 {
  margin-bottom: 85px !important;
}

.mar-lef-85 {
  margin-left: 85px !important;
}

.mar-top-neg-85 {
  margin-top: -85px !important;
}

.mar-rig-neg-85 {
  margin-right: -85px !important;
}

.mar-bot-neg-85 {
  margin-bottom: -85px !important;
}

.mar-lef-neg-85 {
  margin-left: -85px !important;
}

.pad-85 {
  padding: 85px !important;
}

.pad-top-85 {
  padding-top: 85px !important;
}

.pad-rig-85 {
  padding-right: 85px !important;
}

.pad-bot-85 {
  padding-bottom: 85px !important;
}

.pad-lef-85 {
  padding-left: 85px !important;
}

.mar-90 {
  margin: 90px !important;
}

.mar-top-90 {
  margin-top: 90px !important;
}

.mar-rig-90 {
  margin-right: 90px !important;
}

.mar-bot-90 {
  margin-bottom: 90px !important;
}

.mar-lef-90 {
  margin-left: 90px !important;
}

.mar-top-neg-90 {
  margin-top: -90px !important;
}

.mar-rig-neg-90 {
  margin-right: -90px !important;
}

.mar-bot-neg-90 {
  margin-bottom: -90px !important;
}

.mar-lef-neg-90 {
  margin-left: -90px !important;
}

.pad-90 {
  padding: 90px !important;
}

.pad-top-90 {
  padding-top: 90px !important;
}

.pad-rig-90 {
  padding-right: 90px !important;
}

.pad-bot-90 {
  padding-bottom: 90px !important;
}

.pad-lef-90 {
  padding-left: 90px !important;
}

.mar-95 {
  margin: 95px !important;
}

.mar-top-95 {
  margin-top: 95px !important;
}

.mar-rig-95 {
  margin-right: 95px !important;
}

.mar-bot-95 {
  margin-bottom: 95px !important;
}

.mar-lef-95 {
  margin-left: 95px !important;
}

.mar-top-neg-95 {
  margin-top: -95px !important;
}

.mar-rig-neg-95 {
  margin-right: -95px !important;
}

.mar-bot-neg-95 {
  margin-bottom: -95px !important;
}

.mar-lef-neg-95 {
  margin-left: -95px !important;
}

.pad-95 {
  padding: 95px !important;
}

.pad-top-95 {
  padding-top: 95px !important;
}

.pad-rig-95 {
  padding-right: 95px !important;
}

.pad-bot-95 {
  padding-bottom: 95px !important;
}

.pad-lef-95 {
  padding-left: 95px !important;
}

.mar-100 {
  margin: 100px !important;
}

.mar-top-100 {
  margin-top: 100px !important;
}

.mar-rig-100 {
  margin-right: 100px !important;
}

.mar-bot-100 {
  margin-bottom: 100px !important;
}

.mar-lef-100 {
  margin-left: 100px !important;
}

.mar-top-neg-100 {
  margin-top: -100px !important;
}

.mar-rig-neg-100 {
  margin-right: -100px !important;
}

.mar-bot-neg-100 {
  margin-bottom: -100px !important;
}

.mar-lef-neg-100 {
  margin-left: -100px !important;
}

.pad-100 {
  padding: 100px !important;
}

.pad-top-100 {
  padding-top: 100px !important;
}

.pad-rig-100 {
  padding-right: 100px !important;
}

.pad-bot-100 {
  padding-bottom: 100px !important;
}

.pad-lef-100 {
  padding-left: 100px !important;
}

.line-height-0 {
  line-height: 0px !important;
}

.line-height-1 {
  line-height: 1px !important;
}

.line-height-2 {
  line-height: 2px !important;
}

.line-height-3 {
  line-height: 3px !important;
}

.line-height-4 {
  line-height: 4px !important;
}

.line-height-5 {
  line-height: 5px !important;
}

.line-height-6 {
  line-height: 6px !important;
}

.line-height-7 {
  line-height: 7px !important;
}

.line-height-8 {
  line-height: 8px !important;
}

.line-height-9 {
  line-height: 9px !important;
}

.line-height-10 {
  line-height: 10px !important;
}

.line-height-11 {
  line-height: 11px !important;
}

.line-height-12 {
  line-height: 12px !important;
}

.line-height-13 {
  line-height: 13px !important;
}

.line-height-14 {
  line-height: 14px !important;
}

.line-height-15 {
  line-height: 15px !important;
}

.line-height-16 {
  line-height: 16px !important;
}

.line-height-17 {
  line-height: 17px !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.line-height-19 {
  line-height: 19px !important;
}

.line-height-20 {
  line-height: 20px !important;
}

.line-height-21 {
  line-height: 21px !important;
}

.line-height-22 {
  line-height: 22px !important;
}

.line-height-23 {
  line-height: 23px !important;
}

.line-height-24 {
  line-height: 24px !important;
}

.line-height-25 {
  line-height: 25px !important;
}

.line-height-26 {
  line-height: 26px !important;
}

.line-height-27 {
  line-height: 27px !important;
}

.line-height-28 {
  line-height: 28px !important;
}

.line-height-29 {
  line-height: 29px !important;
}

.line-height-30 {
  line-height: 30px !important;
}

.line-height-31 {
  line-height: 31px !important;
}

.line-height-32 {
  line-height: 32px !important;
}

.line-height-33 {
  line-height: 33px !important;
}

.line-height-34 {
  line-height: 34px !important;
}

.line-height-35 {
  line-height: 35px !important;
}

.line-height-36 {
  line-height: 36px !important;
}

.line-height-37 {
  line-height: 37px !important;
}

.line-height-38 {
  line-height: 38px !important;
}

.line-height-39 {
  line-height: 39px !important;
}

.line-height-40 {
  line-height: 40px !important;
}

.line-height-41 {
  line-height: 41px !important;
}

.line-height-42 {
  line-height: 42px !important;
}

.line-height-43 {
  line-height: 43px !important;
}

.line-height-44 {
  line-height: 44px !important;
}

.line-height-45 {
  line-height: 45px !important;
}

.line-height-46 {
  line-height: 46px !important;
}

.line-height-47 {
  line-height: 47px !important;
}

.line-height-48 {
  line-height: 48px !important;
}

.line-height-49 {
  line-height: 49px !important;
}

.line-height-50 {
  line-height: 50px !important;
}

.line-height-51 {
  line-height: 51px !important;
}

.line-height-52 {
  line-height: 52px !important;
}

.line-height-53 {
  line-height: 53px !important;
}

.line-height-54 {
  line-height: 54px !important;
}

.line-height-55 {
  line-height: 55px !important;
}

.line-height-56 {
  line-height: 56px !important;
}

.line-height-57 {
  line-height: 57px !important;
}

.line-height-58 {
  line-height: 58px !important;
}

.line-height-59 {
  line-height: 59px !important;
}

.line-height-60 {
  line-height: 60px !important;
}

.dis-block {
  display: block !important;
}

.dis-none {
  display: none !important;
}

.dis-inline {
  display: inline !important;
}

.dis-inline-block {
  display: inline-block !important;
}

.text-cen {
  text-align: center !important;
}

.text-lef {
  text-align: left !important;
}

.text-rig {
  text-align: right !important;
}

.text-jus {
  text-align: justify !important;
}

.pos-re {
  position: relative !important;
}

.pos-ab {
  position: absolute !important;
}

.pos-st {
  position: static !important;
}

@media (min-width: 0px) {
  .font-size-xs-8 {
    font-size: 8px !important;
  }
  .font-size-xs-9 {
    font-size: 9px !important;
  }
  .font-size-xs-10 {
    font-size: 10px !important;
  }
  .font-size-xs-11 {
    font-size: 11px !important;
  }
  .font-size-xs-12 {
    font-size: 12px !important;
  }
  .font-size-xs-13 {
    font-size: 13px !important;
  }
  .font-size-xs-14 {
    font-size: 14px !important;
  }
  .font-size-xs-15 {
    font-size: 15px !important;
  }
  .font-size-xs-16 {
    font-size: 16px !important;
  }
  .font-size-xs-17 {
    font-size: 17px !important;
  }
  .font-size-xs-18 {
    font-size: 18px !important;
  }
  .font-size-xs-19 {
    font-size: 19px !important;
  }
  .font-size-xs-20 {
    font-size: 20px !important;
  }
  .font-size-xs-21 {
    font-size: 21px !important;
  }
  .font-size-xs-22 {
    font-size: 22px !important;
  }
  .font-size-xs-23 {
    font-size: 23px !important;
  }
  .font-size-xs-24 {
    font-size: 24px !important;
  }
  .font-size-xs-25 {
    font-size: 25px !important;
  }
  .font-size-xs-26 {
    font-size: 26px !important;
  }
  .font-size-xs-27 {
    font-size: 27px !important;
  }
  .font-size-xs-28 {
    font-size: 28px !important;
  }
  .font-size-xs-29 {
    font-size: 29px !important;
  }
  .font-size-xs-30 {
    font-size: 30px !important;
  }
  .font-size-xs-31 {
    font-size: 31px !important;
  }
  .font-size-xs-32 {
    font-size: 32px !important;
  }
  .font-size-xs-33 {
    font-size: 33px !important;
  }
  .font-size-xs-34 {
    font-size: 34px !important;
  }
  .font-size-xs-35 {
    font-size: 35px !important;
  }
  .font-size-xs-36 {
    font-size: 36px !important;
  }
  .font-size-xs-37 {
    font-size: 37px !important;
  }
  .font-size-xs-38 {
    font-size: 38px !important;
  }
  .font-size-xs-39 {
    font-size: 39px !important;
  }
  .font-size-xs-40 {
    font-size: 40px !important;
  }
  .font-size-xs-41 {
    font-size: 41px !important;
  }
  .font-size-xs-42 {
    font-size: 42px !important;
  }
  .font-size-xs-43 {
    font-size: 43px !important;
  }
  .font-size-xs-44 {
    font-size: 44px !important;
  }
  .font-size-xs-45 {
    font-size: 45px !important;
  }
  .font-size-xs-46 {
    font-size: 46px !important;
  }
  .font-size-xs-47 {
    font-size: 47px !important;
  }
  .font-size-xs-48 {
    font-size: 48px !important;
  }
  .font-size-xs-49 {
    font-size: 49px !important;
  }
  .font-size-xs-50 {
    font-size: 50px !important;
  }
  .font-size-xs-51 {
    font-size: 51px !important;
  }
  .font-size-xs-52 {
    font-size: 52px !important;
  }
  .font-size-xs-53 {
    font-size: 53px !important;
  }
  .font-size-xs-54 {
    font-size: 54px !important;
  }
  .font-size-xs-55 {
    font-size: 55px !important;
  }
  .font-size-xs-56 {
    font-size: 56px !important;
  }
  .font-size-xs-57 {
    font-size: 57px !important;
  }
  .font-size-xs-58 {
    font-size: 58px !important;
  }
  .font-size-xs-59 {
    font-size: 59px !important;
  }
  .font-size-xs-60 {
    font-size: 60px !important;
  }
  .mar-xs-0 {
    margin: 0px !important;
  }
  .mar-top-xs-0 {
    margin-top: 0px !important;
  }
  .mar-rig-xs-0 {
    margin-right: 0px !important;
  }
  .mar-bot-xs-0 {
    margin-bottom: 0px !important;
  }
  .mar-lef-xs-0 {
    margin-left: 0px !important;
  }
  .mar-top-neg-xs-0 {
    margin-top: -0px !important;
  }
  .mar-rig-neg-xs-0 {
    margin-right: -0px !important;
  }
  .mar-bot-neg-xs-0 {
    margin-bottom: -0px !important;
  }
  .mar-lef-neg-xs-0 {
    margin-left: -0px !important;
  }
  .pad-xs-0 {
    padding: 0px !important;
  }
  .pad-top-xs-0 {
    padding-top: 0px !important;
  }
  .pad-rig-xs-0 {
    padding-right: 0px !important;
  }
  .pad-bot-xs-0 {
    padding-bottom: 0px !important;
  }
  .pad-lef-xs-0 {
    padding-left: 0px !important;
  }
  .mar-xs-5 {
    margin: 5px !important;
  }
  .mar-top-xs-5 {
    margin-top: 5px !important;
  }
  .mar-rig-xs-5 {
    margin-right: 5px !important;
  }
  .mar-bot-xs-5 {
    margin-bottom: 5px !important;
  }
  .mar-lef-xs-5 {
    margin-left: 5px !important;
  }
  .mar-top-neg-xs-5 {
    margin-top: -5px !important;
  }
  .mar-rig-neg-xs-5 {
    margin-right: -5px !important;
  }
  .mar-bot-neg-xs-5 {
    margin-bottom: -5px !important;
  }
  .mar-lef-neg-xs-5 {
    margin-left: -5px !important;
  }
  .pad-xs-5 {
    padding: 5px !important;
  }
  .pad-top-xs-5 {
    padding-top: 5px !important;
  }
  .pad-rig-xs-5 {
    padding-right: 5px !important;
  }
  .pad-bot-xs-5 {
    padding-bottom: 5px !important;
  }
  .pad-lef-xs-5 {
    padding-left: 5px !important;
  }
  .mar-xs-10 {
    margin: 10px !important;
  }
  .mar-top-xs-10 {
    margin-top: 10px !important;
  }
  .mar-rig-xs-10 {
    margin-right: 10px !important;
  }
  .mar-bot-xs-10 {
    margin-bottom: 10px !important;
  }
  .mar-lef-xs-10 {
    margin-left: 10px !important;
  }
  .mar-top-neg-xs-10 {
    margin-top: -10px !important;
  }
  .mar-rig-neg-xs-10 {
    margin-right: -10px !important;
  }
  .mar-bot-neg-xs-10 {
    margin-bottom: -10px !important;
  }
  .mar-lef-neg-xs-10 {
    margin-left: -10px !important;
  }
  .pad-xs-10 {
    padding: 10px !important;
  }
  .pad-top-xs-10 {
    padding-top: 10px !important;
  }
  .pad-rig-xs-10 {
    padding-right: 10px !important;
  }
  .pad-bot-xs-10 {
    padding-bottom: 10px !important;
  }
  .pad-lef-xs-10 {
    padding-left: 10px !important;
  }
  .mar-xs-15 {
    margin: 15px !important;
  }
  .mar-top-xs-15 {
    margin-top: 15px !important;
  }
  .mar-rig-xs-15 {
    margin-right: 15px !important;
  }
  .mar-bot-xs-15 {
    margin-bottom: 15px !important;
  }
  .mar-lef-xs-15 {
    margin-left: 15px !important;
  }
  .mar-top-neg-xs-15 {
    margin-top: -15px !important;
  }
  .mar-rig-neg-xs-15 {
    margin-right: -15px !important;
  }
  .mar-bot-neg-xs-15 {
    margin-bottom: -15px !important;
  }
  .mar-lef-neg-xs-15 {
    margin-left: -15px !important;
  }
  .pad-xs-15 {
    padding: 15px !important;
  }
  .pad-top-xs-15 {
    padding-top: 15px !important;
  }
  .pad-rig-xs-15 {
    padding-right: 15px !important;
  }
  .pad-bot-xs-15 {
    padding-bottom: 15px !important;
  }
  .pad-lef-xs-15 {
    padding-left: 15px !important;
  }
  .mar-xs-20 {
    margin: 20px !important;
  }
  .mar-top-xs-20 {
    margin-top: 20px !important;
  }
  .mar-rig-xs-20 {
    margin-right: 20px !important;
  }
  .mar-bot-xs-20 {
    margin-bottom: 20px !important;
  }
  .mar-lef-xs-20 {
    margin-left: 20px !important;
  }
  .mar-top-neg-xs-20 {
    margin-top: -20px !important;
  }
  .mar-rig-neg-xs-20 {
    margin-right: -20px !important;
  }
  .mar-bot-neg-xs-20 {
    margin-bottom: -20px !important;
  }
  .mar-lef-neg-xs-20 {
    margin-left: -20px !important;
  }
  .pad-xs-20 {
    padding: 20px !important;
  }
  .pad-top-xs-20 {
    padding-top: 20px !important;
  }
  .pad-rig-xs-20 {
    padding-right: 20px !important;
  }
  .pad-bot-xs-20 {
    padding-bottom: 20px !important;
  }
  .pad-lef-xs-20 {
    padding-left: 20px !important;
  }
  .mar-xs-25 {
    margin: 25px !important;
  }
  .mar-top-xs-25 {
    margin-top: 25px !important;
  }
  .mar-rig-xs-25 {
    margin-right: 25px !important;
  }
  .mar-bot-xs-25 {
    margin-bottom: 25px !important;
  }
  .mar-lef-xs-25 {
    margin-left: 25px !important;
  }
  .mar-top-neg-xs-25 {
    margin-top: -25px !important;
  }
  .mar-rig-neg-xs-25 {
    margin-right: -25px !important;
  }
  .mar-bot-neg-xs-25 {
    margin-bottom: -25px !important;
  }
  .mar-lef-neg-xs-25 {
    margin-left: -25px !important;
  }
  .pad-xs-25 {
    padding: 25px !important;
  }
  .pad-top-xs-25 {
    padding-top: 25px !important;
  }
  .pad-rig-xs-25 {
    padding-right: 25px !important;
  }
  .pad-bot-xs-25 {
    padding-bottom: 25px !important;
  }
  .pad-lef-xs-25 {
    padding-left: 25px !important;
  }
  .mar-xs-30 {
    margin: 30px !important;
  }
  .mar-top-xs-30 {
    margin-top: 30px !important;
  }
  .mar-rig-xs-30 {
    margin-right: 30px !important;
  }
  .mar-bot-xs-30 {
    margin-bottom: 30px !important;
  }
  .mar-lef-xs-30 {
    margin-left: 30px !important;
  }
  .mar-top-neg-xs-30 {
    margin-top: -30px !important;
  }
  .mar-rig-neg-xs-30 {
    margin-right: -30px !important;
  }
  .mar-bot-neg-xs-30 {
    margin-bottom: -30px !important;
  }
  .mar-lef-neg-xs-30 {
    margin-left: -30px !important;
  }
  .pad-xs-30 {
    padding: 30px !important;
  }
  .pad-top-xs-30 {
    padding-top: 30px !important;
  }
  .pad-rig-xs-30 {
    padding-right: 30px !important;
  }
  .pad-bot-xs-30 {
    padding-bottom: 30px !important;
  }
  .pad-lef-xs-30 {
    padding-left: 30px !important;
  }
  .mar-xs-35 {
    margin: 35px !important;
  }
  .mar-top-xs-35 {
    margin-top: 35px !important;
  }
  .mar-rig-xs-35 {
    margin-right: 35px !important;
  }
  .mar-bot-xs-35 {
    margin-bottom: 35px !important;
  }
  .mar-lef-xs-35 {
    margin-left: 35px !important;
  }
  .mar-top-neg-xs-35 {
    margin-top: -35px !important;
  }
  .mar-rig-neg-xs-35 {
    margin-right: -35px !important;
  }
  .mar-bot-neg-xs-35 {
    margin-bottom: -35px !important;
  }
  .mar-lef-neg-xs-35 {
    margin-left: -35px !important;
  }
  .pad-xs-35 {
    padding: 35px !important;
  }
  .pad-top-xs-35 {
    padding-top: 35px !important;
  }
  .pad-rig-xs-35 {
    padding-right: 35px !important;
  }
  .pad-bot-xs-35 {
    padding-bottom: 35px !important;
  }
  .pad-lef-xs-35 {
    padding-left: 35px !important;
  }
  .mar-xs-40 {
    margin: 40px !important;
  }
  .mar-top-xs-40 {
    margin-top: 40px !important;
  }
  .mar-rig-xs-40 {
    margin-right: 40px !important;
  }
  .mar-bot-xs-40 {
    margin-bottom: 40px !important;
  }
  .mar-lef-xs-40 {
    margin-left: 40px !important;
  }
  .mar-top-neg-xs-40 {
    margin-top: -40px !important;
  }
  .mar-rig-neg-xs-40 {
    margin-right: -40px !important;
  }
  .mar-bot-neg-xs-40 {
    margin-bottom: -40px !important;
  }
  .mar-lef-neg-xs-40 {
    margin-left: -40px !important;
  }
  .pad-xs-40 {
    padding: 40px !important;
  }
  .pad-top-xs-40 {
    padding-top: 40px !important;
  }
  .pad-rig-xs-40 {
    padding-right: 40px !important;
  }
  .pad-bot-xs-40 {
    padding-bottom: 40px !important;
  }
  .pad-lef-xs-40 {
    padding-left: 40px !important;
  }
  .mar-xs-45 {
    margin: 45px !important;
  }
  .mar-top-xs-45 {
    margin-top: 45px !important;
  }
  .mar-rig-xs-45 {
    margin-right: 45px !important;
  }
  .mar-bot-xs-45 {
    margin-bottom: 45px !important;
  }
  .mar-lef-xs-45 {
    margin-left: 45px !important;
  }
  .mar-top-neg-xs-45 {
    margin-top: -45px !important;
  }
  .mar-rig-neg-xs-45 {
    margin-right: -45px !important;
  }
  .mar-bot-neg-xs-45 {
    margin-bottom: -45px !important;
  }
  .mar-lef-neg-xs-45 {
    margin-left: -45px !important;
  }
  .pad-xs-45 {
    padding: 45px !important;
  }
  .pad-top-xs-45 {
    padding-top: 45px !important;
  }
  .pad-rig-xs-45 {
    padding-right: 45px !important;
  }
  .pad-bot-xs-45 {
    padding-bottom: 45px !important;
  }
  .pad-lef-xs-45 {
    padding-left: 45px !important;
  }
  .mar-xs-50 {
    margin: 50px !important;
  }
  .mar-top-xs-50 {
    margin-top: 50px !important;
  }
  .mar-rig-xs-50 {
    margin-right: 50px !important;
  }
  .mar-bot-xs-50 {
    margin-bottom: 50px !important;
  }
  .mar-lef-xs-50 {
    margin-left: 50px !important;
  }
  .mar-top-neg-xs-50 {
    margin-top: -50px !important;
  }
  .mar-rig-neg-xs-50 {
    margin-right: -50px !important;
  }
  .mar-bot-neg-xs-50 {
    margin-bottom: -50px !important;
  }
  .mar-lef-neg-xs-50 {
    margin-left: -50px !important;
  }
  .pad-xs-50 {
    padding: 50px !important;
  }
  .pad-top-xs-50 {
    padding-top: 50px !important;
  }
  .pad-rig-xs-50 {
    padding-right: 50px !important;
  }
  .pad-bot-xs-50 {
    padding-bottom: 50px !important;
  }
  .pad-lef-xs-50 {
    padding-left: 50px !important;
  }
  .mar-xs-55 {
    margin: 55px !important;
  }
  .mar-top-xs-55 {
    margin-top: 55px !important;
  }
  .mar-rig-xs-55 {
    margin-right: 55px !important;
  }
  .mar-bot-xs-55 {
    margin-bottom: 55px !important;
  }
  .mar-lef-xs-55 {
    margin-left: 55px !important;
  }
  .mar-top-neg-xs-55 {
    margin-top: -55px !important;
  }
  .mar-rig-neg-xs-55 {
    margin-right: -55px !important;
  }
  .mar-bot-neg-xs-55 {
    margin-bottom: -55px !important;
  }
  .mar-lef-neg-xs-55 {
    margin-left: -55px !important;
  }
  .pad-xs-55 {
    padding: 55px !important;
  }
  .pad-top-xs-55 {
    padding-top: 55px !important;
  }
  .pad-rig-xs-55 {
    padding-right: 55px !important;
  }
  .pad-bot-xs-55 {
    padding-bottom: 55px !important;
  }
  .pad-lef-xs-55 {
    padding-left: 55px !important;
  }
  .mar-xs-60 {
    margin: 60px !important;
  }
  .mar-top-xs-60 {
    margin-top: 60px !important;
  }
  .mar-rig-xs-60 {
    margin-right: 60px !important;
  }
  .mar-bot-xs-60 {
    margin-bottom: 60px !important;
  }
  .mar-lef-xs-60 {
    margin-left: 60px !important;
  }
  .mar-top-neg-xs-60 {
    margin-top: -60px !important;
  }
  .mar-rig-neg-xs-60 {
    margin-right: -60px !important;
  }
  .mar-bot-neg-xs-60 {
    margin-bottom: -60px !important;
  }
  .mar-lef-neg-xs-60 {
    margin-left: -60px !important;
  }
  .pad-xs-60 {
    padding: 60px !important;
  }
  .pad-top-xs-60 {
    padding-top: 60px !important;
  }
  .pad-rig-xs-60 {
    padding-right: 60px !important;
  }
  .pad-bot-xs-60 {
    padding-bottom: 60px !important;
  }
  .pad-lef-xs-60 {
    padding-left: 60px !important;
  }
  .mar-xs-65 {
    margin: 65px !important;
  }
  .mar-top-xs-65 {
    margin-top: 65px !important;
  }
  .mar-rig-xs-65 {
    margin-right: 65px !important;
  }
  .mar-bot-xs-65 {
    margin-bottom: 65px !important;
  }
  .mar-lef-xs-65 {
    margin-left: 65px !important;
  }
  .mar-top-neg-xs-65 {
    margin-top: -65px !important;
  }
  .mar-rig-neg-xs-65 {
    margin-right: -65px !important;
  }
  .mar-bot-neg-xs-65 {
    margin-bottom: -65px !important;
  }
  .mar-lef-neg-xs-65 {
    margin-left: -65px !important;
  }
  .pad-xs-65 {
    padding: 65px !important;
  }
  .pad-top-xs-65 {
    padding-top: 65px !important;
  }
  .pad-rig-xs-65 {
    padding-right: 65px !important;
  }
  .pad-bot-xs-65 {
    padding-bottom: 65px !important;
  }
  .pad-lef-xs-65 {
    padding-left: 65px !important;
  }
  .mar-xs-70 {
    margin: 70px !important;
  }
  .mar-top-xs-70 {
    margin-top: 70px !important;
  }
  .mar-rig-xs-70 {
    margin-right: 70px !important;
  }
  .mar-bot-xs-70 {
    margin-bottom: 70px !important;
  }
  .mar-lef-xs-70 {
    margin-left: 70px !important;
  }
  .mar-top-neg-xs-70 {
    margin-top: -70px !important;
  }
  .mar-rig-neg-xs-70 {
    margin-right: -70px !important;
  }
  .mar-bot-neg-xs-70 {
    margin-bottom: -70px !important;
  }
  .mar-lef-neg-xs-70 {
    margin-left: -70px !important;
  }
  .pad-xs-70 {
    padding: 70px !important;
  }
  .pad-top-xs-70 {
    padding-top: 70px !important;
  }
  .pad-rig-xs-70 {
    padding-right: 70px !important;
  }
  .pad-bot-xs-70 {
    padding-bottom: 70px !important;
  }
  .pad-lef-xs-70 {
    padding-left: 70px !important;
  }
  .mar-xs-75 {
    margin: 75px !important;
  }
  .mar-top-xs-75 {
    margin-top: 75px !important;
  }
  .mar-rig-xs-75 {
    margin-right: 75px !important;
  }
  .mar-bot-xs-75 {
    margin-bottom: 75px !important;
  }
  .mar-lef-xs-75 {
    margin-left: 75px !important;
  }
  .mar-top-neg-xs-75 {
    margin-top: -75px !important;
  }
  .mar-rig-neg-xs-75 {
    margin-right: -75px !important;
  }
  .mar-bot-neg-xs-75 {
    margin-bottom: -75px !important;
  }
  .mar-lef-neg-xs-75 {
    margin-left: -75px !important;
  }
  .pad-xs-75 {
    padding: 75px !important;
  }
  .pad-top-xs-75 {
    padding-top: 75px !important;
  }
  .pad-rig-xs-75 {
    padding-right: 75px !important;
  }
  .pad-bot-xs-75 {
    padding-bottom: 75px !important;
  }
  .pad-lef-xs-75 {
    padding-left: 75px !important;
  }
  .mar-xs-80 {
    margin: 80px !important;
  }
  .mar-top-xs-80 {
    margin-top: 80px !important;
  }
  .mar-rig-xs-80 {
    margin-right: 80px !important;
  }
  .mar-bot-xs-80 {
    margin-bottom: 80px !important;
  }
  .mar-lef-xs-80 {
    margin-left: 80px !important;
  }
  .mar-top-neg-xs-80 {
    margin-top: -80px !important;
  }
  .mar-rig-neg-xs-80 {
    margin-right: -80px !important;
  }
  .mar-bot-neg-xs-80 {
    margin-bottom: -80px !important;
  }
  .mar-lef-neg-xs-80 {
    margin-left: -80px !important;
  }
  .pad-xs-80 {
    padding: 80px !important;
  }
  .pad-top-xs-80 {
    padding-top: 80px !important;
  }
  .pad-rig-xs-80 {
    padding-right: 80px !important;
  }
  .pad-bot-xs-80 {
    padding-bottom: 80px !important;
  }
  .pad-lef-xs-80 {
    padding-left: 80px !important;
  }
  .mar-xs-85 {
    margin: 85px !important;
  }
  .mar-top-xs-85 {
    margin-top: 85px !important;
  }
  .mar-rig-xs-85 {
    margin-right: 85px !important;
  }
  .mar-bot-xs-85 {
    margin-bottom: 85px !important;
  }
  .mar-lef-xs-85 {
    margin-left: 85px !important;
  }
  .mar-top-neg-xs-85 {
    margin-top: -85px !important;
  }
  .mar-rig-neg-xs-85 {
    margin-right: -85px !important;
  }
  .mar-bot-neg-xs-85 {
    margin-bottom: -85px !important;
  }
  .mar-lef-neg-xs-85 {
    margin-left: -85px !important;
  }
  .pad-xs-85 {
    padding: 85px !important;
  }
  .pad-top-xs-85 {
    padding-top: 85px !important;
  }
  .pad-rig-xs-85 {
    padding-right: 85px !important;
  }
  .pad-bot-xs-85 {
    padding-bottom: 85px !important;
  }
  .pad-lef-xs-85 {
    padding-left: 85px !important;
  }
  .mar-xs-90 {
    margin: 90px !important;
  }
  .mar-top-xs-90 {
    margin-top: 90px !important;
  }
  .mar-rig-xs-90 {
    margin-right: 90px !important;
  }
  .mar-bot-xs-90 {
    margin-bottom: 90px !important;
  }
  .mar-lef-xs-90 {
    margin-left: 90px !important;
  }
  .mar-top-neg-xs-90 {
    margin-top: -90px !important;
  }
  .mar-rig-neg-xs-90 {
    margin-right: -90px !important;
  }
  .mar-bot-neg-xs-90 {
    margin-bottom: -90px !important;
  }
  .mar-lef-neg-xs-90 {
    margin-left: -90px !important;
  }
  .pad-xs-90 {
    padding: 90px !important;
  }
  .pad-top-xs-90 {
    padding-top: 90px !important;
  }
  .pad-rig-xs-90 {
    padding-right: 90px !important;
  }
  .pad-bot-xs-90 {
    padding-bottom: 90px !important;
  }
  .pad-lef-xs-90 {
    padding-left: 90px !important;
  }
  .mar-xs-95 {
    margin: 95px !important;
  }
  .mar-top-xs-95 {
    margin-top: 95px !important;
  }
  .mar-rig-xs-95 {
    margin-right: 95px !important;
  }
  .mar-bot-xs-95 {
    margin-bottom: 95px !important;
  }
  .mar-lef-xs-95 {
    margin-left: 95px !important;
  }
  .mar-top-neg-xs-95 {
    margin-top: -95px !important;
  }
  .mar-rig-neg-xs-95 {
    margin-right: -95px !important;
  }
  .mar-bot-neg-xs-95 {
    margin-bottom: -95px !important;
  }
  .mar-lef-neg-xs-95 {
    margin-left: -95px !important;
  }
  .pad-xs-95 {
    padding: 95px !important;
  }
  .pad-top-xs-95 {
    padding-top: 95px !important;
  }
  .pad-rig-xs-95 {
    padding-right: 95px !important;
  }
  .pad-bot-xs-95 {
    padding-bottom: 95px !important;
  }
  .pad-lef-xs-95 {
    padding-left: 95px !important;
  }
  .mar-xs-100 {
    margin: 100px !important;
  }
  .mar-top-xs-100 {
    margin-top: 100px !important;
  }
  .mar-rig-xs-100 {
    margin-right: 100px !important;
  }
  .mar-bot-xs-100 {
    margin-bottom: 100px !important;
  }
  .mar-lef-xs-100 {
    margin-left: 100px !important;
  }
  .mar-top-neg-xs-100 {
    margin-top: -100px !important;
  }
  .mar-rig-neg-xs-100 {
    margin-right: -100px !important;
  }
  .mar-bot-neg-xs-100 {
    margin-bottom: -100px !important;
  }
  .mar-lef-neg-xs-100 {
    margin-left: -100px !important;
  }
  .pad-xs-100 {
    padding: 100px !important;
  }
  .pad-top-xs-100 {
    padding-top: 100px !important;
  }
  .pad-rig-xs-100 {
    padding-right: 100px !important;
  }
  .pad-bot-xs-100 {
    padding-bottom: 100px !important;
  }
  .pad-lef-xs-100 {
    padding-left: 100px !important;
  }
  .line-height-xs-0 {
    line-height: 0px !important;
  }
  .line-height-xs-1 {
    line-height: 1px !important;
  }
  .line-height-xs-2 {
    line-height: 2px !important;
  }
  .line-height-xs-3 {
    line-height: 3px !important;
  }
  .line-height-xs-4 {
    line-height: 4px !important;
  }
  .line-height-xs-5 {
    line-height: 5px !important;
  }
  .line-height-xs-6 {
    line-height: 6px !important;
  }
  .line-height-xs-7 {
    line-height: 7px !important;
  }
  .line-height-xs-8 {
    line-height: 8px !important;
  }
  .line-height-xs-9 {
    line-height: 9px !important;
  }
  .line-height-xs-10 {
    line-height: 10px !important;
  }
  .line-height-xs-11 {
    line-height: 11px !important;
  }
  .line-height-xs-12 {
    line-height: 12px !important;
  }
  .line-height-xs-13 {
    line-height: 13px !important;
  }
  .line-height-xs-14 {
    line-height: 14px !important;
  }
  .line-height-xs-15 {
    line-height: 15px !important;
  }
  .line-height-xs-16 {
    line-height: 16px !important;
  }
  .line-height-xs-17 {
    line-height: 17px !important;
  }
  .line-height-xs-18 {
    line-height: 18px !important;
  }
  .line-height-xs-19 {
    line-height: 19px !important;
  }
  .line-height-xs-20 {
    line-height: 20px !important;
  }
  .line-height-xs-21 {
    line-height: 21px !important;
  }
  .line-height-xs-22 {
    line-height: 22px !important;
  }
  .line-height-xs-23 {
    line-height: 23px !important;
  }
  .line-height-xs-24 {
    line-height: 24px !important;
  }
  .line-height-xs-25 {
    line-height: 25px !important;
  }
  .line-height-xs-26 {
    line-height: 26px !important;
  }
  .line-height-xs-27 {
    line-height: 27px !important;
  }
  .line-height-xs-28 {
    line-height: 28px !important;
  }
  .line-height-xs-29 {
    line-height: 29px !important;
  }
  .line-height-xs-30 {
    line-height: 30px !important;
  }
  .line-height-xs-31 {
    line-height: 31px !important;
  }
  .line-height-xs-32 {
    line-height: 32px !important;
  }
  .line-height-xs-33 {
    line-height: 33px !important;
  }
  .line-height-xs-34 {
    line-height: 34px !important;
  }
  .line-height-xs-35 {
    line-height: 35px !important;
  }
  .line-height-xs-36 {
    line-height: 36px !important;
  }
  .line-height-xs-37 {
    line-height: 37px !important;
  }
  .line-height-xs-38 {
    line-height: 38px !important;
  }
  .line-height-xs-39 {
    line-height: 39px !important;
  }
  .line-height-xs-40 {
    line-height: 40px !important;
  }
  .line-height-xs-41 {
    line-height: 41px !important;
  }
  .line-height-xs-42 {
    line-height: 42px !important;
  }
  .line-height-xs-43 {
    line-height: 43px !important;
  }
  .line-height-xs-44 {
    line-height: 44px !important;
  }
  .line-height-xs-45 {
    line-height: 45px !important;
  }
  .line-height-xs-46 {
    line-height: 46px !important;
  }
  .line-height-xs-47 {
    line-height: 47px !important;
  }
  .line-height-xs-48 {
    line-height: 48px !important;
  }
  .line-height-xs-49 {
    line-height: 49px !important;
  }
  .line-height-xs-50 {
    line-height: 50px !important;
  }
  .line-height-xs-51 {
    line-height: 51px !important;
  }
  .line-height-xs-52 {
    line-height: 52px !important;
  }
  .line-height-xs-53 {
    line-height: 53px !important;
  }
  .line-height-xs-54 {
    line-height: 54px !important;
  }
  .line-height-xs-55 {
    line-height: 55px !important;
  }
  .line-height-xs-56 {
    line-height: 56px !important;
  }
  .line-height-xs-57 {
    line-height: 57px !important;
  }
  .line-height-xs-58 {
    line-height: 58px !important;
  }
  .line-height-xs-59 {
    line-height: 59px !important;
  }
  .line-height-xs-60 {
    line-height: 60px !important;
  }
  .dis-block-xs {
    display: block !important;
  }
  .dis-none-xs {
    display: none !important;
  }
  .dis-inline-xs {
    display: inline !important;
  }
  .dis-inline-block-xs {
    display: inline-block !important;
  }
  .text-cen-xs {
    text-align: center !important;
  }
  .text-lef-xs {
    text-align: left !important;
  }
  .text-rig-xs {
    text-align: right !important;
  }
  .text-jus-xs {
    text-align: justify !important;
  }
  .pos-re-xs {
    position: relative !important;
  }
  .pos-ab-xs {
    position: absolute !important;
  }
  .pos-st-xs {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .font-size-sm-8 {
    font-size: 8px !important;
  }
  .font-size-sm-9 {
    font-size: 9px !important;
  }
  .font-size-sm-10 {
    font-size: 10px !important;
  }
  .font-size-sm-11 {
    font-size: 11px !important;
  }
  .font-size-sm-12 {
    font-size: 12px !important;
  }
  .font-size-sm-13 {
    font-size: 13px !important;
  }
  .font-size-sm-14 {
    font-size: 14px !important;
  }
  .font-size-sm-15 {
    font-size: 15px !important;
  }
  .font-size-sm-16 {
    font-size: 16px !important;
  }
  .font-size-sm-17 {
    font-size: 17px !important;
  }
  .font-size-sm-18 {
    font-size: 18px !important;
  }
  .font-size-sm-19 {
    font-size: 19px !important;
  }
  .font-size-sm-20 {
    font-size: 20px !important;
  }
  .font-size-sm-21 {
    font-size: 21px !important;
  }
  .font-size-sm-22 {
    font-size: 22px !important;
  }
  .font-size-sm-23 {
    font-size: 23px !important;
  }
  .font-size-sm-24 {
    font-size: 24px !important;
  }
  .font-size-sm-25 {
    font-size: 25px !important;
  }
  .font-size-sm-26 {
    font-size: 26px !important;
  }
  .font-size-sm-27 {
    font-size: 27px !important;
  }
  .font-size-sm-28 {
    font-size: 28px !important;
  }
  .font-size-sm-29 {
    font-size: 29px !important;
  }
  .font-size-sm-30 {
    font-size: 30px !important;
  }
  .font-size-sm-31 {
    font-size: 31px !important;
  }
  .font-size-sm-32 {
    font-size: 32px !important;
  }
  .font-size-sm-33 {
    font-size: 33px !important;
  }
  .font-size-sm-34 {
    font-size: 34px !important;
  }
  .font-size-sm-35 {
    font-size: 35px !important;
  }
  .font-size-sm-36 {
    font-size: 36px !important;
  }
  .font-size-sm-37 {
    font-size: 37px !important;
  }
  .font-size-sm-38 {
    font-size: 38px !important;
  }
  .font-size-sm-39 {
    font-size: 39px !important;
  }
  .font-size-sm-40 {
    font-size: 40px !important;
  }
  .font-size-sm-41 {
    font-size: 41px !important;
  }
  .font-size-sm-42 {
    font-size: 42px !important;
  }
  .font-size-sm-43 {
    font-size: 43px !important;
  }
  .font-size-sm-44 {
    font-size: 44px !important;
  }
  .font-size-sm-45 {
    font-size: 45px !important;
  }
  .font-size-sm-46 {
    font-size: 46px !important;
  }
  .font-size-sm-47 {
    font-size: 47px !important;
  }
  .font-size-sm-48 {
    font-size: 48px !important;
  }
  .font-size-sm-49 {
    font-size: 49px !important;
  }
  .font-size-sm-50 {
    font-size: 50px !important;
  }
  .font-size-sm-51 {
    font-size: 51px !important;
  }
  .font-size-sm-52 {
    font-size: 52px !important;
  }
  .font-size-sm-53 {
    font-size: 53px !important;
  }
  .font-size-sm-54 {
    font-size: 54px !important;
  }
  .font-size-sm-55 {
    font-size: 55px !important;
  }
  .font-size-sm-56 {
    font-size: 56px !important;
  }
  .font-size-sm-57 {
    font-size: 57px !important;
  }
  .font-size-sm-58 {
    font-size: 58px !important;
  }
  .font-size-sm-59 {
    font-size: 59px !important;
  }
  .font-size-sm-60 {
    font-size: 60px !important;
  }
  .mar-sm-0 {
    margin: 0px !important;
  }
  .mar-top-sm-0 {
    margin-top: 0px !important;
  }
  .mar-rig-sm-0 {
    margin-right: 0px !important;
  }
  .mar-bot-sm-0 {
    margin-bottom: 0px !important;
  }
  .mar-lef-sm-0 {
    margin-left: 0px !important;
  }
  .mar-top-neg-sm-0 {
    margin-top: -0px !important;
  }
  .mar-rig-neg-sm-0 {
    margin-right: -0px !important;
  }
  .mar-bot-neg-sm-0 {
    margin-bottom: -0px !important;
  }
  .mar-lef-neg-sm-0 {
    margin-left: -0px !important;
  }
  .pad-sm-0 {
    padding: 0px !important;
  }
  .pad-top-sm-0 {
    padding-top: 0px !important;
  }
  .pad-rig-sm-0 {
    padding-right: 0px !important;
  }
  .pad-bot-sm-0 {
    padding-bottom: 0px !important;
  }
  .pad-lef-sm-0 {
    padding-left: 0px !important;
  }
  .mar-sm-5 {
    margin: 5px !important;
  }
  .mar-top-sm-5 {
    margin-top: 5px !important;
  }
  .mar-rig-sm-5 {
    margin-right: 5px !important;
  }
  .mar-bot-sm-5 {
    margin-bottom: 5px !important;
  }
  .mar-lef-sm-5 {
    margin-left: 5px !important;
  }
  .mar-top-neg-sm-5 {
    margin-top: -5px !important;
  }
  .mar-rig-neg-sm-5 {
    margin-right: -5px !important;
  }
  .mar-bot-neg-sm-5 {
    margin-bottom: -5px !important;
  }
  .mar-lef-neg-sm-5 {
    margin-left: -5px !important;
  }
  .pad-sm-5 {
    padding: 5px !important;
  }
  .pad-top-sm-5 {
    padding-top: 5px !important;
  }
  .pad-rig-sm-5 {
    padding-right: 5px !important;
  }
  .pad-bot-sm-5 {
    padding-bottom: 5px !important;
  }
  .pad-lef-sm-5 {
    padding-left: 5px !important;
  }
  .mar-sm-10 {
    margin: 10px !important;
  }
  .mar-top-sm-10 {
    margin-top: 10px !important;
  }
  .mar-rig-sm-10 {
    margin-right: 10px !important;
  }
  .mar-bot-sm-10 {
    margin-bottom: 10px !important;
  }
  .mar-lef-sm-10 {
    margin-left: 10px !important;
  }
  .mar-top-neg-sm-10 {
    margin-top: -10px !important;
  }
  .mar-rig-neg-sm-10 {
    margin-right: -10px !important;
  }
  .mar-bot-neg-sm-10 {
    margin-bottom: -10px !important;
  }
  .mar-lef-neg-sm-10 {
    margin-left: -10px !important;
  }
  .pad-sm-10 {
    padding: 10px !important;
  }
  .pad-top-sm-10 {
    padding-top: 10px !important;
  }
  .pad-rig-sm-10 {
    padding-right: 10px !important;
  }
  .pad-bot-sm-10 {
    padding-bottom: 10px !important;
  }
  .pad-lef-sm-10 {
    padding-left: 10px !important;
  }
  .mar-sm-15 {
    margin: 15px !important;
  }
  .mar-top-sm-15 {
    margin-top: 15px !important;
  }
  .mar-rig-sm-15 {
    margin-right: 15px !important;
  }
  .mar-bot-sm-15 {
    margin-bottom: 15px !important;
  }
  .mar-lef-sm-15 {
    margin-left: 15px !important;
  }
  .mar-top-neg-sm-15 {
    margin-top: -15px !important;
  }
  .mar-rig-neg-sm-15 {
    margin-right: -15px !important;
  }
  .mar-bot-neg-sm-15 {
    margin-bottom: -15px !important;
  }
  .mar-lef-neg-sm-15 {
    margin-left: -15px !important;
  }
  .pad-sm-15 {
    padding: 15px !important;
  }
  .pad-top-sm-15 {
    padding-top: 15px !important;
  }
  .pad-rig-sm-15 {
    padding-right: 15px !important;
  }
  .pad-bot-sm-15 {
    padding-bottom: 15px !important;
  }
  .pad-lef-sm-15 {
    padding-left: 15px !important;
  }
  .mar-sm-20 {
    margin: 20px !important;
  }
  .mar-top-sm-20 {
    margin-top: 20px !important;
  }
  .mar-rig-sm-20 {
    margin-right: 20px !important;
  }
  .mar-bot-sm-20 {
    margin-bottom: 20px !important;
  }
  .mar-lef-sm-20 {
    margin-left: 20px !important;
  }
  .mar-top-neg-sm-20 {
    margin-top: -20px !important;
  }
  .mar-rig-neg-sm-20 {
    margin-right: -20px !important;
  }
  .mar-bot-neg-sm-20 {
    margin-bottom: -20px !important;
  }
  .mar-lef-neg-sm-20 {
    margin-left: -20px !important;
  }
  .pad-sm-20 {
    padding: 20px !important;
  }
  .pad-top-sm-20 {
    padding-top: 20px !important;
  }
  .pad-rig-sm-20 {
    padding-right: 20px !important;
  }
  .pad-bot-sm-20 {
    padding-bottom: 20px !important;
  }
  .pad-lef-sm-20 {
    padding-left: 20px !important;
  }
  .mar-sm-25 {
    margin: 25px !important;
  }
  .mar-top-sm-25 {
    margin-top: 25px !important;
  }
  .mar-rig-sm-25 {
    margin-right: 25px !important;
  }
  .mar-bot-sm-25 {
    margin-bottom: 25px !important;
  }
  .mar-lef-sm-25 {
    margin-left: 25px !important;
  }
  .mar-top-neg-sm-25 {
    margin-top: -25px !important;
  }
  .mar-rig-neg-sm-25 {
    margin-right: -25px !important;
  }
  .mar-bot-neg-sm-25 {
    margin-bottom: -25px !important;
  }
  .mar-lef-neg-sm-25 {
    margin-left: -25px !important;
  }
  .pad-sm-25 {
    padding: 25px !important;
  }
  .pad-top-sm-25 {
    padding-top: 25px !important;
  }
  .pad-rig-sm-25 {
    padding-right: 25px !important;
  }
  .pad-bot-sm-25 {
    padding-bottom: 25px !important;
  }
  .pad-lef-sm-25 {
    padding-left: 25px !important;
  }
  .mar-sm-30 {
    margin: 30px !important;
  }
  .mar-top-sm-30 {
    margin-top: 30px !important;
  }
  .mar-rig-sm-30 {
    margin-right: 30px !important;
  }
  .mar-bot-sm-30 {
    margin-bottom: 30px !important;
  }
  .mar-lef-sm-30 {
    margin-left: 30px !important;
  }
  .mar-top-neg-sm-30 {
    margin-top: -30px !important;
  }
  .mar-rig-neg-sm-30 {
    margin-right: -30px !important;
  }
  .mar-bot-neg-sm-30 {
    margin-bottom: -30px !important;
  }
  .mar-lef-neg-sm-30 {
    margin-left: -30px !important;
  }
  .pad-sm-30 {
    padding: 30px !important;
  }
  .pad-top-sm-30 {
    padding-top: 30px !important;
  }
  .pad-rig-sm-30 {
    padding-right: 30px !important;
  }
  .pad-bot-sm-30 {
    padding-bottom: 30px !important;
  }
  .pad-lef-sm-30 {
    padding-left: 30px !important;
  }
  .mar-sm-35 {
    margin: 35px !important;
  }
  .mar-top-sm-35 {
    margin-top: 35px !important;
  }
  .mar-rig-sm-35 {
    margin-right: 35px !important;
  }
  .mar-bot-sm-35 {
    margin-bottom: 35px !important;
  }
  .mar-lef-sm-35 {
    margin-left: 35px !important;
  }
  .mar-top-neg-sm-35 {
    margin-top: -35px !important;
  }
  .mar-rig-neg-sm-35 {
    margin-right: -35px !important;
  }
  .mar-bot-neg-sm-35 {
    margin-bottom: -35px !important;
  }
  .mar-lef-neg-sm-35 {
    margin-left: -35px !important;
  }
  .pad-sm-35 {
    padding: 35px !important;
  }
  .pad-top-sm-35 {
    padding-top: 35px !important;
  }
  .pad-rig-sm-35 {
    padding-right: 35px !important;
  }
  .pad-bot-sm-35 {
    padding-bottom: 35px !important;
  }
  .pad-lef-sm-35 {
    padding-left: 35px !important;
  }
  .mar-sm-40 {
    margin: 40px !important;
  }
  .mar-top-sm-40 {
    margin-top: 40px !important;
  }
  .mar-rig-sm-40 {
    margin-right: 40px !important;
  }
  .mar-bot-sm-40 {
    margin-bottom: 40px !important;
  }
  .mar-lef-sm-40 {
    margin-left: 40px !important;
  }
  .mar-top-neg-sm-40 {
    margin-top: -40px !important;
  }
  .mar-rig-neg-sm-40 {
    margin-right: -40px !important;
  }
  .mar-bot-neg-sm-40 {
    margin-bottom: -40px !important;
  }
  .mar-lef-neg-sm-40 {
    margin-left: -40px !important;
  }
  .pad-sm-40 {
    padding: 40px !important;
  }
  .pad-top-sm-40 {
    padding-top: 40px !important;
  }
  .pad-rig-sm-40 {
    padding-right: 40px !important;
  }
  .pad-bot-sm-40 {
    padding-bottom: 40px !important;
  }
  .pad-lef-sm-40 {
    padding-left: 40px !important;
  }
  .mar-sm-45 {
    margin: 45px !important;
  }
  .mar-top-sm-45 {
    margin-top: 45px !important;
  }
  .mar-rig-sm-45 {
    margin-right: 45px !important;
  }
  .mar-bot-sm-45 {
    margin-bottom: 45px !important;
  }
  .mar-lef-sm-45 {
    margin-left: 45px !important;
  }
  .mar-top-neg-sm-45 {
    margin-top: -45px !important;
  }
  .mar-rig-neg-sm-45 {
    margin-right: -45px !important;
  }
  .mar-bot-neg-sm-45 {
    margin-bottom: -45px !important;
  }
  .mar-lef-neg-sm-45 {
    margin-left: -45px !important;
  }
  .pad-sm-45 {
    padding: 45px !important;
  }
  .pad-top-sm-45 {
    padding-top: 45px !important;
  }
  .pad-rig-sm-45 {
    padding-right: 45px !important;
  }
  .pad-bot-sm-45 {
    padding-bottom: 45px !important;
  }
  .pad-lef-sm-45 {
    padding-left: 45px !important;
  }
  .mar-sm-50 {
    margin: 50px !important;
  }
  .mar-top-sm-50 {
    margin-top: 50px !important;
  }
  .mar-rig-sm-50 {
    margin-right: 50px !important;
  }
  .mar-bot-sm-50 {
    margin-bottom: 50px !important;
  }
  .mar-lef-sm-50 {
    margin-left: 50px !important;
  }
  .mar-top-neg-sm-50 {
    margin-top: -50px !important;
  }
  .mar-rig-neg-sm-50 {
    margin-right: -50px !important;
  }
  .mar-bot-neg-sm-50 {
    margin-bottom: -50px !important;
  }
  .mar-lef-neg-sm-50 {
    margin-left: -50px !important;
  }
  .pad-sm-50 {
    padding: 50px !important;
  }
  .pad-top-sm-50 {
    padding-top: 50px !important;
  }
  .pad-rig-sm-50 {
    padding-right: 50px !important;
  }
  .pad-bot-sm-50 {
    padding-bottom: 50px !important;
  }
  .pad-lef-sm-50 {
    padding-left: 50px !important;
  }
  .mar-sm-55 {
    margin: 55px !important;
  }
  .mar-top-sm-55 {
    margin-top: 55px !important;
  }
  .mar-rig-sm-55 {
    margin-right: 55px !important;
  }
  .mar-bot-sm-55 {
    margin-bottom: 55px !important;
  }
  .mar-lef-sm-55 {
    margin-left: 55px !important;
  }
  .mar-top-neg-sm-55 {
    margin-top: -55px !important;
  }
  .mar-rig-neg-sm-55 {
    margin-right: -55px !important;
  }
  .mar-bot-neg-sm-55 {
    margin-bottom: -55px !important;
  }
  .mar-lef-neg-sm-55 {
    margin-left: -55px !important;
  }
  .pad-sm-55 {
    padding: 55px !important;
  }
  .pad-top-sm-55 {
    padding-top: 55px !important;
  }
  .pad-rig-sm-55 {
    padding-right: 55px !important;
  }
  .pad-bot-sm-55 {
    padding-bottom: 55px !important;
  }
  .pad-lef-sm-55 {
    padding-left: 55px !important;
  }
  .mar-sm-60 {
    margin: 60px !important;
  }
  .mar-top-sm-60 {
    margin-top: 60px !important;
  }
  .mar-rig-sm-60 {
    margin-right: 60px !important;
  }
  .mar-bot-sm-60 {
    margin-bottom: 60px !important;
  }
  .mar-lef-sm-60 {
    margin-left: 60px !important;
  }
  .mar-top-neg-sm-60 {
    margin-top: -60px !important;
  }
  .mar-rig-neg-sm-60 {
    margin-right: -60px !important;
  }
  .mar-bot-neg-sm-60 {
    margin-bottom: -60px !important;
  }
  .mar-lef-neg-sm-60 {
    margin-left: -60px !important;
  }
  .pad-sm-60 {
    padding: 60px !important;
  }
  .pad-top-sm-60 {
    padding-top: 60px !important;
  }
  .pad-rig-sm-60 {
    padding-right: 60px !important;
  }
  .pad-bot-sm-60 {
    padding-bottom: 60px !important;
  }
  .pad-lef-sm-60 {
    padding-left: 60px !important;
  }
  .mar-sm-65 {
    margin: 65px !important;
  }
  .mar-top-sm-65 {
    margin-top: 65px !important;
  }
  .mar-rig-sm-65 {
    margin-right: 65px !important;
  }
  .mar-bot-sm-65 {
    margin-bottom: 65px !important;
  }
  .mar-lef-sm-65 {
    margin-left: 65px !important;
  }
  .mar-top-neg-sm-65 {
    margin-top: -65px !important;
  }
  .mar-rig-neg-sm-65 {
    margin-right: -65px !important;
  }
  .mar-bot-neg-sm-65 {
    margin-bottom: -65px !important;
  }
  .mar-lef-neg-sm-65 {
    margin-left: -65px !important;
  }
  .pad-sm-65 {
    padding: 65px !important;
  }
  .pad-top-sm-65 {
    padding-top: 65px !important;
  }
  .pad-rig-sm-65 {
    padding-right: 65px !important;
  }
  .pad-bot-sm-65 {
    padding-bottom: 65px !important;
  }
  .pad-lef-sm-65 {
    padding-left: 65px !important;
  }
  .mar-sm-70 {
    margin: 70px !important;
  }
  .mar-top-sm-70 {
    margin-top: 70px !important;
  }
  .mar-rig-sm-70 {
    margin-right: 70px !important;
  }
  .mar-bot-sm-70 {
    margin-bottom: 70px !important;
  }
  .mar-lef-sm-70 {
    margin-left: 70px !important;
  }
  .mar-top-neg-sm-70 {
    margin-top: -70px !important;
  }
  .mar-rig-neg-sm-70 {
    margin-right: -70px !important;
  }
  .mar-bot-neg-sm-70 {
    margin-bottom: -70px !important;
  }
  .mar-lef-neg-sm-70 {
    margin-left: -70px !important;
  }
  .pad-sm-70 {
    padding: 70px !important;
  }
  .pad-top-sm-70 {
    padding-top: 70px !important;
  }
  .pad-rig-sm-70 {
    padding-right: 70px !important;
  }
  .pad-bot-sm-70 {
    padding-bottom: 70px !important;
  }
  .pad-lef-sm-70 {
    padding-left: 70px !important;
  }
  .mar-sm-75 {
    margin: 75px !important;
  }
  .mar-top-sm-75 {
    margin-top: 75px !important;
  }
  .mar-rig-sm-75 {
    margin-right: 75px !important;
  }
  .mar-bot-sm-75 {
    margin-bottom: 75px !important;
  }
  .mar-lef-sm-75 {
    margin-left: 75px !important;
  }
  .mar-top-neg-sm-75 {
    margin-top: -75px !important;
  }
  .mar-rig-neg-sm-75 {
    margin-right: -75px !important;
  }
  .mar-bot-neg-sm-75 {
    margin-bottom: -75px !important;
  }
  .mar-lef-neg-sm-75 {
    margin-left: -75px !important;
  }
  .pad-sm-75 {
    padding: 75px !important;
  }
  .pad-top-sm-75 {
    padding-top: 75px !important;
  }
  .pad-rig-sm-75 {
    padding-right: 75px !important;
  }
  .pad-bot-sm-75 {
    padding-bottom: 75px !important;
  }
  .pad-lef-sm-75 {
    padding-left: 75px !important;
  }
  .mar-sm-80 {
    margin: 80px !important;
  }
  .mar-top-sm-80 {
    margin-top: 80px !important;
  }
  .mar-rig-sm-80 {
    margin-right: 80px !important;
  }
  .mar-bot-sm-80 {
    margin-bottom: 80px !important;
  }
  .mar-lef-sm-80 {
    margin-left: 80px !important;
  }
  .mar-top-neg-sm-80 {
    margin-top: -80px !important;
  }
  .mar-rig-neg-sm-80 {
    margin-right: -80px !important;
  }
  .mar-bot-neg-sm-80 {
    margin-bottom: -80px !important;
  }
  .mar-lef-neg-sm-80 {
    margin-left: -80px !important;
  }
  .pad-sm-80 {
    padding: 80px !important;
  }
  .pad-top-sm-80 {
    padding-top: 80px !important;
  }
  .pad-rig-sm-80 {
    padding-right: 80px !important;
  }
  .pad-bot-sm-80 {
    padding-bottom: 80px !important;
  }
  .pad-lef-sm-80 {
    padding-left: 80px !important;
  }
  .mar-sm-85 {
    margin: 85px !important;
  }
  .mar-top-sm-85 {
    margin-top: 85px !important;
  }
  .mar-rig-sm-85 {
    margin-right: 85px !important;
  }
  .mar-bot-sm-85 {
    margin-bottom: 85px !important;
  }
  .mar-lef-sm-85 {
    margin-left: 85px !important;
  }
  .mar-top-neg-sm-85 {
    margin-top: -85px !important;
  }
  .mar-rig-neg-sm-85 {
    margin-right: -85px !important;
  }
  .mar-bot-neg-sm-85 {
    margin-bottom: -85px !important;
  }
  .mar-lef-neg-sm-85 {
    margin-left: -85px !important;
  }
  .pad-sm-85 {
    padding: 85px !important;
  }
  .pad-top-sm-85 {
    padding-top: 85px !important;
  }
  .pad-rig-sm-85 {
    padding-right: 85px !important;
  }
  .pad-bot-sm-85 {
    padding-bottom: 85px !important;
  }
  .pad-lef-sm-85 {
    padding-left: 85px !important;
  }
  .mar-sm-90 {
    margin: 90px !important;
  }
  .mar-top-sm-90 {
    margin-top: 90px !important;
  }
  .mar-rig-sm-90 {
    margin-right: 90px !important;
  }
  .mar-bot-sm-90 {
    margin-bottom: 90px !important;
  }
  .mar-lef-sm-90 {
    margin-left: 90px !important;
  }
  .mar-top-neg-sm-90 {
    margin-top: -90px !important;
  }
  .mar-rig-neg-sm-90 {
    margin-right: -90px !important;
  }
  .mar-bot-neg-sm-90 {
    margin-bottom: -90px !important;
  }
  .mar-lef-neg-sm-90 {
    margin-left: -90px !important;
  }
  .pad-sm-90 {
    padding: 90px !important;
  }
  .pad-top-sm-90 {
    padding-top: 90px !important;
  }
  .pad-rig-sm-90 {
    padding-right: 90px !important;
  }
  .pad-bot-sm-90 {
    padding-bottom: 90px !important;
  }
  .pad-lef-sm-90 {
    padding-left: 90px !important;
  }
  .mar-sm-95 {
    margin: 95px !important;
  }
  .mar-top-sm-95 {
    margin-top: 95px !important;
  }
  .mar-rig-sm-95 {
    margin-right: 95px !important;
  }
  .mar-bot-sm-95 {
    margin-bottom: 95px !important;
  }
  .mar-lef-sm-95 {
    margin-left: 95px !important;
  }
  .mar-top-neg-sm-95 {
    margin-top: -95px !important;
  }
  .mar-rig-neg-sm-95 {
    margin-right: -95px !important;
  }
  .mar-bot-neg-sm-95 {
    margin-bottom: -95px !important;
  }
  .mar-lef-neg-sm-95 {
    margin-left: -95px !important;
  }
  .pad-sm-95 {
    padding: 95px !important;
  }
  .pad-top-sm-95 {
    padding-top: 95px !important;
  }
  .pad-rig-sm-95 {
    padding-right: 95px !important;
  }
  .pad-bot-sm-95 {
    padding-bottom: 95px !important;
  }
  .pad-lef-sm-95 {
    padding-left: 95px !important;
  }
  .mar-sm-100 {
    margin: 100px !important;
  }
  .mar-top-sm-100 {
    margin-top: 100px !important;
  }
  .mar-rig-sm-100 {
    margin-right: 100px !important;
  }
  .mar-bot-sm-100 {
    margin-bottom: 100px !important;
  }
  .mar-lef-sm-100 {
    margin-left: 100px !important;
  }
  .mar-top-neg-sm-100 {
    margin-top: -100px !important;
  }
  .mar-rig-neg-sm-100 {
    margin-right: -100px !important;
  }
  .mar-bot-neg-sm-100 {
    margin-bottom: -100px !important;
  }
  .mar-lef-neg-sm-100 {
    margin-left: -100px !important;
  }
  .pad-sm-100 {
    padding: 100px !important;
  }
  .pad-top-sm-100 {
    padding-top: 100px !important;
  }
  .pad-rig-sm-100 {
    padding-right: 100px !important;
  }
  .pad-bot-sm-100 {
    padding-bottom: 100px !important;
  }
  .pad-lef-sm-100 {
    padding-left: 100px !important;
  }
  .line-height-sm-0 {
    line-height: 0px !important;
  }
  .line-height-sm-1 {
    line-height: 1px !important;
  }
  .line-height-sm-2 {
    line-height: 2px !important;
  }
  .line-height-sm-3 {
    line-height: 3px !important;
  }
  .line-height-sm-4 {
    line-height: 4px !important;
  }
  .line-height-sm-5 {
    line-height: 5px !important;
  }
  .line-height-sm-6 {
    line-height: 6px !important;
  }
  .line-height-sm-7 {
    line-height: 7px !important;
  }
  .line-height-sm-8 {
    line-height: 8px !important;
  }
  .line-height-sm-9 {
    line-height: 9px !important;
  }
  .line-height-sm-10 {
    line-height: 10px !important;
  }
  .line-height-sm-11 {
    line-height: 11px !important;
  }
  .line-height-sm-12 {
    line-height: 12px !important;
  }
  .line-height-sm-13 {
    line-height: 13px !important;
  }
  .line-height-sm-14 {
    line-height: 14px !important;
  }
  .line-height-sm-15 {
    line-height: 15px !important;
  }
  .line-height-sm-16 {
    line-height: 16px !important;
  }
  .line-height-sm-17 {
    line-height: 17px !important;
  }
  .line-height-sm-18 {
    line-height: 18px !important;
  }
  .line-height-sm-19 {
    line-height: 19px !important;
  }
  .line-height-sm-20 {
    line-height: 20px !important;
  }
  .line-height-sm-21 {
    line-height: 21px !important;
  }
  .line-height-sm-22 {
    line-height: 22px !important;
  }
  .line-height-sm-23 {
    line-height: 23px !important;
  }
  .line-height-sm-24 {
    line-height: 24px !important;
  }
  .line-height-sm-25 {
    line-height: 25px !important;
  }
  .line-height-sm-26 {
    line-height: 26px !important;
  }
  .line-height-sm-27 {
    line-height: 27px !important;
  }
  .line-height-sm-28 {
    line-height: 28px !important;
  }
  .line-height-sm-29 {
    line-height: 29px !important;
  }
  .line-height-sm-30 {
    line-height: 30px !important;
  }
  .line-height-sm-31 {
    line-height: 31px !important;
  }
  .line-height-sm-32 {
    line-height: 32px !important;
  }
  .line-height-sm-33 {
    line-height: 33px !important;
  }
  .line-height-sm-34 {
    line-height: 34px !important;
  }
  .line-height-sm-35 {
    line-height: 35px !important;
  }
  .line-height-sm-36 {
    line-height: 36px !important;
  }
  .line-height-sm-37 {
    line-height: 37px !important;
  }
  .line-height-sm-38 {
    line-height: 38px !important;
  }
  .line-height-sm-39 {
    line-height: 39px !important;
  }
  .line-height-sm-40 {
    line-height: 40px !important;
  }
  .line-height-sm-41 {
    line-height: 41px !important;
  }
  .line-height-sm-42 {
    line-height: 42px !important;
  }
  .line-height-sm-43 {
    line-height: 43px !important;
  }
  .line-height-sm-44 {
    line-height: 44px !important;
  }
  .line-height-sm-45 {
    line-height: 45px !important;
  }
  .line-height-sm-46 {
    line-height: 46px !important;
  }
  .line-height-sm-47 {
    line-height: 47px !important;
  }
  .line-height-sm-48 {
    line-height: 48px !important;
  }
  .line-height-sm-49 {
    line-height: 49px !important;
  }
  .line-height-sm-50 {
    line-height: 50px !important;
  }
  .line-height-sm-51 {
    line-height: 51px !important;
  }
  .line-height-sm-52 {
    line-height: 52px !important;
  }
  .line-height-sm-53 {
    line-height: 53px !important;
  }
  .line-height-sm-54 {
    line-height: 54px !important;
  }
  .line-height-sm-55 {
    line-height: 55px !important;
  }
  .line-height-sm-56 {
    line-height: 56px !important;
  }
  .line-height-sm-57 {
    line-height: 57px !important;
  }
  .line-height-sm-58 {
    line-height: 58px !important;
  }
  .line-height-sm-59 {
    line-height: 59px !important;
  }
  .line-height-sm-60 {
    line-height: 60px !important;
  }
  .dis-block-sm {
    display: block !important;
  }
  .dis-none-sm {
    display: none !important;
  }
  .dis-inline-sm {
    display: inline !important;
  }
  .dis-inline-block-sm {
    display: inline-block !important;
  }
  .text-cen-sm {
    text-align: center !important;
  }
  .text-lef-sm {
    text-align: left !important;
  }
  .text-rig-sm {
    text-align: right !important;
  }
  .text-jus-sm {
    text-align: justify !important;
  }
  .pos-re-sm {
    position: relative !important;
  }
  .pos-ab-sm {
    position: absolute !important;
  }
  .pos-st-sm {
    position: static !important;
  }
}

@media (min-width: 992px) {
  .font-size-md-8 {
    font-size: 8px !important;
  }
  .font-size-md-9 {
    font-size: 9px !important;
  }
  .font-size-md-10 {
    font-size: 10px !important;
  }
  .font-size-md-11 {
    font-size: 11px !important;
  }
  .font-size-md-12 {
    font-size: 12px !important;
  }
  .font-size-md-13 {
    font-size: 13px !important;
  }
  .font-size-md-14 {
    font-size: 14px !important;
  }
  .font-size-md-15 {
    font-size: 15px !important;
  }
  .font-size-md-16 {
    font-size: 16px !important;
  }
  .font-size-md-17 {
    font-size: 17px !important;
  }
  .font-size-md-18 {
    font-size: 18px !important;
  }
  .font-size-md-19 {
    font-size: 19px !important;
  }
  .font-size-md-20 {
    font-size: 20px !important;
  }
  .font-size-md-21 {
    font-size: 21px !important;
  }
  .font-size-md-22 {
    font-size: 22px !important;
  }
  .font-size-md-23 {
    font-size: 23px !important;
  }
  .font-size-md-24 {
    font-size: 24px !important;
  }
  .font-size-md-25 {
    font-size: 25px !important;
  }
  .font-size-md-26 {
    font-size: 26px !important;
  }
  .font-size-md-27 {
    font-size: 27px !important;
  }
  .font-size-md-28 {
    font-size: 28px !important;
  }
  .font-size-md-29 {
    font-size: 29px !important;
  }
  .font-size-md-30 {
    font-size: 30px !important;
  }
  .font-size-md-31 {
    font-size: 31px !important;
  }
  .font-size-md-32 {
    font-size: 32px !important;
  }
  .font-size-md-33 {
    font-size: 33px !important;
  }
  .font-size-md-34 {
    font-size: 34px !important;
  }
  .font-size-md-35 {
    font-size: 35px !important;
  }
  .font-size-md-36 {
    font-size: 36px !important;
  }
  .font-size-md-37 {
    font-size: 37px !important;
  }
  .font-size-md-38 {
    font-size: 38px !important;
  }
  .font-size-md-39 {
    font-size: 39px !important;
  }
  .font-size-md-40 {
    font-size: 40px !important;
  }
  .font-size-md-41 {
    font-size: 41px !important;
  }
  .font-size-md-42 {
    font-size: 42px !important;
  }
  .font-size-md-43 {
    font-size: 43px !important;
  }
  .font-size-md-44 {
    font-size: 44px !important;
  }
  .font-size-md-45 {
    font-size: 45px !important;
  }
  .font-size-md-46 {
    font-size: 46px !important;
  }
  .font-size-md-47 {
    font-size: 47px !important;
  }
  .font-size-md-48 {
    font-size: 48px !important;
  }
  .font-size-md-49 {
    font-size: 49px !important;
  }
  .font-size-md-50 {
    font-size: 50px !important;
  }
  .font-size-md-51 {
    font-size: 51px !important;
  }
  .font-size-md-52 {
    font-size: 52px !important;
  }
  .font-size-md-53 {
    font-size: 53px !important;
  }
  .font-size-md-54 {
    font-size: 54px !important;
  }
  .font-size-md-55 {
    font-size: 55px !important;
  }
  .font-size-md-56 {
    font-size: 56px !important;
  }
  .font-size-md-57 {
    font-size: 57px !important;
  }
  .font-size-md-58 {
    font-size: 58px !important;
  }
  .font-size-md-59 {
    font-size: 59px !important;
  }
  .font-size-md-60 {
    font-size: 60px !important;
  }
  .mar-md-0 {
    margin: 0px !important;
  }
  .mar-top-md-0 {
    margin-top: 0px !important;
  }
  .mar-rig-md-0 {
    margin-right: 0px !important;
  }
  .mar-bot-md-0 {
    margin-bottom: 0px !important;
  }
  .mar-lef-md-0 {
    margin-left: 0px !important;
  }
  .mar-top-neg-md-0 {
    margin-top: -0px !important;
  }
  .mar-rig-neg-md-0 {
    margin-right: -0px !important;
  }
  .mar-bot-neg-md-0 {
    margin-bottom: -0px !important;
  }
  .mar-lef-neg-md-0 {
    margin-left: -0px !important;
  }
  .pad-md-0 {
    padding: 0px !important;
  }
  .pad-top-md-0 {
    padding-top: 0px !important;
  }
  .pad-rig-md-0 {
    padding-right: 0px !important;
  }
  .pad-bot-md-0 {
    padding-bottom: 0px !important;
  }
  .pad-lef-md-0 {
    padding-left: 0px !important;
  }
  .mar-md-5 {
    margin: 5px !important;
  }
  .mar-top-md-5 {
    margin-top: 5px !important;
  }
  .mar-rig-md-5 {
    margin-right: 5px !important;
  }
  .mar-bot-md-5 {
    margin-bottom: 5px !important;
  }
  .mar-lef-md-5 {
    margin-left: 5px !important;
  }
  .mar-top-neg-md-5 {
    margin-top: -5px !important;
  }
  .mar-rig-neg-md-5 {
    margin-right: -5px !important;
  }
  .mar-bot-neg-md-5 {
    margin-bottom: -5px !important;
  }
  .mar-lef-neg-md-5 {
    margin-left: -5px !important;
  }
  .pad-md-5 {
    padding: 5px !important;
  }
  .pad-top-md-5 {
    padding-top: 5px !important;
  }
  .pad-rig-md-5 {
    padding-right: 5px !important;
  }
  .pad-bot-md-5 {
    padding-bottom: 5px !important;
  }
  .pad-lef-md-5 {
    padding-left: 5px !important;
  }
  .mar-md-10 {
    margin: 10px !important;
  }
  .mar-top-md-10 {
    margin-top: 10px !important;
  }
  .mar-rig-md-10 {
    margin-right: 10px !important;
  }
  .mar-bot-md-10 {
    margin-bottom: 10px !important;
  }
  .mar-lef-md-10 {
    margin-left: 10px !important;
  }
  .mar-top-neg-md-10 {
    margin-top: -10px !important;
  }
  .mar-rig-neg-md-10 {
    margin-right: -10px !important;
  }
  .mar-bot-neg-md-10 {
    margin-bottom: -10px !important;
  }
  .mar-lef-neg-md-10 {
    margin-left: -10px !important;
  }
  .pad-md-10 {
    padding: 10px !important;
  }
  .pad-top-md-10 {
    padding-top: 10px !important;
  }
  .pad-rig-md-10 {
    padding-right: 10px !important;
  }
  .pad-bot-md-10 {
    padding-bottom: 10px !important;
  }
  .pad-lef-md-10 {
    padding-left: 10px !important;
  }
  .mar-md-15 {
    margin: 15px !important;
  }
  .mar-top-md-15 {
    margin-top: 15px !important;
  }
  .mar-rig-md-15 {
    margin-right: 15px !important;
  }
  .mar-bot-md-15 {
    margin-bottom: 15px !important;
  }
  .mar-lef-md-15 {
    margin-left: 15px !important;
  }
  .mar-top-neg-md-15 {
    margin-top: -15px !important;
  }
  .mar-rig-neg-md-15 {
    margin-right: -15px !important;
  }
  .mar-bot-neg-md-15 {
    margin-bottom: -15px !important;
  }
  .mar-lef-neg-md-15 {
    margin-left: -15px !important;
  }
  .pad-md-15 {
    padding: 15px !important;
  }
  .pad-top-md-15 {
    padding-top: 15px !important;
  }
  .pad-rig-md-15 {
    padding-right: 15px !important;
  }
  .pad-bot-md-15 {
    padding-bottom: 15px !important;
  }
  .pad-lef-md-15 {
    padding-left: 15px !important;
  }
  .mar-md-20 {
    margin: 20px !important;
  }
  .mar-top-md-20 {
    margin-top: 20px !important;
  }
  .mar-rig-md-20 {
    margin-right: 20px !important;
  }
  .mar-bot-md-20 {
    margin-bottom: 20px !important;
  }
  .mar-lef-md-20 {
    margin-left: 20px !important;
  }
  .mar-top-neg-md-20 {
    margin-top: -20px !important;
  }
  .mar-rig-neg-md-20 {
    margin-right: -20px !important;
  }
  .mar-bot-neg-md-20 {
    margin-bottom: -20px !important;
  }
  .mar-lef-neg-md-20 {
    margin-left: -20px !important;
  }
  .pad-md-20 {
    padding: 20px !important;
  }
  .pad-top-md-20 {
    padding-top: 20px !important;
  }
  .pad-rig-md-20 {
    padding-right: 20px !important;
  }
  .pad-bot-md-20 {
    padding-bottom: 20px !important;
  }
  .pad-lef-md-20 {
    padding-left: 20px !important;
  }
  .mar-md-25 {
    margin: 25px !important;
  }
  .mar-top-md-25 {
    margin-top: 25px !important;
  }
  .mar-rig-md-25 {
    margin-right: 25px !important;
  }
  .mar-bot-md-25 {
    margin-bottom: 25px !important;
  }
  .mar-lef-md-25 {
    margin-left: 25px !important;
  }
  .mar-top-neg-md-25 {
    margin-top: -25px !important;
  }
  .mar-rig-neg-md-25 {
    margin-right: -25px !important;
  }
  .mar-bot-neg-md-25 {
    margin-bottom: -25px !important;
  }
  .mar-lef-neg-md-25 {
    margin-left: -25px !important;
  }
  .pad-md-25 {
    padding: 25px !important;
  }
  .pad-top-md-25 {
    padding-top: 25px !important;
  }
  .pad-rig-md-25 {
    padding-right: 25px !important;
  }
  .pad-bot-md-25 {
    padding-bottom: 25px !important;
  }
  .pad-lef-md-25 {
    padding-left: 25px !important;
  }
  .mar-md-30 {
    margin: 30px !important;
  }
  .mar-top-md-30 {
    margin-top: 30px !important;
  }
  .mar-rig-md-30 {
    margin-right: 30px !important;
  }
  .mar-bot-md-30 {
    margin-bottom: 30px !important;
  }
  .mar-lef-md-30 {
    margin-left: 30px !important;
  }
  .mar-top-neg-md-30 {
    margin-top: -30px !important;
  }
  .mar-rig-neg-md-30 {
    margin-right: -30px !important;
  }
  .mar-bot-neg-md-30 {
    margin-bottom: -30px !important;
  }
  .mar-lef-neg-md-30 {
    margin-left: -30px !important;
  }
  .pad-md-30 {
    padding: 30px !important;
  }
  .pad-top-md-30 {
    padding-top: 30px !important;
  }
  .pad-rig-md-30 {
    padding-right: 30px !important;
  }
  .pad-bot-md-30 {
    padding-bottom: 30px !important;
  }
  .pad-lef-md-30 {
    padding-left: 30px !important;
  }
  .mar-md-35 {
    margin: 35px !important;
  }
  .mar-top-md-35 {
    margin-top: 35px !important;
  }
  .mar-rig-md-35 {
    margin-right: 35px !important;
  }
  .mar-bot-md-35 {
    margin-bottom: 35px !important;
  }
  .mar-lef-md-35 {
    margin-left: 35px !important;
  }
  .mar-top-neg-md-35 {
    margin-top: -35px !important;
  }
  .mar-rig-neg-md-35 {
    margin-right: -35px !important;
  }
  .mar-bot-neg-md-35 {
    margin-bottom: -35px !important;
  }
  .mar-lef-neg-md-35 {
    margin-left: -35px !important;
  }
  .pad-md-35 {
    padding: 35px !important;
  }
  .pad-top-md-35 {
    padding-top: 35px !important;
  }
  .pad-rig-md-35 {
    padding-right: 35px !important;
  }
  .pad-bot-md-35 {
    padding-bottom: 35px !important;
  }
  .pad-lef-md-35 {
    padding-left: 35px !important;
  }
  .mar-md-40 {
    margin: 40px !important;
  }
  .mar-top-md-40 {
    margin-top: 40px !important;
  }
  .mar-rig-md-40 {
    margin-right: 40px !important;
  }
  .mar-bot-md-40 {
    margin-bottom: 40px !important;
  }
  .mar-lef-md-40 {
    margin-left: 40px !important;
  }
  .mar-top-neg-md-40 {
    margin-top: -40px !important;
  }
  .mar-rig-neg-md-40 {
    margin-right: -40px !important;
  }
  .mar-bot-neg-md-40 {
    margin-bottom: -40px !important;
  }
  .mar-lef-neg-md-40 {
    margin-left: -40px !important;
  }
  .pad-md-40 {
    padding: 40px !important;
  }
  .pad-top-md-40 {
    padding-top: 40px !important;
  }
  .pad-rig-md-40 {
    padding-right: 40px !important;
  }
  .pad-bot-md-40 {
    padding-bottom: 40px !important;
  }
  .pad-lef-md-40 {
    padding-left: 40px !important;
  }
  .mar-md-45 {
    margin: 45px !important;
  }
  .mar-top-md-45 {
    margin-top: 45px !important;
  }
  .mar-rig-md-45 {
    margin-right: 45px !important;
  }
  .mar-bot-md-45 {
    margin-bottom: 45px !important;
  }
  .mar-lef-md-45 {
    margin-left: 45px !important;
  }
  .mar-top-neg-md-45 {
    margin-top: -45px !important;
  }
  .mar-rig-neg-md-45 {
    margin-right: -45px !important;
  }
  .mar-bot-neg-md-45 {
    margin-bottom: -45px !important;
  }
  .mar-lef-neg-md-45 {
    margin-left: -45px !important;
  }
  .pad-md-45 {
    padding: 45px !important;
  }
  .pad-top-md-45 {
    padding-top: 45px !important;
  }
  .pad-rig-md-45 {
    padding-right: 45px !important;
  }
  .pad-bot-md-45 {
    padding-bottom: 45px !important;
  }
  .pad-lef-md-45 {
    padding-left: 45px !important;
  }
  .mar-md-50 {
    margin: 50px !important;
  }
  .mar-top-md-50 {
    margin-top: 50px !important;
  }
  .mar-rig-md-50 {
    margin-right: 50px !important;
  }
  .mar-bot-md-50 {
    margin-bottom: 50px !important;
  }
  .mar-lef-md-50 {
    margin-left: 50px !important;
  }
  .mar-top-neg-md-50 {
    margin-top: -50px !important;
  }
  .mar-rig-neg-md-50 {
    margin-right: -50px !important;
  }
  .mar-bot-neg-md-50 {
    margin-bottom: -50px !important;
  }
  .mar-lef-neg-md-50 {
    margin-left: -50px !important;
  }
  .pad-md-50 {
    padding: 50px !important;
  }
  .pad-top-md-50 {
    padding-top: 50px !important;
  }
  .pad-rig-md-50 {
    padding-right: 50px !important;
  }
  .pad-bot-md-50 {
    padding-bottom: 50px !important;
  }
  .pad-lef-md-50 {
    padding-left: 50px !important;
  }
  .mar-md-55 {
    margin: 55px !important;
  }
  .mar-top-md-55 {
    margin-top: 55px !important;
  }
  .mar-rig-md-55 {
    margin-right: 55px !important;
  }
  .mar-bot-md-55 {
    margin-bottom: 55px !important;
  }
  .mar-lef-md-55 {
    margin-left: 55px !important;
  }
  .mar-top-neg-md-55 {
    margin-top: -55px !important;
  }
  .mar-rig-neg-md-55 {
    margin-right: -55px !important;
  }
  .mar-bot-neg-md-55 {
    margin-bottom: -55px !important;
  }
  .mar-lef-neg-md-55 {
    margin-left: -55px !important;
  }
  .pad-md-55 {
    padding: 55px !important;
  }
  .pad-top-md-55 {
    padding-top: 55px !important;
  }
  .pad-rig-md-55 {
    padding-right: 55px !important;
  }
  .pad-bot-md-55 {
    padding-bottom: 55px !important;
  }
  .pad-lef-md-55 {
    padding-left: 55px !important;
  }
  .mar-md-60 {
    margin: 60px !important;
  }
  .mar-top-md-60 {
    margin-top: 60px !important;
  }
  .mar-rig-md-60 {
    margin-right: 60px !important;
  }
  .mar-bot-md-60 {
    margin-bottom: 60px !important;
  }
  .mar-lef-md-60 {
    margin-left: 60px !important;
  }
  .mar-top-neg-md-60 {
    margin-top: -60px !important;
  }
  .mar-rig-neg-md-60 {
    margin-right: -60px !important;
  }
  .mar-bot-neg-md-60 {
    margin-bottom: -60px !important;
  }
  .mar-lef-neg-md-60 {
    margin-left: -60px !important;
  }
  .pad-md-60 {
    padding: 60px !important;
  }
  .pad-top-md-60 {
    padding-top: 60px !important;
  }
  .pad-rig-md-60 {
    padding-right: 60px !important;
  }
  .pad-bot-md-60 {
    padding-bottom: 60px !important;
  }
  .pad-lef-md-60 {
    padding-left: 60px !important;
  }
  .mar-md-65 {
    margin: 65px !important;
  }
  .mar-top-md-65 {
    margin-top: 65px !important;
  }
  .mar-rig-md-65 {
    margin-right: 65px !important;
  }
  .mar-bot-md-65 {
    margin-bottom: 65px !important;
  }
  .mar-lef-md-65 {
    margin-left: 65px !important;
  }
  .mar-top-neg-md-65 {
    margin-top: -65px !important;
  }
  .mar-rig-neg-md-65 {
    margin-right: -65px !important;
  }
  .mar-bot-neg-md-65 {
    margin-bottom: -65px !important;
  }
  .mar-lef-neg-md-65 {
    margin-left: -65px !important;
  }
  .pad-md-65 {
    padding: 65px !important;
  }
  .pad-top-md-65 {
    padding-top: 65px !important;
  }
  .pad-rig-md-65 {
    padding-right: 65px !important;
  }
  .pad-bot-md-65 {
    padding-bottom: 65px !important;
  }
  .pad-lef-md-65 {
    padding-left: 65px !important;
  }
  .mar-md-70 {
    margin: 70px !important;
  }
  .mar-top-md-70 {
    margin-top: 70px !important;
  }
  .mar-rig-md-70 {
    margin-right: 70px !important;
  }
  .mar-bot-md-70 {
    margin-bottom: 70px !important;
  }
  .mar-lef-md-70 {
    margin-left: 70px !important;
  }
  .mar-top-neg-md-70 {
    margin-top: -70px !important;
  }
  .mar-rig-neg-md-70 {
    margin-right: -70px !important;
  }
  .mar-bot-neg-md-70 {
    margin-bottom: -70px !important;
  }
  .mar-lef-neg-md-70 {
    margin-left: -70px !important;
  }
  .pad-md-70 {
    padding: 70px !important;
  }
  .pad-top-md-70 {
    padding-top: 70px !important;
  }
  .pad-rig-md-70 {
    padding-right: 70px !important;
  }
  .pad-bot-md-70 {
    padding-bottom: 70px !important;
  }
  .pad-lef-md-70 {
    padding-left: 70px !important;
  }
  .mar-md-75 {
    margin: 75px !important;
  }
  .mar-top-md-75 {
    margin-top: 75px !important;
  }
  .mar-rig-md-75 {
    margin-right: 75px !important;
  }
  .mar-bot-md-75 {
    margin-bottom: 75px !important;
  }
  .mar-lef-md-75 {
    margin-left: 75px !important;
  }
  .mar-top-neg-md-75 {
    margin-top: -75px !important;
  }
  .mar-rig-neg-md-75 {
    margin-right: -75px !important;
  }
  .mar-bot-neg-md-75 {
    margin-bottom: -75px !important;
  }
  .mar-lef-neg-md-75 {
    margin-left: -75px !important;
  }
  .pad-md-75 {
    padding: 75px !important;
  }
  .pad-top-md-75 {
    padding-top: 75px !important;
  }
  .pad-rig-md-75 {
    padding-right: 75px !important;
  }
  .pad-bot-md-75 {
    padding-bottom: 75px !important;
  }
  .pad-lef-md-75 {
    padding-left: 75px !important;
  }
  .mar-md-80 {
    margin: 80px !important;
  }
  .mar-top-md-80 {
    margin-top: 80px !important;
  }
  .mar-rig-md-80 {
    margin-right: 80px !important;
  }
  .mar-bot-md-80 {
    margin-bottom: 80px !important;
  }
  .mar-lef-md-80 {
    margin-left: 80px !important;
  }
  .mar-top-neg-md-80 {
    margin-top: -80px !important;
  }
  .mar-rig-neg-md-80 {
    margin-right: -80px !important;
  }
  .mar-bot-neg-md-80 {
    margin-bottom: -80px !important;
  }
  .mar-lef-neg-md-80 {
    margin-left: -80px !important;
  }
  .pad-md-80 {
    padding: 80px !important;
  }
  .pad-top-md-80 {
    padding-top: 80px !important;
  }
  .pad-rig-md-80 {
    padding-right: 80px !important;
  }
  .pad-bot-md-80 {
    padding-bottom: 80px !important;
  }
  .pad-lef-md-80 {
    padding-left: 80px !important;
  }
  .mar-md-85 {
    margin: 85px !important;
  }
  .mar-top-md-85 {
    margin-top: 85px !important;
  }
  .mar-rig-md-85 {
    margin-right: 85px !important;
  }
  .mar-bot-md-85 {
    margin-bottom: 85px !important;
  }
  .mar-lef-md-85 {
    margin-left: 85px !important;
  }
  .mar-top-neg-md-85 {
    margin-top: -85px !important;
  }
  .mar-rig-neg-md-85 {
    margin-right: -85px !important;
  }
  .mar-bot-neg-md-85 {
    margin-bottom: -85px !important;
  }
  .mar-lef-neg-md-85 {
    margin-left: -85px !important;
  }
  .pad-md-85 {
    padding: 85px !important;
  }
  .pad-top-md-85 {
    padding-top: 85px !important;
  }
  .pad-rig-md-85 {
    padding-right: 85px !important;
  }
  .pad-bot-md-85 {
    padding-bottom: 85px !important;
  }
  .pad-lef-md-85 {
    padding-left: 85px !important;
  }
  .mar-md-90 {
    margin: 90px !important;
  }
  .mar-top-md-90 {
    margin-top: 90px !important;
  }
  .mar-rig-md-90 {
    margin-right: 90px !important;
  }
  .mar-bot-md-90 {
    margin-bottom: 90px !important;
  }
  .mar-lef-md-90 {
    margin-left: 90px !important;
  }
  .mar-top-neg-md-90 {
    margin-top: -90px !important;
  }
  .mar-rig-neg-md-90 {
    margin-right: -90px !important;
  }
  .mar-bot-neg-md-90 {
    margin-bottom: -90px !important;
  }
  .mar-lef-neg-md-90 {
    margin-left: -90px !important;
  }
  .pad-md-90 {
    padding: 90px !important;
  }
  .pad-top-md-90 {
    padding-top: 90px !important;
  }
  .pad-rig-md-90 {
    padding-right: 90px !important;
  }
  .pad-bot-md-90 {
    padding-bottom: 90px !important;
  }
  .pad-lef-md-90 {
    padding-left: 90px !important;
  }
  .mar-md-95 {
    margin: 95px !important;
  }
  .mar-top-md-95 {
    margin-top: 95px !important;
  }
  .mar-rig-md-95 {
    margin-right: 95px !important;
  }
  .mar-bot-md-95 {
    margin-bottom: 95px !important;
  }
  .mar-lef-md-95 {
    margin-left: 95px !important;
  }
  .mar-top-neg-md-95 {
    margin-top: -95px !important;
  }
  .mar-rig-neg-md-95 {
    margin-right: -95px !important;
  }
  .mar-bot-neg-md-95 {
    margin-bottom: -95px !important;
  }
  .mar-lef-neg-md-95 {
    margin-left: -95px !important;
  }
  .pad-md-95 {
    padding: 95px !important;
  }
  .pad-top-md-95 {
    padding-top: 95px !important;
  }
  .pad-rig-md-95 {
    padding-right: 95px !important;
  }
  .pad-bot-md-95 {
    padding-bottom: 95px !important;
  }
  .pad-lef-md-95 {
    padding-left: 95px !important;
  }
  .mar-md-100 {
    margin: 100px !important;
  }
  .mar-top-md-100 {
    margin-top: 100px !important;
  }
  .mar-rig-md-100 {
    margin-right: 100px !important;
  }
  .mar-bot-md-100 {
    margin-bottom: 100px !important;
  }
  .mar-lef-md-100 {
    margin-left: 100px !important;
  }
  .mar-top-neg-md-100 {
    margin-top: -100px !important;
  }
  .mar-rig-neg-md-100 {
    margin-right: -100px !important;
  }
  .mar-bot-neg-md-100 {
    margin-bottom: -100px !important;
  }
  .mar-lef-neg-md-100 {
    margin-left: -100px !important;
  }
  .pad-md-100 {
    padding: 100px !important;
  }
  .pad-top-md-100 {
    padding-top: 100px !important;
  }
  .pad-rig-md-100 {
    padding-right: 100px !important;
  }
  .pad-bot-md-100 {
    padding-bottom: 100px !important;
  }
  .pad-lef-md-100 {
    padding-left: 100px !important;
  }
  .line-height-md-0 {
    line-height: 0px !important;
  }
  .line-height-md-1 {
    line-height: 1px !important;
  }
  .line-height-md-2 {
    line-height: 2px !important;
  }
  .line-height-md-3 {
    line-height: 3px !important;
  }
  .line-height-md-4 {
    line-height: 4px !important;
  }
  .line-height-md-5 {
    line-height: 5px !important;
  }
  .line-height-md-6 {
    line-height: 6px !important;
  }
  .line-height-md-7 {
    line-height: 7px !important;
  }
  .line-height-md-8 {
    line-height: 8px !important;
  }
  .line-height-md-9 {
    line-height: 9px !important;
  }
  .line-height-md-10 {
    line-height: 10px !important;
  }
  .line-height-md-11 {
    line-height: 11px !important;
  }
  .line-height-md-12 {
    line-height: 12px !important;
  }
  .line-height-md-13 {
    line-height: 13px !important;
  }
  .line-height-md-14 {
    line-height: 14px !important;
  }
  .line-height-md-15 {
    line-height: 15px !important;
  }
  .line-height-md-16 {
    line-height: 16px !important;
  }
  .line-height-md-17 {
    line-height: 17px !important;
  }
  .line-height-md-18 {
    line-height: 18px !important;
  }
  .line-height-md-19 {
    line-height: 19px !important;
  }
  .line-height-md-20 {
    line-height: 20px !important;
  }
  .line-height-md-21 {
    line-height: 21px !important;
  }
  .line-height-md-22 {
    line-height: 22px !important;
  }
  .line-height-md-23 {
    line-height: 23px !important;
  }
  .line-height-md-24 {
    line-height: 24px !important;
  }
  .line-height-md-25 {
    line-height: 25px !important;
  }
  .line-height-md-26 {
    line-height: 26px !important;
  }
  .line-height-md-27 {
    line-height: 27px !important;
  }
  .line-height-md-28 {
    line-height: 28px !important;
  }
  .line-height-md-29 {
    line-height: 29px !important;
  }
  .line-height-md-30 {
    line-height: 30px !important;
  }
  .line-height-md-31 {
    line-height: 31px !important;
  }
  .line-height-md-32 {
    line-height: 32px !important;
  }
  .line-height-md-33 {
    line-height: 33px !important;
  }
  .line-height-md-34 {
    line-height: 34px !important;
  }
  .line-height-md-35 {
    line-height: 35px !important;
  }
  .line-height-md-36 {
    line-height: 36px !important;
  }
  .line-height-md-37 {
    line-height: 37px !important;
  }
  .line-height-md-38 {
    line-height: 38px !important;
  }
  .line-height-md-39 {
    line-height: 39px !important;
  }
  .line-height-md-40 {
    line-height: 40px !important;
  }
  .line-height-md-41 {
    line-height: 41px !important;
  }
  .line-height-md-42 {
    line-height: 42px !important;
  }
  .line-height-md-43 {
    line-height: 43px !important;
  }
  .line-height-md-44 {
    line-height: 44px !important;
  }
  .line-height-md-45 {
    line-height: 45px !important;
  }
  .line-height-md-46 {
    line-height: 46px !important;
  }
  .line-height-md-47 {
    line-height: 47px !important;
  }
  .line-height-md-48 {
    line-height: 48px !important;
  }
  .line-height-md-49 {
    line-height: 49px !important;
  }
  .line-height-md-50 {
    line-height: 50px !important;
  }
  .line-height-md-51 {
    line-height: 51px !important;
  }
  .line-height-md-52 {
    line-height: 52px !important;
  }
  .line-height-md-53 {
    line-height: 53px !important;
  }
  .line-height-md-54 {
    line-height: 54px !important;
  }
  .line-height-md-55 {
    line-height: 55px !important;
  }
  .line-height-md-56 {
    line-height: 56px !important;
  }
  .line-height-md-57 {
    line-height: 57px !important;
  }
  .line-height-md-58 {
    line-height: 58px !important;
  }
  .line-height-md-59 {
    line-height: 59px !important;
  }
  .line-height-md-60 {
    line-height: 60px !important;
  }
  .dis-block-md {
    display: block !important;
  }
  .dis-none-md {
    display: none !important;
  }
  .dis-inline-md {
    display: inline !important;
  }
  .dis-inline-block-md {
    display: inline-block !important;
  }
  .text-cen-md {
    text-align: center !important;
  }
  .text-lef-md {
    text-align: left !important;
  }
  .text-rig-md {
    text-align: right !important;
  }
  .text-jus-md {
    text-align: justify !important;
  }
  .pos-re-md {
    position: relative !important;
  }
  .pos-ab-md {
    position: absolute !important;
  }
  .pos-st-md {
    position: static !important;
  }
}

@media (min-width: 1200px) {
  .font-size-lg-8 {
    font-size: 8px !important;
  }
  .font-size-lg-9 {
    font-size: 9px !important;
  }
  .font-size-lg-10 {
    font-size: 10px !important;
  }
  .font-size-lg-11 {
    font-size: 11px !important;
  }
  .font-size-lg-12 {
    font-size: 12px !important;
  }
  .font-size-lg-13 {
    font-size: 13px !important;
  }
  .font-size-lg-14 {
    font-size: 14px !important;
  }
  .font-size-lg-15 {
    font-size: 15px !important;
  }
  .font-size-lg-16 {
    font-size: 16px !important;
  }
  .font-size-lg-17 {
    font-size: 17px !important;
  }
  .font-size-lg-18 {
    font-size: 18px !important;
  }
  .font-size-lg-19 {
    font-size: 19px !important;
  }
  .font-size-lg-20 {
    font-size: 20px !important;
  }
  .font-size-lg-21 {
    font-size: 21px !important;
  }
  .font-size-lg-22 {
    font-size: 22px !important;
  }
  .font-size-lg-23 {
    font-size: 23px !important;
  }
  .font-size-lg-24 {
    font-size: 24px !important;
  }
  .font-size-lg-25 {
    font-size: 25px !important;
  }
  .font-size-lg-26 {
    font-size: 26px !important;
  }
  .font-size-lg-27 {
    font-size: 27px !important;
  }
  .font-size-lg-28 {
    font-size: 28px !important;
  }
  .font-size-lg-29 {
    font-size: 29px !important;
  }
  .font-size-lg-30 {
    font-size: 30px !important;
  }
  .font-size-lg-31 {
    font-size: 31px !important;
  }
  .font-size-lg-32 {
    font-size: 32px !important;
  }
  .font-size-lg-33 {
    font-size: 33px !important;
  }
  .font-size-lg-34 {
    font-size: 34px !important;
  }
  .font-size-lg-35 {
    font-size: 35px !important;
  }
  .font-size-lg-36 {
    font-size: 36px !important;
  }
  .font-size-lg-37 {
    font-size: 37px !important;
  }
  .font-size-lg-38 {
    font-size: 38px !important;
  }
  .font-size-lg-39 {
    font-size: 39px !important;
  }
  .font-size-lg-40 {
    font-size: 40px !important;
  }
  .font-size-lg-41 {
    font-size: 41px !important;
  }
  .font-size-lg-42 {
    font-size: 42px !important;
  }
  .font-size-lg-43 {
    font-size: 43px !important;
  }
  .font-size-lg-44 {
    font-size: 44px !important;
  }
  .font-size-lg-45 {
    font-size: 45px !important;
  }
  .font-size-lg-46 {
    font-size: 46px !important;
  }
  .font-size-lg-47 {
    font-size: 47px !important;
  }
  .font-size-lg-48 {
    font-size: 48px !important;
  }
  .font-size-lg-49 {
    font-size: 49px !important;
  }
  .font-size-lg-50 {
    font-size: 50px !important;
  }
  .font-size-lg-51 {
    font-size: 51px !important;
  }
  .font-size-lg-52 {
    font-size: 52px !important;
  }
  .font-size-lg-53 {
    font-size: 53px !important;
  }
  .font-size-lg-54 {
    font-size: 54px !important;
  }
  .font-size-lg-55 {
    font-size: 55px !important;
  }
  .font-size-lg-56 {
    font-size: 56px !important;
  }
  .font-size-lg-57 {
    font-size: 57px !important;
  }
  .font-size-lg-58 {
    font-size: 58px !important;
  }
  .font-size-lg-59 {
    font-size: 59px !important;
  }
  .font-size-lg-60 {
    font-size: 60px !important;
  }
  .mar-lg-0 {
    margin: 0px !important;
  }
  .mar-top-lg-0 {
    margin-top: 0px !important;
  }
  .mar-rig-lg-0 {
    margin-right: 0px !important;
  }
  .mar-bot-lg-0 {
    margin-bottom: 0px !important;
  }
  .mar-lef-lg-0 {
    margin-left: 0px !important;
  }
  .mar-top-neg-lg-0 {
    margin-top: -0px !important;
  }
  .mar-rig-neg-lg-0 {
    margin-right: -0px !important;
  }
  .mar-bot-neg-lg-0 {
    margin-bottom: -0px !important;
  }
  .mar-lef-neg-lg-0 {
    margin-left: -0px !important;
  }
  .pad-lg-0 {
    padding: 0px !important;
  }
  .pad-top-lg-0 {
    padding-top: 0px !important;
  }
  .pad-rig-lg-0 {
    padding-right: 0px !important;
  }
  .pad-bot-lg-0 {
    padding-bottom: 0px !important;
  }
  .pad-lef-lg-0 {
    padding-left: 0px !important;
  }
  .mar-lg-5 {
    margin: 5px !important;
  }
  .mar-top-lg-5 {
    margin-top: 5px !important;
  }
  .mar-rig-lg-5 {
    margin-right: 5px !important;
  }
  .mar-bot-lg-5 {
    margin-bottom: 5px !important;
  }
  .mar-lef-lg-5 {
    margin-left: 5px !important;
  }
  .mar-top-neg-lg-5 {
    margin-top: -5px !important;
  }
  .mar-rig-neg-lg-5 {
    margin-right: -5px !important;
  }
  .mar-bot-neg-lg-5 {
    margin-bottom: -5px !important;
  }
  .mar-lef-neg-lg-5 {
    margin-left: -5px !important;
  }
  .pad-lg-5 {
    padding: 5px !important;
  }
  .pad-top-lg-5 {
    padding-top: 5px !important;
  }
  .pad-rig-lg-5 {
    padding-right: 5px !important;
  }
  .pad-bot-lg-5 {
    padding-bottom: 5px !important;
  }
  .pad-lef-lg-5 {
    padding-left: 5px !important;
  }
  .mar-lg-10 {
    margin: 10px !important;
  }
  .mar-top-lg-10 {
    margin-top: 10px !important;
  }
  .mar-rig-lg-10 {
    margin-right: 10px !important;
  }
  .mar-bot-lg-10 {
    margin-bottom: 10px !important;
  }
  .mar-lef-lg-10 {
    margin-left: 10px !important;
  }
  .mar-top-neg-lg-10 {
    margin-top: -10px !important;
  }
  .mar-rig-neg-lg-10 {
    margin-right: -10px !important;
  }
  .mar-bot-neg-lg-10 {
    margin-bottom: -10px !important;
  }
  .mar-lef-neg-lg-10 {
    margin-left: -10px !important;
  }
  .pad-lg-10 {
    padding: 10px !important;
  }
  .pad-top-lg-10 {
    padding-top: 10px !important;
  }
  .pad-rig-lg-10 {
    padding-right: 10px !important;
  }
  .pad-bot-lg-10 {
    padding-bottom: 10px !important;
  }
  .pad-lef-lg-10 {
    padding-left: 10px !important;
  }
  .mar-lg-15 {
    margin: 15px !important;
  }
  .mar-top-lg-15 {
    margin-top: 15px !important;
  }
  .mar-rig-lg-15 {
    margin-right: 15px !important;
  }
  .mar-bot-lg-15 {
    margin-bottom: 15px !important;
  }
  .mar-lef-lg-15 {
    margin-left: 15px !important;
  }
  .mar-top-neg-lg-15 {
    margin-top: -15px !important;
  }
  .mar-rig-neg-lg-15 {
    margin-right: -15px !important;
  }
  .mar-bot-neg-lg-15 {
    margin-bottom: -15px !important;
  }
  .mar-lef-neg-lg-15 {
    margin-left: -15px !important;
  }
  .pad-lg-15 {
    padding: 15px !important;
  }
  .pad-top-lg-15 {
    padding-top: 15px !important;
  }
  .pad-rig-lg-15 {
    padding-right: 15px !important;
  }
  .pad-bot-lg-15 {
    padding-bottom: 15px !important;
  }
  .pad-lef-lg-15 {
    padding-left: 15px !important;
  }
  .mar-lg-20 {
    margin: 20px !important;
  }
  .mar-top-lg-20 {
    margin-top: 20px !important;
  }
  .mar-rig-lg-20 {
    margin-right: 20px !important;
  }
  .mar-bot-lg-20 {
    margin-bottom: 20px !important;
  }
  .mar-lef-lg-20 {
    margin-left: 20px !important;
  }
  .mar-top-neg-lg-20 {
    margin-top: -20px !important;
  }
  .mar-rig-neg-lg-20 {
    margin-right: -20px !important;
  }
  .mar-bot-neg-lg-20 {
    margin-bottom: -20px !important;
  }
  .mar-lef-neg-lg-20 {
    margin-left: -20px !important;
  }
  .pad-lg-20 {
    padding: 20px !important;
  }
  .pad-top-lg-20 {
    padding-top: 20px !important;
  }
  .pad-rig-lg-20 {
    padding-right: 20px !important;
  }
  .pad-bot-lg-20 {
    padding-bottom: 20px !important;
  }
  .pad-lef-lg-20 {
    padding-left: 20px !important;
  }
  .mar-lg-25 {
    margin: 25px !important;
  }
  .mar-top-lg-25 {
    margin-top: 25px !important;
  }
  .mar-rig-lg-25 {
    margin-right: 25px !important;
  }
  .mar-bot-lg-25 {
    margin-bottom: 25px !important;
  }
  .mar-lef-lg-25 {
    margin-left: 25px !important;
  }
  .mar-top-neg-lg-25 {
    margin-top: -25px !important;
  }
  .mar-rig-neg-lg-25 {
    margin-right: -25px !important;
  }
  .mar-bot-neg-lg-25 {
    margin-bottom: -25px !important;
  }
  .mar-lef-neg-lg-25 {
    margin-left: -25px !important;
  }
  .pad-lg-25 {
    padding: 25px !important;
  }
  .pad-top-lg-25 {
    padding-top: 25px !important;
  }
  .pad-rig-lg-25 {
    padding-right: 25px !important;
  }
  .pad-bot-lg-25 {
    padding-bottom: 25px !important;
  }
  .pad-lef-lg-25 {
    padding-left: 25px !important;
  }
  .mar-lg-30 {
    margin: 30px !important;
  }
  .mar-top-lg-30 {
    margin-top: 30px !important;
  }
  .mar-rig-lg-30 {
    margin-right: 30px !important;
  }
  .mar-bot-lg-30 {
    margin-bottom: 30px !important;
  }
  .mar-lef-lg-30 {
    margin-left: 30px !important;
  }
  .mar-top-neg-lg-30 {
    margin-top: -30px !important;
  }
  .mar-rig-neg-lg-30 {
    margin-right: -30px !important;
  }
  .mar-bot-neg-lg-30 {
    margin-bottom: -30px !important;
  }
  .mar-lef-neg-lg-30 {
    margin-left: -30px !important;
  }
  .pad-lg-30 {
    padding: 30px !important;
  }
  .pad-top-lg-30 {
    padding-top: 30px !important;
  }
  .pad-rig-lg-30 {
    padding-right: 30px !important;
  }
  .pad-bot-lg-30 {
    padding-bottom: 30px !important;
  }
  .pad-lef-lg-30 {
    padding-left: 30px !important;
  }
  .mar-lg-35 {
    margin: 35px !important;
  }
  .mar-top-lg-35 {
    margin-top: 35px !important;
  }
  .mar-rig-lg-35 {
    margin-right: 35px !important;
  }
  .mar-bot-lg-35 {
    margin-bottom: 35px !important;
  }
  .mar-lef-lg-35 {
    margin-left: 35px !important;
  }
  .mar-top-neg-lg-35 {
    margin-top: -35px !important;
  }
  .mar-rig-neg-lg-35 {
    margin-right: -35px !important;
  }
  .mar-bot-neg-lg-35 {
    margin-bottom: -35px !important;
  }
  .mar-lef-neg-lg-35 {
    margin-left: -35px !important;
  }
  .pad-lg-35 {
    padding: 35px !important;
  }
  .pad-top-lg-35 {
    padding-top: 35px !important;
  }
  .pad-rig-lg-35 {
    padding-right: 35px !important;
  }
  .pad-bot-lg-35 {
    padding-bottom: 35px !important;
  }
  .pad-lef-lg-35 {
    padding-left: 35px !important;
  }
  .mar-lg-40 {
    margin: 40px !important;
  }
  .mar-top-lg-40 {
    margin-top: 40px !important;
  }
  .mar-rig-lg-40 {
    margin-right: 40px !important;
  }
  .mar-bot-lg-40 {
    margin-bottom: 40px !important;
  }
  .mar-lef-lg-40 {
    margin-left: 40px !important;
  }
  .mar-top-neg-lg-40 {
    margin-top: -40px !important;
  }
  .mar-rig-neg-lg-40 {
    margin-right: -40px !important;
  }
  .mar-bot-neg-lg-40 {
    margin-bottom: -40px !important;
  }
  .mar-lef-neg-lg-40 {
    margin-left: -40px !important;
  }
  .pad-lg-40 {
    padding: 40px !important;
  }
  .pad-top-lg-40 {
    padding-top: 40px !important;
  }
  .pad-rig-lg-40 {
    padding-right: 40px !important;
  }
  .pad-bot-lg-40 {
    padding-bottom: 40px !important;
  }
  .pad-lef-lg-40 {
    padding-left: 40px !important;
  }
  .mar-lg-45 {
    margin: 45px !important;
  }
  .mar-top-lg-45 {
    margin-top: 45px !important;
  }
  .mar-rig-lg-45 {
    margin-right: 45px !important;
  }
  .mar-bot-lg-45 {
    margin-bottom: 45px !important;
  }
  .mar-lef-lg-45 {
    margin-left: 45px !important;
  }
  .mar-top-neg-lg-45 {
    margin-top: -45px !important;
  }
  .mar-rig-neg-lg-45 {
    margin-right: -45px !important;
  }
  .mar-bot-neg-lg-45 {
    margin-bottom: -45px !important;
  }
  .mar-lef-neg-lg-45 {
    margin-left: -45px !important;
  }
  .pad-lg-45 {
    padding: 45px !important;
  }
  .pad-top-lg-45 {
    padding-top: 45px !important;
  }
  .pad-rig-lg-45 {
    padding-right: 45px !important;
  }
  .pad-bot-lg-45 {
    padding-bottom: 45px !important;
  }
  .pad-lef-lg-45 {
    padding-left: 45px !important;
  }
  .mar-lg-50 {
    margin: 50px !important;
  }
  .mar-top-lg-50 {
    margin-top: 50px !important;
  }
  .mar-rig-lg-50 {
    margin-right: 50px !important;
  }
  .mar-bot-lg-50 {
    margin-bottom: 50px !important;
  }
  .mar-lef-lg-50 {
    margin-left: 50px !important;
  }
  .mar-top-neg-lg-50 {
    margin-top: -50px !important;
  }
  .mar-rig-neg-lg-50 {
    margin-right: -50px !important;
  }
  .mar-bot-neg-lg-50 {
    margin-bottom: -50px !important;
  }
  .mar-lef-neg-lg-50 {
    margin-left: -50px !important;
  }
  .pad-lg-50 {
    padding: 50px !important;
  }
  .pad-top-lg-50 {
    padding-top: 50px !important;
  }
  .pad-rig-lg-50 {
    padding-right: 50px !important;
  }
  .pad-bot-lg-50 {
    padding-bottom: 50px !important;
  }
  .pad-lef-lg-50 {
    padding-left: 50px !important;
  }
  .mar-lg-55 {
    margin: 55px !important;
  }
  .mar-top-lg-55 {
    margin-top: 55px !important;
  }
  .mar-rig-lg-55 {
    margin-right: 55px !important;
  }
  .mar-bot-lg-55 {
    margin-bottom: 55px !important;
  }
  .mar-lef-lg-55 {
    margin-left: 55px !important;
  }
  .mar-top-neg-lg-55 {
    margin-top: -55px !important;
  }
  .mar-rig-neg-lg-55 {
    margin-right: -55px !important;
  }
  .mar-bot-neg-lg-55 {
    margin-bottom: -55px !important;
  }
  .mar-lef-neg-lg-55 {
    margin-left: -55px !important;
  }
  .pad-lg-55 {
    padding: 55px !important;
  }
  .pad-top-lg-55 {
    padding-top: 55px !important;
  }
  .pad-rig-lg-55 {
    padding-right: 55px !important;
  }
  .pad-bot-lg-55 {
    padding-bottom: 55px !important;
  }
  .pad-lef-lg-55 {
    padding-left: 55px !important;
  }
  .mar-lg-60 {
    margin: 60px !important;
  }
  .mar-top-lg-60 {
    margin-top: 60px !important;
  }
  .mar-rig-lg-60 {
    margin-right: 60px !important;
  }
  .mar-bot-lg-60 {
    margin-bottom: 60px !important;
  }
  .mar-lef-lg-60 {
    margin-left: 60px !important;
  }
  .mar-top-neg-lg-60 {
    margin-top: -60px !important;
  }
  .mar-rig-neg-lg-60 {
    margin-right: -60px !important;
  }
  .mar-bot-neg-lg-60 {
    margin-bottom: -60px !important;
  }
  .mar-lef-neg-lg-60 {
    margin-left: -60px !important;
  }
  .pad-lg-60 {
    padding: 60px !important;
  }
  .pad-top-lg-60 {
    padding-top: 60px !important;
  }
  .pad-rig-lg-60 {
    padding-right: 60px !important;
  }
  .pad-bot-lg-60 {
    padding-bottom: 60px !important;
  }
  .pad-lef-lg-60 {
    padding-left: 60px !important;
  }
  .mar-lg-65 {
    margin: 65px !important;
  }
  .mar-top-lg-65 {
    margin-top: 65px !important;
  }
  .mar-rig-lg-65 {
    margin-right: 65px !important;
  }
  .mar-bot-lg-65 {
    margin-bottom: 65px !important;
  }
  .mar-lef-lg-65 {
    margin-left: 65px !important;
  }
  .mar-top-neg-lg-65 {
    margin-top: -65px !important;
  }
  .mar-rig-neg-lg-65 {
    margin-right: -65px !important;
  }
  .mar-bot-neg-lg-65 {
    margin-bottom: -65px !important;
  }
  .mar-lef-neg-lg-65 {
    margin-left: -65px !important;
  }
  .pad-lg-65 {
    padding: 65px !important;
  }
  .pad-top-lg-65 {
    padding-top: 65px !important;
  }
  .pad-rig-lg-65 {
    padding-right: 65px !important;
  }
  .pad-bot-lg-65 {
    padding-bottom: 65px !important;
  }
  .pad-lef-lg-65 {
    padding-left: 65px !important;
  }
  .mar-lg-70 {
    margin: 70px !important;
  }
  .mar-top-lg-70 {
    margin-top: 70px !important;
  }
  .mar-rig-lg-70 {
    margin-right: 70px !important;
  }
  .mar-bot-lg-70 {
    margin-bottom: 70px !important;
  }
  .mar-lef-lg-70 {
    margin-left: 70px !important;
  }
  .mar-top-neg-lg-70 {
    margin-top: -70px !important;
  }
  .mar-rig-neg-lg-70 {
    margin-right: -70px !important;
  }
  .mar-bot-neg-lg-70 {
    margin-bottom: -70px !important;
  }
  .mar-lef-neg-lg-70 {
    margin-left: -70px !important;
  }
  .pad-lg-70 {
    padding: 70px !important;
  }
  .pad-top-lg-70 {
    padding-top: 70px !important;
  }
  .pad-rig-lg-70 {
    padding-right: 70px !important;
  }
  .pad-bot-lg-70 {
    padding-bottom: 70px !important;
  }
  .pad-lef-lg-70 {
    padding-left: 70px !important;
  }
  .mar-lg-75 {
    margin: 75px !important;
  }
  .mar-top-lg-75 {
    margin-top: 75px !important;
  }
  .mar-rig-lg-75 {
    margin-right: 75px !important;
  }
  .mar-bot-lg-75 {
    margin-bottom: 75px !important;
  }
  .mar-lef-lg-75 {
    margin-left: 75px !important;
  }
  .mar-top-neg-lg-75 {
    margin-top: -75px !important;
  }
  .mar-rig-neg-lg-75 {
    margin-right: -75px !important;
  }
  .mar-bot-neg-lg-75 {
    margin-bottom: -75px !important;
  }
  .mar-lef-neg-lg-75 {
    margin-left: -75px !important;
  }
  .pad-lg-75 {
    padding: 75px !important;
  }
  .pad-top-lg-75 {
    padding-top: 75px !important;
  }
  .pad-rig-lg-75 {
    padding-right: 75px !important;
  }
  .pad-bot-lg-75 {
    padding-bottom: 75px !important;
  }
  .pad-lef-lg-75 {
    padding-left: 75px !important;
  }
  .mar-lg-80 {
    margin: 80px !important;
  }
  .mar-top-lg-80 {
    margin-top: 80px !important;
  }
  .mar-rig-lg-80 {
    margin-right: 80px !important;
  }
  .mar-bot-lg-80 {
    margin-bottom: 80px !important;
  }
  .mar-lef-lg-80 {
    margin-left: 80px !important;
  }
  .mar-top-neg-lg-80 {
    margin-top: -80px !important;
  }
  .mar-rig-neg-lg-80 {
    margin-right: -80px !important;
  }
  .mar-bot-neg-lg-80 {
    margin-bottom: -80px !important;
  }
  .mar-lef-neg-lg-80 {
    margin-left: -80px !important;
  }
  .pad-lg-80 {
    padding: 80px !important;
  }
  .pad-top-lg-80 {
    padding-top: 80px !important;
  }
  .pad-rig-lg-80 {
    padding-right: 80px !important;
  }
  .pad-bot-lg-80 {
    padding-bottom: 80px !important;
  }
  .pad-lef-lg-80 {
    padding-left: 80px !important;
  }
  .mar-lg-85 {
    margin: 85px !important;
  }
  .mar-top-lg-85 {
    margin-top: 85px !important;
  }
  .mar-rig-lg-85 {
    margin-right: 85px !important;
  }
  .mar-bot-lg-85 {
    margin-bottom: 85px !important;
  }
  .mar-lef-lg-85 {
    margin-left: 85px !important;
  }
  .mar-top-neg-lg-85 {
    margin-top: -85px !important;
  }
  .mar-rig-neg-lg-85 {
    margin-right: -85px !important;
  }
  .mar-bot-neg-lg-85 {
    margin-bottom: -85px !important;
  }
  .mar-lef-neg-lg-85 {
    margin-left: -85px !important;
  }
  .pad-lg-85 {
    padding: 85px !important;
  }
  .pad-top-lg-85 {
    padding-top: 85px !important;
  }
  .pad-rig-lg-85 {
    padding-right: 85px !important;
  }
  .pad-bot-lg-85 {
    padding-bottom: 85px !important;
  }
  .pad-lef-lg-85 {
    padding-left: 85px !important;
  }
  .mar-lg-90 {
    margin: 90px !important;
  }
  .mar-top-lg-90 {
    margin-top: 90px !important;
  }
  .mar-rig-lg-90 {
    margin-right: 90px !important;
  }
  .mar-bot-lg-90 {
    margin-bottom: 90px !important;
  }
  .mar-lef-lg-90 {
    margin-left: 90px !important;
  }
  .mar-top-neg-lg-90 {
    margin-top: -90px !important;
  }
  .mar-rig-neg-lg-90 {
    margin-right: -90px !important;
  }
  .mar-bot-neg-lg-90 {
    margin-bottom: -90px !important;
  }
  .mar-lef-neg-lg-90 {
    margin-left: -90px !important;
  }
  .pad-lg-90 {
    padding: 90px !important;
  }
  .pad-top-lg-90 {
    padding-top: 90px !important;
  }
  .pad-rig-lg-90 {
    padding-right: 90px !important;
  }
  .pad-bot-lg-90 {
    padding-bottom: 90px !important;
  }
  .pad-lef-lg-90 {
    padding-left: 90px !important;
  }
  .mar-lg-95 {
    margin: 95px !important;
  }
  .mar-top-lg-95 {
    margin-top: 95px !important;
  }
  .mar-rig-lg-95 {
    margin-right: 95px !important;
  }
  .mar-bot-lg-95 {
    margin-bottom: 95px !important;
  }
  .mar-lef-lg-95 {
    margin-left: 95px !important;
  }
  .mar-top-neg-lg-95 {
    margin-top: -95px !important;
  }
  .mar-rig-neg-lg-95 {
    margin-right: -95px !important;
  }
  .mar-bot-neg-lg-95 {
    margin-bottom: -95px !important;
  }
  .mar-lef-neg-lg-95 {
    margin-left: -95px !important;
  }
  .pad-lg-95 {
    padding: 95px !important;
  }
  .pad-top-lg-95 {
    padding-top: 95px !important;
  }
  .pad-rig-lg-95 {
    padding-right: 95px !important;
  }
  .pad-bot-lg-95 {
    padding-bottom: 95px !important;
  }
  .pad-lef-lg-95 {
    padding-left: 95px !important;
  }
  .mar-lg-100 {
    margin: 100px !important;
  }
  .mar-top-lg-100 {
    margin-top: 100px !important;
  }
  .mar-rig-lg-100 {
    margin-right: 100px !important;
  }
  .mar-bot-lg-100 {
    margin-bottom: 100px !important;
  }
  .mar-lef-lg-100 {
    margin-left: 100px !important;
  }
  .mar-top-neg-lg-100 {
    margin-top: -100px !important;
  }
  .mar-rig-neg-lg-100 {
    margin-right: -100px !important;
  }
  .mar-bot-neg-lg-100 {
    margin-bottom: -100px !important;
  }
  .mar-lef-neg-lg-100 {
    margin-left: -100px !important;
  }
  .pad-lg-100 {
    padding: 100px !important;
  }
  .pad-top-lg-100 {
    padding-top: 100px !important;
  }
  .pad-rig-lg-100 {
    padding-right: 100px !important;
  }
  .pad-bot-lg-100 {
    padding-bottom: 100px !important;
  }
  .pad-lef-lg-100 {
    padding-left: 100px !important;
  }
  .line-height-lg-0 {
    line-height: 0px !important;
  }
  .line-height-lg-1 {
    line-height: 1px !important;
  }
  .line-height-lg-2 {
    line-height: 2px !important;
  }
  .line-height-lg-3 {
    line-height: 3px !important;
  }
  .line-height-lg-4 {
    line-height: 4px !important;
  }
  .line-height-lg-5 {
    line-height: 5px !important;
  }
  .line-height-lg-6 {
    line-height: 6px !important;
  }
  .line-height-lg-7 {
    line-height: 7px !important;
  }
  .line-height-lg-8 {
    line-height: 8px !important;
  }
  .line-height-lg-9 {
    line-height: 9px !important;
  }
  .line-height-lg-10 {
    line-height: 10px !important;
  }
  .line-height-lg-11 {
    line-height: 11px !important;
  }
  .line-height-lg-12 {
    line-height: 12px !important;
  }
  .line-height-lg-13 {
    line-height: 13px !important;
  }
  .line-height-lg-14 {
    line-height: 14px !important;
  }
  .line-height-lg-15 {
    line-height: 15px !important;
  }
  .line-height-lg-16 {
    line-height: 16px !important;
  }
  .line-height-lg-17 {
    line-height: 17px !important;
  }
  .line-height-lg-18 {
    line-height: 18px !important;
  }
  .line-height-lg-19 {
    line-height: 19px !important;
  }
  .line-height-lg-20 {
    line-height: 20px !important;
  }
  .line-height-lg-21 {
    line-height: 21px !important;
  }
  .line-height-lg-22 {
    line-height: 22px !important;
  }
  .line-height-lg-23 {
    line-height: 23px !important;
  }
  .line-height-lg-24 {
    line-height: 24px !important;
  }
  .line-height-lg-25 {
    line-height: 25px !important;
  }
  .line-height-lg-26 {
    line-height: 26px !important;
  }
  .line-height-lg-27 {
    line-height: 27px !important;
  }
  .line-height-lg-28 {
    line-height: 28px !important;
  }
  .line-height-lg-29 {
    line-height: 29px !important;
  }
  .line-height-lg-30 {
    line-height: 30px !important;
  }
  .line-height-lg-31 {
    line-height: 31px !important;
  }
  .line-height-lg-32 {
    line-height: 32px !important;
  }
  .line-height-lg-33 {
    line-height: 33px !important;
  }
  .line-height-lg-34 {
    line-height: 34px !important;
  }
  .line-height-lg-35 {
    line-height: 35px !important;
  }
  .line-height-lg-36 {
    line-height: 36px !important;
  }
  .line-height-lg-37 {
    line-height: 37px !important;
  }
  .line-height-lg-38 {
    line-height: 38px !important;
  }
  .line-height-lg-39 {
    line-height: 39px !important;
  }
  .line-height-lg-40 {
    line-height: 40px !important;
  }
  .line-height-lg-41 {
    line-height: 41px !important;
  }
  .line-height-lg-42 {
    line-height: 42px !important;
  }
  .line-height-lg-43 {
    line-height: 43px !important;
  }
  .line-height-lg-44 {
    line-height: 44px !important;
  }
  .line-height-lg-45 {
    line-height: 45px !important;
  }
  .line-height-lg-46 {
    line-height: 46px !important;
  }
  .line-height-lg-47 {
    line-height: 47px !important;
  }
  .line-height-lg-48 {
    line-height: 48px !important;
  }
  .line-height-lg-49 {
    line-height: 49px !important;
  }
  .line-height-lg-50 {
    line-height: 50px !important;
  }
  .line-height-lg-51 {
    line-height: 51px !important;
  }
  .line-height-lg-52 {
    line-height: 52px !important;
  }
  .line-height-lg-53 {
    line-height: 53px !important;
  }
  .line-height-lg-54 {
    line-height: 54px !important;
  }
  .line-height-lg-55 {
    line-height: 55px !important;
  }
  .line-height-lg-56 {
    line-height: 56px !important;
  }
  .line-height-lg-57 {
    line-height: 57px !important;
  }
  .line-height-lg-58 {
    line-height: 58px !important;
  }
  .line-height-lg-59 {
    line-height: 59px !important;
  }
  .line-height-lg-60 {
    line-height: 60px !important;
  }
  .dis-block-lg {
    display: block !important;
  }
  .dis-none-lg {
    display: none !important;
  }
  .dis-inline-lg {
    display: inline !important;
  }
  .dis-inline-block-lg {
    display: inline-block !important;
  }
  .text-cen-lg {
    text-align: center !important;
  }
  .text-lef-lg {
    text-align: left !important;
  }
  .text-rig-lg {
    text-align: right !important;
  }
  .text-jus-lg {
    text-align: justify !important;
  }
  .pos-re-lg {
    position: relative !important;
  }
  .pos-ab-lg {
    position: absolute !important;
  }
  .pos-st-lg {
    position: static !important;
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinAround {
  from {
    -moz-transform: rotate(0deg)
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes toLeftFromRight {
  0% {
    opacity: 0;
  }
  49% {
    -webkit-transform: translate(0%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toLeftFromRight {
  0% {
    opacity: 0;
  }
  49% {
    -moz-transform: translate(0%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toLeftFromRight {
  0% {
    opacity: 0;
  }
  49% {
    transform: translate(0%);
  }
  50% {
    opacity: 0;
    transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}

@-webkit-keyframes toRightFromLeft {
  0% {
    opacity: 0;
  }
  49% {
    -webkit-transform: translate(0%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  0% {
    opacity: 0;
  }
  49% {
    -moz-transform: translate(0%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  0% {
    opacity: 0;
  }
  49% {
    transform: translate(0%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-webkit-keyframes toTopFromBottom {
  0% {
    opacity: 0;
  }
  49% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  0% {
    opacity: 0;
  }
  49% {
    -moz-transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  0% {
    opacity: 0;
  }
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop {
  0% {
    opacity: 0;
  }
  49% {
    transform: translateY(200%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-webkit-keyframes toBottomFromTop {
  0% {
    opacity: 0;
  }
  49% {
    -webkit-transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toBottomFromTop {
  0% {
    opacity: 0;
  }
  49% {
    -moz-transform: translateY(200%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*@-webkit-viewport { width: device-width; } @-moz-viewport { width: device-width; } @-ms-viewport { width: device-width; } @-o-viewport { width: device-width; } @viewport { width: device-width; }*/
.page-section {
  width: 100%;
  float: left;
}

*[data-animation="toLeftFromRight"] {
  -o-animation: toLeftFromRight 0.9s forwards;
  -webkit-animation: toLeftFromRight 0.9s forwards;
  -moz-animation: toLeftFromRight 0.9s forwards;
  -ms-animation: toLeftFromRight 0.9s forwards;
  animation: toLeftFromRight 0.9s forwards;
}

*[data-animation="toRightFromLeft"] {
  -o-animation: toRightFromLeft 0.9s forwards;
  -webkit-animation: toRightFromLeft 0.9s forwards;
  -moz-animation: toRightFromLeft 0.9s forwards;
  -ms-animation: toRightFromLeft 0.9s forwards;
  animation: toRightFromLeft 0.9s forwards;
}

*[data-animation="toTopFromBottom"] {
  -o-animation: toTopFromBottom 0.9s forwards;
  -webkit-animation: toTopFromBottom 0.9s forwards;
  -moz-animation: toTopFromBottom 0.9s forwards;
  -ms-animation: toTopFromBottom 0.9s forwards;
  animation: toTopFromBottom 0.9s forwards;
}

*[data-animation="toBottomFromTop"] {
  -o-animation: toBottomFromTop 0.9s forwards;
  -webkit-animation: toBottomFromTop 0.9s forwards;
  -moz-animation: toBottomFromTop 0.9s forwards;
  -ms-animation: toBottomFromTop 0.9s forwards;
  animation: toBottomFromTop 0.9s forwards;
}

.width-auto {
  width: auto !important;
}

.middle-auto {
  margin-left: auto;
  margin-right: auto;
}

*:focus, a:focus, a:hover {
  outline: 0 !important;
  text-decoration: none;
}

.w-normal {
  font-weight: 400;
}

.w-bold {
  font-weight: 700;
}

.w-lighter {
  font-weight: 300;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.no-border {
  border: 0 none !important;
}

.no-radius {
  border-radius: 0 !important;
}

/*PUBLIC CSS*/
/* public padding css */
.tooltip {
  white-space: nowrap;
  z-index: 999;
}

.top-menu-wrapper {
  height: 70px;
  width: 100%;
  .container {
    height: 70px;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
  .box-shadow-left-right-homepage {
    box-shadow: 7px 12px 9px -7px rgba(0, 0, 0, 0.2), -9px 15px 9px -7px rgba(0, 0, 0, 0.2);
  }

  .top-menu-wrapper, .al-footer {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .box-main-content-page {
    .container {
      background-color: #fff;
      &.dis-flex {
        display: flex;
        flex-wrap: wrap;
        .main-content-video {
          width: calc(100% - 281px);
        }
      }
    }

  }
}

/*@media (min-width: 768px) {
  .page-top, .top-menu-wrapper, .al-footer {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .box-main-content-page {
    .container {
      background-color: #fff;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
    }
  }
}*/
/*.safari {
  .header-about, .header-page, .header-videos {
    margin-top: -5px;
  }
}*/

.box-shadow-left-right {
  box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
  padding: 60px;
}

@media only screen and (max-width: 1200px) {
  .box-shadow-left-right {
    box-shadow: none;
    padding: 40px 20px;
  }
}

.modal-dialog {
  margin: 15px !important;
  @media only screen and (max-width: 413px) {
    margin: 0 auto !important;
  }
}

#quiz {
  .header-page {
    .title-header-page {
      h1 {
        &:before {
          @include bg-picture('icon-quiz.png');
        }
      }
    }
  }
}

.header-page {
  @include bg-image('theme/background-title.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*height: 176px;*/
  /*@media only screen and (max-width: 1023px) {
    height: 154px;
  }*/
  .title-header-page {
    padding: 65px 0;
    text-align: center;
    h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      font-size: 42px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 22px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        @include bg-picture('icon-about-pain.png');
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
  .title-terms {
    h1 {
      &:before {
        height: 0;
        width: 0;
      }
    }
  }
}

.box-main-content-page {
  /*background-color: #fff;*/
  color: $github-color;
  .container {
    background-color: #fff;
    &.container-flex {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
      aside {
        width: 280px;
        @media only screen and (min-width: 768px) {
          position: relative;
          z-index: 1;
          top: 0;
        }
      }
      article {
        width: calc(100% - 281px);
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        padding-left: 0;
        padding-right: 0;
        aside {
          width: 280px;
        }
        article {
          width: calc(100% - 281px);
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        aside {
          width: 220px;
        }
        article {
          width: calc(100% - 221px);
        }
      }
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        aside {
          width: 100%;
          background-color: #bed74b;
        }
        article {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .container {
      padding: 0;
      box-shadow: none;
    }
  }
  .left-aside {
    float: left;
    width: 280px;
    background-color: #f2f3f3;
    h4 {
      display: none;
    }
    .aside-main-menu {
      padding: 0;
      display: block;
      margin-bottom: 0;
      @media only screen and (min-width: 768px) {
        display: block !important;
      }
      > li {
        border-bottom: 1px solid $default;
        &.tab-custom-menu-item {
          &.last-child {
              margin-bottom: 30px;
          }
        }
      }
      li {
        position: relative;
        cursor: pointer;
        background-color: #f2f3f3;
        display: table;
        width: 100%;
        &.has-sub {
          &:hover {
            background-color: #ebebeb;
            color: $color-very-dark;
            /*.arrow {
              border-right: 1px solid #FFF;
              border-top: 1px solid #FFF;
            }*/
          }
          &.selected {
            .arrow {
              border-right: 1px solid #FFF;
              border-top: 1px solid #FFF;
            }
          }
          &.toggle {
            padding-bottom: 0;
            background-color: #bbbdbf;
            a {
              color: $default;
              span {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
              }
              &:hover {
                background-color: transparent;
                color: #fff;
              }
            }
            .arrow {
              border-right: 1px solid $default;
              border-top: 1px solid $default;
            }
          }
          .arrow {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-right: 1px solid #83878a;
            border-top: 1px solid #83878a;
            -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
            margin-left: 10px;
            cursor: pointer;
            position: absolute;
            right: 15px;
            -webkit-transition: all .3s; // Chrome
            -moz-transition: all .3s; // Mozilla
            -o-transition: all .3s; // Opera
            transition: all .3s;
            top: 30px;
            left: initial;
            background-image: none;
          }
          &.tab-custom-menu-item-dropdown {
            .arrow {
              -webkit-transform: rotate(135deg) !important;
              transform: rotate(135deg) !important;
              top: 25px;
            }
          }
          .drop-menu-sub {
            padding-left: 0;
            display: none;
            li {
              border-top: 1px solid $default;
              &:last-child {
                border-bottom: 1px solid $color-bg-gray-hover;
              }
              a {
                color: #FFF;
                &:hover {
                  background-color: #7b7d83;
                  //color: #FFF;
                  /*span {
                    filter: brightness(0) invert(1);
                    -o-filter: brightness(0) invert(1);
                    -moz-filter: brightness(0) invert(1);
                    -webkit-filter: brightness(0) invert(1);
                  }*/
                }
              }
            }
          }
          a {
            color: $color-very-dark;
            &:hover {
              background-color: #ebebeb;
              //color: #FFF;
              /*span {
                filter: brightness(0) invert(1);
                -o-filter: brightness(0) invert(1);
                -moz-filter: brightness(0) invert(1);
                -webkit-filter: brightness(0) invert(1);
              }*/
            }
            &.active {
              background-color: #bbbdbf;
              color: $color-very-dark;
            }
          }
          &.active {
            &:after {
              background-color: transparent;
            }
            .arrow {
              -webkit-transform: translateY(-50%) rotate(135deg);
              transform: translateY(-50%) rotate(135deg);
            }
          }
        }
        a {
          padding-left: 60px;
          top: 50%;
          padding-right: 25px;
          position: relative;
          font-size: 14px;
          font-family: "Lato", Arial;
          color: $color-very-dark;
          height: 60px;
          display: table-cell;
          vertical-align: middle;
          width: 100%;
          span {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            content: "";
            @include bg-image('app/menu-icons/library.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            width: 25px;
            height: 25px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              margin-top: 20px;
            }            
          }
          &:hover {
            background-color: #ebebeb;
            //color: #FFF;
            /*span {
              filter: brightness(0) invert(1);
              -o-filter: brightness(0) invert(1);
              -moz-filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            }*/
          }
        }
        &:hover {
          background-color: #e8e9e9;
        }
      }
      > li {
        &.active {
          /*background-color: #e8e9e9;*/
          .drop-menu-sub {
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1100px) {
    .left-aside {
      width: 220px;
    }
    .container {
      padding: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0;
    }
    .left-aside {
      float: none;
      width: 100%;
      &.fix-menu{
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 999;
      }
      h4 {
        display: block;
        padding: 15px;
        background-color: #bed74b;
        color: $default;
        font-size: 16px;
        position: relative;
        margin: 0;
        z-index: 5;
        .arrow-drop-menu-page {
          cursor: pointer;
          content: "";
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid $default;
        }
      }
      .aside-main-menu {
        position: absolute;
        width: 100%;
        height: 0;
        top: 47px;
        left: 0;
        overflow-y: scroll;
        display: none;
        padding-bottom: 20px;
        background-color: #f2f3f3;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .main-content-article {
    padding: 60px;
    .box-content-page {
      .box-title-page {
        padding-bottom: 30px;
        @media (max-width: 991px) {
          padding-bottom: 20px;
        }
        .box-social {
          text-align: right;
          @media (max-width: 1024px) {
            width: 100%;
            text-align: left;
            margin-top: 15px;
          }
        }
        .box-title {
          h2 {
            font-size: 28px;
            font-family: "PT Sans", sans-serif;
            font-weight: bold;
            color: $color-strong-blue;
            a {
              font-size: 32px;
              font-family: "PT Sans", sans-serif;
              font-weight: bold;
              color: $color-strong-blue;
            }
          }
          @media (max-width: 1024px) {
            width: 100%;
            text-align: left;
          }
        }
        @media only screen and (max-width: 767px) {
          .box-social {
            text-align: left;
          }
        }
      }
      .content-page {
        margin-bottom: 50px;
        &.logo {
          .list-sub-image {
            img {
              display: inline-block;
              height: 40px;
            }
          }
        }
        p, span {
          font-size: 16px;
          font-family: "Lato", Arial;
          color: $color-dark-grayish-blue;
          a {
            color: $color-strong-blue;
            &:hover {
              text-decoration: underline !important;
            }
          }
        }
        h4 {
          font-size: 20px;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          color: #292f35;
        }
      }
      .box-feedback {
        border-bottom: 2px solid #e3e2e2;
        h5 {
          padding: 25px 0;
          margin-bottom: 0;
          color: #bdbebf;
          font-size: 15px;
          font-family: "PT Sans", sans-serif;
          text-transform: uppercase;
          display: inline-block;
          letter-spacing: 3px;
        }
      }
      .box-helpful {
        border-bottom: 2px solid #e3e2e2;
        h5 {
          color: #808385;
          font-size: 16px;
          font-family: "Lato", Arial;
          font-weight: bold;
          padding: 35px 0;
          margin-bottom: 0;
          display: inline-block;
        }
        .btn-yes, .btn-no {
          padding: 8px 35px;
          background-color: transparent;
          border: 1px solid $color-strong-blue;
          font-size: 14px;
          font-family: "Lato", Arial;
          font-weight: bold;
          color: $color-strong-blue;
          margin-left: 30px;
          margin-right: 0;
          &:hover {
            background-color: #177bbe;
            color: #ffffff;
          }
        }
        @media only screen and (max-width: 767px) {
          h5 {
            width: 100%;
            padding: 30px 0;
          }
          .btn-yes, .btn-no {
            margin-left: 0;
            margin-right: 30px;
            margin-bottom: 30px;
          }
        }
      }
      .box-socical-like {
        max-width: 192px;
        display: inline-block;
        .socical-share {
          float: left;
          margin-right: 35px;
          ul {
            padding: 0;
            li {
              display: inline-block;
              a {
                color: $default;
                display: block;
                /*background-color: rgba(180, 182, 184, .8);*/
                background-color: #b4b6b8;
                margin-right: 10px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                position: relative;
                font-size: 20px;
                transition: 1s;
                padding: 0;
                &.facebook {
                  &:hover {
                    background-color: $facebook-color;
                  }
                }
                &.twitter {
                  &:hover {
                    background-color: $twitter-color;
                  }
                }
                &.envelope {
                  &:hover {
                    background-color: $color-very-dark;
                  }
                }
                i {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              &:last-child {
                a {
                  font-size: 16px;
                }
              }
            }
          }
        }
        .box-like {
          float: right;
          .tooltip {
            white-space: nowrap;
          }
          span {
            cursor: pointer;
            height: 30px;
            width: 30px;
            display: inline-block;
            @include bg-image('app/video-img/heart.png');
            background-size: 30px 30px;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            -webkit-transition: all .8s;
            transition: all .8s;
            -moz-transition: all .7s;
            &:hover, &.active {
              @include bg-image('app/video-img/heart-active.png');
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s;
            }
          }
          &.active {
            span {
              @include bg-image('app/video-img/heart-active.png');
              -webkit-transition: all .8s;
              transition: all .8s;
              -moz-transition: all .7s;
            }
          }
        }
      }
    }

    @media (max-width: 1023px) {
      .box-content-page {
        .box-title-page {
          .box-social {
            padding-right: 15px;
          }
        }
      }
    }

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      padding: 30px;
    }
    @media only screen and (max-width: 480px) {
      padding: 15px;
    }
  }
}
#modal_form_login_ajax{
  z-index: 99999;
}
#modal_favourite {
  z-index: 99999;
  .modal-dialog {
    width: 400px;
    @media (max-width: 420px) {
      width: 92%;
    }
    .modal-favourite {
      .modal-header {
        padding-bottom: 0;
      }
      .modal-body {
        padding: 0 50px 50px 50px;
        @media (max-width: 767px) {
          padding: 0 20px 20px 20px;
        }
        .main-content {
          h2 {
            color: $color-strong-blue;
            font-size: 32px;
            font-family: "PT Sans";
            font-weight: bold;
          }
          p {
            color: #6a6e72;
            font-size: 16px;
            font-weight: bold;
            padding: 20px 0;
            border-bottom: 1px solid #f9f9f9;
          }
        }
        .button-auth {
          .option-auth {
            p {
              text-align: center;
              margin-top: 10px;
              a {
                color: #929497;
              }
            }
          }
        }
      }
    }
  }
}

#message_comment-error {
  color: #ff0000;
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: -10px;
  margin-bottom: 15px;
  &:before {
    content: "\00d7";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    font-weight: normal;
  }
}

#dashboard {
  .box-main-content-page {
    .container {
      padding: 0;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
    }
  }
}

@media (max-width: 1200px) and (min-width: 320px) {
  .container {
    width: 100%;
  }
}