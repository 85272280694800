
.modal-content {
  border-radius: 5px !important;
}

#page_guideme {

  .header-guide-me {
    .title-header-page {
      h1 {
        &:before {
          @include bg-picture('icon-faq.png');
        }
      }
    }
  }
  .box-guide-wrapper {
    min-height: 500px;
    width: 100%;
    @include bg-image('background-guide.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      background-size: cover;
      background-position: center;
    }
    .box-guide {
      padding: 80px 0;
      width: 50%;
      h2 {
        font-size: 40px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
        color: $color-strong-blue;
        margin-bottom: 40px;
      }
      p {
        font-family: "Lato", Arial;
        color: $color-very-dark;
        font-size: 20px;
      }
      .link-info {
        margin-bottom: 40px;
        a {
          color: $background-strong-blue;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      background-image: none;
      .box-guide {
        width: 100%;
        padding: 40px 0;
      }
    }
  }
}

.modal-guide-info {
  .content-guide {
    padding: 0 40px 40px;
    @media (max-width: 480px) {
      padding: 0 10px 10px;
    }
    h2 {
      color: $color-strong-blue;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
      margin-bottom: 30px;
    }
    p {
      font-family: "Lato", Arial;
      color: $color-very-dark;
      font-size: 20px;
    }
    .box-check {
      padding: 20px 0;
    }
  }
}