@font-face {
  font-family: 'SS Gizmo';
  src: url('#{$fonts-root}ss-gizmo.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'SS Social';
  src: url('#{$fonts-root}ss-social-regular.otf') format('opentype');
  font-weight: bold;
}

.gizmo-icon {
  font-family: 'SS Gizmo';
  vertical-align: middle;
}
.gizmo-play {
  font-family: 'SS Gizmo';
  //content: "\f101";
}