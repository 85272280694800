.box-main-content-search {
  .main-content {
    padding: 50px;
    .box-header-search {
      h2 {
        font-size: 26px;
        font-family: "PT Sans";
        font-weight: bold;
        color: #177BBE;
        padding-bottom: 15px;
        border-bottom: 1px solid #E9EAEA;
        margin-bottom: 0;
      }
    }
    .content-search{
      .item-search{
        margin-top: 40px;
        h3{
          font-size: 20px;
          font-family: "PT Sans";
          font-weight: bold;
          color: #177BBE;
          a{
            &:hover{
              text-decoration: underline;
            }
          }
        }
        p{
          font-size: 16px;
          font-family: "Lato";
          font-weight: normal;
          color: #6A6E72;
          b{
            color: #177BBE;
          }
        }
      }
    }
  }
}