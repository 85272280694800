#page_question {
  .modal-dialog {
    width: 500px;
    .modal-signup-success {
      .modal-body {
        padding: 50px;
        .content-signup-success {
          h2 {
            color: $color-strong-blue;
            font-size: 32px;
            font-family: "PT Sans";
            font-weight: bold;
          }
          p {
            font-size: 16px;
            font-family: "Lato";
            font-weight: normal;
            padding-top: 20px;
            padding-bottom: 30px;
          }
        }
      }
    }
  }
  .header-guide-me {
    .title-header-page {
      h1 {
        &:before {
          @include bg-picture('icon-faq.png');
        }
      }
    }
  }
  .main-content-question {
    padding-top: 60px;
    .box-content-question {
      padding-bottom: 30px;
    }
    .box-question {
      h2 {
        font-size: 28px;
        font-family: "PT Sans";
        font-weight: bold;
        color: $color-strong-blue;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        font-family: "Lato";
        font-weight: bold;
        color: #292f35;
        padding: 30px 0;
      }
      .question {
        .item-question {
          padding: 20px 0;
          position: relative;
          border-top: 1px solid #f4f4f5;
          display: none;
          &#question_item_8_1 {
            padding-bottom: 30px;
            .box-radio {
              position: relative;
              padding-bottom: 35px;
              span.error {
                bottom: 10px;
                left: 0px;
              }
            }
            .box-time-frame {
              span.error {
                bottom: -20px;
                left: 15px;
              }
            }
          }
          &#question_item_8_1_0 {
            padding-bottom: 30px;
            span.error {
              bottom: -20px;
              left: 15px;
            }
          }
          span.error {
            position: absolute;
            bottom: 10px;
            left: 0;
            color: #f00;
            padding-left: 25px;
            font-weight: normal;
            font-size: 15px;
            .for-default {
              display: block;
            }
            .for-account,.for-myaccount {
              display: none;
            }
            &:before {
              content: "\00d7";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #ff0000;
              color: #ffffff;
              font-size: 20px;
              line-height: 18px;
              text-align: center;
              font-weight: normal;
            }
          }

          #select_error{
            span.error{
              position: relative;
              bottom: 0;
              display: inline-block;
              white-space: nowrap;
            }
          }
          h4 {
            font-size: 20px;
            font-family: "Lato";
            font-weight: bold;
            color: #292f35;
          }
          h5 {
            font-size: 20px;
            font-family: "PT Sans";
            color: $color-strong-blue;
            &.middle-title {
              font-size: 26px;
              line-height: 1.4;
              font-weight: bold;
              color: #292f35;
              margin: 30px 0 10px 0;
            }
          }
          p {
            padding: 0;
            font-weight: normal;
            font-style: italic;
            color: #6a6e72;
          }
          .box-input {
            label {
              font-size: 16px;
              font-family: "Lato";
              color: #292f35;
              font-weight: normal;
              margin-bottom: 15px;
            }
            input {
              border-radius: 0;
              height: 50px;
            }
          }
          .box-time, .box-select {
            h6 {
              font-size: 16px;
              font-family: "Lato";
              font-weight: bold;
              color: #292f35;
            }
            .selectize-input {
              padding: 10px;
              border-radius: 0px;
              height: 41px;
              &.dropdown-active {
                padding-top: 9px;
              }
              input {
                font-size: 16px;
                font-family: "Lato";
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  margin-top: -1px;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  margin-top: -1px;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  margin-top: -1px;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  margin-top: -1px;
                }
              }
            }
          }
          .box-checkbox {
            label.custom-checkbox > span:hover:before, label.custom-radio > span:hover:before {
              border-color: $color-strong-blue;
            }
            label.custom-checkbox {
              span {
                color: #6a6e72;
                a {
                  color: #6a6e72;
                  text-decoration: underline !important;
                }
              }
              #closeButton:checked ~ span:before {
                border-color: $color-strong-blue;
                color: $color-strong-blue;
              }
            }
          }
          .box-time-frame {
            h6 {
              font-size: 16px;
              font-family: "Lato";
              color: #292f35;
              font-weight: normal;
              margin-bottom: 25px;
            }
          }
          .box-radio {
            padding: 20px 0;
            label {
              &.custom-radio {
                margin-right: 10px;
                position: static;
                input[type=radio]:checked ~ span {
                  &:before {
                    content: '';
                    border-color: transparent;
                    @include bg-image('check.png');
                  }
                }
                input[type=radio]:checked ~ label ~ span {
                  &:before {
                    content: '';
                    border-color: transparent;
                    @include bg-image('check.png');
                  }
                }
                > span {
                  color: #6a6e72;
                  font-family: "Lato";
                  font-size: 16px;
                  font-weight: normal;
                  &:before {
                    color: $color-strong-blue;
                    border-color: #939598;
                  }
                }
              }
            }
            @media (max-width: 767px) {
              label {
                &.custom-radio {
                  width: 100%;
                  padding: 10px 0;
                }
                &.radio-inline + .radio-inline {
                  margin-left: 0;
                }
              }
            }
          }
          .box-slider {
            margin: 0 20px 0 20px;
            padding: 0 0 50px;
            .slider-input {
              opacity: 0;
              top: 0;
              position: absolute;
            }
            .slider-custom {
              background-color: #D1D3D4;
              height: 2px;
              margin: 40px 0;
              border: none;
              .ui-slider-label {
                white-space: nowrap;
                padding-top: 30px;
              }
              .ui-slider-pip-first {
                .ui-slider-label {
                  width: 6em;
                  text-align: left;
                  padding-left: 5px;
                }
              }
              .ui-slider-handle {
                width: 40px;
                height: 40px;
                background-color: #D1D3D4;
                color: #D1D3D4;
                text-align: center;
                padding-top: 7px;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                margin-left: -17px;
                top: -20px;
                border: 2px solid #fff;
                -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
                -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
                &:active, &:focus,&.selected {
                  background-color: $color-strong-blue;
                  color: $default;
                }
              }
              .ui-slider-pip {
                color: #6a6e72;
                font-family: "Lato";
                font-size: 14px;
                font-weight: normal;
                .ui-slider-line {
                  background: #D1D3D4;
                  width: 6px;
                  height: 6px;
                  position: absolute;
                  left: 48%;
                  top: -22px;
                  border-radius: 50%;
                  &:before {
                    content: "";
                    height: 40px;
                    width: 70px;
                    position: absolute;
                    top: -17px;
                    left: -30px;
                  }
                }
              }
            }
            .ui-slider-pips {
              &:not(.ui-slider-disabled) {
                .ui-slider-pip:hover {
                  .ui-slider-label {
                    color: #6a6e72;
                    font-weight: normal;
                  }
                }
              }
              [class*=ui-slider-pip-selected], [class*=ui-slider-pip-initial] {
                color: #6a6e72;
                font-weight: normal;
              }
            }
            .slider_1 {
              .ui-slider-label {
                white-space: nowrap;
              }
              .ui-slider-pip-first {
                .ui-slider-label {
                  width: 6em;
                  text-align: left;
                  padding-left: 5px;
                }
              }
              .ui-slider-pip-last {
                .ui-slider-label {
                  margin-left: -4em;
                }
              }
            }
            .slider_2 {
              .ui-slider-pip-last {
                .ui-slider-label {
                  margin-left: -5em;
                }
              }
            }
            .slider_3 {
              .ui-slider-pip-last {
                .ui-slider-label {
                  margin-left: -7em;
                  @media (max-width: 767px) {
                    margin-left: -4em;
                  }
                }
              }
              @media (max-width: 767px) {
                .ui-slider-label {
                  white-space: normal;
                }
              }
            }
            .slider_4 {
              .ui-slider-label {
                margin-left: -4em;
              }
              @media (max-width: 767px) {
                .ui-slider-label {
                  white-space: normal;
                  width: 3.5em !important;
                  margin-left: -1em;
                }
              }
              .ui-slider-pip-last {
                .ui-slider-label {
                  margin-left: -4em;
                  @media (max-width: 767px) {
                    margin-left: -2em;
                  }
                }
              }
              .ui-slider-pip-first {
                .ui-slider-label {
                  margin-left: -1em;
                }
              }
            }
          }

        }
        .box-note {
          padding: 30px;
          background-color: #b2c946;
          h6 {
            font-size: 18px;
            font-family: "Lato";
            font-weight: bold;
            color: #282e34;
          }
          p {
            margin-bottom: 0;
            padding: 0;
            padding-top: 10px;
            color: #282e34;
            font-weight: normal;
            font-style: normal;
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &.account {
        .item-question {
          label.error {
            bottom: -25px;
            .for-default {
              display: none;
            }
            .for-account {
              display: block;
            }
          }
        }
      }
      &.account {
        .item-question {
          .form-group {
            position: relative;
            span.error {
              bottom: -27px;
              span {
                display: none;
                &.for-account {
                  display: block;
                }
                &.for-myaccount {
                  display: none;
                }
                &.for-default {
                  display: none;
                }
              }
            }
          }
        }
        #question_item_term_conditions {
          padding-bottom: 30px;
          span.error {
            bottom: 4px;
            span {
              display: none;
              &.for-account {
                display: block;
              }
              &.for-myaccount {
                display: none;
              }
              &.for-default {
                display: none;
              }
            }
          }
        }
      }
    }
    .box-continue {
      padding: 60px 0;
      background-color: #f1f2f2;
      display: none;
    }
    .box-process {
      text-align: center;
      background-color: #F2F3F3;
      padding-bottom: 30px;
      padding-top: 25px;
      display: none;
      h3 {
        font-size: 28px;
        font-family: "PT Sans";
        color: #292F35;
      }
      #bluecircle {
        margin: 0 auto;
        margin-top: 20px;
        float: none;
        display: inline-block;
        span {
          transform: translateY(-50%);
          h2 {
            font-size: 52px;
            font-family: "Lato";
            font-weight: bold;
            color: $color-strong-blue;
            line-height: 0;
            display: inline-block;
            sup {
              font-size: 20px;
              font-family: "Lato";
              font-weight: bold;
              color: $color-strong-blue;
              opacity: .8;
              top: -20px;
            }
          }
          span {
            font-size: 12px;
            font-family: "Lato";
            font-weight: bold;
            color: $color-strong-blue;
            display: block;
            margin-top: 15px;
          }
        }
      }
      #question_percircle {
        position: relative;
        height: 277px;
        svg {
          width: 274px;
          path {
            -webkit-transition: all .3s; // Chrome
            -moz-transition: all .3s; // Mozilla
            -o-transition: all .3s; // Opera
            transition: all .3s;
          }
        }
        &.active {
          svg {
            path {
              display: block;
            }
          }
        }
        .text {
          position: absolute;
          top: 50%;
          text-align: center;
          width: 100%;
          display: block;
          margin-top: -20px;
          h2 {
            font-size: 60px;
            font-family: "Lato";
            font-weight: bold;
            color: #177bbe;
            line-height: 0;
            display: inline-block;
            sup {
              font-size: 20px;
              font-family: "Lato";
              font-weight: bold;
              color: #177bbe;
              opacity: .8;
              top: -20px;
            }
          }
          span {
            font-size: 12px;
            font-family: "Lato";
            font-weight: bold;
            color: #177bbe;
            display: block;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .question-progress {
    background-color: #F2F3F3;
    padding: 30px 0;
    .title {
      font-size: 20px;
      color: $color-strong-blue;
      font-weight: bold;
      margin-bottom: 20px;
      height: 24px;
      opacity: 1;
      visibility: visible;
      -webkit-transition: all .3s; // Chrome
      -moz-transition: all .3s; // Mozilla
      -o-transition: all .3s; // Opera
      transition: all .3s;
      .left {
        height: 24px;
      }
      .right {
        height: 24px;
      }
    }
    .question-progress-bar-full {
      height: 20px;
      background-color: #CCC;
      border-radius: 10px;
      position: relative;
      .question-progress-bar {
        position: absolute;
        height: 20px;
        background-color: $color-strong-blue;
        border-radius: 10px;
        width: 0;
        -webkit-transition: all .3s; // Chrome
        -moz-transition: all .3s; // Mozilla
        -o-transition: all .3s; // Opera
        transition: all .3s;
      }
    }
    &.fixedsticky {
      z-index: 3;
      top: 140px;
      @media (max-width: 767px) {
        top: 50px;
      }
      .title {
        margin-bottom: 0px;
        height: 0px;
        opacity: 0;
        visibility: hidden;
        .left {
          height: 0px;
        }
        .right {
          height: 0px;
        }
      }
    }
  }
}