/*Loader*/
.loading .loading-bg {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.loading .loading-bg .progress-ring {
  position: absolute;
  padding-top: 12px;
  width: 60px;
  height: 60px;
  margin-left: -20px;
  margin-top: -20px;
  left: 50%;
  top: 50%;
  z-index: 2001;
}
.loading .loading-bg .progress-ring .progress-ring__wrap {
  position: absolute;
  width: 58px;
  height: 58px;
}
.loading .loading-bg .progress-ring .progress-ring__wrap .progress-ring__circle {
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 4000ms;
  width: 58px;
  height: 58px;
  opacity: 0;
}
.loading .loading-bg .progress-ring .progress-ring__wrap .progress-ring__circle:after {
  content: '';
  position: absolute;
  width: 7.5px;
  height: 7.5px;
  border-radius: 7.5px;
  box-shadow: 0px 0px 5% #fff;
  background: #fff;
  /* Pick a color */
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(2) {
  transform: rotate(-14deg);
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(2) .progress-ring__circle {
  animation-delay: 133.33333333ms;
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(3) {
  transform: rotate(-28deg);
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(3) .progress-ring__circle {
  animation-delay: 266.66666667ms;
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(4) {
  transform: rotate(-42deg);
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(4) .progress-ring__circle {
  animation-delay: 400ms;
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(5) {
  transform: rotate(-56deg);
}
.loading .loading-bg .progress-ring .progress-ring__wrap:nth-child(5) .progress-ring__circle {
  animation-delay: 533.33333333ms;
}
@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  35% {
    transform: rotate(495deg);
    animation-timing-function: ease-in-out;
  }
  42% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(835deg);
    opacity: 1;
    animation-timing-function: linear;
  }
  76% {
    opacity: 1;
  }
  77% {
    transform: rotate(955deg);
    animation-timing-function: ease-in;
  }
  78% {
    transform: rotate(955deg);
    opacity: 0;
  }
  100% {
    transform: rotate(955deg);
    opacity: 0;
  }
}
/*Loader*/