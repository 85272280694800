.header-quiz {
  @include bg-image('theme/background-title.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .title-header-page {
    padding: 70px 0;
    text-align: center;
    h1 {
      display: inline;
      padding-left: 120px;
      font-family: "PT Sans", sans-serif;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
      position: relative;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        @include bg-picture('icon-quiz.png');
        background-size: 100%;
        width: 100px;
        height: 100px;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}
.box-content-quiz{
  min-height: 400px;
}