.main-content-article {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

$home-upper-height: 520px;
.safari {
  .home-upper-wrapper {
    margin-top: -5px;
    .box-text-intro {
      .box-button {
        .btn-info, .btn-back {
          @media (min-width: 768px) and (max-width: 992px) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .box-home {
    .box-home-info {
      @media (min-width: 768px) and (max-width: 992px) {
        button {
          font-size: 14px;
          &.btn-info {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.home-upper-wrapper {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat no-repeat;
  position: relative;
  width: 100%;
  @include bg-image('bacnkground-home-upper-wrapper.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .box-text-intro {
    padding: 50px;
    text-align: center;
    color: $default;
    h2 {
      font-size: 44px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
      margin-bottom: 0;
    }
    h3 {
      color: $default;
      font-size: 44px;
      font-weight: normal;
      font-family: "PT Sans", sans-serif;
    }
    h5 {
      padding: 20px 0;
      font-size: 28px;
      font-weight: bold;
      font-family: "PT Sans", sans-serif;
    }
    p {
      font-size: 18px;
      font-weight: normal;
      font-family: "PT Sans", sans-serif;
      a {
        font-weight: bold;
        color: $default;
      }
    }
    .box-button {
      padding: 20px 0;
      .btn-info {
        float: right;
        line-height: 35px;
        background: transparent;
        color: $default;
        border: 1px solid $default;
        font-weight: 500;
        font-family: $font-family;
        font-size: 16px;
        font-weight: 900;
        .gizmo-play {
          background-size: 25px 25px;
          background-repeat: no-repeat;
          width: 25px;
          height: 25px;
          margin-right: 0px;
          margin-bottom: 2px;
        }
        &:hover {
          background-color: #ffffff;
          color: #177bbe;
        }
      }
      .btn-back {
        float: left;
        background: #282e34;
        color: $default;
        font-family: $font-family;
        font-weight: 500;
        font-size: 16px;
        font-weight: 900;
        line-height: 35px;
        &:hover {
          background-color: #33393e;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .box-button {
        padding: 20px 0 60px 0;
        .btn-info, .btn-back {
          float: none;
        }
      }
    }
  }
  @media only screen and (min-width: 530px) and (max-width: 992px) {
    .box-text-intro {
      padding: 50px 10px;
      .box-button {
        .btn-info, .btn-back {
          margin-top: 10px;
          font-size: 20px;
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 530px) {
    .box-text-intro {
      padding: 50px 10px;
      .box-button {
        .btn-info, .btn-back {
          margin-top: 10px;
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }
}

.box-home-intro-image {
  width: 100%;
  margin: 0 auto;
  clear: both;
  position: relative;
  padding: 0;
  margin-top: -30px;
  z-index: 2;
  .col-33 {
    @media (min-width: 1200px) {
      width: 400px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      width: 33.333339%;
    }
    float: left;
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    .col-33 {
      display: none;
    }
  }
  .button-arrow {
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -12px;
    cursor: pointer;
    @include bg-image('app/round-arrow.png');
  }
}

.box-home {
  padding-left: 0;
  padding-right: 0;
  &.background-gray {
    background-color: #f1f2f2;
  }
  .box-home-about.background-green {
    background-color: #f1f2f2;
    button.btn-info {
      i {
        margin-left: 10px;
      }
    }
  }
  .box-home-about {
    padding: 60px;
    .box-hcp {
      padding-left: 0;
      padding-right: 30px;
      @media only screen and (max-width: 992px) {
        padding-right: 6px;
      }
      @media only screen and (max-width: 767px) {
        padding-right: 0px;
        margin-bottom: 30px;
      }
    }
    .box-careplan {
      padding-left: 30px;
      padding-right: 0;
      button.btn-info {
        position: relative;
        @media only screen and (max-width: 480px) {
          line-height: 1.5;
        }
        i {
          position: absolute;
          top: 53%;
          right: 10px;
          transform: translateY(-50%);
        }
      }
      @media only screen and (max-width: 992px) {
        padding-left: 6px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 0;
      }
    }
    @media (max-width: 1200px) {
      padding: 40px 20px;
    }
    .box-content-info {
      cursor: pointer;
    }
  }
  .box-home-about, .box-home-info {
    text-align: center;
    h2 {
      color: $color-strong-blue;
      margin-bottom: 40px;
      font-size: 36px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold;
    }
    h3 {
      font-size: 28px;
      color: $color-very-dark;
      font-family: "PT Sans", sans-serif;
    }
    p {
      padding: 0 15px;
      font-size: 18px;
    }
    .box-img {
      padding: 30px;
      img {
        display: inline-block;
        width: 100px;
      }
    }
    button {
      margin-top: 30px;
      font-size: 16px;
    }
  }
  .box-home-info {
    h3 {
      font-size: 28px;
      font-family: "PT Sans", sans-serif;
    }
    p {
      font-size: 16px
    }
    .border-box-content {
      border: 1px solid #ececed;
      padding: 40px 30px;
      border-radius: 3px;
      cursor: pointer;
    }
    button {
      margin-top: 40px;
      font-size: 16px;
      @include lato;
      &.btn-info {
        background: #dce5e8;
        border: none;
        color: $color-strong-blue;
        position: relative;
        &:hover,&:active,&:focus,&:visited {
          background: #cee0e6;
          border: none;
          color: $color-strong-blue;
        }
        p {
          padding: 0;
        }
        i {
          margin-left: 0px;
          font-size: 13px;
        }
        .box-icon-chevron-right {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .box-home-what-best {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    .box-what-best-img {
      padding: 0;
      height: 370px;
      background-size: cover;
      background-position: left;
      /*img {
        width: 100%;
        height: 100%;
      }*/
    }
    .box-what-best {
      @include bg-image('app/home-img/bacnkground-box-what-best.png');
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      padding: 50px 60px;
      color: #fff;
      overflow: hidden;
      p {
        font-size: 18px;
        width: 100%;
      }
      h2 {
        padding: 20px 0;
        font-size: 28px;
        font-family: "PT Sans", sans-serif;
        font-weight: bold;
      }
      .btn-what-best {
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        color: #fff;
        i {
          margin-left: 0px;
        }
        &:hover {
          background-color: #ffffff;
          color: $color-strong-blue;
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      .box-what-best {
        padding: 35px 20px;
      }
      .box-what-best {
        p {
          font-size: 18px;
          width: 100%;
        }
      }
    }
  }
  .box-home-text {
    .box-text {
      margin-top: 50px;
      padding: 40px;
      border: 1px solid #ececed;
      border-radius: 3px;
      .box-text-title {
        position: relative;
        h4 {
          color: $color-very-dark;
          padding-left: 40px;
          line-height: 40px;
          font-size: 22px;
          font-weight: bold;
        }
        .img-box-text-title {
          top: 10px;
          left: 0;
          position: absolute;
          width: 23px;
          height: 23px;
        }
      }
      ul {
        padding: 0;
        li {
          position: relative;
          color: $color-dark-grayish-blue;
          line-height: 26px;
          font-size: 16px;
          padding-left: 40px;
          &:before {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            transform: translateY(-50%);
            height: 15px;
            width: 15px;
            @include bg-image('app/arrow/ticked.png');
            background-size: contain;
          }
        }
      }
    }
    @media only screen and (max-width: 547px) {
      .box-text {
        padding: 35px 30px;
      }
    }
  }
  .box-home-text-info {
    padding: 50px 15px;
    color: $color-very-dark;
    .box-text {
      h5 {
        font-size: 12px;
        @include lato_black;
        color: $color-text-gray;
      }
      p {
        color: #6a6e72;
        @include lato;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        color: $color-text-gray;
        @media only screen and (max-width: 992px) {
          line-height: 18px;
        }
        @media only screen and (max-width: 480px) {
          line-height: 18px;
        }
        a {
          &:hover {
            color: #177bbe;
            text-decoration: underline !important;
          }
        }
      }

    }
  }
  @media (min-width: 1200px) {
    .box-home-text-info {
      padding: 50px 60px;
    }
    .box-home-text {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    .box-home-text-info {
      padding: 50px 20px;
    }
    .box-home-text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media (min-width: 1200px) {
  #startHome {
    &.container {
      background-color: #fff;
      box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
      margin-top: -15px;
    }
  }
  &.box-home-intro-image {
    box-shadow: 7px 0 9px -7px rgba(0, 0, 0, 0.2), -7px 0 9px -7px rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (max-width: 767px) {
  .box-home {
    padding-left: 20px;
    padding-right: 20px;
    .box-home-about {
      padding: 40px 0;
      .box-hcp {
        padding: 0;
        margin-bottom: 30px;
      }
      .box-careplan {
        padding: 0;
      }
    }
    .box-home-info {
      padding: 0;
      .border-box-content {
        padding: 40px 20px;
      }
      button {
        line-height: 1;
        margin: 0;
      }
    }
    .box-home-what-best {
      text-align: center;
      .box-what-best-img {
        height: 300px;
      }
    }
  }
}

.text-mobile {
  display: none;
}

@media (max-width: 480px) {
  .text-mobile {
    display: inline-block;
  }
  .text-desktop {
    display: none;
  }
}

.page-not-found {
  text-align: center;
  h1 {
    font-size: 22px;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    color: #177bbe;
    padding: 50px 0px 20px 0px;
    border-bottom: 1px solid #e3e2e2;
  }
  p {
    font-size: 22px;
    padding: 30px 0;
  }
}