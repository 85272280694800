.fixedsticky {
  top: 50px;
  z-index: 99;
}

body {
  overflow-y: scroll;
  &.is-active {
    @media (max-width: 992px) {
      overflow: hidden;
    }
  }
}

.master-content {
  padding-top: 140px;
  @media (max-width: 767px) {
    padding-top: 50px;
  }
}

#site-container {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  &.is-active {
    @media (max-width: 992px) {
      position: fixed;
      transform: translateX(-270px);
    }
  }
}

.modal {
  .sn-link-close {
    color: $color-strong-blue;
    font-size: 40px;
    &:hover {
      color: $color-strong-blue;
    }
  }
  .modal-header {
    .close {
      margin-top: -5px;
      text-shadow: none;
    }
  }
}

.page-top {
  width: 100%;
  margin: 0 auto;
  height: 70px;
  line-height: 70px;
  position: relative;
  /*overflow: hidden;*/
  @media only screen and (min-width: 993px) {
    .container {
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 50px;
    line-height: 50px;
  }
  .box-logo-header {
    float: left;
    width: 30%;
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      width: 36%;
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      width: 40%;
    }
    @media only screen and (max-width: 419px) {
      width: 70%;
    }
    .page-top-logo {
      img {
        width: 100%;
      }
      &.mbp-logo {
        padding-right: 0;
        @media only screen and (min-width: 768px) {
          width: 192px;
        }
        img {
          border-right: 1px solid #cbcaca;
          padding-right: 15px;
        }
      }
      &.arthritis-logo {
        @media only screen and (min-width: 768px) {
          width: 96px;
        }
      }
    }
  }
  .box-search-donate {
    float: left;
    width: 45%;
    @media only screen and (max-width: 992px) {
      display: none;
    }
    &.auth {
      width: 60%;
      @media only screen and (min-width: 768px) {
        width: 55%;
      }
    }
    .box-action-search {
      .box-search {
        float: right;
        width: 55%;
        .search {
          line-height: 70px;
          padding: 0 30px;
          text-align: right;
          margin-left: 15px;
          i {
            top: 0;
            right: 15px;
            &.left {
              -webkit-transition: all .5s;
              transition: all .5s;
              right: 85%;
            }
          }
          input {
            background: transparent;
            padding-left: 15px;
          }
        }
      }
      .box-donate {
        float: left;
        width: 45%;
        .top-menu {
          text-align: center;
          p {
            line-height: 70px;
            margin-bottom: 0;
            a {
              font-family: "Lato", Sans-Serif;
              font-size: 16px;
              color: #037bc0;
            }
          }
        }
        .community {
          padding-right: 0;
        }
        .donate {
          padding-left: 0;
        }
      }
    }
  }
  .box-user-profile {
    float: left;
    width: 25%;
    position: relative;
    line-height: normal;
    height: 70px;
    &.auth {
      width: 15%;
      padding: 0 20px;
      background-color: #f3f4f4;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      width: 65%;
    }
    .top-dropdown-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background: $default;
      z-index: 99;
      padding: 0;
      width: 100%;
      li {
        line-height: normal;
        &:hover {
          background: #177bbe;
          a {
            color: $default;
            i {
              -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
            }
          }
        }
        a {
          font-size: 14px;
          font-family: "Lato";
          color: #282e34;
          position: relative;
          padding: 20px 10px 20px 40px;
          display: block;
          &:hover {
            color: $default;
          }
          i {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            &.icon-edit-account {
              height: 18px;
              width: 18px;
              @include bg-image('app/menu-icons/edit-profile.png');
            }
            &.icon-logout {
              height: 18px;
              width: 23px;
              @include bg-image('app/menu-icons/icon-logout.png');
            }
          }
        }
      }
    }
    .al-user-profile {
      line-height: 70px;
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        line-height: 50px;
      }
      .user-drop-down {
        color: #282e34;
        font-size: 16px;
        font-family: "Lato";
        display: inline-block;
        cursor: pointer;
        line-height: normal;
        width: 100%;
        position: relative;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 3.5px 0 3.5px;
          border-color: #797d81 transparent transparent transparent;
          margin: 0 auto;
          position: absolute;
          top: 50%;
          right: 0;
          cursor: pointer;
        }
      }
    }
    .box-action-user {
      display: inline-block;
      width: 100%;
      line-height: 70px;
      background: #f3f4f4;
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        background: transparent;
      }
      .action-login {
        float: left;
        width: 30%;
        @media only screen and (max-width: 992px) {
          display: none;
        }
        p {
          margin-bottom: 0;
          line-height: 70px;
          text-align: center;
          a {
            font-family: "Lato", Sans-Serif;
            font-size: 16px;
            color: #037bc0;
          }
        }
      }
      .action-signup-free {
        float: left;
        width: 70%;
        @media only screen and (min-width: 768px) and (max-width: 992px) {
          width: 80%;
          text-align: right;
        }
        @media only screen and (max-width: 767px) {
          display: none;
        }
        .btn {
          padding: 10px 31px;
          @media only screen and (min-width: 993px) and (max-width: 1199px) {
            padding: 10px 20px;
          }
          font-family: "Lato", Sans-Serif;
          font-size: 16px;
          font-weight: bold;
          color: $default;
        }
      }
    }
  }
}

.search {
  text-shadow: none;
  color: $info;
  font-size: 24px;
  line-height: 24px;
  transition: all 0.5s ease;
  white-space: nowrap;
  position: relative;

  p {
    line-height: 40px;
    height: 100px;
    margin: 0px;
  }

  label {
    cursor: pointer;
  }
  i {
    width: 16px;
    display: inline-block;
    cursor: pointer;
    padding-left: 1px;
    font-size: 24px;
    margin-right: 13px;
    transition: all .5s;
    position: absolute;
    top: 10px;
    right: 0;
    &.left {
      transition: all .5s;
      right: 100%;
    }
  }

  input {
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    line-height: 30px;
    width: 0;
    transition: all .5s;
    &.expand {
      width: 100%;
      border-bottom: 1px solid;
    }

    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $info-light;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $info-light;
      opacity: 1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $info-light;
      opacity: 1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $info-light;
    }

  }
}

/*.safari {
  .top-menu-wrapper {
    .menu {
      float: right;
      height: auto;
      ul {
        float: none;
        line-height: 1.2;
        > li {
          a {
            //padding: 25px 20px 26px;
            padding: 25px 10px;
          }
          li {
            a {
              padding: 25px 20px !important;
            }
          }
        }
      }
    }
  }
}*/
.logged {
  .top-menu-wrapper {
    .menu {
      ul {
        margin: 0px auto;
      }
    }
  }
}
.top-menu-wrapper {
  background-color: #282E34;
  .menu {
    display: block;
    height: 70px;
    > ul {
      > li {
        float: left;
        &:hover,&.active {
          border-bottom: 3px solid #bfd554;
          height: 70px;
        }
      }
    }
    ul {
      margin: 0;
      line-height: 70px;
      float: right;
      z-index: 2;
      padding-left: 0;
      > li {
        list-style: none;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        position: relative;
        height: 70px;
        .sub-menu {
          position: absolute;
          top: 0;
          opacity: 0;
          left: 0;
          display: none;
          min-width: 100%;
          padding: 0;
          background-color: $default;
          transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -ms-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
          li {
            display: block;
            text-align: left;
            margin: 0;
            cursor: pointer;
            &:hover,&:active {
              background-color: #f2f3f3;
            }
            a {
              width: 100%;
              padding: 0 20px 0 20px;
              /*@media only screen and (min-width: 810px) and (max-width: 1026px) {
                padding: 0 15px 0 15px;
              }
              @media only screen and (min-width: 768px) and (max-width: 790px) {
                padding: 0 10px 0 10px;
              }*/
              i {
                color: #bfd554;
                padding-right: 0px;
                height: 20px;
                width: 20px;
                display: inline-block;
                background-position: center center;
                background-repeat: no-repeat no-repeat;
                background-size: contain;
                vertical-align: middle;
                &:before {
                  content: ' ';
                }
              }
            }
          }
        }
        &:hover {
          background-color: #33393e;
          /*border-bottom: 3px solid #bfd554;*/
          .sub-menu {
            top: 100%;
            opacity: 1;
            left: 0;
            z-index: 1;
            margin-top: 3px;
            /*border-top: 3px solid #bfd554;*/
            display: inline-block;
            transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -ms-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1) 0s;
            -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68);
            box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.68);
            li {
              a {
                color: #000;
                &:before {
                  content: "";
                  height: 0;
                }
              }
            }
            &.last {
              right: 0;
              left: auto;
            }
          }
        }
        /*&.active, &:hover {
          > a {
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0;
              height: 3px;
              !*background-color: #bfd554;*!
              !*transition: all .1s ease;*!
              transition: all 0.6s;
              -webkit-transition: all;
              -ms-transition: all 0.6s;
              -moz-transition: all 0.6s;
            }
          }
        }*/
        a {
          display: inline-block;
          color: #ffffff;
          white-space: nowrap;
          position: relative;
          padding: 0 20px 0 20px;
          height: 70px;
          @media only screen and (min-width: 810px) and (max-width: 1026px) {
            padding: 0 13px 0 13px;
          }
          @media only screen and (min-width: 768px) and (max-width: 810px) {
            padding: 0 9px 0 9px;
          }
          font-size: 14px;
          font-family: "Lato", Arial;
          &.sub {
            &:after {
              content: "";
              display: inline-block;
              width: 8px;
              height: 8px;
              border-right: 2px solid #bfd554;
              border-top: 2px solid #bfd554;
              transform: rotate(135deg);
              margin-left: 10px;
            }
          }
          i {
            /*color: $primary;*/
            color: #bfd554;
            padding-right: 20px;
            margin-right: 10px;
            height: 30px;
            width: 30px;
            display: inline;
            background-size: contain;
            background-repeat: no-repeat;
            &:before {
              content: ' ';
            }
          }

          i.ion-android-home {
            @include bg-image('app/menu-icons/home.png');
          }

          i.ion-dashboard {
            @include bg-image('app/menu-icons/dashboard.png');
          }

          i.ion-information-circled {
            @include bg-image('app/menu-icons/abp.png');
          }

          i.ion-ionic {
            @include bg-image('app/menu-icons/treatments.png');
          }

          i.ion-ios-play {
            @include bg-image('app/menu-icons/library.png');
          }
          i.ion-treatment {
            @include bg-image('app/menu-icons/treatment.png');
          }
          i.ion-hcp {
            @include bg-image('app/menu-icons/hcp.png');
          }
          i.ion-guide {
            @include bg-image('app/menu-icons/guide.png');
          }

          i.ion-ios-alarm-outline {
            @include bg-image('app/menu-icons/aboutus.png');
          }

          i.ion-ios-albums {
            @include bg-image('app/menu-icons/help.png');
          }
          i.ion-guide-me {
            @include bg-image('app/menu-icons/guide-me.png');
          }
          i.ion-donate {
            @include bg-image('app/menu-icons/donate.png');
          }
          i.ion-contact {
            @include bg-image('app/menu-icons/contact.png');
          }
          i.ion-do-it-yourself {
            @include bg-image('app/menu-icons/do-it-yourself.png');
          }
          i.ion-for-family-and-friends {
            @include bg-image('app/menu-icons/for-family-and-friends.png');
          }
        }
      }
      @media only screen and (min-width: 992px) and (max-width: 1220px) {
        > li {
          /*margin: 0px 10px;*/
        }
      }
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.fixed {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 89;
  background: $default;
  transform: translateX(0);
  &.is-active {
    @media (max-width: 992px) {
      transform: translateX(-270px);
    }
  }
}

@media (max-width: 767px) {
  .fixed {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;
  }
}

.navbar {
  position: fixed;
  z-index: 91;
  width: 310px;
  height: 100%;
  border-radius: 0;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border: none;
  @media (max-width: 480px) {
    width: calc(100% - 50px);
  }
  &.is-active {
    @media (max-width: 992px) {
      transform: translateX(0);
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .navbar-logo {
        display: block;
      }
    }
  }
  #navbar-nav {
    background-color: #282e34;
    padding-left: 0px;
    margin-bottom: 0;
    li {
      line-height: 50px;
      position: relative;
      border-bottom: 1px solid #535858;
      &.click {
        > a{
          background-color: #464a50;
        }
      }
      a {
      //  -webkit-tap-highlight-color: #464a50;
        &:active, &:focus, &:hover {
          background-color: #464a50;
        }
      }
      &.has-sub {
        &.click {
          > a{
            background-color: transparent;
          }
        }
        span {
          display: inline-block;
          position: absolute;
          top: 20px;
          right: 15px;
          width: 10px;
          height: 10px;
          border-right: 2px solid #bed74b;
          border-top: 2px solid #bed74b;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          transition: all 0.6s;
          -webkit-transition: all 0.6s;
          -ms-transition: all 0.6s;
          -moz-transition: all 0.6s;
          margin-left: 10px;
          &.dropdown_menu {

          }
          &.active {
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            top: 18px;
          }
        }
      }
      a {
        color: #fff;
        display: inline-block;
        padding-left: 15px;
        width: 100%;
      }
      ul {
        padding: 0;
        padding-left: 0px;
        &.sub-menu {
          background: #33383e;
          max-height: 0;
          overflow: hidden;
          transition: all 0.6s;
          -webkit-transition: all 0.6s;
          li {
            position: relative;
            &:active, &:focus {
              background: #464a50;
            }
            &.click {
              background: #464a50;
              &:after {
                content: "";
                height: 100%;
                width: 4px;
                background: #bed74b;
                position: absolute;
                right: 0;
                top: 0;
              }
            }

            &:last-child {
              border-bottom: none;
            }
          }
          i {
            color: $primary;
            padding-right: 0px;
            height: 20px;
            width: 20px;
            display: inline-block;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat no-repeat;
            vertical-align: middle;
            &:before {
              content: ' ';
            }
          }
        }
        &.active {
          display: block;
          height: auto;
          max-height: 500px;
          border-top:1px solid #535858;
        }
        li {

        }
      }
    }
    i {
      color: $primary;
      padding-right: 20px;
      margin-right: 10px;
      height: $menu-height;
      width: 50px;
      display: inline;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat no-repeat;
      &:before {
        content: ' ';
      }
    }

    i.ion-android-home {
      @include bg-image('app/menu-icons/home.png');
    }

    i.ion-information-circled {
      @include bg-image('app/menu-icons/abp.png');
    }

    i.ion-ionic {
      @include bg-image('app/menu-icons/treatments.png');
    }
    i.ion-dashboard {
      @include bg-image('app/menu-icons/dashboard.png');
    }
    i.ion-ios-play {
      @include bg-image('app/menu-icons/library.png');
    }
    i.ion-treatment {
      @include bg-image('app/menu-icons/treatment.png');
    }
    i.ion-donate {
      @include bg-image('app/menu-icons/donate.png');
    }
    i.ion-contact {
      @include bg-image('app/menu-icons/contact.png');
    }
    i.ion-hcp {
      @include bg-image('app/menu-icons/hcp.png');
    }
    i.ion-guide {
      @include bg-image('app/menu-icons/guide.png');
    }

    i.ion-ios-alarm-outline {
      @include bg-image('app/menu-icons/aboutus.png');
    }

    i.ion-ios-albums {
      @include bg-image('app/menu-icons/help.png');
    }
    i.ion-guide-me {
      @include bg-image('app/menu-icons/guide-me.png');
    }
    i.ion-do-it-yourself {
      @include bg-image('app/menu-icons/do-it-yourself.png');
    }
    i.ion-for-family-and-friends {
      @include bg-image('app/menu-icons/for-family-and-friends.png');
    }
  }
  .navbar-logo {
    position: fixed;
    top: 0;
    right: 0;
    width: 310px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
    }
    .box-logo {
      background-color: #f2f3f3;
      .box-logo-menures {
        height: 70px;
        line-height: 70px;
        width: 100%;
        @media (max-width: 767px) {
          height: 50px;
          line-height: 50px;
        }
        .mbp-logo {
          padding-right: 0;
          float: left;
          display: none;
          img {
            width: 158px;
            margin-left: 15px;
            border-right: 1px solid #cbcaca;
            padding-right: 15px;
            padding-left: 15px;
          }
        }
        .arthritis-logo {
          float: left;
          display: none;
          img {
            width: 58px;
            margin-left: 15px;
          }
        }
      }
    }
    display: none;
  }
  .navbar-black {
    margin-top: 0px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    padding-bottom: 100px;
    position: relative;
    background-color: #282e34;
    height: 100%;
    .box-action-dobate {
      background-color: #282e34;
      padding: 20px;
      a {
        padding: 10px;
        color: #ffffff;
        border: 1px solid #535858;
        width: 100%;
        display: block;
        text-align: center;
        line-height: normal;
        font-size: 14px;
        font-family: "Lato";
        font-weight: normal;
      }
    }
    .box-action-menures {
      background: #3e4349;
      position: relative;
      line-height: 70px;
      border-bottom: 1px solid #535858;
      @media (max-width: 767px) {
        line-height: 50px;
      }
      .user-none-login {
        width: auto;
        @media (max-width: 480px) {
          width: calc(100% - 50px);
        }
      }
      .action-signup {
        float: left;
        width: 145px;
        background-color: #177bbe;
        @media (max-width: 480px) {
          width: 55%;
        }
        button {
          border-radius: 0;
          width: 100%;
          background-color: #177bbe;
          font-size: 14px;
          text-transform: uppercase;
          height: 70px;
          padding: 0;
          @media (max-width: 767px) {
            height: 50px;
          }
          vertical-align: inherit;
        }
      }
      .action-login {
        float: left;
        width: 115px;
        @media (max-width: 480px) {
          width: 45%;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          width: 80px;
        }
        background-color: #1a88cc;
        button {
          border-radius: 0;
          width: 100%;
          background-color: #1a88cc;
          font-size: 14px;
          text-transform: uppercase;
          height: 70px;
          padding: 0;
          @media (max-width: 767px) {
            height: 50px;
          }
          vertical-align: inherit;
        }
      }
      .user-logined {
        float: left;
        width: 260px;
        padding-left: 20px;
        background: #282e34;
        @media (max-width: 480px) {
          width: calc(100% - 50px);
        }
        .user-drop-down-res {
          color: $default;
          font-size: 16px;
          font-family: "Lato";
          display: inline-block;
          position: relative;
          cursor: pointer;
          width: 100%;
          line-height: normal;
          i {
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translateY(-50%);
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
            &.icon-logout {
              height: 12px;
              width: 15px;
              @include bg-image('app/menu-icons/icon-logout.png');
            }
          }
        }
      }
      .top-dropdown-menu-res {
        display: none;
        background: #3e4349;
        z-index: 99;
        padding: 0;
        margin-bottom: 0;
        li {
          line-height: normal;
          padding: 15px 20px;
          border-bottom: 1px solid #535858;
          &:first-child {
            border-top: 1px solid #535858;
          }
          &:hover {
            background: #177bbe;
            a {
              color: $default;
              i {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
              }
            }
          }
          a {
            font-size: 14px;
            font-family: "Lato";
            color: $default;
            position: relative;
            padding-left: 30px;
            &:hover {
              color: $default;
            }
            i {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              margin-right: 10px;
              background-size: contain;
              background-repeat: no-repeat;
              &.icon-edit-account {
                height: 18px;
                width: 18px;
                @include bg-image('app/menu-icons/edit-profile.png');
              }
              &.icon-logout {
                height: 18px;
                width: 23px;
                @include bg-image('app/menu-icons/icon-logout.png');
              }
            }
          }
        }
      }
      .action-search {
        float: left;
        width: 50px;
        transition: all .5s;
        text-align: center;
        position: absolute;
        right: 0;
        .search-res {
          color: $default;
          height: 70px;
          @media (max-width: 767px) {
            height: 50px;
          }
          i {
            width: 16px;
            display: inline-block;
            cursor: pointer;
            padding-left: 1px;
            font-size: 24px;
            transition: all .5s;
          }

        }
      }
      .box-form-search-res {
        display: none;
        height: 70px;
        background: #3e4349;
        margin: 0 10px;
        @media (max-width: 767px) {
          height: 50px;
        }
        input {
          border: none;
          outline: none;
          font-size: 16px;
          line-height: 25px;
          color: $default;
          background: transparent;
          width: 100%;
        }
      }
    }
  }
}

.overlay-bg {
  &.is-active {
    @media (max-width: 992px) {
      position: fixed;
      margin-top: 70px;
      @media (max-width: 767px) {
        margin-top: 50px;
      }
      top: 0;
      left: 0;
      z-index: 90;
      background: rgba(0, 0, 0, .5);
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
}

.icon-menu-responsive {
  .navbar-toggle {
    position: absolute;
    z-index: 999;
    top: 0;
    left: -70px;
    margin: 0;
    height: 70px;
    width: 70px;
    background: #f1f1f2;
    padding: 20px;
    cursor: pointer;
    display: none;
    border-radius: 0;
    /*transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -moz-transition: all 0.3s;*/
    @media (max-width: 992px) {
      display: block;
    }
    @media only screen and (max-width: 767px) {
      height: 50px;
      width: 50px;
      padding: 10px;
      left: -50px;
    }
    .icon-bar {
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -moz-transition: all 0.3s;
      background-color: #1b8bcf;
      margin-top: 5px;
      width: 25px;
    }
    &.is-active {
      background: $default;
      -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
      .icon-bar {
        background-color: #1b8bcf;
        &:nth-child(1) {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          margin-top: 13px;
          margin-left: 2px;
          position: absolute;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          margin-top: 13px;
          margin-left: 3px;
        }
      }
    }
  }
}

#frmUpdatetPassword {
  .box-password {
    position: relative;
    strong {
      position: absolute;
      top: 50%;
      right: 15px;
      font-size: 14px;
      cursor: pointer;
      transform: translateY(-50%);
      text-transform: uppercase;
      font-family: "Lato";
      font-weight: normal;
    }
  }
}

#frmUpdatetPassword {
  .form-group {
    margin-bottom: 10px;
  }
}
input[type=text],input[type=email],textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}