h3.with-line {
  border-bottom: 1px solid $border;
  color: $default-text;
  font-weight: $font-normal;
  padding-top: 30px;
  margin-bottom: 14px;
  line-height: 39px;
  width: 100%;
}

.panel .panel-body h3 {
  &.with-line {
    margin-bottom: 14px;
    margin-top: 0;
  }
}

.profile-block {
  background: $default;

  .nav-tabs > li {
    & > a {
      border-radius: 0;
      transition: background, 0s, ease !important;
    }
  }

  .progress {
    border-radius: 0;
    margin-bottom: 6px;
  }

  .progress-info {
    margin-bottom: 8px;
    margin-top: -2px;
  }

  .tab-content {
    padding: 15px 15px 25px;
  }

  .progress-bar-primary {
    background-color: $primary-light;
  }

  .form-group {
    margin-bottom: 14px;
    label {
      text-align: right;
    }
  }

  .form-control {
    font-size: 13px;
  }
}

.notification {
  .form-group {
    label {
      text-align: left;
      padding-left: 26px;
    }
  }
}

.userpic {
  border: 1px dashed #b8b8b8;
  width: 202px;
  height: 202px;
  position: relative;
  cursor: pointer;
  .userpic-wrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  i {
    display: none;
    position: absolute;
    font-size: 32px;
    background: $default;
    cursor: pointer;
    color: $primary;
    top: -11px;
    right: -11px;
    height: 26px;
    border-radius: 50%;
    &:before {
      line-height: 26px;
    }
    &:hover {
      color: $danger;
    }
  }
  a.change-userpic {
    display: none;
    width: 202px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 200ms ease-in-out;
    color: $default-text;
    text-decoration: none;
    position: absolute;
    bottom: -1px;
    left: -1px;
    line-height: 32px;
    text-align: center;
  }
  &:hover {
    i {
      display: block;
    }
    .change-userpic {
      display: block;
    }
  }
}

.save-profile {
  margin: 23px 0 12px 12px;
}

$snColor: $primary;
$snPadding: 8px;
a.sn-link {
  transition: none;
  display: block;
  border-radius: 5px;
  background-color: transparent;
  padding: $snPadding;
  color: $default-text;
  border: 1px solid transparent;
  margin-bottom: 23px;
  width: 100%;
  position: relative;
  cursor: pointer;
  text-decoration: none !important;
  i.socicon {
    padding: 6px;
    border-radius: 50%;
    width: auto;
    font-size: 17px;
    margin-right: 8px;
    background-color: rgba(0,0,0,.15);
    color: $default-text;
  }
  span {

  }

  span {
    font-size: 14px;
    line-height: 29px;
  }

  @mixin activeSn() {
    border-color: $snColor;
    border-width: 2px;
    padding: $snPadding - 1;
    span {
      color: $default-text;
    }
    i {
      background: $snColor;
    }
  }

  &.connected {
    @include activeSn();
    em {
      display: block;
    }
  }
  &:hover {
    @include activeSn();
  }

  .socicon {
    background: transparent;
  }
}

.close {
  opacity: 1;
  box-shadow: none;
  outline: none !important;
  &:hover, &:focus, &:active {
    opacity: 1;
  }
}

.connected .sn-link-close {
  position: absolute;
  top: 1px;
  right: 0;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 32px;
  color: $snColor;
  transition: all 0.1s ease;
  outline: none;
  &:hover {
    color: $danger;
    transform: scale(1.2);
  }
}

.social-profiles {
  padding-top: 3px;
  margin-bottom: -3px;
  padding-left: 12px;
}

.profile-page {
  .form-group {
    label {
      line-height: 34px;
      font-size: 14px;
      font-weight: $font-light;
    }
    input{
      font-weight: $font-light;
    }
  }
}

#my_account {

}