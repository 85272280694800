#page_treatment {
  padding-right: 0 !important;
  .box-popup-evidence {
    .modal-dialog {
      width: 740px;
      @media (max-width: 767px) {
        width: 92%;
      }
    }
    .box-evidence {
      .item {
        border-bottom: 1px solid #f9f9f9;
        margin-bottom: 20px;
      }

    }
  }
  .header-treatment {
    .title-header-page {
      h1 {
        &:before {
          @include bg-picture('icon-treatments.png');
        }
      }
    }
  }
  .main-content-treatment {
    .box-effectiveness-one, .box-effectiveness-tow {
      border: 1px solid #e1e1e2;
      border-radius: 5px;
      margin-bottom: 30px;
      h4 {
        color: #808385;
        padding: 25px 15px;
        padding-right: 0;
        font-size: 18px;
        font-family: "Lato";
        font-weight: bold;
        margin-bottom: 0;
        position: relative;
        display: inline-block;
        white-space: nowrap;
        @media (max-width: 767px) {
          padding-bottom: 5px;
        }
        &:after {
          content: "";
          display: inline-block;
          margin-left: 5px;
          vertical-align: middle;
          height: 20px;
          width: 20px;
          @include bg-image("app/effectiveness/icon-question.png");
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
      .box-status {
        @media only screen and (max-width: 767px) {
          text-align: left;
        }
        img {
          display: inline-block;
        }
      }
    }
    .box-effectiveness-one {
      .box-status {
        text-align: right;
        @media (max-width: 767px) {
          text-align: left;
        }
        img {
          padding: 15px;
        }
      }
      .effectiveness-tow {
        padding: 15px 30px;
        text-align: left;
        .box-status {
          text-align: left;
          float: left;
          margin-bottom: 15px;
          margin-left: 15px;
          &:first-child {
            margin-right: 20px;
            @media (max-width: 420px) {
              margin-right: 0px;
            }
          }
          .item-text {
            width: 80px;
            float: left;
          }
          .item-img {
            float: left;
            vertical-align: bottom;
            img {
              padding: 0;
              padding-left: 5px;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .box-content-feedback {
      border-bottom: 2px solid #e3e2e2;
      button {
        &:hover {
          background-color: $color-strong-blue;
          color: $default;
        }
        &.active {
          background-color: $color-strong-blue;
          color: $default;
        }
      }
      .box-helpful {
        border: none;
      }
      .box-thank {
        h6 {
          padding: 35px 0 0 0;
          font-size: 16px;
          font-family: "Lato", Arial;
          color: #6a6e72;
        }
        .box-feedback-success{
          @media (max-width: 767px) {
            h6{
              padding: 35px 0 35px 35px;
            }
          }
        }
        .box-btn {
          display: block;
          text-align: right;
          .btn-tell-me {
            &:hover {
              background-color: $color-strong-blue;;
              color: $default;
              &:before {
                @include bg-image("app/effectiveness/icon-feed-active.png");
              }
            }
            &.active {
              background-color: $color-strong-blue;;
              color: $default;
              &:before {
                @include bg-image("app/effectiveness/icon-feed-active.png");
              }
            }
            margin: 20px 0;
            padding: 8px 15px;
            padding-left: 40px;
            background-color: transparent;
            border: 1px solid $color-strong-blue;
            font-size: 14px;
            font-family: "Lato", Arial;
            font-weight: bold;
            color: $color-strong-blue;
            margin-right: 0;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
              width: 13px;
              height: 11px;
              @include bg-image("app/effectiveness/icon-feed.png");
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
      .box-comment {
        padding: 20px;
        background: rgba(16, 18, 20, 0.05);
        h4 {
          font-size: 18px;
          font-family: "Lato";
          font-weight: bold;
          color: #292f35;
          display: inline-block;
          &:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 15px;
            @include bg-image("app/effectiveness/icon-feed-black.png");
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            margin-right: 15px;
          }
          &:after {
            content: "";
            display: inline-block;
            width: 13px;
            height: 8px;
            @include bg-image("app/effectiveness/icon-arrow.png");
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            margin-left: 15px;
          }
        }
        form {
          input, textarea {
            margin-bottom: 15px;
          }
          .btn-info {
            padding: 8px 20px;
          }
        }
      }
      .box-feedback-success {
        h6 {
          padding: 35px 0 35px 35px;
          font-size: 16px;
          font-family: "Lato", Arial;
          color: #6a6e72;
          @include bg-image("app/effectiveness/icon-success.png");
          background-repeat: no-repeat;
          background-size: 25px;
          background-position-y: 32px;
        }
      }
      @media only screen and (max-width: 767px) {
        .box-thank {
          h6 {
            padding: 30px 0 10px 0;
            background-position-y: 30px;
          }
          .box-btn {
            text-align: left;
            .btn-tell-me {
              margin: 0 0 30px;
            }
          }
        }
      }
    }
  }
  .box-table-content{
    table{
      h2{
        margin-bottom: 0;
        text-transform: uppercase;
        color: #FFF;
        font-family: "Lato";
        font-size: 18px;
        font-weight: 900;
      }
      tr{
        &:first-child{
          background: #247BBB;
        }
        td{
          line-height: 20px;
          padding: 15px;
          text-align: left;
          width: 50%;
        }
      }
    }
  }
}