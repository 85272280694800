/* include public class */
.pos-re {
  position: relative !important;
}

.pos-ab {
  position: absolute !important;
}

.full-hieght {
  height: 100% !important;
}

.img-height-responsive {
  max-height: 100%;
  width: auto;
  display: inline-block;
}

/* include public class */
#FAQs {
  background-color: #f2f3f3;
  .header-page {
    .title-header-page {
      padding: 65px 0;
      text-align: center;
    }
    h1 {
      &:before {
        @include bg-picture('icon-faq.png');
      }
    }
    @media (max-width: 480px) {
      h1 {
        font-size: 22px;
        padding-left: 75px;
        &:before {
          width: 70px;
          height: 70px;
        }
      }
    }
    @media (max-width: 340px) {
      h1 {
        font-size: 18px;
        padding-left: 75px;
        &:before {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  .list-asked {
    padding: 60px 0;
    .list-current-asked {
      background-color: #fff;
      position: relative;
      text-align: center;
      box-shadow: 0px 0px 10px #e3e4e4;
      padding: 30px 20px;
      .img-current-asked {
        text-align: center;
        height: 110px;
        overflow: hidden;
        display: inline-block;
      }
      .title-current-asked {
        font-size: 20px;
        margin: 30px 0;
        font-weight: 700;
        font-family: "PT Sans", sans-serif;
        color: #282e34;
        min-height: 53px;
      }
      .button-current-asked {
        background-color: #f6f7f8;
        padding:20px 26px 23px;
        color: #037bc0;
        border-radius: 5px;
        display: inline-block;
        font-size: 14px;
        @include lato();
        position: relative;
        white-space: nowrap;
        @media only screen and (min-width: 768px) and (max-width: 992px) {
          padding:20px 15px 20px;
        }
        i {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          @media only screen and (min-width: 768px) and (max-width: 992px) {
            right: 10px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      .list-current-asked {
        padding: 30px 10px;
      }
    }
  }
  .top-rated-questions {
    background-color: #fff;
    padding-top: 65px;
    padding-bottom: 100px;
    .title-rated-questions {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 700;
      @include lato();
      color: #282e34;
    }
    .current-top-rated-question {
      margin-bottom: 25px;
      a {
        font-size: 16px;
        color: #037bc0;
        &:hover, &:focus {
          text-decoration: underline !important;
        }
      }
    }
  }
  .list-post-FAQs {
    .box-post-title-FAQs {
      .title-post-FAQs {
        @include PT_San();
        font-size: 20px;
        color: $color-strong-blue;
      }
      .button-show-content {
        color: $color-strong-blue;
        border: 1px solid $color-strong-blue;
        height: 35px;
        width: 35px;
        padding: 0;
        position: relative;
        float: right;
        cursor: pointer;
        &.show {
          &:after {
            content: "";
            height: 0;
            width: 0;
            background-color: transparent;
          }
        }
        &:before {
          content: "";
          width: 15px;
          height: 2px;
          background-color: $color-strong-blue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:after {
          content: "";
          height: 15px;
          width: 2px;
          background-color: $color-strong-blue;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);;
        }
      }
    }
  }
  @media (max-width: 1200px) and (min-width: 320px) {
    .container {
      width: 100%;
      padding: 40px 20px;
    }
    .top-rated-questions {
      padding: 0;
    }
    .box-main-content-page {
      .container {
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .list-asked {
      padding: 0;
      margin-right: -10px;
      margin-left: -15px;
    }
  }
  @media (max-width: 547px) {
    .list-asked > div {
      width: 100%;
      .list-current-asked {
        padding: 30px 10px;
        .container {
          padding: 40px 20px;
        }
      }
    }
  }
}