.modal-backdrop  {
  z-index: 100;
  ~ .modal-backdrop {
    z-index: 1051;
  }
}

.modal-content {
  border-radius: 0;
  border: none;
  color: $dropdown-text;
  iframe {
    margin-bottom: -4px;
  }
}

.modal-header {
  color: $label-text;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  .modal-title {
    color: $dropdown-text;
  }
}

.modal-buttons {
  margin-top: -12px;
  margin-right: -22px;
  button {
    float: left;
    margin-right: 24px;
    margin-top: 12px;
  }
  &.same-width {
    button {
      width: 160px;
      text-align: center;
    }
  }
}

$modal-input-border: #e7e7e7;

.modal {
  text-align: center;
  .modal-dialog {
    display: inline-block;
    text-align: left;
    margin: 0 auto;
    vertical-align: middle;
    .modal-body p, .modal-body div, .modal-body span {
      color: $dropdown-text;
    }
  }
  .modal-header {
    border: none;
    .close {
      margin-top: -5px;
    }
  }
  .modal-footer {
    border: none;
    padding-top: 0;
  }
  .modal-icon {
    margin-right: 3px;
  }
  .sn-link-close {
    color: $dropdown-text;
    font-size: 30px;
    &:hover {
      color: $danger;
    }
  }
}

#box_popup_evidence {
  .modal-dialog {
    width: 740px;
    .modal-content.modal-favourite {
      padding: 50px;
      position: relative;
      .modal-header {
        position: absolute;
        top: 15px;
        right: 15px;
        .sn-link-close {
          color: #177bbe;
        }
      }
      .main-content {
        h2 {
          font-family: "PT Sans", Arial;
          font-weight: bold;
          font-size: 28px;
          color: #177bbe;
        }
        p {
          margin-bottom: 0;
          padding-bottom: 20px;
          border-bottom: 1px solid #f9f9f9;
        }
      }
      .box-evidence {
        .item {
          padding-top: 20px;
          border-bottom: 1px solid #f9f9f9;
          p {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .modal-dialog {
      width: 660px;
      .modal-content.modal-favourite {
        padding: 50px 30px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .modal-dialog {
      width: 290px;
      .modal-content.modal-favourite {
        padding: 50px 15px;
      }
    }
  }
}

.modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}


@media screen and (min-width: $resM) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-auth {
  .modal-header {
    padding-bottom: 0;
  }
  .modal-body {
    &.modal-success {
      .content-auth {
        h2 {
          text-align: left;
          margin-bottom: 30px;
        }
        p {
          text-align: left;
          margin-bottom: 30px;  
        }
      }
    }
    padding: 50px;
    .content-auth {
      h2 {
        color: $color-strong-blue;
        font-size: 24px;
        font-family: "Lato", Arial;
        text-align: center;
        font-weight: bold;
      }
      p {
        text-align: center;
        font-size: 16px;
      }
      .box-message-reset {
        .message-reset {
          padding: 15px 40px;
          background-color: #a9c600;
          color: $default;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          line-height: 20px;
          margin-bottom: 15px;
          border-radius: 5px;
          &.danger {
            background-color: #F00;
          }
        }
        p {
          text-align: left;
          padding: 15px 40px;
          position: relative;
          background-color: #f4f4f4;
          i {
            position: absolute;
            left: 4%;
            top: 25%;
          }
        }
      }
      .alert-messages {
        padding: 15px 0;
        span {
          color: #f00;
          position: relative;
          padding-left: 25px;
          &:after {
            content: "\00d7";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #ff0000;
            color: #ffffff;
            font-size: 23px;
            line-height: 18px;
            text-align: center;
          }
        }
      }
      #frmResendVerification {
        .alert-messages {
          position: absolute;
          padding: 7px 0;
        }
      }
      .form-group {
        position: relative;
        margin-bottom: 35px;
        label {
          color: $color-very-dark;
          font-size: 14px;
          font-weight: bold;
          &.error {
            position: absolute;
            bottom: -32px;
            color: #f00;
            padding-left: 25px;
            font-weight: normal;
            font-size: 15px;
            .for-default,.for-myaccount {
              display: none;
              color: #F00;
            }
            .for-account {
              display: block;
              color: #F00;
            }
            &:before {
              content: "\00d7";
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #ff0000;
              color: #ffffff;
              font-size: 20px;
              line-height: 18px;
              text-align: center;
              font-weight: normal;
            }
          }
        }
        .form-control {
          height: 50px;
          &:focus {
            border-color: $color-strong-blue;
          }
        }
      }
      .checkbox {
        padding: 15px 0;
        .custom-checkbox {
          span {
            &:before {
              color: $color-strong-blue;
              border-radius: 4px;
              height: 20px;
              line-height: 18px;
              min-width: 20px;
            }
            &:hover {
              &:before {
                border-color: $color-strong-blue;
              }
            }
          }
          #closeButton:checked ~ span:before {
            border-color: $color-strong-blue;
          }
        }
      }
      .button-auth {
        .option-auth {
          p {
            text-align: right;
            a {
              color: $dropdown-text;
              &:hover {
                color: $color-very-dark;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 547px) {
      padding: 20px;
      .content-auth {
        .button-auth {
          .option-auth {
            margin-top: 30px;
            p{
              text-align: left;
            }
          }
        }
      }
    }
  }
}